import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
@Injectable({
    providedIn: 'root'
})
export class NotesService {
    constructor(public http: HttpClient, public errorService: ErrorService, public global: Globalservice) {
    }
    public  SaveUpdateEvvNotes(data):Observable<any> {
        let url="api/EvvNotes/SaveUpdateEvvNotes"
        return this.http.post(url,data).pipe(catchError(this.errorService.handleError));
      }    
      public GetEvvNotes(param: URLSearchParams): Observable<any> {
        let url = "api/EvvNotes/GetEvvNotes?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
    public DeleteEvvNotes(param: URLSearchParams): Observable<any> {
        let url = "api/EvvNotes/DeleteEvvNotes?"
        return this.http.delete(url + param).pipe(catchError(this.errorService.handleError));
    }
}








