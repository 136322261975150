import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { HomemakingCarePlanBO, HomeMakingExpandingBo, HomeMakingSignatureBo } from './homemaking-care-plan.model';
import { HomeMakingService } from './homemaking-care-plan.service';
@Component({
  selector: 'app-homemaking-care-plan',
  templateUrl: './homemaking-care-plan.page.html',
  styleUrls: ['./homemaking-care-plan.page.scss'],
})
export class HomemakingCarePlanPage implements OnInit {
 clientId: any;
  getHomeMakingList: HomemakingCarePlanBO = new HomemakingCarePlanBO();
  sign: HomeMakingSignatureBo = new HomeMakingSignatureBo();
  isExpand: HomeMakingExpandingBo = new HomeMakingExpandingBo();
  selectedHomeMakingcareplan: any;
  HomeMakingCarePlans: any;
  ShowHomeMakingCarePlans: boolean = false;
  constructor(private activatedRoute: ActivatedRoute, private router: Router,public homeMakingService: HomeMakingService, public global: Globalservice, public errorService: ErrorService, public PopupController: ModalController,
    public datePipe: DatePipe,) {
      this.global.checkingLogin();
      // this.activatedRoute.queryParams.subscribe(params => {
      //   if (params && params.HomeMakingcare) {
      //     this.clientId = JSON.parse(params.HomeMakingcare);
      //   }
      // });
     }
  ngOnInit() {
  }

  async ionViewWillEnter() {
    this.clear();
    await this.global.checkRefreshTokenValidity()
    {
    this.gethomeMakingCareplanDateDropdown();
    }
  }
  //......for date dropdown.................................................................
  async gethomeMakingCareplanDateDropdown() {
   
    let param = new URLSearchParams();
    param.append('ClientId', this.global.clientid.toString())
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.homeMakingService.gethomeMakingCareplanDateDropdown(param).subscribe((data: any) => {
      this.HomeMakingCarePlans = data;
     
      if(data.length==0)
      {
        this.global.Toastdanger("No Records Found");
      }
      this.global.disableloading();
      this.HomeMakingCarePlans?.forEach(element => {
        element.Value = this.datePipe.transform(element.Value, "MM-dd-yyyy")
      });
    }, err => {
      this.errorService.check(err);
      this.global.disableloading();
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  // .......onChangeEvent..................................................................
  onChangeEvent() {
    if (this.selectedHomeMakingcareplan != null) {
      this.gethomemakingcarePlanDetails();
    }
  }
  //..................gethomemakingcarePlanDetails...........................................................
  async gethomemakingcarePlanDetails() {
   
    let param = new URLSearchParams();
    param.append('id', this.selectedHomeMakingcareplan)
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.homeMakingService.gethomemakingcarePlanDetails(param).subscribe((data: any) => {
      this.getHomeMakingList = JSON.parse(JSON.stringify(data));
      this.getHomeMakingList.serviceactivity = data.serviceactivity;
      this.getHomeMakingList.pOCConsultation = data.pocConsultation;
      this.getHomeMakingList.pOCDevelopedby = data.pocDevelopedby;
      this.getHomeMakingList.hMKOrientedClientBy = data.hmkOrientedClientBy;
      this.getHomeMakingList.pOCDevelopedDate = data.pocDevelopedDate;
      this.getHomeMakingList.hMKOrientedDate = data.hmkOrientedDate;
      this.getHomeMakingList.hMKCorrdinatorSignDate = data.hmkCorrdinatorSignDate;
      this.sign.Hmksign = data.hmkCoordinatorSignUrl;
      this.sign.HomeMakingsign = data.homeMakerSignUrl;
      this.sign.Clientsign = data.clientSignUrl;
      this.sign.Rpsign = data.responsiblePartySignUrl;
      this.ShowHomeMakingCarePlans = true;
      this.clearweightheight();
      this.global.disableloading();
    }, err => {
      this.errorService.check(err);
      this.ShowHomeMakingCarePlans = false;
      this.global.disableloading();
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  //............... Back...............................................................................
  Back() {
    this.clear();
    this.router.navigateByUrl('tabs/my-clients');
  }

  clear()
  {
    this.isExpand=new  HomeMakingExpandingBo();
    this.sign=new HomeMakingSignatureBo();
    this.getHomeMakingList=new HomemakingCarePlanBO();
    this.ShowHomeMakingCarePlans = false;
    this.selectedHomeMakingcareplan = null;
  }
  clearweightheight() {
    if (this.getHomeMakingList.weight == 0 || this.getHomeMakingList.weight == null || this.getHomeMakingList.weight == undefined) {
      this.getHomeMakingList.weight = null;
    }
    if (this.getHomeMakingList.height == 0 || this.getHomeMakingList.height == null || this.getHomeMakingList.height == undefined) {
      this.getHomeMakingList.height = null;
    }
  }
}
