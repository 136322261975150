import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { ClientSignAllTimesheetService } from '../client-sign-all-timesheet/client-sign-all-timesheet.service';
import { Activity, MyClient, Services } from '../my-employee/my-employee.model';
import { MyEmployeesService } from '../my-employee/my-employee.service';
import { ClientTimesheetService } from './client-timesheet.service';

@Component({
  selector: 'app-client-timesheet',
  templateUrl: './client-timesheet.page.html',
  styleUrls: ['./client-timesheet.page.scss'],
})
export class ClientTimesheetPage {

  clientData: any;
  serviceName: string = "";
  activitiesList: Array<Activity> = [];
  startTime: string = null;
  endTime: string = null;
  customPickerOptions: any;
  clientSign: string = null;
  employeeSign: string = null;
  clientId: number;
  serviceId: number;
  totalHours: string;
  isSubmitButton: boolean = true;
  backto: string = "";
  scheduleId: number;
  type: string;
  notes: string="";
  isRP: boolean = false;
  isDifferentlyAbled:boolean=false;
  evvTimesheetId:number=0;
  idList: any =[];
  returnData: any;
  completed: boolean = false;
  constructor(public clientService: MyEmployeesService, public errorService: ErrorService, public timesheetService: ClientTimesheetService,
    private router: Router, private route: ActivatedRoute, public global: Globalservice, public http: ClientSignAllTimesheetService, 
    private toastCtrl: ToastController, public general:generalservice,public platform: Platform,public alertController: AlertController) {
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
     
        this.type = data.type;
        this.clientId = data.clientId;
        this.serviceId = data.serviceId;
        this.startTime = data.startTime;
        this.endTime = data.endTime;
        this.clientSign = data.clientSign;
        this.employeeSign = data.employeeSign;
        this.isSubmitButton = data.isSubmitButton;
        // this.backto = data.backto;
        this.backto='client-all-timesheets'
        this.scheduleId = data.scheduleId;
        this.isRP = data.isRP!=null?data.isRP:false
        this.evvTimesheetId=data.evvTimesheetId;
        this.notes=this.notes!=""?this.notes:data.notes;
        this.returnData=data.returnData;
        if (this.type == "sign") {
          this.activitiesList = data.activity;
        }
      
        let totalseconds = (new Date(this.endTime).getTime() - new Date(this.startTime).getTime()) / 1000;
        this.totalHours = ((totalseconds) / (60 * 24)).toFixed(2);
      }
    });
    this.global.checkingLogin();
    this.customPickerOptions = {
      buttons: [{
        text: 'Cancel',
        handler: () => {
      
        }
      }, {
        text: 'Save',
        handler: () => {
       
        }
      }]
    }
  }

  async ionViewWillEnter() {
       ;
    this.clientData = null;
    this.serviceName = "";
   
    await this.global.checkRefreshTokenValidity()
    {
if(this.type!=undefined||this.type!=null)
{
  this.getTimesheet();
}
this.verifyTSCreation();

    }

    // if (this.type != "signed") {
    //    this.getActivities();
    //  }

    // //totalHours this.getService();
  }
  
  selected: boolean = false;
  VerifyActivities(activities){
    this.selected = false;
    activities.forEach( data => {
      if(data.isSelected == true){
        this.selected=true;
      }
    });
    return this.selected;
  }

  TsType: any;
  createInputs() {
    const theNewInputs = [];
    for (let i = 0; i < this.activitiesList.length; i++) {
        theNewInputs.push(
          {
            type: 'checkbox',
            label: this.activitiesList[i].value,
            value: '' + i,
            checked: this.activitiesList[i].isSelected,
            disabled: true,
            handler: data => {
             
              if(data.label==this.activitiesList[i].value){
                this.activitiesList[i].isSelected=data.checked;
              }
            
            }
          }
        );
      
    }
    return theNewInputs;
  }
  async SelectActivites(){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Activities',
      backdropDismiss: false,
      inputs: this.createInputs(),
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          
          cssClass: 'secondary',
          handler: () => {
           
          }
        }, {
          text: 'Ok',
          handler: () => {
           
         
          }
        }
      ]
    });
    await alert.present();
  }
  async getTimesheet() {
    //this.clientId=4;
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("scheduleId", this.evvTimesheetId.toString())
    param.append("clientId",  this.global.EvvUserDetails.entityId.toString())
    await this.global.showLoading();
    this.timesheetService.getTimesheet(param).subscribe((data: any) => {
   
  
      this.clientData = data;
      this.isDifferentlyAbled=  this.clientData.isDifferentlyAbled;
      this.clientData.mobileNumberFormat= this.clientData.mobileNumber!=null?this.general.converPhoneGoogleLib(this.clientData.mobileNumber.toString()):null
           this.serviceName = data.service
      this.startTime = data.startDate
      this.endTime = data.endDate
      this.notes=data.notes
      this.isRP=data.isRP
      let valweeks = data.totalHours.toString().split('.');
        if (valweeks.length == 1) {
          this.totalHours = valweeks[0] + " hr " + "0 Min";
        }
        else {
          let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
          this.totalHours = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + " Min";
        }
      // let val=data.totalHours.toString().split('.');
      // if(val.length>1)
      // {
      //   this.totalHours = val[0] +" hr "+val[1]+" min";
      // }
      // else{
      //   this.totalHours = val[0] +" hr "+"0"+" min";
      // }
      this.clientSign = data.clientSign!=null?data.clientSign:this.clientSign;
      this.employeeSign = data.employeeSign!=null? data.employeeSign:this.employeeSign;
      this.idList.push(data.evvTimesheetId);
      if (this.type != "sign") 
        {
         
            this.activitiesList = data.activities;
          
            this.isRP = data.isRP!=null?data.isRP:false
        }
        this.VerifyActivities(data.activities);
        this.evvTimesheetId=data.evvTimesheetId;
     
        this.notes=this.notes!=""?this.notes:data.notes;
     
        
        this.global.disableloading()
      
      
      // if (this.type == "sign") {
      //   //this.activitiesList = data.activity;
      //   this.clientSign =  this.clientSign;
      //   this.employeeSign =this.employeeSign;
      // }
   
    },err => {
      this.errorService.check(err);
      this.global.disableloading()
      if(err.Status==401){
        this.global.GloballogOut();
      }
    })
    this.verifyTSCreation();
  }
  }
  async getService() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("Client", this.clientId.toString())
    this.clientService.getServices(param).subscribe((data: Array<Services>) => {
      data.forEach(element => {
        if (element.serviceId == this.serviceId) {
          this.serviceName = element.serviceName;
        }
      });
    }, err => {
      this.errorService.check(err);
      if(err.Status==401){
        this.global.GloballogOut();
      }
    })
  }
  }

  async getClients() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("EmployeeId", this.global.EvvUserDetails.entityId.toString())
    param.append("SearchText", "")
    this.clientService.getClients(param).subscribe((data: Array<MyClient>) => {
      data.forEach(element => {
        if (element.clientId == this.clientId) {
          this.clientData = element;
        }
      });
    }, err => {
      this.errorService.check(err);
      if(err.Status==401){
        this.global.GloballogOut();
      }
    })
  }
  }
  async getActivities() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("Client", this.clientId.toString())
    param.append("Employee", this.global.EvvUserDetails.entityId.toString())
    param.append("service", this.serviceId.toString())
    this.timesheetService.getActivities(param).subscribe((data: any) => {
      this.activitiesList = []
      data.forEach(element => {
     
        element.activities.forEach(tempdata => {
       
          let temp: any
          temp = {
            activityId: tempdata.activityId,
            value: tempdata.value,
            isSelected: tempdata.isSelected != null ? tempdata.isSelected : false,
          }
         
          this.activitiesList.push(temp)
        });

       
      });
      this.global.disableloading()
    
    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if(err.Status==401){
        this.global.GloballogOut();
      }
    })
  }
  }
  back() {
    this.isRP=false;
    this.router.navigate(['/client/' + this.backto])
  }
  sign(signType: number) {
    let navigationExtras: NavigationExtras = {
      state: {
        clientId: this.clientId,
        serviceId: this.serviceId,
        startTime: this.startTime,
        endTime: this.endTime,
        clientSign: this.clientSign,
        employeeSign: this.employeeSign,
        signType: signType,
        scheduleId: this.scheduleId,
        evvTimesheetId: this.evvTimesheetId,
        activity: this.activitiesList,
        isRp:this.isRP,
        type: "signed",
        notes:this.notes,
        idList: this.idList
      }
    }
    
    this.router.navigate(['/client/client-signature'], navigationExtras)
  }
  async complete() {

    // let val1 = this.activitiesList.filter(a => a.isSelected == true);
    // // if (val1.length > 0) {
    //   await this.global.showLoading();
    
    //   let data: any = [];
    //   let val = {
    //     clientId: this.global.EvvUserDetails.entityId,
    //     employeeId: this.clientData.employeeId,
    //     notes: this.notes!=""?this.notes:null,
    //     activities: this.activitiesList,
    //     clientSign: this.clientSign,
    //     employeeSign: this.employeeSign,
    //     evvTimesheetId: this.evvTimesheetId,
    //     scheduleId:this.evvTimesheetId,
    //     isrpsign:this.isRP
    //   }
    //   data.push(val);


    //   this.http.saveweeklytimesheet(data).subscribe((data) => {
    //     this.isRP=false;
        this.router.navigate(['/client/client-all-timesheets'])
      //   this.global.disableloading()

      // },err => {
      //   this.errorService.check(err);
      //   this.global.disableloading()
      //   if(err.Status==401){
      //     this.global.GloballogOut();
      //   }
      // })
    // }
    // else {
    //   let val = "Please select an activity"
    //   this.presentToast(val);
    // }
  }
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'danger',

    });



    toast.present();
  }

  async CliSignAgreement(){
    const alert = await this.alertController.create({
      header: 'Acknowledgement',
      backdropDismiss: false,
      message: 'Review the completed time sheet for accuracy before signing.It is a federal crime to provide false information on personal care service billings for Medical Assistance payment. By signing below, you swear and verify the time and services entered above are accurate and that the services were performed by the PCA listed below as specified in the PCA Care Plan.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
           
          }
        }, {
          text: 'Agree',
          handler: () => {
           
            this.sign(1);
           
          }
        }
      ]
    });
    await alert.present();
  }

  verifyTSCreation(){
    
    if(this.returnData!=null){
      if(this.returnData.TSCreated==true){
        this.global.disableloading();
        this.completed = true;
      
        setTimeout(() => {
          this.global.ToastSuccess("Timesheet(s) Completed Successfully");

          this.router.navigate(['/client/client-all-timesheets'])
          // this.isSubmitButton = true;
          this.completed=false;
        }, 3000);
      }
    }
  }

  
}
