import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
@Injectable({
    providedIn: 'root'
})
export class BasicCareplanService {
    constructor(public httpClient: HttpClient, public errorService: ErrorService, public global: Globalservice) {
    }
    public getBasicCareplanDateDropdown(param: URLSearchParams): Observable<any> {
        let url = "api/BasicCarePlan/getBasicCareplanDateDropdown?"
        return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
      }
      public getbasiccarePlanDetails(param: URLSearchParams): Observable<any> {
        let url = "api/BasicCarePlan/getbasiccarePlanDetails?"
        return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
      }
      public GetauthorizationDatesForBasicCarePlan(param: URLSearchParams): Observable<any> {
        let url = "api/BasicCarePlan/GetauthorizationDatesForBasicCarePlan?"
        return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
      }
   

    
}








