import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
@Injectable({
    providedIn: 'root'
})
export class ClientServiceUnitsService {
    constructor(public http: HttpClient, public errorService: ErrorService, public global: Globalservice) {
    }
    public getClientServiceUnits(params:URLSearchParams): Observable<any> {
        let url = "api/ClientAuthorization/ServiceUnitDetailsforClient?";
        return this.http.get(url + params).pipe(catchError(this.errorService.handleError));
    }
}








