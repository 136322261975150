import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeScheduleServiceInfo {
  constructor(public http: HttpClient, public errorService: ErrorService, public global: Globalservice) {
  }
  public startTimesheets(data): Observable<any> {
    let url = "api/Visit/saveMobileClockInWithSchedule?"
    return this.http.post(url , data).pipe(catchError(this.errorService.handleError));
  }
  public stopTimesheets(data,scheduleId): Observable<any> {
    let url = "api/Visit/saveMobileClockOutWithSchedule?"
    return this.http.post(url+scheduleId, data).pipe(catchError(this.errorService.handleError));
  }
  public getClientById(param: URLSearchParams): Observable<any> {
    let url = "api/EvvClient/getClientByIdSchNew?"
    return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
  }
}
