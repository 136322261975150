export class BasicCarePlanBo {
    id: number=0;
    clientId: number;
    agencyId: number | null;
    agencyName: string;
    agencyLocation: number | null;
    agencyCity: string;
    agencyAddress: string;
    agencyState: string;
    agencyZipcode: string;
    agencyEmail: string;
    agencyPhone: string;
    agencyFax: string;
    clientFirstName: string;
    clientLastName: string;
    clientMiddleName: string;
    clientDOB: string;
    height: number | null;
  weight: number | null;
    clientCity: string;
    clientAddress: string;
    clientState: string;
    clientZipcode: string;
    clientEmail: string;
    clientPhone: string;
    clientCounty: string;
    clientFullName: string;
    IshealthInsurance:boolean|null;
    medicaid: string;
    medicare: string;
    insuranceNo: string;
    nPI: string;
    frequencyOfVisit: number | null;
    clientAuthId: number | null;
    qpEmployeeID: number | null;
    payorId: number | null;
    physician: string;
    payor: string;
    physicianAddress: string;
    physicianPhone: string;
    icd10primaryId: string;
    icd10Desc: string;
    serviceId: number | null;
    serviceDescription: string;
    transportation: string;
    clientSummary: string;
    goals: string;
    other: string;
    serviceName: string;
    payorName: string;
    clientLocationName: string;
    backupStaffingPlan: string;
    emergencyProceduresPlan: string;
    emergencyContactName: string;
    emergencyContactPhone: string;
    qPPhone: string;
    qPName: string;
    caseManagerName: string;
    caseManagerPhone: string;
    responsiblePartySign: string;
    pcaSign: string;
    nurseSign: string;
    clientSign: string;
    responsiblePartySignUrl: string;
    pcaSignUrl: string;
    nurseSignUrl: string;
    clientSignUrl: string;
    responsiblePartySignDate: string | null;
    pcaSignDate: string | null;
    nurseSignDate: string | null;
    clientSignDate: string | null;
    date: string;
    serviceactivity: BasicCarePlanServiceActivitiesBO[];
  }
  export class BasicCarePlanServiceActivitiesBO {
    id: number;
    basiccareplanId: number;
    masterServiceActivityId: number;
    masterServiceActivityName: string;
    serviceActivitiesComment: string;
  }

  export class BasicExpandingBo {

    ProviderInformationBool: boolean = true;
    ClientInformationBool: boolean = false;
    AuthorizationDetailsBool: boolean = false;
    PhysicianInformationBool: boolean = false;
    ClientSummaryBool: boolean = false;
    ServiceInformationBool: boolean = false;
    ServiceDescriptionBool: boolean = false;
    TransportationBool: boolean = false;
    OthersBool:boolean = false;
    BackupStaffingPlanBool:boolean = false;
    GoalsBool:boolean = false;
    EmergencyProceduresandplanBool:boolean = false;
    EmergencyProceduresandplanPlanBool:boolean = false;
    
    EmergencyContactBool:boolean = false;
    QualifiedProfessionalBool:boolean = false;
    CaseManagerBool:boolean = false;
    ResponsiblePartyBool:boolean=false;
    NurseBool:boolean=false;
    PCABool:boolean=false;
    ClientBool:boolean=false;
}

export class BasicSignatureBo {
  RPsign: string;
  Nursesign: string;
  PCAsign: string;
  Clientsign: string;
}