import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanguagesPage } from '../languages/languages.page';
import { ForgotPasswordPage } from './forgot-password/forgot-password.page';
import { LoginPassPage } from './login-password/login-password.page';
import { LoginPage } from './login.page';
import { ResetPasswordPage } from './reset-password/reset-password.page';
import { SplashPage } from './splash/splash.page';
import { AppMaintenancePage } from './appmaintenance/appmaintenance.page';
const routes: Routes = [
  {
    path: 'login',
    component: LoginPage,
    children: [
      {
        path: 'login-password',
        component:LoginPassPage
      },
      {
        path: 'forgot-password',
        component:ForgotPasswordPage
      },
      {
        path: 'reset-password',
        component:ResetPasswordPage
      },
      {
        path: 'language',
        component:LanguagesPage
      },
      {
        path: 'splash',
        component:SplashPage
      },
      {
        path: 'app-maintenance',
        component:AppMaintenancePage,
        // canActivate: [OfflineGuard]
      }, 
      {
        path: '',
        redirectTo: '/login/login-password',
        pathMatch: 'full'
      },
     
    ]
  },
  {
    path: '',
    redirectTo: '/login/login-password',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginPageRoutingModule {}
