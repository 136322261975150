import { Component, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { CalendarComponent } from 'ionic2-calendar';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { MyCalendarService } from './calendarscheduler.service';
@Component({
  selector: 'app-calenderscheduler',
  templateUrl: './calenderscheduler.page.html',
  styleUrls: ['./calenderscheduler.page.scss'],
})
export class CalenderschedulerPage implements OnInit {
  showEventData: boolean = false;
  viewTitle: string = "";
  employeeList: any = [];
  serviceList: any = [];
  errorList: any = [];
  showError: any = false;
  customFrequency: any = [];
  event: any
  date=this.global.DateandTimeFormat(new Date()).replace("Z", "");  
  eventSource = [];
  calendar = {
    mode: 'month',
    currentDate: new Date()
  }
  slideTwoForm: FormGroup;
  @ViewChildren(CalendarComponent) myCalendar: CalendarComponent;
  constructor(public calendaerService: MyCalendarService,
    public formBuilder: FormBuilder,
    private toastCtrl: ToastController,
    public global: Globalservice, public errorService: ErrorService) {
    this.slideTwoForm = this.formBuilder.group({
      'FemployeeId': ['', Validators.required],
      'FserviceId': ['', Validators.required],
      'FstartDate': ['', Validators.required],
      'FendDate': ['', Validators.required],
      'FstartTime': ['', Validators.required],
      'FendTime': ['', Validators.required],
      'Ffrequency': ['', Validators.required]

    })
    this.event = {
      employeeId: "",
      serviceGroupId: "",
      ClientId: this.global.EvvUserDetails.entityId,
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      customFrequencyLid: ""
    };
      
  }
  submitForm(form) {
  }
  ionViewDidLoad() {
  }
  async ngOnInit() {
    await this.global.checkRefreshTokenValidity()
    {
    this.getSchedule();
    this.getEmployee();
    this.resetEvent();
    this.getLovDropDown();
    }
  }
  resetEvent() {
    this.event = {
      employeeId: "",
      serviceGroupId: "",
      ClientId: this.global.EvvUserDetails.entityId,
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      customFrequencyLid: ""
    };
  }
  ionViewWillEnter()
  {
       
  }
  addEvent() {
    this.showEventData = true;
    // this.getService();
    this.resetEvent();
  }
  async saveEventDetails() {
    await this.global.checkRefreshTokenValidity()
    {
    let datestartTime = new Date(this.event.startDate);
    let startTime = new Date(this.event.startTime);
    startTime.setFullYear(datestartTime.getFullYear());
    startTime.setMonth(datestartTime.getMonth());
    startTime.setDate(datestartTime.getDate());
    this.event.startTime = startTime.toString();

    let dateendTime = new Date(this.event.endDate);
    let endTime = new Date(this.event.endTime);
    endTime.setFullYear(dateendTime.getFullYear());
    endTime.setMonth(dateendTime.getMonth());
    endTime.setDate(dateendTime.getDate());
    this.event.endTime = endTime.toString();

    let eventDetails: any = {
      employeeId: this.event.employeeId,
      serviceGroupId: this.event.serviceId,
      ClientId: this.global.EvvUserDetails.entityId,
      startDate: new Date(this.event.startDate),
      endDate: new Date(this.event.endDate),
      startTime: new Date(this.event.startTime),
      endTime: new Date(this.event.endTime),
      customFrequencyLid: this.event.frequency
    }
    this.eventSource = [];
    var d1 = eventDetails.startDate;
    var d2 = eventDetails.endDate;
    var t1 = eventDetails.startTime;
    var t2 = eventDetails.endTime;
    eventDetails.startDate = this.global.getDateTimeString(d1);
    eventDetails.endDate = this.global.getDateTimeString(d2);
    eventDetails.startTime = this.global.getDateTimeString(t1);
    eventDetails.endTime = this.global.getDateTimeString(t2);
    eventDetails.customFrequencyLid = parseInt(eventDetails.customFrequencyLid)
    this.calendaerService.saveCalendarTimesheet(eventDetails).subscribe((data) => {
      this.errorList = data;
      // if (this.errorList.length != 0) {
      //   this.showError = true;
      //   this.resetEvent();
      // }
      // else {

      //   this.getSchedule();
      // }
      this.getSchedule();
      this.resetEvent();
      this.global.ToastSuccess("Schedule Saved Successfully!")
      this.showEventData = false;
      this.showError = false;

    },
      (err) => {
        this.global.disableloading()
        this.errorService.check(err);
        this.presentToast(err.message);
        if (err.Status == 401) {
          this.global.GloballogOut();
        }


      })
     } }
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'danger',
    });
    toast.present();
  }
  cancelEvent() {
    this.showEventData = false;
    this.showError = false;
  }
  onEventSelected(event): void {


  }
  onCurrentDateChanged(): void {

  }
  onTimeSelected(): void {

  }
  onViewTitleChanged(title) {
    this.viewTitle = title;
  }

  next() {
    var swiper = document.querySelector('.swiper-container')['swiper'];
    swiper.slideNext();
  }

  back() {
    var swiper = document.querySelector('.swiper-container')['swiper'];
    swiper.slidePrev();
  }


  async getEmployee() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("ClientId", this.global.EvvUserDetails.entityId.toString());
    this.calendaerService.getEmployee(param).subscribe((data: Array<any>) => {
      this.employeeList = [];
      this.employeeList = data;
    }, err => {
      this.errorService.check(err);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  async getService(employeeId) {
    await this.global.checkRefreshTokenValidity()
    {
    if (employeeId != 0 && employeeId != null && employeeId != undefined) {
      let param = new URLSearchParams();
      param.append("clientId", this.global.EvvUserDetails.entityId.toString());
      param.append("employeeId", employeeId.toString());
      await this.global.showLoading();
      this.calendaerService.getServices(param).subscribe((data: Array<any>) => {

        this.serviceList = [];
        this.serviceList = data;
        this.global.disableloading();
      }, err => {
        this.errorService.check(err);
        this.global.disableloading()
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
  }

  closeError() {
    this.showEventData = false;
    this.showError = false;
  }

  async getSchedule() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("ClientId", this.global.EvvUserDetails.entityId.toString());
    await this.global.showLoading();
    this.calendaerService.getCalendarList(param).subscribe((data: Array<any>) => {
      this.eventSource = [];
      this.eventSource = data;

      this.eventSource.forEach(ele => {
        ele.startTime = new Date(ele.startTime);
        ele.endTime = new Date(ele.endTime);
      })
      this.global.disableloading()
    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }

  }

  CheckDate() {
    // this.event.startTime = "";
    // this.event.endTime = "";
    if (new Date(this.event.startDate).getTime() > new Date(this.event.endDate).getTime()) {
      this.event.endDate = new Date().toISOString();
      this.event.startDate = new Date().toISOString();
      this.presentToast("End Date must be greater than Start Date");

    }
  }

  CheckTime(element) {
    if (element.startTime == undefined || element.startTime == null || element.startTime == '') {
      element.startTime = this.global.DateandTimeFormat(new Date());
      if (element.startTime != undefined) {
        element.startTime = element.startTime.split("+")[0];
      }
    }
    if (element.endTime == undefined || element.endTime == null || element.v == '') {
      element.endTime = this.global.DateandTimeFormat(new Date());
      if (element.endTime != undefined) {
        element.endTime = element.endTime.split("+")[0]
      }
    }
    if (new Date(this.event.startTime).getTime() > new Date(this.event.endTime).getTime()) {
      this.event.endTime = this.global.DateandTimeFormat(new Date()),
        this.event.startTime = this.global.DateandTimeFormat(new Date()),
        this.presentToast("End Time must be greater than Start Time");

    }
  }
  async presentToastSuccess(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'success',

    });
    toast.present();
  }
  async getLovDropDown() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("Code", "CUSTOMFREQUENCY");
    this.calendaerService.getLovDropDown(param).subscribe((data: Array<any>) => {
      data?.filter(e => {
        if (e.Value == "DAILY") {
          this.customFrequency.push(e)
        }
      })

    }, err => {
      this.errorService.check(err);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }

  }

}
