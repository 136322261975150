import { Injectable } from "@angular/core";
import { Storage } from "@ionic/Storage";
import { TranslateService } from "@ngx-translate/core";
import { Globalservice } from "./global.service";
const LNG_KEY = "SELECTED_LANGUAGE";
@Injectable({
	providedIn: "root",
})
export class LanguageService {
	selected = "";
	constructor(private translate: TranslateService, private storage: Storage, public global: Globalservice) { }
	setInitialAppLanguage() {
		const language = this.translate.getBrowserLang();
		this.translate.setDefaultLang(language);
	}
	getLanguages() {
		return [
			{ text: "English", value: "en" },
			{ text: "Spanish", value: "es" },
			{ text: "Russian", value: "ru" },
			{ text: "Hmong", value: "hmong" },
			{ text: "Somalian", value: "somalian" },
			{ text: "Arabic", value: "arabic" },
			{ text: "Vietnamese", value: "vietnamese" },
		];
	}

	// lng can be 'en', 'fr' or 'sp'
	setLanguage(lng) {
		if (this.global.role == "CLIENT") {
			lng = localStorage.getItem("Evv_clientlanguage");
		}
		else if (this.global.role == 'PCAEMPLOYEE' || this.global.role == 'QPEMPLOYEE') {
			lng = localStorage.getItem("Evv_employeelanguages");
		}
		this.translate.use(lng);
		this.selected = lng;
		this.storage.set(LNG_KEY, lng);
	}
}
