import { Location } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { Storage } from "@ionic/Storage";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Globalservice } from 'src/app/global.service';
import { LanguageService } from 'src/app/language.service';
import { LanguageBO } from './languages.model';
@Component({
  selector: 'app-languages',
  templateUrl: './languages.page.html',
  styleUrls: ['./languages.page.scss'],
})
export class LanguagesPage implements OnInit {
  es: FormGroup;
  eng: FormGroup;
  rus: FormGroup;
  languageBo: LanguageBO = new LanguageBO()
  setlang: boolean = true;
  userId: string;
  data: any;
  languages = [];
  selected = "";
  selecteslan: any;
  constructor(public translateService: TranslateService, private location: Location, public router: Router, public global: Globalservice, public storage: Storage, public toastCtrl: ToastController,
    private languageService: LanguageService, public http: HttpClient, public alertController: AlertController, public trans: TranslateModule) {
    this.global.checkingLogin();
   
      
  }
   ionViewWillEnter() {
    if (
      this.global.applicationVersionNumber == null ||
      this.global.applicationVersionNumber == undefined
    ) {
      // if (this.global.appPages.length > 0) {
      //   let index = this.global.appPages.findIndex(
      //     (page) => page.title == 'Offline Manager'
      //   );
      //   if (index != -1) {
      //     this.global.appPages.splice(index, 1);
      //   }
      // }
    }
 
    
  }
  ngOnInit() {
    this.languages = this.languageService.getLanguages();
    this.selected = this.languageService.selected;
    this.global.EvvUserDetails.setLanguage = localStorage.getItem("Evv_employeelanguages");
    this.selected = this.global.EvvUserDetails.setLanguage;
  }
  //......................................................select............................................
  select(lng) {
    this.languageService.setLanguage(lng);
    this.selected = lng;
    this.translateService.use(lng);
    localStorage.setItem("Evv_employeelanguages", lng);
    this.goToNextPage();
  }
  //......................................................goToNextPage............................................
  async goToNextPage() {
    await this.global.checkRefreshTokenValidity()
    {
    this.languageBo.userId = this.global.EvvUserDetails.entityId.toString();
    this.languageBo.language = this.selected;
    let url = 'api/LoginUser/SetLanguageemp';
    this.http.post(url, this.languageBo).subscribe((data) => {
      localStorage.setItem("Evv_employeelanguages", this.languageBo.language);
      this.isLoggedin();
      this.router.navigate(['/tabs/all-timesheets'])
      this.presentToast('Language Changed Successfully')
    }), err => {
      this.global.disableloading();
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    }
  }
  }
  //......................................................isLoggedin............................................
  async isLoggedin() {
    await this.global.checkRefreshTokenValidity();
    {
    let param = new URLSearchParams()
    param.append("UserId", this.global.EvvUserDetails.entityId.toString());
    let url = 'api/LoginUser/SetLoginDetailemp?'
    this.http.post(url + param, '').subscribe((data) => {

    })
  }
  }
  //......................................................presentToast............................................
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }
  //......................................................back............................................
  back() {
    this.router.navigate(['/tabs/all-timesheets'])
  }
}



