import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { ActiveSessionService } from './employee-active-session.service';

@Component({
  selector: 'app-employee-active-session',
  templateUrl: './employee-active-session.page.html',
  styleUrls: ['./employee-active-session.page.scss'],
})
export class EmployeeActiveSessionPage implements OnInit {
  ActiveSessionList: any = [];
  islogoutOfAllSessions: boolean = false;
  constructor(public modalController: ModalController, public ActivesessionService: ActiveSessionService, public global: Globalservice,
    public errorService: ErrorService, private datePipe: DatePipe, public router: Router, public http: HttpClient) {
    this.global.checkingLogin();
  }
  ngOnInit() {
  }
  async ionViewWillEnter() {
    await this.global.checkRefreshTokenValidity()
    {
    this.getAllOpenSessions();
    }

  }
  async getAllOpenSessions() {
   
    let params = new URLSearchParams();
    params.append("refreshToken", this.global.refreshTokenString);
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.ActivesessionService.getAllOpenSessions(params).subscribe((data: any) => {
      this.ActiveSessionList = data;
      this.global.disableloading();
      this.ActiveSessionList.forEach(element => {
        element.loginTime = this.datePipe.transform(element.loginTime, "MM/dd/yyyy HH:mm:ss");
        element.lastUsedTime = this.datePipe.transform(element.lastUsedTime, "MM/dd/yyyy HH:mm:ss");
        
      });
    },
      err => {
        this.global.disableloading()
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
  async revokeSessions(activeFields) {
  
    let params = new URLSearchParams();
    params.append("refreshToken", this.global.refreshTokenString);
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.ActivesessionService.revokeSessions([activeFields.id], params).subscribe((data: any) => {
      this.global.ToastSuccess('Session Revoked SuccessFully!');
      this.global.disableloading();
      this.getAllOpenSessions();
    },
      (err: HttpErrorResponse) => {
        this.global.disableloading()
        this.global.Toastdanger(err.message);
      });
    }
  }


  async logoutSession() {
    let url = "api/Auth/logout?"
    let params = new URLSearchParams();
    this.islogoutOfAllSessions = true;
    params.append("revokeAllSessions", JSON.stringify(this.islogoutOfAllSessions))
    params.append("refreshToken", this.global.refreshTokenString);
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();

    this.http.post(url + params, null).pipe().subscribe((data: any) => {
      this.global.disableloading();
      this.global.ToastSuccess('Logout Successful');
      this.logout();
    },
      (err: HttpErrorResponse) => {
        this.global.disableloading();
        this.global.ToastSuccess('Logout Successful');
        this.logout();
      })
    }
  }
  logout() {
    this.global.refreshSubscription.unsubscribe();
    localStorage.clear();
    this.router.navigate(['/login'], { replaceUrl: true })
  }
}
