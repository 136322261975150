import { Component, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { AlertController, ToastController } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { SaveSignBO } from './signature.model';
import { TimesheetService } from '../timesheet/timesheet.service';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { Network } from '@ionic-native/network/ngx';
@Component({
  selector: 'app-signature',
  templateUrl: './signature.page.html',
  styleUrls: ['./signature.page.scss'],
})
export class SignaturePage {
  drawstart: boolean = false;
  deviceWidth: any;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  public signaturePadOptions: Object;
  signimg: any = null;
  width: any;
  startTime: string = null;
  endTime: string = null;
  clientSign: string = null;
  employeeSign: string = null;
  clientId: number;
  serviceId: number;
  signType: number;
  type: string;
  clientData: any;
  scheduleId: number;
  frompage: string = "";
  evvTimesheetId: number = 0;
  Activivity: any;
  isRp: boolean = false;
  notes: string = "";
  idList: any = [];
  SaveSignBO: SaveSignBO = new SaveSignBO();
  isOffline: boolean = false;
  constructor(public alertCtrl: AlertController, public errorService: ErrorService, private screenOrientation: ScreenOrientation, private toastCtrl: ToastController, public http: TimesheetService,
    public global: Globalservice, private router: Router, private route: ActivatedRoute, private network: Network,) {
      this.global.checkingLogin();
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.isRp=data.isRPSign;
        this.type = data.type;
        if (this.type == "signed") {
          this.clientId = data.clientId;
          this.serviceId = data.serviceId;
          this.startTime = data.startTime;
          this.endTime = data.endTime;
          this.clientSign = data.clientSign;
          this.employeeSign = data.employeeSign;
          this.signType = data.signType;
          this.scheduleId = data.scheduleId;
          this.Activivity = data.activity;
          this.evvTimesheetId = data.evvTimesheetId;
          this.frompage = data.frompage;
          this.notes = data.notes;
          this.idList = data.idList
        }
        else if (this.type == "all-sign") {
          this.clientData = data.data;
          this.clientSign = data.clientSign;
          this.employeeSign = data.employeeSign;
          this.signType = data.signType;
          this.idList = data.idList;
          this.clientId = data.clientId;
        }
      }
      this.signimg = null;
    });
    if (window.innerWidth < 650) {
      this.width = window.innerWidth - 33;
      this.deviceWidth = (window.innerWidth - 30) + 'px';
    }
    else {
      this.width = 600;
      this.deviceWidth = 603 + 'px';
    }
    this.signaturePadOptions = {
      minWidth: 5,
      canvasWidth: this.width,
      canvasHeight: 200,
      penColor: "blue"
    }
    this.screenOrientation.onChange().subscribe(
      () => {
        setTimeout(() => {
          this.getDeviceWidth();
        }, 200)
      }
    );
    this.network.onConnect().subscribe(() => {
      this.isOffline = false;
    })
    this.network.onDisconnect().subscribe(() => {
      this.isOffline = true;
    })
   
  }
  getDeviceWidth() {
    if (window.innerWidth < 650) {
      this.width = window.innerWidth - 33;
      this.deviceWidth = (window.innerWidth - 30) + 'px';
      this.canvasResize();
    }
    else {
      this.width = 600;
      this.deviceWidth = 603 + 'px';
      this.canvasResize();
    }
  }
  canvasResize() {
    this.signaturePad.set("minWidth", 1);
    this.signaturePad.set("canvasWidth", this.width);
    this.signaturePad.set("canvasHeight", 200);
    if (!this.signaturePad.isEmpty()) {
      this.signaturePad.fromDataURL(this.signimg);
    }
  }
  ionViewWillEnter() {
    this.ngAfterViewInit();
  }
  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 5); // set signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from signature_pad API
    this.signimg = null 
    this.canvasResize();
    this.drawstart = false;
  }

  drawComplete() {
    // will be notified of signature_pad's onEnd event
    this.signimg = this.signaturePad.toDataURL();
  }
  drawStart() {
    // will be notified of signature_pad's onBegin event
    this.drawstart = true;
  }
  back() {
    let navigationExtras: NavigationExtras;
    if (this.type == "signed") {
      navigationExtras = {
        state: {
          clientId: this.clientId,
          serviceId: this.serviceId,
          startTime: this.startTime,
          endTime: this.endTime,
          clientSign: this.clientSign,
          employeeSign: this.employeeSign,
          scheduleId: this.scheduleId,
          evvTimesheetId: this.evvTimesheetId,
          activity: this.Activivity,
          isRP: this.isRp,
          backto: "all-timesheets",
          type: "sign",
          isSubmitButton: true,
          frompage: this.frompage,
          returnData:this.returnData
        }
      }
    
      
      this.router.navigate(['/tabs/timesheet'], navigationExtras)
    }
    else if (this.type == "all-sign") {
      navigationExtras = {
        state: {
          data: this.clientData,
          clientSign: this.clientSign,
          employeeSign: this.employeeSign,
          returnData:this.returnData
        }
      }
      this.router.navigate(['/tabs/sign-all-timesheet'], navigationExtras)
     
    }
  }
returnData: any;
  async submit() {
    await this.global.checkRefreshTokenValidity()
    {
    if (this.signimg == null || this.signimg == undefined || this.signimg == '') { 
      this.global.Toastdanger("Please enter your signature");
      return;
    }
    await this.global.showLoading();
    if (this.signType == 1) {
      this.SaveSignBO.ids = this.idList;
      this.SaveSignBO.sign = this.signimg;
      this.SaveSignBO.employeeId = this.clientId;
      this.SaveSignBO.isRp = this.isRp;
      let url = '';
      this.http.saveClientSign(this.SaveSignBO).subscribe((data: any) => {
        this.returnData=data;
        this.global.disableloading();
        this.presentToast('Sign Saved Successfully', 'success')
        this.navigateBackaftersign();
      }, err => {
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
        this.errorService.check(err);
        this.presentToast('Error:' + err.message, 'danger')
      })
    } else {
      this.SaveSignBO.ids = this.idList;
      this.SaveSignBO.sign = this.signimg;
      this.SaveSignBO.employeeId = this.global.EvvUserDetails.entityId;
      this.SaveSignBO.isRp = this.isRp;
      this.http.saveEMployeeSign(this.SaveSignBO).subscribe((data: any) => {
        this.returnData=data;
        this.global.disableloading();
        this.presentToast('Sign Saved Successfully', 'success')
        this.navigateBackaftersign();
      }, err => {
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
        this.presentToast('Error:' + err.message, 'danger')
      })
    }
  }
  }

  navigateBackaftersign() {
    if (this.signType == 1) {
      this.clientSign = this.signimg;
    }
    else if (this.signType == 2) {
      this.employeeSign = this.signimg;
    }
    this.back();
  }

  async presentToast(msg, color) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 1500,
      position: 'top',
      color: color,
    });
    toast.present();
  }
}
