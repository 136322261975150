import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { scheduleDashboardEmployeeBO } from './employeecurrentdashboard.model';
@Injectable({
  providedIn: 'root'
})
export class MyEmployeeCurrentDashboardService {
  constructor(private httpClient: HttpClient,public errorService: ErrorService,public global:Globalservice) { }
  public  getDashboardDetails(param:URLSearchParams):Observable<any> {
    let url="api/EvvEmployee/getCurrentDashboardDetailsNew?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
  public  cancelClientAppt(clientData:scheduleDashboardEmployeeBO):Observable<any> {
    let url="api/EvvEmployee/cancelClientAppointment"
    return this.httpClient.post(url,clientData).pipe(catchError(this.errorService.handleError));
  }
  public  getscheduleofflinedata(param:URLSearchParams):Observable<any> {
    let url="api/EvvEmployee/getScheduleofflinedata?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
  public  getWeeklyHours(param:URLSearchParams):Observable<any> {
    let url="api/EvvEmployee/getWeeklyHours?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
  public  getMonthlyHours(param:URLSearchParams):Observable<any> {
    let url="api/EvvEmployee/getMonthlyHours?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
  public  getClientofflinedata(param:URLSearchParams):Observable<any> {
    let url="api/EvvClient/getClientOffline?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
  public  getClientServiceofflinedata():Observable<any> {
    let url="api/EvvClient/getClientServiceOffline?"
    return this.httpClient.get(url).pipe(catchError(this.errorService.handleError));
  }
}
