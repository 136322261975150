import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';


@Injectable({
  providedIn: 'root'
})
export class MyEmployeesService {

  constructor(private httpClient: HttpClient,public errorService: ErrorService,public global:Globalservice) { }
  public  getClients(param:URLSearchParams):Observable<any> {
    let url="api/EvvClient/getClient?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
  public  getServices(param:URLSearchParams):Observable<any> {
    let url="api/EvvClient/getClientServiceNew?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
  public  getClientById(param:URLSearchParams):Observable<any> {
    let url="api/VisitClient/getClientbyId?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
}
