import { ActivityLstBO } from "../offline/offline.modal";

export class MyClient {
  clientId: number;
  name: string;
  age: number;
  address: string;
  street: string;
  email: string;
  cityStateZip: string;
  country: string;
  mobileNumberFormat: string;
  mobileNumber: number;
  serviceId: number;
  startTime: string;
  endTime: string;
  clientSign: string;
  employeeSign: string;
  recordType: string;
  scheduleId: number;
  evvTimehseetId: number;
  expanded: boolean;
  evvTimesheetId: number;
  sharedClientId: any[];
  sharedtsId: any[];
  apt: string;
  appointmentId: string;
  clientAuthId: number;
  serviceGroupId: number;
  inprogressValidationforOver24Hours: boolean = false;
  selectElectronicForms: any;
  visitStatus: string = ""
  visitNote: string = ""
}

export interface EvvClientsOffline {
  clientId: number;
  name: string;
  age: number | null;
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  mobileNumber: string;
  email: string;
  scheduleId: number | null;
  serviceId: number | null;
  cityStateZip: string;
  startTime: string | null;
  endTime: string | null;
  evvTimehseetId: number | null;
  sharedClientId: number[];
  sharedtsId: number[];
  mappedServices: Services[];
}
export class Services {
  serviceId: number;
  serviceName: string;
  remaininghrs: string;
  remaingHours: number;
  ratio: number;
  value: string;
  masterServiceName: string;
  startDate: string;
  endDate: string;
  serviceGroupId: number = 0;
  remainingHours: string;
  remainingHoursPerDay: any;
  remainingHoursPerWeek: any;
  authDetails: AuthServicesBo[];
  clientAuthId: number;
  serviceDescription: any;


}
export class AuthServicesBo {
  clientAuthId: any;
  startDate: any;
  endDate: any;
  masterServiceId: any;
  masterServiceName: any;
  userUnits: any;
  remainingUnits: any;
  remainingHoursPerDay: any;
  remainingHoursPerWeek: any;
  remainingHours: any;
  ratio: any;
  clientId: any;
  clientName: any;
  payorId: any;
  payorName: any;
  totalUnits: any;
  hoursPerDay: any;
  hoursPerWeek: any
  remainingHPDBasedOnLastVisitDate: any
  remainingHPDBasedOnCurrentDate: any
  remainingUPDBasedOnLastVisitDate: any
  remainingUPDBasedOnCurrentDate: any
  serviceDescription: any
  confirmedUnits: any
  unconfirmedUnits: any
}

export interface Activity {
  activityId: number,
  value: string,
  isSelected: boolean

}

export interface ActivityBo {
  activityId: number,
  value: string
}

export class SharedCareClients {
  clientId: number;
  clientName: string;
  isSelected: boolean;
}

export class sharedCare {
  // ids: number[];
  // employeeId: number;
  // time: string;
  // serviceId: number;
  // scheduleId: number;
  // longs: string;
  // lat: string;

  // New Code
  clientId: number[];
  evvTimesheetId: number[];
  employeeId: number;
  masterServiceId: number;
  callLatitude: number;
  callLongitude: number;
  scheduleId: number;
  callTime: string;
  clientAuthId: number;
  serviceGroupId: number;


}

export class saveActivitiesBO {
  evvTsId: number;
  activities: ActivityLstBO[];
}

export class saveTsNotesBO {
  evvTsId: number;
  note: string = '';
}

export class UpdateVisitBO {
  evvTimesheetId: number;
  masterServiceId: number;
  visitStartTime: string | null;
  visitEndTime: string | null;
  visitTypeLid: number | null;
  updateReason: string;
  note: string;
  isVisitEdited: true;
  clientAuthId:any;
}
