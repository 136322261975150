import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';

@Injectable({
    providedIn: 'root'
})
export class ICLSService {
    constructor(public httpClient: HttpClient, public errorService: ErrorService, public global: Globalservice) {
    }
    public getICLSCarePlanDateDropdown(param: URLSearchParams): Observable<any> {
        let url = "api/ICLSCarePlan/getICLSCarePlanDateDropdown?"
        return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
      }
      public getICLSCarePlanDetails(param: URLSearchParams): Observable<any> {
        let url = "api/ICLSCarePlan/getICLSCarePlanDetails?"
        return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
      }
}








