import { Component } from '@angular/core';
import { ActionSheetController, LoadingController, PopoverController, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { Storage } from "@ionic/Storage";
import { Network } from '@ionic-native/network/ngx';
import { ClientDashboardNewService } from './client-dashboard-new.service';
import { DashboardHourDetailsPage } from '../../dashboard-hour-details/dashboard-hour-details.page';
@Component({
  selector: 'app-client-dashboard-new',
  templateUrl: './client-dashboard-new.page.html',
  styleUrls: ['./client-dashboard-new.page.scss'],
})
export class ClientDashboardNewPage {
  count: number = 0;
  datanotfound: boolean = false;
  servicesListContent = [];
  database: any = [];
  offlinedata: any = [];
  ShowLoading: boolean = true;
  acceptLoading = null;
  showLoadingweekly: boolean = true;
  showLoadingmonthly: boolean = true;
  monthlyHours: any;
  weeklyHours: any;
  weeklyhourdata:any;
  monthlyhoursdata:any;
  dashboardLoad = null;
  isOffline: boolean=false;
  constructor(public CS: ClientDashboardNewService,public errorService: ErrorService, 
    public actionSheetCtrl: ActionSheetController,
     public global: Globalservice,
    public general: generalservice, public toastCtrl: ToastController,public popoverController: PopoverController,
    public storage: Storage, private network: Network,
    public loadingCtrl: LoadingController,) {
      this.network.onConnect().subscribe(() => {
        this.isOffline=false;
      })
      this.network.onDisconnect().subscribe(() => {
        this.isOffline=true;
      })
      this.global.checkingLogin();
  }
  async ionViewWillEnter() {
    await this.global.checkRefreshTokenValidity()
    {
    this.getweeklyHours();
    this.getMonthlyHours();
    }
  }
  async getweeklyHours(){ 
    await this.global.checkRefreshTokenValidity()
    {
    let params = new URLSearchParams(); 
    params.append("ClientId",this.global.EvvUserDetails.entityId.toString());
    this.CS.getClientWeeklyHours(params).subscribe((data: any) => {
      this.weeklyhourdata=data;
      this.showLoadingweekly=false;
      let valweeks = data.totalHours.toString().split('.');
        if (valweeks.length == 1) {
          this.weeklyHours = valweeks[0] + " hr " + "0 Min";
        }
        else {
          let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
          this.weeklyHours = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + " Min";
        }
    })
  }
  } 
  async getMonthlyHours(){
    await this.global.checkRefreshTokenValidity()
    {
    let params = new URLSearchParams();
    params.append("ClientId",this.global.EvvUserDetails.entityId.toString());
    this.CS.getClientMonthlyHours(params).subscribe((data: any) => {
      this.monthlyhoursdata=data;
      this.showLoadingmonthly=false;
      let valweeks = data.totalHours.toString().split('.');
        if (valweeks.length == 1) {
          this.monthlyHours = valweeks[0] + " hr " + "0 Min";
        }
        else {
          let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
          this.monthlyHours = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + " Min";
        }
    })
  }
  }
  async presentPopover(ev: any,type) {
    let val:any;
    if(type=='m'){
      val=this.monthlyhoursdata;
    } else if(type=='w'){
      val=this.weeklyhourdata;
    }
    // this.CalculateHours(val);
  
    const popover = await this.popoverController.create({
      component: DashboardHourDetailsPage,
      componentProps:{vals:val},
      cssClass: 'popOverClass',
      event: ev,
      // translucent: true,
    });
    await popover.present();


  }
}