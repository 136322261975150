import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { sqliteService } from 'src/app/sqlite.service';
import { Activity, MyClient, saveActivitiesBO, saveTsNotesBO, Services, UpdateVisitBO } from '../my-clients/my-clients.model';
import { MyClientsService } from '../my-clients/my-clients.service';
import { SignAllTimesheetService } from '../sign-all-timesheet/sign-all-timesheet.service';
import { TimesheetService } from './timesheet.service';
import { DatePipe } from '@angular/common';
import BackgroundGeolocation from 'cordova-background-geolocation-lt';
import { parse } from 'path';
@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.page.html',
  styleUrls: ['./timesheet.page.scss'],
})
export class TimesheetPage {
  clientData: MyClient;
  serviceName: string = "";
  activitiesList: Array<Activity> = [];
  startTime: string = null;
  endTime: string = null;
  customPickerOptions: any;
  clientSign: string = null;
  isDifferentlyAbled: boolean = false;
  employeeSign: string = null;
  employeeSignId: any;
  clientId: number;
  serviceCode: any = "";
  serviceId: number;
  totalHours: string;
  isSubmitButton: boolean = true;
  backto: string = "";
  scheduleId: number = 0;
  type: string;
  updateVisitBO: UpdateVisitBO = new UpdateVisitBO();
  notes: string = "";
  isRP: boolean = false;
  frompage: string = "";
  myInputs: any = [];
  clientRecordsstartDate: any = "";
  clientRecordsendDate: any = "";
  IsEditableVisit: any;
  idList: any = [];
  clientAuthId: any;
  evvTimesheetId: number = 0;
  TsType: any;
  slideOpts = {
    slidesPerView: 3,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }
  };
  completed: boolean = false;
  isOffline: boolean = false;
  returnData: any;
  constructor(private datepipe: DatePipe, public clientService: MyClientsService, public errorService: ErrorService, public timesheetService: TimesheetService,
    private router: Router, private route: ActivatedRoute, public global: Globalservice, public http: SignAllTimesheetService,
    private toastCtrl: ToastController, public general: generalservice, public platform: Platform, public alertController: AlertController,
    private network: Network, public sqlService: sqliteService, private sqlite: SQLite) {
    this.route.queryParams.subscribe(params => {
      this.myInputs = this.createInputs();
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.type = data.type;
        this.clientId = data.clientId;
        this.serviceId = data.serviceId;
        this.startTime = data.startTime;
        this.endTime = data.endTime;
        this.clientSign = data.clientSign;
        this.employeeSign = data.employeeSign;
        this.employeeSignId = data.employeeSignId;
        this.isSubmitButton = data.isSubmitButton;
        this.backto = data.backto;
        this.scheduleId = data.scheduleId;
        this.evvTimesheetId = data.evvTimesheetId;
        this.frompage = data.frompage;
        this.isRP = data.isRP != null ? data.isRP : false;

        this.TsType = data.tsType;
        this.notes = this.notes != "" ? this.notes : data.notes;
        this.returnData = data.returnData;
        if (this.type == "sign") {
          this.activitiesList = data.activity;
        }
        let totalseconds = (new Date(this.endTime).getTime() - new Date(this.startTime).getTime()) / 1000;
        this.totalHours = ((totalseconds) / (60 * 24)).toFixed(2);
      }
    });
    this.global.checkingLogin();
    // this.verifyTSCreation();
    this.customPickerOptions = {
      buttons: [{
        text: 'Cancel',
        handler: () => {

        }
      }, {
        text: 'Save',
        handler: () => {

        }
      }]
    }
    this.network.onConnect().subscribe(() => {

      this.isOffline = false;
    })
    this.network.onDisconnect().subscribe(() => {

      this.isOffline = true;
    })
  }

  async ionViewWillEnter() {
    if (this.global.applicationVersionNumber != null && this.global.applicationVersionNumber != undefined && this.global.applicationVersionNumber != "") {
      BackgroundGeolocation.stop();
    }
    this.serviceCode = null;
    this.clientAuthId = null;
    this.global.isModalEdit = false;
    this.clientData = null;
    this.notes = "";
    this.idList = [];
    this.serviceName = "";
    this.global.role = JSON.parse(localStorage.getItem("Evv_role"));

    this.completed = false;
    this.isSubmitButton = true;


    if (this.type != undefined || this.type != null) {
      if (!this.global.isOffline) {
        await this.global.checkRefreshTokenValidity()
        {
        this.getIsEditableVisit();
        this.getTimesheet();
        }
      } else if (this.global.isOffline) {
        this.getOfflineClientData();
        this.getActivitesForTS();
        this.getServiceNameOffline(this.clientId, this.serviceId)
      }
    }
    // if (this.type != "signed") {
    //    this.getActivities();
    //  }

    // //totalHours this.getService();
  }

  getOfflineClientData() {
    this.clientData = new MyClient();
    this.sqlite.create({
      name: 'evv.db',
      location: 'default'
    }).then((db: SQLiteObject) => {
      db.executeSql('select DISTINCT * from client where ClientId==' + this.clientId, [])
        .then((data) => {

          if (data.rows.length > 0) {
            this.clientData.clientId = data.rows.item(0).ClientId;
            this.clientData.name = data.rows.item(0).name
            this.clientData.mobileNumber = data.rows.item(0).mobileNumber;
            this.clientData.mobileNumberFormat = data.rows.item(0).mobileNumber;
            this.clientData.email = data.rows.item(0).email;
            this.clientData.street = data.rows.item(0).street;
            this.clientData.cityStateZip = data.rows.item(0).cityStateZip;
          }
        })
        .catch(e => {

        })
    })

  }
  async getTimesheet() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("EvvTimesheetId", this.evvTimesheetId.toString())
    param.append("ClientId", this.clientId.toString())
    await this.global.showLoading();
    this.timesheetService.getTimesheet(param).subscribe((data: any) => {
      this.clientData = data;
      this.clientData.mobileNumberFormat = this.clientData.mobileNumber != null ? this.general.converPhoneGoogleLib(this.clientData.mobileNumber.toString()) : null
      this.serviceName = data.service
      this.startTime = data.startDate
      this.endTime = data.endDate;
      this.serviceCode = data.serviceCodeWithoutMod + " -" + this.serviceName;
      this.clientAuthId = data.clientAuthId;
      let valweeks = data.totalHours.toString().split('.');
      if (valweeks.length == 1) {
        this.totalHours = valweeks[0] + " hr " + "0 Min";
      }
      else {
        let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
        this.totalHours = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + " Min";
      }
      this.idList.push(data.evvTimesheetId);
      this.clientSign = data.clientSign != null ? data.clientSign : data.clientSign;
      this.isDifferentlyAbled = data.isDifferentlyAbled;
      this.employeeSign = data.employeeSign != null ? data.employeeSign : this.employeeSign;
      this.employeeSignId = data.employeeSignId != null ? data.employeeSignId : this.employeeSignId;
      this.notes = this.notes != "" ? this.notes : data.notes;
      if (this.type != "sign") {
        this.activitiesList = data.activities;
        this.isRP = data.isRP != null ? data.isRP : false
      }
      this.VerifyActivities(data.activities);
      this.global.disableloading()
    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
    this.verifyTSCreation();
  }
  }
  async getService() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("Client", this.clientId.toString())
    this.clientService.getServices(param).subscribe((data: Array<Services>) => {
      data.forEach(element => {
        if (element.serviceId == this.serviceId) {
          this.serviceName = element.serviceName;
        }
      });
    }, err => {
      this.errorService.check(err);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  async getClients() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("EmployeeId", this.global.EvvUserDetails.entityId.toString())
    param.append("SearchText", "")
    this.clientService.getClients(param).subscribe((data: Array<MyClient>) => {
      data.forEach(element => {
        if (element.clientId == this.clientId) {
          this.clientData = element;
        }
      });
    }, err => {
      this.errorService.check(err);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  async getActivities() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("Client", this.clientId.toString())
    param.append("Employee", this.global.EvvUserDetails.entityId.toString())
    param.append("service", this.serviceId.toString())
    this.timesheetService.getActivities(param).subscribe((data: any) => {
      this.activitiesList = []
      data.forEach(element => {

        element.activities.forEach(tempdata => {

          let temp: any
          temp = {
            activityId: tempdata.activityId,
            value: tempdata.value,
            isSelected: tempdata.isSelected != null ? tempdata.isSelected : false,
          }

          this.activitiesList.push(temp)
        });


      });
      this.global.disableloading()

    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  back() {
    this.isRP = false;
    this.global.isModalEdit = false;
    this.router.navigate(['/tabs/' + this.backto])
  }
  bactoTs() {
    this.router.navigate(['/tabs/all-timesheets'])
  }
  sign(signType: number) {
    let navigationExtras: NavigationExtras = {
      state: {
        clientId: this.clientId,
        serviceId: this.serviceId,
        startTime: this.startTime,
        endTime: this.endTime,
        clientSign: this.clientSign,
        employeeSign: this.employeeSign,
        signType: signType,
        scheduleId: this.scheduleId,
        evvTimesheetId: this.evvTimesheetId,
        frompage: "timesheetschedule",
        activity: this.activitiesList,
        isRp: this.isRP,
        isRPSign: this.isRP,
        type: "signed",
        notes: this.notes,
        idList: this.idList
      }
    }

    this.router.navigate(['/tabs/signature'], navigationExtras)
  }
  getActivitesForTS() {
    this.activitiesList = [];
    this.sqlite.create({
      name: 'evv.db',
      location: 'default'
    }).then((db: SQLiteObject) => {
      db.executeSql('select * from timesheetActivty where timesheetId==' + this.evvTimesheetId, [])
        .then((data) => {
          if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {
              var Activity = { Id: null, value: null, isSelected: null, activityId: null, evvTimesheetId: null };
              Activity.value = data.rows.item(i).activity;
              Activity.Id = data.rows.item(i).Id;
              Activity.isSelected = data.rows.item(i).isSelected == 0 ? false : true;
              Activity.activityId = data.rows.item(i).activityId;
              Activity.evvTimesheetId = this.evvTimesheetId;
              this.activitiesList.push(Activity);
            }
          }
        })
        .catch(e => {

        })
    })

  }
  CompleteOffline() {
    this.activitiesList.forEach(element => {
      this.sqlService.UpdateTimehseetActivites(element);
    })
    this.global.ToastSuccess("Activities saved Successfully");
    this.router.navigate(['/tabs/all-timesheets']);
    // this.sqlService.UpdateTimehseetActivites()
  }
  async complete() {
    await this.global.checkRefreshTokenValidity()
    {
    this.isSubmitButton = false;
    let val1 = this.activitiesList.filter(a => a.isSelected == true);
    if (val1.length > 0 && this.clientSign != null && this.employeeSign != null) {

      await this.global.showLoading();

      let data: any = [];
      let val = {
        clientId: this.clientData.clientId,
        employeeId: this.global.EvvUserDetails.entityId,
        notes: this.notes != "" ? this.notes : null,
        activities: this.activitiesList,
        clientSign: this.clientSign,
        employeeSign: this.employeeSign,
        evvTimesheetId: this.evvTimesheetId,
        scheduleId: this.evvTimesheetId,
        isrpsign: this.isRP,
      }
      data.push(val);

      this.http.saveweeklytimesheet(data).subscribe((data) => {
        this.isRP = false;
        this.global.disableloading();
        this.isSubmitButton = false;
        this.completed = true;
        setTimeout(() => {
          this.router.navigate(['/tabs/all-timesheets'])
          this.isSubmitButton = true;
        }, 3000);
      }, err => {
        this.isSubmitButton = true;
        this.completed = false;
        this.errorService.check(err);
        this.global.disableloading()
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
    else {
      let val = "Please select an activity & Please complete all signatures"
      this.presentToast(val);
    }
  }
  }
  createInputs() {
    const theNewInputs = [];
    for (let i = 0; i < this.activitiesList.length; i++) {
      if (this.TsType == 'signed') {
        theNewInputs.push(
          {
            type: 'checkbox',
            label: this.activitiesList[i].value,
            value: '' + i,
            checked: this.activitiesList[i].isSelected,
            disabled: true,
            handler: data => {

              if (data.label == this.activitiesList[i].value) {
                this.activitiesList[i].isSelected = data.checked;
              }

            }
          }
        );
      } else {
        theNewInputs.push(
          {
            type: 'checkbox',
            label: this.activitiesList[i].value,
            value: '' + i,
            checked: this.activitiesList[i].isSelected,
            disabled: false,
            handler: data => {

              if (data.label == this.activitiesList[i].value) {
                this.activitiesList[i].isSelected = data.checked;
              }

            }
          }
        );
      }
    }
    return theNewInputs;
  }

  async SelectActivites() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Activities',
      id: "Checkbox",
      inputs: this.createInputs(),
      backdropDismiss: false,
      buttons: [
        {
          id: "CancelActivies",
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          id: "okorsaveActivies",
          text: this.TsType == 'signed' ? 'Ok' : 'Save Activities',
          handler: () => {
            if (this.global.isOffline != true) {
              if (this.TsType != 'signed') {


                this.saveActivities();
              }
            }
            else {
              this.CompleteOffline();
            }




          }
        }
      ]
    });
    await alert.present();
  }

  async NotesInput() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Notes',
      inputs: [
        {
          name: 'notes',
          id: 'notes',
          type: 'textarea',
          value: this.notes,
          disabled: this.TsType == 'signed' ? true : false,
          handler: (data) => {

          },
          placeholder: 'Enter Notes Here'
        }
      ],
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: this.TsType == 'signed' ? 'Ok' : 'Save Notes',
          handler: (data) => {
            if (this.TsType != 'signed') {
              this.notes = data.notes;
              this.saveNotes();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async EmpSignAgreement() {
    const alert = await this.alertController.create({
      header: 'Acknowledgement',
      backdropDismiss: false,
      message: 'I certify and swear under penalty of law that I have accurately reported on this time sheet the hours I actually worked, the services I provided, and the dates and times worked. I understand that misreporting my hours is fraud for which I could face criminal prosecution and civil proceedings.',
      buttons: [
        {
          id: "CancelEmployeeAcknowledgement",
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          id: "AgreeEmployeeAcknowledgement",
          text: 'Agree',
          handler: () => {

            this.sign(2);

          }
        }
      ]
    });
    await alert.present();
  }
  async CliSignAgreement() {
    const alert = await this.alertController.create({
      header: 'Acknowledgement',
      backdropDismiss: false,
      message: 'Review the completed time sheet for accuracy before signing.It is a federal crime to provide false information on personal care service billings for Medical Assistance payment. By signing below, you swear and verify the time and services entered above are accurate and that the services were performed by the PCA listed below as specified in the PCA Care Plan.',
      buttons: [
        {
          id: "CancelEmployeeAcknowledgement",
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          id: "AgreeClientAcknowledgement",
          text: 'Agree',
          handler: () => {

            this.sign(1);

          }
        }
      ]
    });
    await alert.present();
  }

  selected: boolean = false;
  VerifyActivities(activities) {
    this.selected = false;
    activities.forEach(data => {
      if (data.isSelected == true) {
        this.selected = true;
      }
    });
    return this.selected;
  }

  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'danger',

    });



    toast.present();
  }

  getServiceNameOffline(clientId, serviceId) {
    var svc = '';
    this.sqlite.create({
      name: 'evv.db',
      location: 'default'
    }).then((db: SQLiteObject) => {
      db.executeSql('select DISTINCT * from service where serviceId==' + serviceId, [])
        .then((data) => {

          if (data.rows.length > 0) {
            this.serviceName = data.rows.item(0).service;
          }
        })
        .catch(e => {

        })
    })

  }

  async saveActivities() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    var saveBO = new saveActivitiesBO();
    saveBO.activities = this.activitiesList;
    saveBO.evvTsId = this.evvTimesheetId;
    this.timesheetService.saveTsActivities(saveBO).subscribe((data) => {
      this.returnData = data;
      this.global.ToastSuccess1("Activities Saved Successfully");
      this.global.disableloading();
      this.verifyTSCreation();
    }, (err) => {
      this.global.disableloading();
      this.errorService.check(err);
      this.global.Toastdanger(err.message);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }

  async saveNotes() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    var saveBO = new saveTsNotesBO();
    saveBO.note = this.notes != "" ? this.notes : null;
    saveBO.evvTsId = this.evvTimesheetId;
    this.timesheetService.saveTsNotes(saveBO).subscribe((data) => {
      this.global.ToastSuccess("Notes Saved Successfully");
      this.global.disableloading();
    }, (err) => {
      this.global.disableloading();
      this.errorService.check(err);
      this.global.Toastdanger(err.message);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }

  verifyTSCreation() {

    if (this.returnData != null) {
      if (this.returnData.TSCreated == true) {
        this.global.disableloading();
        this.completed = true;
        setTimeout(() => {
          this.global.ToastSuccess("Timesheet Completed  Successfully");
          this.router.navigate(['/tabs/all-timesheets'])
          // this.isSubmitButton = true;
          this.completed = false;
        }, 3000);
      }
    }
  }

  checkEditedTimes(clientData) {

    let clientRecords = JSON.parse(JSON.stringify(clientData));
    this.updateVisitBO.evvTimesheetId = clientRecords.evvTimesheetId;
    this.clientRecordsstartDate = clientRecords.startDate;
    this.clientRecordsendDate = clientRecords.endDate;
    if (this.platform.is("android")) {
      this.updateVisitBO.visitStartTime = clientRecords.startDate != null ? this.datepipe.transform(clientRecords.startDate, "yyyy-MM-dd HH:mm:ss") : null;
      this.updateVisitBO.visitEndTime = clientRecords.endDate != null ? this.datepipe.transform(clientRecords.endDate, "yyyy-MM-dd HH:mm:ss") : null;
    }
    else {
      this.updateVisitBO.visitStartTime = clientRecords.startDate;
      this.updateVisitBO.visitEndTime = clientRecords.endDate;
    }
    this.updateVisitBO.masterServiceId = clientRecords.serviceId;
    this.updateVisitBO.note = clientRecords.notes;
    this.updateVisitBO.visitTypeLid = clientRecords.visitTypeLid;
    this.global.isModalEdit = false;
    setTimeout(() => {
      this.global.isModalEdit = true;
    }, 100);
  }
  async getIsEditableVisit() {
    await this.global.checkRefreshTokenValidity()
    {
    this.timesheetService.getIsEditableVisit().subscribe((data: any) => {
      this.IsEditableVisit = data;
    }, err => {
      this.errorService.check(err);

      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }

  }
  async UpdateVisitInMobile() {
    await this.global.checkRefreshTokenValidity()
    {
    var currentdate = new Date();
    let Cd = this.global.DateandTimeFormat(currentdate);
    const apiStartDate = new Date(this.clientRecordsstartDate);
    const apiEndDate = new Date(this.clientRecordsendDate);
    const selectedStartDate = new Date(this.updateVisitBO.visitStartTime);
    const selectedEndDate = new Date(this.updateVisitBO.visitEndTime);
    if (this.clientAuthId == null || this.clientAuthId == undefined || this.clientAuthId == 0) {
      this.global.ToastDangerMiniDuration("Unable to modify time here, please try it on the agency application.")
      return
    }
    if (this.updateVisitBO.visitStartTime != null && this.updateVisitBO.visitEndTime != null && this.updateVisitBO.visitStartTime != "" && this.updateVisitBO.visitEndTime != "")
      if (new Date(this.updateVisitBO.visitStartTime).getTime() > new Date(this.updateVisitBO.visitEndTime).getTime()) {
        this.global.ToastDangerMiniDuration("End Time Should greater than Start Time");
        return;
      }
    if (new Date(Cd).getDate() == new Date(this.updateVisitBO.visitStartTime).getDate()) {
      if (new Date(currentdate).getTime() < new Date(this.updateVisitBO.visitStartTime).getTime()) {
        this.global.ToastDangerMiniDuration("Future Timings are not allowed!");
        return;
      }
    }
    if (new Date(Cd).getDate() == new Date(this.updateVisitBO.visitEndTime).getDate()) {
      if (new Date(currentdate).getTime() < new Date(this.updateVisitBO.visitEndTime).getTime()) {
        this.global.ToastDangerMiniDuration("Future Timings are not allowed!");
        return;
      }
    }
   
    await this.global.showLoading();
    if (this.platform.is("android")) {
      this.updateVisitBO.visitStartTime = this.global.getDateTimeString(this.updateVisitBO.visitStartTime);
      this.updateVisitBO.visitEndTime = this.global.getDateTimeString(this.updateVisitBO.visitEndTime);
    }
    this.updateVisitBO.isVisitEdited = true;
    this.updateVisitBO.clientAuthId = this.clientAuthId != null && this.clientAuthId != 0 ? parseInt(this.clientAuthId) : 0,
      this.timesheetService.UpdateVisitInMobile(this.updateVisitBO).subscribe((data) => {
        this.global.disableloading();
        if (data.length > 0) {
          this.global.ToastDangerMiniDuration(data);
          return;
        }
        else {
          this.global.ToastSuccess("Timesheet updated successfully");
          this.global.isModalEdit = false;
          this.getTimesheet();
        }


      }, (err) => {
        this.global.disableloading();
        this.global.isModalEdit = true;
        this.errorService.check(err);
        this.global.Toastdanger(err.message);
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }}
  closeEditedTimesModal() {
    this.global.isModalEdit = false;
  }
}
