import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
@Injectable({
  providedIn: 'root'
})
export class MyCalendarService {
  constructor(private httpClient: HttpClient, public errorService: ErrorService, public global: Globalservice) { }
  public getEmployee(param: URLSearchParams): Observable<any> {
    let url = "api/EvvClient/getEmployee?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public getServices(param: URLSearchParams): Observable<any> {
    // let url = "api/EvvClient/getServiceClient?"
    let url="api/VisitSchedule/getClientServiceDropdown?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public saveCalendarTimesheet(data): Observable<any> {

    let url = "api/VisitSchedule/SaveCalendarVisitSchedule"
    return this.httpClient.post(url , data, { observe: 'response', responseType: "text" },).pipe(catchError(this.errorService.handleError));
  }

  
  public getCalendarList(param: URLSearchParams): Observable<any> {
    // let url="api/Schedule/getCalendarScheduleList?"
    let url = "api/VisitSchedule/getCalendarVisitScheduleList?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  getLovDropDown(paramsData: URLSearchParams): Observable<any[]> {
    let url = "api/LOV/getLovDropDown?"
    return this.httpClient.get<any[]>(url + paramsData, { observe: 'response' }).pipe(
      map(data => data.body),
      catchError(this.errorService.handleError)
    )
  }

}
