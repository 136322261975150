import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AlertController, ToastController } from '@ionic/angular';
import { SignaturePad } from 'angular2-signaturepad';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { ClientSignAllTimesheetService } from '../client-sign-all-timesheet/client-sign-all-timesheet.service';
import { SaveSignBO } from './client-signature.model';

@Component({
  selector: 'app-client-signature',
  templateUrl: './client-signature.page.html',
  styleUrls: ['./client-signature.page.scss'],
})
export class ClientSignaturePage {
  drawstart: boolean = false;
  deviceWidth: any;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  public signaturePadOptions: Object;
  signimg: any = null;
  width: any;
  startTime: string = null;
  endTime: string = null;
  clientSign: string = null;
  employeeSign: string = null;
  evvTimesheetId:number=0;
  clientId: number;
  serviceId: number;
  signType:number;
  type:string;
  clientData:any;
  scheduleId:number;
  Activivity:any;
  isRp:boolean=false;
  idList: any =[];
  navdata: any;
  SaveSignBO: SaveSignBO = new SaveSignBO();
  notes:string="";
  returnData: any;
  constructor(public alertCtrl: AlertController, private screenOrientation: ScreenOrientation,public http: ClientSignAllTimesheetService,private toastCtrl: ToastController,
    private router: Router, public global: Globalservice,private route: ActivatedRoute,private httpClient: HttpClient,public errorService: ErrorService) {
      this.route.queryParams.subscribe(params => {
        if (this.router.getCurrentNavigation().extras.state) {
          let data = this.router.getCurrentNavigation().extras.state;
          this.navdata=data;
          this.type = data.type;
        
          if(this.type=="signed"){
          this.clientId = data.clientId;
          this.serviceId = data.serviceId;
          this.startTime = data.startTime;
          this.endTime = data.endTime;
          this.clientSign = data.clientSign;
          this.employeeSign = data.employeeSign;
          this.signType = data.signType;
          this.scheduleId=data.scheduleId;
          this.evvTimesheetId=data.evvTimesheetId;
          this.Activivity=data.activity
          this.isRp=data.isRp;
          this.notes=data.notes;
          this.idList=data.idList;
         
          
          }
          else if(this.type=="all-sign"){
            this.clientData = data.data;
            this.clientSign = data.clientSign;
            this.employeeSign = data.employeeSign;
            this.signType = data.signType;
            this.idList=data.idList;
            this.isRp=data.isRp;
          }
        }
        this.signimg=null;
      });
      this.global.checkingLogin();
    if (window.innerWidth < 650) {
      this.width = window.innerWidth - 33;
      this.deviceWidth = (window.innerWidth - 30) + 'px';
    }
    else {
      this.width = 600;
      this.deviceWidth = 603 + 'px';
    }
    this.signaturePadOptions = {
      minWidth: 5,
      canvasWidth: this.width,
      canvasHeight: 200,
      penColor:"blue"
      
    }
    this.screenOrientation.onChange().subscribe(
      () => {
        setTimeout(() => {
          this.getDeviceWidth();
        }, 200)
      }
    );
  }

  getDeviceWidth() {
    if (window.innerWidth < 650) {
      this.width = window.innerWidth - 33;
      this.deviceWidth = (window.innerWidth - 30) + 'px';
      this.canvasResize();

    }
    else {
      this.width = 600;
      this.deviceWidth = 603 + 'px';
      this.canvasResize();
    }
  }
  canvasResize() {
    this.signaturePad.set("minWidth", 1);
    this.signaturePad.set("canvasWidth", this.width);
    this.signaturePad.set("canvasHeight", 200);
    if (!this.signaturePad.isEmpty()) {
      this.signaturePad.fromDataURL(this.signimg);
    }
  }
  ionViewWillEnter(){
   
    this.ngAfterViewInit();
  }
  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set("minWidth", 5); // set signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from signature_pad API
    this.signimg = null ;
    this.canvasResize();
   
    this.drawstart = false;
  }

  drawComplete() {
    // will be notified of signature_pad's onEnd event

    this.signimg = this.signaturePad.toDataURL();
    
  }
  drawStart() {
    // will be notified of signature_pad's onBegin event
  
    this.drawstart = true;
  }
  back() {
    let navigationExtras: NavigationExtras;
    
    if(this.type=="signed"){
        navigationExtras = {
      state: {
        clientId: this.clientId,
        serviceId: this.serviceId,
        startTime:this.startTime,
        endTime:this.endTime,
        clientSign:this.clientSign,
        employeeSign:this.employeeSign,
        scheduleId:this.scheduleId,
        activity:this.Activivity,
        isRP:this.isRp,
        backto:"all-timesheets",
       type:"sign",
        isSubmitButton:true,
        notes:this.notes,
        evvTimesheetId:this.evvTimesheetId,
        returnData:this.returnData
      }
    }
    this.router.navigate(['/client/client-timesheet'],navigationExtras)

  }
  else if(this.type=="all-sign"){
    navigationExtras = {
      state: {
        data: this.clientData,
        clientSign:this.clientSign,
        employeeSign:this.employeeSign,
        returnData:this.returnData
      }
    }
    this.router.navigate(['/client/client-sign-all-timesheet'],navigationExtras)

  }
  }
  async submit(){
    await this.global.checkRefreshTokenValidity()
    {
    if (this.signimg == null || this.signimg == undefined|| this.signimg == '') { 
      this.global.Toastdanger("Please enter your signature")
      return;
    }
    await this.global.showLoading();
    
    this.SaveSignBO.ids=this.idList;
    this.SaveSignBO.sign=this.signimg;
    this.SaveSignBO.employeeId=this.global.EvvUserDetails.entityId;
    this.SaveSignBO.isRp=this.isRp;
    let url ='';
    this.http.saveEMployeeSign(this.SaveSignBO).subscribe((data: any) => {
      this.returnData=data;
      this.presentToast('Sign Saved Successfully','success')
      this.navigateBackaftersign();
      this.global.disableloading();
    }, err => {
      this.errorService.check(err);
      this.global.disableloading();
      this.presentToast('Error:'+err.message,'danger')
      if(err.Status==401){
        this.global.GloballogOut();
      }
    })}
   
  }

  navigateBackaftersign(){
  
    if(this.signType==1){
      this.clientSign = this.signimg;
    }
    else if(this.signType==2){
      this.employeeSign = this.signimg;
    }
    let navigationExtras: NavigationExtras;
   
    if(this.type=="signed"){
        navigationExtras = {
      state: {
        clientId: this.clientId,
        serviceId: this.serviceId,
        startTime:this.startTime,
        endTime:this.endTime,
        clientSign:this.clientSign,
        employeeSign:this.employeeSign,
        scheduleId:this.scheduleId,
        activity:this.Activivity,
        isRP:this.isRp,
        backto:"all-timesheets",
       type:"sign",
        isSubmitButton:true,
        returnData:this.returnData,
        evvTimesheetId:this.evvTimesheetId,
      }
    }
    this.router.navigate(['/client/client-timesheet'],navigationExtras)

    }
    else if(this.type=="all-sign"){
      navigationExtras = {
        state: {
          data: this.clientData,
          clientSign:this.clientSign,
          returnData:this.returnData,
          employeeSign:this.employeeSign
        }
      }
      this.router.navigate(['/client/client-sign-all-timesheet'],navigationExtras)

    }
  }

  async presentToast(msg,color) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 1500,
      position: 'top',
      color: color,

    });



    toast.present();
  }
}
