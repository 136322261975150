import { Component } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { ActionSheetController, AlertController, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { scheduleDashboardEmployeeBO } from './employeescheduledashboard.model';
import { MyEmployeeDashboardService } from './employeescheduledashboard.service';
@Component({
  selector: 'app-employeescheduledashboard',
  templateUrl: './employeescheduledashboard.page.html',
  styleUrls: ['./employeescheduledashboard.page.scss'],
})
export class EmployeescheduledashboardPage {
  scheduleList: Array<scheduleDashboardEmployeeBO> = [];
  fromDate: any = new Date().toISOString();
  toDate: any = new Date().toISOString();
  searchText: any = "";
  count: number = 0;
  datanotfound: boolean = false;
  isOffline: boolean = false;
  constructor(public clientService: MyEmployeeDashboardService, public errorService: ErrorService, public actionSheetCtrl: ActionSheetController,
    private alertController: AlertController, public global: Globalservice, private network: Network,
    public general: generalservice, public toastCtrl: ToastController) {
    this.network.onConnect().subscribe(() => {
      this.isOffline = false;
    })
    this.network.onDisconnect().subscribe(() => {
      this.isOffline = true;
    })
  }
  async ionViewWillEnter() {
    if (this.global.timezone == "US") {
      this.fromDate = this.global.getDateWithStartTime(new Date());
      this.toDate = this.global.getDateWithEndTime(new Date());
    }
    else {
      this.fromDate = new Date().toISOString();
      this.toDate = new Date().toISOString();

    }
    await this.global.checkRefreshTokenValidity()
    {
    this.getClientsSchduleDashboard();
    }
  }
  //========================Get schedule for dashboard
  async getClientsSchduleDashboard() {

    let fromdate;
    let toDate;
    if (this.global.timezone == "US") {
      fromdate = this.global.getDateWithStartTime(this.fromDate);
      toDate = this.global.getDateWithEndTime(this.toDate);
    }
    else {
      fromdate = this.fromDate
      toDate = this.toDate
    }

    if (new Date(new Date(this.fromDate).toDateString()).getTime() > new Date(new Date(this.toDate).toDateString()).getTime()) {
      this.presentToast('Error: Enddate must be greater than Fromdate', 'danger')
    } else {
      let param = new URLSearchParams();
      param.append("EmployeeId", this.global.EvvUserDetails.entityId.toString());
      // param.append("start",new Date(new Date(this.fromDate).toDateString() + " " + new Date(this.fromDate).toLocaleTimeString() + " " + "GMT").toISOString());
      // param.append("start",new Date(this.fromDate).toLocaleDateString());
      param.append("start", fromdate);
      // param.append("end",new Date(new Date(this.toDate).toDateString() + " " + new Date(this.toDate).toLocaleTimeString() + " " + "GMT").toISOString());
      // param.append("end",new Date(this.toDate).toLocaleDateString());
      param.append("end", toDate);
      param.append("searchText", this.searchText);
      await this.global.checkRefreshTokenValidity()
      {
      await this.global.showLoading();;
      this.clientService.getDashboardDetailsNew(param).subscribe((data: Array<scheduleDashboardEmployeeBO>) => {
        this.scheduleList = data;
        if (data.length > 0) {
          this.scheduleList.forEach(c => c.employeePhnFormat = c.employeePhn != null ? this.general.converPhoneGoogleLib(c.employeePhn.toString()) : null)
          this.datanotfound = false;
        }
        else {
          this.datanotfound = true;
        }
        this.global.disableloading();
      }, err => {
        this.errorService.check(err);
        this.global.disableloading()
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
    }
  }

  ///----------  Show Appointment cancel sheet 
  presentActionSheet(clientData: scheduleDashboardEmployeeBO) {
    this.presentAlertConfirm(clientData);
  }
  async presentAlertConfirm(clientData: scheduleDashboardEmployeeBO) {
    const alert = await this.alertController.create({
      header: 'Cancel Appointment',
      message: '<strong>Do you want to cancel this appointment?</strong>',
      buttons: [
        {
          id: "CancelAppointmentYes",
          text: 'Yes',
          handler: () => {
            this.cancelRequest(clientData);
          }
        },
        {
          id: "CancelAppointmentNo",
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }
      ]
    });

    await alert.present();
  }
  //=================Cancel  requested appointment
  async cancelRequest(clientData: scheduleDashboardEmployeeBO) {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();;
    this.clientService.cancelClientAppt(clientData).subscribe((data: any) => {
      this.presentToast('Schedule has been cancelled successfully', 'success');
      this.getClientsSchduleDashboard();
      this.global.disableloading();
    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  //=============Toast controller
  async presentToast(msg, color) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: color,
    });
    await toast.present();
  }
}
