import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ClientLanguagesPageRoutingModule } from './client-languages-routing.module';
import { ClientLanguagesPage } from './client-languages.page';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ClientLanguagesPageRoutingModule,
    TranslateModule,
  
  ],
  declarations: [ClientLanguagesPage],

  
})
export class ClientLanguagesPageModule {}
