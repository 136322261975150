import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { DatePipe } from '@angular/common';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { saveActivitiesBO, saveTsNotesBO } from '../my-clients/my-clients.model';
import { MyClientsService } from '../my-clients/my-clients.service';
import { SignAllTimesheetService } from './sign-all-timesheet.service';
import { TimesheetService } from '../timesheet/timesheet.service';
@Component({
  selector: 'app-sign-all-timesheet',
  templateUrl: './sign-all-timesheet.page.html',
  styleUrls: ['./sign-all-timesheet.page.scss'],
})
export class SignAllTimesheetPage {
  returnData: any;
  clientData: any;
  clientSign: string = null;
  employeeSign: string = null;
  employeeSignId: any;
  clientAuthId:any;
  EditEndTime: any;
  selected: boolean = false;
  EditvisitTypeLid;
  EditmasterServiceId: any;
  EditEvvtimesheetid: any;
  Editnotes: any;
  serviceCode:any="";
  IsSignAllTimesheetEditableVisit: any;
  EditStartTime: any;
  isAllowanyonetosign: boolean = false;
  isDifferentlyAbled: boolean = false;
  start: string;
  end: string;
  clientRecordsstartDate: any = "";
  clientRecordsendDate: any = "";
  isRP: boolean = false;
  tempdata: any = [];
  isDataLoaded: boolean = false;
  idList: any = [];
  completed: boolean = false;
  isOffline: boolean = false;
  tsType: any = "";
  constructor(private datepipe: DatePipe, public clientService: MyClientsService, public errorService: ErrorService, public timesheetService: TimesheetService,
    private router: Router, private route: ActivatedRoute, public global: Globalservice, public http: SignAllTimesheetService,
    private toastCtrl: ToastController, public general: generalservice, public platform: Platform, public alertController: AlertController,
    private network: Network,) {

    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.clientData = data.data;
        this.tempdata = data
        this.start = data.data.startDate
        this.end = data.data.endDate
        this.clientSign = data.clientSign;
        this.employeeSign = data.employeeSign;
        this.employeeSignId = data.employeeSignId;
        this.tsType = data.tsType;
        this.returnData = data.returnData;
      }
      this.network.onConnect().subscribe(() => {
        this.isOffline = false;
      })
      this.network.onDisconnect().subscribe(() => {
        this.isOffline = true;
      })
    });
    this.global.checkingLogin();
  }
  async ionViewWillEnter() {
    this.serviceCode=null;
    this.clientAuthId=null;
    this.global.isModalSignAllTimesheetEdit = false;
    await this.global.checkRefreshTokenValidity()
    {
    this.getweekly();
    }
    this.completed = false;
    this.global.role = JSON.parse(localStorage.getItem("Evv_role"));
  }
  async getweekly() {
    await this.global.checkRefreshTokenValidity()
    {
    this.getIsEditableVisit()
    if (this.tempdata.type == "timesheet") {
      this.idList = [];
      await this.global.showLoading();
      let param = new URLSearchParams();
      param.append("employeeId", this.global.EvvUserDetails.entityId.toString());
      param.append("clientId", this.clientData.clientId.toString())
      param.append("startDate", this.start)
      param.append("endDate", this.end)
      this.http.getWeeklydata(param).subscribe((data: any) => {
        if (data != null) {
          this.clientData = data.result;
          this.isDifferentlyAbled=this.clientData.isDifferentlyAbled;
          this.employeeSign = this.clientData.employeeSign == null ? null : this.clientData.employeeSign;
          this.employeeSignId = this.clientData.employeeSignId == null ? null : this.clientData.employeeSignId;
          this.clientSign = this.clientData.clientSign == null ? null : this.clientData.clientSign;
          this.clientData.mobileNumberFormat = this.clientData.mobileNumber != null ? this.general.converPhoneGoogleLib(this.clientData.mobileNumber.toString()) : null
          this.clientData.records.forEach(c => {
            let valweeks = c.totalHours.toString().split('.');
            if (valweeks.length == 1) {
              c.totalHours = valweeks[0] + " hr " + "0 Min";
            }
            else {
              let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
              c.totalHours = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + " Min";
            }
            this.idList.push(c.evvTimesheetId);
          });
          this.isRP = this.clientData.isRP != null ? this.clientData.isRP : false;
        };
        this.isDataLoaded = true;
        this.global.disableloading();

      }, err => {
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
    this.verifyTSCreation();
  }
  }
  back() {

    this.isRP = false;
    this.global.isModalSignAllTimesheetEdit = false;
    this.router.navigate(['/tabs/all-timesheets'])
  }
  sign(signType: number) {
    this.clientData.startDate=this.start
    this.clientData.endDate=this.end;
    let navigationExtras: NavigationExtras = {
      state: {
        isRPSign:this.isRP,
        data: this.clientData,
        clientSign: this.clientSign,
        employeeSign: this.employeeSign,
        signType: signType,
        type: "all-sign",
        idList: this.idList,
        clientId: this.clientData.clientId
      }
    }
    
    this.router.navigate(['/tabs/signature'], navigationExtras)
  }
  async complete() {
    await this.global.checkRefreshTokenValidity()
    {
    let activitycehck: number = 0;
    let data: any = [];
    this.clientData.records.forEach((element) => {
      let val = element.activities.filter(a => a.isSelected == true);
      if (val.length == 0) {
        activitycehck = activitycehck + 1;
      }
    })
    if (activitycehck == 0 && this.clientSign != null && this.employeeSign != null) {
      await this.global.showLoading();
      this.clientData.records.forEach((element) => {
        let val = {
          clientId: this.clientData.clientId,
          employeeId: this.global.EvvUserDetails.entityId,
          notes: element.notes,
          activities: element.activities,
          clientSign: this.clientSign,
          employeeSign: this.employeeSign,
          scheduleId: element.scheduleId,
          evvTimesheetId: element.evvTimesheetId,
          isrpsign: this.isRP
        }
        data.push(val);
      })
      this.http.saveweeklytimesheet(data).subscribe((data) => {
        this.isRP = false;
        this.router.navigate(['/tabs/all-timesheets'])
        this.global.disableloading()

      }, err => {
        this.presentToast(err.message);
        this.errorService.check(err);
        this.global.disableloading()
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
    else {
      let val = "Please select an activity & Please complete all signatures"
      this.presentToast(val);
    }
  }
  }
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'danger',

    });
    toast.present();
  }
  createInputs(activities) {
    const theNewInputs = [];
    for (let i = 0; i < activities.length; i++) {
      theNewInputs.push(
        {
          type: 'checkbox',
          label: activities[i].value,
          value: '' + i,
          checked: activities[i].isSelected,
          handler: data => {
            if (data.label == activities[i].value) {
              activities[i].isSelected = data.checked;
            }
          }
        }
      );
    }
    return theNewInputs;
  }
  async SelectActivites(activities, tsId) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Activities',
      inputs: this.createInputs(activities),
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: this.tsType == 'signed' ? 'Ok' : 'Save Activities',
          handler: () => {
            if (this.tsType != 'signed') {
              this.saveActivities(activities, tsId);
            }
          }
        }
      ]
    });
    await alert.present();
  }
  async NotesInput(note, tsId) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Notes',
      inputs: [
        {
          name: 'notes',
          id: 'notes',
          type: 'textarea',
          value: note,
          disabled: this.tsType == 'signed' ? true : false,
          handler: (data) => {

          },
          placeholder: 'Enter Notes Here'
        }
      ],
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: this.tsType == 'signed' ? 'Ok' : 'Save Notes',
          handler: (data) => {
            if (this.tsType != 'signed') {
              note = data.notes;
              this.saveNotes(note, tsId);
            }
          }
        }
      ]
    });
    await alert.present();
  }
  VerifyActivities(activities) {
    this.selected = false;
    activities.forEach(data => {
      if (data.isSelected == true) {
        this.selected = true;
      }
    });
    return this.selected;
  }
  async EmpSignAgreement() {
    const alert = await this.alertController.create({
      header: 'Acknowledgement',
      backdropDismiss: false,
      message: 'I certify and swear under penalty of law that I have accurately reported on this time sheet the hours I actually worked, the services I provided, and the dates and times worked. I understand that misreporting my hours is fraud for which I could face criminal prosecution and civil proceedings.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Agree',
          handler: () => {

            this.sign(2);
          }
        }
      ]
    });
    await alert.present();
  }
  async CliSignAgreement() {
    const alert = await this.alertController.create({
      header: 'Acknowledgement',
      backdropDismiss: false,
      message: 'Review the completed time sheet for accuracy before signing.It is a federal crime to provide false information on personal care service billings for Medical Assistance payment. By signing below, you swear and verify the time and services entered above are accurate and that the services were performed by the PCA listed below as specified in the PCA Care Plan.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Agree',
          handler: () => {
            this.sign(1);
          }
        }
      ]
    });
    await alert.present();
  }
  bactoTs() {
    this.router.navigate(['/tabs/all-timesheets'])
  }
  async saveActivities(acts, tsId) {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    var saveBO = new saveActivitiesBO();
    saveBO.activities = acts;
    saveBO.evvTsId = tsId;
    this.http.saveTsActivities(saveBO).subscribe((data) => {
      this.returnData = data;
      this.global.ToastSuccess("Activities Saved Successfully");
      this.global.disableloading();
      this.verifyTsCreationActivities(tsId);
    }, (err) => {
      this.global.disableloading();
      this.errorService.check(err);
      this.global.Toastdanger(err.message);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  async saveNotes(note, tsId) {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    var saveBO = new saveTsNotesBO();
    saveBO.note = note != "" ? note : null;
    saveBO.evvTsId = tsId;
    this.http.saveTsNotes(saveBO).subscribe((data) => {
      this.global.ToastSuccess("Notes Saved Successfully");
      this.global.disableloading();
    }, (err) => {
      this.global.disableloading();
      this.errorService.check(err);
      this.global.Toastdanger(err.message);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  verifyTSCreation() {
    if (this.returnData != null) {
      if (this.returnData.TSCreated == true) {
        this.global.disableloading();
        this.completed = true;
        setTimeout(() => {
          this.global.ToastSuccess("Timesheet(s) Completed  Successfully");
          this.router.navigate(['/tabs/all-timesheets'])
          this.completed = false;
        }, 3000);
      }
    }
  }
  verifyTsCreationActivities(evvid) {
    if (this.returnData != null) {
      if (this.returnData.TSCreated == true) {
        if (this.clientData.records.length > 1) {
          this.clientData.records.forEach(c => {
            if (c.evvTimesheetId == evvid) {
              this.clientData.records.splice(c.index, 1);
            }
          })
          this.global.disableloading();
          this.completed = true;
          setTimeout(() => {
            this.global.ToastSuccess("Timesheet Completed Successfully");
            this.completed = false;
          }, 3000);
        } else {
          this.global.disableloading();
          this.completed = true;
          setTimeout(() => {
            this.global.ToastSuccess("Timesheet Completed Successfully");
            this.router.navigate(['/tabs/all-timesheets'])
            this.completed = false;
          }, 3000);
        }
      }
    }
  }
  checkSignAllTimesheetEditedTimes(item) {
    this.global.isModalSignAllTimesheetEdit = false;
    setTimeout(() => {
      this.global.isModalSignAllTimesheetEdit = true;
    }, 100);

    if (this.platform.is("android")) {
      this.EditStartTime = item.startTime != null ? this.datepipe.transform(item.startTime, "yyyy-MM-dd HH:mm:ss") : null;
      this.EditEndTime = item.endTime != null ? this.datepipe.transform(item.endTime, "yyyy-MM-dd HH:mm:ss") : null;
    }
    else {
      this.EditStartTime = item.startTime;
      this.EditEndTime = item.endTime;
    }
    this.EditvisitTypeLid = item.visitTypeLid;
    this.EditmasterServiceId = item.masterServiceId;
    this.Editnotes = item.notes;
    this.EditEvvtimesheetid = item.evvTimesheetId;
    this.serviceCode=item.serviceCodeWithoutMod + " -" + item.service ;
    this.clientAuthId=item.clientAuthId;
    this.clientRecordsstartDate = item.startTime;
    this.clientRecordsendDate = item.endTime;
    
  }
  async UpdateSignAllTimesheetVisitInMobile() {
    await this.global.checkRefreshTokenValidity()
    {
    var currentdate = new Date();
    let Cd = this.global.DateandTimeFormat(currentdate);
    const apiStartDate = new Date(this.clientRecordsstartDate);
    const apiEndDate = new Date(this.clientRecordsendDate);
    const selectedStartDate = new Date(this.EditStartTime);
    const selectedEndDate = new Date(this.EditEndTime);
   
    if(this.clientAuthId==null ||this.clientAuthId==undefined || this.clientAuthId==0)
    {
      this.global.ToastDangerMiniDuration("Unable to modify time here, please try it on the agency application.")
      return
    }
    if (this.EditStartTime != null && this.EditEndTime != null && this.EditStartTime != "" && this.EditEndTime != "")
      if (new Date(this.EditStartTime).getTime() > new Date(this.EditEndTime).getTime()) {
        this.global.ToastDangerMiniDuration("End Time Should greater than Start Time");
        return;
      }
    if (new Date(Cd).getDate() == new Date(this.EditStartTime).getDate()) {
      if (new Date(currentdate).getTime() < new Date(this.EditStartTime).getTime()) {
        this.global.ToastDangerMiniDuration("Future Timings are not allowed!");
        return;
      }
    }
    if (new Date(Cd).getDate() == new Date(this.EditEndTime).getDate()) {
      if (new Date(currentdate).getTime() < new Date(this.EditEndTime).getTime()) {
        this.global.ToastDangerMiniDuration("Future Timings are not allowed!");
        return;
      }
    }
    await this.global.showLoading();
    let savePayload = {
      evvTimesheetId: this.EditEvvtimesheetid,
      masterServiceId: this.EditmasterServiceId,
      visitStartTime: ((this.platform.is("android")) ? this.global.getDateTimeString(this.EditStartTime) : this.EditStartTime),
      visitEndTime: ((this.platform.is("android")) ? this.global.getDateTimeString(this.EditEndTime) : this.EditEndTime),
      visitTypeLid: this.EditvisitTypeLid,
      note: this.Editnotes,
      isVisitEdited: true,
      clientAuthId:this.clientAuthId!=null &&this.clientAuthId!=0?parseInt(this.clientAuthId):0,
    }
    this.timesheetService.UpdateVisitInMobile(savePayload).subscribe((data) => {
      this.global.disableloading();
      if (data.length > 0) {
        this.global.ToastDangerMiniDuration(data);
        return;
      }
      else {
        this.EditStartTime = null;
        this.EditEndTime = null;
        this.EditvisitTypeLid = null;
        this.EditmasterServiceId = null;
        this.Editnotes = null;
        this.EditEvvtimesheetid = null;
        this.global.ToastSuccess("Timesheet updated successfully");
        this.global.isModalSignAllTimesheetEdit = false;
        this.tempdata.type = "timesheet";
        this.tsType ="unSigned";
        this.getweekly();
      }
    }, (err) => {
      this.global.disableloading();
      this.global.disableloading();
      this.errorService.check(err);
      this.global.Toastdanger(err.message);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
    }}
  async getIsEditableVisit() {
    await this.global.checkRefreshTokenValidity()
    {
    this.timesheetService.getIsEditableVisit().subscribe((data: any) => {
      this.IsSignAllTimesheetEditableVisit = data;

    }, err => {
      this.errorService.check(err);

      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  closeModalSignAllTimesheetEditModal() {
    this.global.isModalSignAllTimesheetEdit = false;
  }
}
