import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActionSheetController,
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { Activity } from '../my-clients/my-clients.model';
import {
  ManualETimesheetDataBO,
  ManualETimesheetsBO,
  ManualETimesheetsSaveBO,
  WeeklyManualETimesheetRecordsBO,
} from './manual-electronic-timesheet.model';
import { ManualElectronicTimeSheetService } from './manual-electronic-timesheet.service';
import { MyClientsService } from '../my-clients/my-clients.service';
import { flush } from '@angular/core/testing';

@Component({
  selector: 'app-manual-electronic-timesheet',
  templateUrl: './manual-electronic-timesheet.page.html',
  styleUrls: ['./manual-electronic-timesheet.page.scss'],
})
export class ManualElectronicTimesheetPage implements OnInit {
  selectedTemplateValues: any = 0;
  templateTitle: string;
  isDeleteAlert: boolean = false;
  serviceRemaingHoursandMinutes: any;
  serviceRemaingHoursPerDay: any;
  serviceRemaingHoursPerWeek: any;
  WeekDates: WeeklyManualETimesheetRecordsBO[] = [];
  isTemplateView: boolean = false;
  TemplateWeeks: WeeklyManualETimesheetRecordsBO;
  errorlist: boolean = false;
  TemplateList: any = [];
  ManualElectronicTimesheetType: any;
  isHidden: boolean = false;
  isShowManualETimeSheet: boolean = false;
  activitiesList: Array<Activity> = [];
  activitiesvaluesList: Array<Activity> = [];
  isViewPage: boolean = false;
  payorId: any = "";
  ActiveAuthorization: any = [];
  isEditservice: boolean = false;
  clientAuthId: number;
  serviceNameforServiceAuth: any = ""

  serviceName: any = "";
  UnConfirmedUnits: any = "";
  billingLov: any = "";
  RemainingUnits: any = "";
  ConfirmedUnits: any = "";
  serviceGroupId: number = 0;
  isPayWeek: string;
  isBiweekly: boolean;
  clientdropDown: any;
  isStarted: any;
  StartDate: any = "";
  EndDate: any = "";
  isEnded: any;
  remainingHPDBasedOnCurrentDate: any = "";
  remainingHPDBasedOnLastVisitDate: any = "";
  isRepresentative: boolean = false;
  ClientId: any;
  servicedropDown: any;
  ServiceId: any;
  serviceDropList: any = [];
  serviceRemaingHours: any;
  myInputs: any = [];
  isConfirm: any;
  masterServiceId: any = "";
  servicesListContent: any = [];
  IsSingleDayEmanualEntry: boolean = false;
  isTemplateHidden: boolean = true;
  isManualSave: boolean = false;
  constructor(
    public CS: MyClientsService,
    public ManualETimeSheetService: ManualElectronicTimeSheetService,
    public global: Globalservice,
    public actionSheetCtrl: ActionSheetController,
    public errorService: ErrorService,
    public toastController: ToastController,
    public alertController: AlertController,
    public datePipe: DatePipe,
    public router: Router,
    public modalController: ModalController
  ) {
    this.global.checkingLogin();
  }
  ngOnInit() { }
  async ionViewWillEnter() {
    this.global.clientName = null;
    this.global.isDifferentlyAbled = null;
    this.isRepresentative = false;
    this.ManualElectronicTimesheetType = false;
    this.ClientId = null;
    this.ServiceId = null;
    this.remainingHPDBasedOnCurrentDate = null;
    this.remainingHPDBasedOnLastVisitDate = null;
    this.serviceRemaingHours = null;
    this.serviceRemaingHoursandMinutes = null;
    this.serviceRemaingHoursPerDay = null;
    this.serviceRemaingHoursPerWeek = null;
    this.serviceNameforServiceAuth = null;
    this.serviceName = null;
    this.UnConfirmedUnits = null;
    this.billingLov = null;
    this.RemainingUnits = null;
    this.ConfirmedUnits = null;
    this.isPayWeek = '';
    this.isBiweekly = false;
    this.isConfirm = null;
    this.isTemplateHidden = true;
    this.isTemplateView = false;
    this.templateTitle = null;
    this.isHidden = false;
    this.selectedTemplateValues = null;
    await this.global.checkRefreshTokenValidity()
    {
      this.getCheckeligibility();
      this.getTemplateValues();
      this.getIsSingleDayEmanualEntry();
      this.global.getServiceType();
    }
  }
  //.....................................Templatevalues...............................................................//
  async getTemplateValues() {
    let param = new URLSearchParams();
    param.append('employeeId', this.global.EvvUserDetails.entityId.toString());
    await this.global.checkRefreshTokenValidity()
    {
      this.ManualETimeSheetService.getTemplatedropdown(param).subscribe(
        (data: any) => {
          this.TemplateList = data;
        },
        (err) => {
          this.errorService.check(err);
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }
  // .......................checkEligibility............................................................................//
  async getCheckeligibility() {

    let param = new URLSearchParams();
    param.append('employeeId', this.global.EvvUserDetails.entityId.toString());
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
      this.ManualETimeSheetService.getCheckeligibility(param).subscribe(
        (data: any) => {
          if (data == false) {
            this.isViewPage = false;
            this.isHidden = true;
            this.errorlist = true;
          } else {
            this.isHidden = false;
            this.errorlist = false;
            this.isViewPage = true;
            this.getClientDropdown();
          }
          this.isShowManualETimeSheet = false;
          this.global.disableloading();
        },
        (err) => {
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }
  back() {
    this.ClientId = null;
    this.global.isDifferentlyAbled = null;
    this.isRepresentative = false;
    this.ServiceId = null;
    this.serviceRemaingHours = null;
    this.serviceRemaingHoursandMinutes = null;
    this.serviceRemaingHoursPerDay = null;
    this.serviceRemaingHoursPerWeek = null;
    this.serviceNameforServiceAuth = null;
    this.serviceName = null;
    this.UnConfirmedUnits = null;
    this.billingLov = null;
    this.RemainingUnits = null;
    this.ConfirmedUnits = null;
    this.isPayWeek = null;
    this.isBiweekly = false;
    this.isConfirm = null;
    this.isTemplateHidden = true;
    this.remainingHPDBasedOnCurrentDate = null;
    this.remainingHPDBasedOnLastVisitDate = null;
    this.isTemplateView = false;
    this.errorlist = false;
    this.isHidden = false;
    this.isShowManualETimeSheet = false;
    this.isViewPage = true;
    this.isEditservice = false;
    this.templateTitle = null;
    this.isDeleteAlert = false;
    this.global.MetemployeeSign = null;
    this.selectedTemplateValues = null;
    this.global.MetclientSign = null;
    this.ManualElectronicTimesheetType = false;
    this.servicedropDown = [];
    this.clearFields();
    this.getCheckeligibility();
    this.getTemplateValues();
  }
  day: string = '';
  getDaysforSelectedDate() {
    // if (this.ManualElectronicTimesheetType) {
    //   const parsedDate = new Date(this.isPayWeek);
    //   const weekday = [
    //     'Sunday',
    //     'Monday',
    //     'Tuesday',
    //     'Wednesday',
    //     'Thursday',
    //     'Friday',
    //     'Saturday',
    //   ];
    //   this.day = weekday[parsedDate.getDay()];
    // }
  } //............................WeekDates..............................................................................//
  async getWeekDates() {
    if (this.serviceRemaingHours > 0) {
    } else {
      this.Toastdanger('No units Remaining.. Contact office');
      return;
    }
    let EManualType = this.ManualElectronicTimesheetType == null ? true : this.ManualElectronicTimesheetType;

    let param = new URLSearchParams();
    param.append('employeeId', this.global.EvvUserDetails.entityId.toString());
    param.append('date', this.isPayWeek);
    param.append('isBiweekly', JSON.stringify(this.isBiweekly));
    param.append('isliving', EManualType);
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
      this.ManualETimeSheetService.getWeekDates(param).subscribe(
        (data: any) => {
          this.isShowManualETimeSheet = true;
          this.isViewPage = false;
          this.isHidden = true;
          this.WeekDates = JSON.parse(JSON.stringify(data));
          this.WeekDates = JSON.parse(JSON.stringify(this.WeekDates));
          this.WeekDates.forEach((e) => {
            e.weekRecords.forEach((w) => {
              w.formattedDate = this.getFormattedDate(w.date);
              this.ActiveAuthorization.forEach(AA => {
                if (this.clientAuthId == AA.clientAuthId) {
                  let weekDate = w.date.split('T')[0];
                  AA.SDate = AA.startDate.split('T')[0];
                  AA.EDate = AA.endDate.split('T')[0];
                  if (weekDate >= AA.SDate && weekDate <= AA.EDate) {
                    w.activeAuthorization = true;
                  } else {
                    w.activeAuthorization = false;
                  }
                }
              })

            });
          });

          this.getActivitiesforManualETimesheet();
          this.global.disableloading();
        },
        (err) => {
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }
  //............................ClientDropdown............................................................................//
  async getClientDropdown() {
    let EManualType = this.ManualElectronicTimesheetType == null ? false : this.ManualElectronicTimesheetType;
    if (this.global.EvvUserDetails.entityId != null) {


      let param = new URLSearchParams();
      param.append(
        'EmployeeId',
        this.global.EvvUserDetails.entityId.toString()
      );
      param.append('living', EManualType);
      await this.global.checkRefreshTokenValidity()
      {
        this.ManualETimeSheetService.getClientDropdown(param).subscribe(
          (data: any) => {
            this.clientdropDown = data;

          },
          (err) => {
            this.errorService.check(err);
            this.global.disableloading();
            if (err.Status == 401) {
              this.global.GloballogOut();
            }
          }
        );
      }
    }
  }
  //...........................clientservice................................................................................//
  async getClientService() {
    this.serviceRemaingHours = null;
    this.serviceRemaingHoursandMinutes = null;
    this.serviceRemaingHoursPerDay = null;
    this.serviceRemaingHoursPerWeek = null;
    this.serviceNameforServiceAuth = null;
    this.serviceName = null;
    this.UnConfirmedUnits = null;
    this.billingLov = null;
    this.RemainingUnits = null;
    this.ConfirmedUnits = null;
    this.remainingHPDBasedOnCurrentDate = null;
    this.remainingHPDBasedOnLastVisitDate = null;
    this.ServiceId = null;
    await this.global.checkRefreshTokenValidity()
    {
      if (this.ClientId != null) {
        await this.global.showLoading();
        let param = new URLSearchParams();
        param.append('Client', this.ClientId.toString());
        param.append(
          'EmployeeId',
          this.global.EvvUserDetails.entityId.toString()
        );

        this.ManualETimeSheetService.getClientService(param).subscribe(
          (data: any) => {
            if (data.length > 0) {
              this.isEditservice = true;
            } else {
              this.isEditservice = false;
            }
            this.servicedropDown = data;
            let clientName = this.clientdropDown?.filter(a => a.Key == this.ClientId)[0];
            if (clientName != null) {
              this.global.clientName = clientName.Value;
            }


            this.global.disableloading();
          },
          (err) => {
            this.errorService.check(err);
            this.global.disableloading();
            if (err.Status == 401) {
              this.global.GloballogOut();
            }
          }
        );
      }
    }
  }

  //....................................getActivitiesforManualETimesheet.....................................................//
  async getActivitiesforManualETimesheet() {
    await this.global.checkRefreshTokenValidity()
    {
      if (this.ClientId != null && this.ServiceId != null) {
        await this.global.showLoading();
        let param = new URLSearchParams();
        param.append('clientId', this.ClientId.toString());
        param.append('ServiceId', this.ServiceId.toString());
        this.ManualETimeSheetService.getActivitiesforManualETimesheet(
          param
        ).subscribe(
          (data: any) => {
            this.activitiesList = data;
            this.activitiesvaluesList = JSON.parse(JSON.stringify(data));
            this.WeekDates.forEach((e) => {
              e.weekRecords.forEach((element) => {
                element.visits = [];
                let etimesheet = new ManualETimesheetDataBO();
                etimesheet.activities = JSON.parse(
                  JSON.stringify(this.activitiesList)
                );
                element.visits.push(etimesheet);
              });
            });
            // this.FutureDateValidation();
            this.global.disableloading();
          },
          (err: HttpErrorResponse) => {
            this.global.disableloading();
            this.Toastdanger(err.message);
          }
        );
      }
    }
  }

  async getActivitesForTemplate() {
    await this.global.checkRefreshTokenValidity()
    {
      if (this.ClientId != null && this.ServiceId != null) {
        await this.global.showLoading();
        let param = new URLSearchParams();
        param.append('clientId', this.ClientId.toString());
        param.append('ServiceId', this.ServiceId.toString());
        this.ManualETimeSheetService.getActivitiesforManualETimesheet(
          param
        ).subscribe(
          (data: any) => {
            this.activitiesList = data;
            this.activitiesvaluesList = JSON.parse(JSON.stringify(data));
            this.WeekDates.forEach((e) => {
              e.weekRecords.forEach((element) => {
                // let etimesheet = new ManualETimesheetDataBO();
                // etimesheet.activities=[];
                // etimesheet.activities = JSON.parse(JSON.stringify(   this.activitiesvaluesList ));
                // element.visits.push(etimesheet);
                if (element.visits.length == 0) {
                  let etimesheet = new ManualETimesheetDataBO();
                  etimesheet.activities = [];
                  etimesheet.activities = JSON.parse(
                    JSON.stringify(this.activitiesvaluesList)
                  );
                  element.visits.push(etimesheet);
                }
                element.visits.forEach((v) => {
                  v.activities = JSON.parse(
                    JSON.stringify(this.activitiesvaluesList)
                  );
                });
              });
            });
            this.global.disableloading();
          },
          (err: HttpErrorResponse) => {
            this.global.disableloading();
            this.Toastdanger(err.message);
          }
        );
      }
    }
  }
  AddService(serviceActivities) {
    const servicevalues = [];
    for (let i = 0; i < serviceActivities.length; ++i) {
      servicevalues.push({
        type: 'checkbox',
        label: serviceActivities[i].value,
        value: '' + i,
        checked: serviceActivities[i].isSelected,
        handler: (data) => {
          if (data.label == serviceActivities[i].value) {
            serviceActivities[i].isSelected = data.checked;
          }
        },
      });
    }
    return servicevalues;
  }
  ////.......................................SelectServiceActivites................................................................//
  async SelectServiceActivites(activities) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Activities',
      inputs: this.AddService(activities),
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { },
        },
        {
          text: 'Ok',
          handler: () => { },
        },
      ],
    });
    await alert.present();
  }
  getDate(date: any): string {
    var dt = this.getFormattedDate(date).split('/');
    var mm = parseInt(dt[0]);
    var dd = parseInt(dt[1]);
    var yyyy = parseInt(dt[2]);
    var ex = new Date(Date.UTC(yyyy, mm - 1, dd));

    return ex.toISOString();
  }
  getFormattedDate(date) {
    var mydate = date.split('T')[0];

    var splitext = mydate.split('-');
    return splitext[1] + '/' + splitext[2] + '/' + splitext[0];
  }
  //.............................................saveManualETimesheets.............................................................//
  async saveManualETimesheets() {
    this.calculateHours();
    this.isManualSave = true
    let data = JSON.parse(JSON.stringify(this.WeekDates));
    data.forEach((label) => {
      label.weekRecords.forEach((ab) => {
        // ab.date = (ab.date != null && ab.date != undefined && ab.date != '') ? new Date(new Date(ab.date).toDateString() + " " + new Date(ab.date).toLocaleTimeString() + " " + "GMT").toISOString() : null;
        ab.date =
          ab.date != null && ab.date != undefined && ab.date != ''
            ? this.getDate(ab.date)
            : null;
        ab.visits.forEach((element) => {
          element.start =
            element.start != null &&
              element.start != undefined &&
              element.start != ''
              ? this.global.getDateTimeString(element.start)
              : null;
          element.end =
            element.end != null && element.end != undefined && element.end != ''
              ? this.global.getDateTimeString(element.end)
              : null;
        });
      });
    });
    await this.global.checkRefreshTokenValidity()
    {

      await this.global.showLoading();
      let savedata = new ManualETimesheetsSaveBO();
      (savedata.serviceId = parseInt(this.masterServiceId)),
        (savedata.serviceRemaingHours = this.serviceRemaingHours),
        (savedata.templateTitle = '');
      savedata.templateId = null;
      savedata.totalhours = null;
      savedata.clientId = parseInt(this.ClientId);
      savedata.employeeId = this.global.EvvUserDetails.entityId;
      savedata.records = data;
      savedata.records.forEach((a) => {
        a.weekTotalHours = a.weekTotalHours;
        a.weekRecords.forEach((vb) => {
          vb.totalhours = vb.totalhours;
          vb.visits.forEach((b) => {
            b.hours = b.hours;
          });
        });
      });
      savedata.isRepresentative = this.isRepresentative;
      savedata.clientSign =
        this.global.MetclientSign != null ? this.global.MetclientSign : null;
      savedata.employeeSign =
        this.global.MetemployeeSign != null ? this.global.MetemployeeSign : null;
      savedata.isDayEntry =
        this.ManualElectronicTimesheetType == null ? true : this.ManualElectronicTimesheetType;
      savedata.isLivein = this.ManualElectronicTimesheetType == true ? true : false;
      savedata.clientAuthId = this.clientAuthId != 0 ? parseInt(this.clientAuthId.toString()) : 0,
        savedata.serviceGroupId = this.serviceGroupId != 0 ? parseInt(this.serviceGroupId.toString()) : 0,
        savedata.payorId = this.payorId != 0 ? parseInt(this.payorId.toString()) : 0,
        this.ManualETimeSheetService.saveManualETimeSheet(savedata).subscribe(
          (data) => {
            this.isManualSave = false
            this.global.disableloading();
            if (data.length > 0) {
              // this.global.Toastdanger(data);
              this.getErrorNotesforManualETimesheet(data);
              return;
            }
            this.ToastSuccess('Congratulations on your successfully entry!');
            this.global.MetclientSign = null;
            this.global.MetemployeeSign = null;
            this.router.navigate(['/tabs/all-timesheets']);
          },
          (err) => {
            this.isManualSave = false
            this.global.Toastdanger(err.message);
            this.errorService.check(err);
            this.global.disableloading();
            if (err.Status == 401) {
              this.global.GloballogOut();
            }
          }
        );
    }
  }

  async getErrorNotesforManualETimesheet(message) {
    const alert = await this.alertController.create({
      header: 'Error Note',
      message: message,
      buttons: [
        {
          id: "NotesStopSharedError",
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ],
      backdropDismiss: false
    });
    await alert.present();
  }
  //...........................................................................................................//

  clearIndexFunction(dayRecord, index) {
    dayRecord.visits[index] = new ManualETimesheetDataBO();
    dayRecord.visits[index].activities = JSON.parse(
      JSON.stringify(this.activitiesList)
    );
  }
  startTime(visit, dayRecord, index, Manualvalue) {
    if (visit.start == undefined && Manualvalue == 'start') {
      visit.start = this.global.DateandTimeFormat(new Date());
      if (visit.start != undefined) {
        visit.start = visit.start.split('+')[0];
      }
    }

    let date = new Date(dayRecord.formattedDate);
    let startdate = new Date(visit.start);
    startdate.setFullYear(date.getFullYear());
    startdate.setMonth(date.getMonth());
    startdate.setDate(date.getDate());
    visit.start = startdate.toString();
    if (visit.start != undefined) {
      visit.start = this.global.DateandTimeFormat(visit.start);
      visit.start = visit.start.split('+')[0];
    }
    let enddate = new Date(visit.end);
    let diff = (enddate.getTime() - startdate.getTime()) / 1000;
    let minutesDifference = Math.abs(Math.floor(diff / 60));
    let roundedMinutes = Math.round(minutesDifference / 15) * 15;
    visit.hours = roundedMinutes / 60;
    this.validateTimes(visit, dayRecord, index, Manualvalue);
    this.validateCurrentTimes(visit, dayRecord, index);
  }
  EndTime(visit, dayRecord, index, Manualvalue) {
    if (visit.end == undefined && Manualvalue == 'end') {
      visit.end = this.global.DateandTimeFormat(new Date());
      visit.end = visit.end.split('+')[0];
    }

    let date = new Date(dayRecord.formattedDate);
    let startdate = new Date(visit.start);
    let enddate = new Date(visit.end);

    enddate.setFullYear(date.getFullYear());
    enddate.setMonth(date.getMonth());
    enddate.setDate(date.getDate());
    visit.end = enddate.toString();
    if (visit.end != undefined) {
      visit.end = this.global.DateandTimeFormat(visit.end);
      visit.end = visit.end.split('+')[0];
    }
    let diff = (enddate.getTime() - startdate.getTime()) / 1000;
    let minutesDifference = Math.abs(Math.floor(diff / 60));
    let roundedMinutes = Math.round(minutesDifference / 15) * 15;
    visit.hours = roundedMinutes / 60;
    this.validateTimes(visit, dayRecord, index, Manualvalue);
    this.validateCurrentTimes(visit, dayRecord, index);
  }
  validateTimes(visit, dayRecord, index, Manualvalue) {
    if (
      visit.start != null &&
      visit.end != null &&
      visit.start != '' &&
      visit.end != ''
    )
      if (new Date(visit.start).getTime() > new Date(visit.end).getTime()) {
        dayRecord.visits[index] = new ManualETimesheetDataBO();
        dayRecord.visits[index].activities = visit.activities;
        this.Toastdanger('End Time Should greater than Start Time');
      }
  }
  validateCurrentTimes(visit, dayRecord, index) {
    var currentdate = new Date();
    let a = this.global.DateandTimeFormat(currentdate);

    let diff =
      (new Date(visit.end).getTime() - new Date(visit.start).getTime()) / 1000;
    let minutesDifference = Math.abs(Math.floor(diff / 60));
    let roundedMinutes = Math.round(minutesDifference / 15) * 15;
    let hours = roundedMinutes / 60;

    if (hours < 0.25) {
      this.global.Toastdanger('Hours should not be zero');
      dayRecord.visits[index] = new ManualETimesheetDataBO();
      dayRecord.visits[index].activities = visit.activities;
    }
    if (new Date(a).getDate() == new Date(dayRecord.formattedDate).getDate()) {
      if (new Date(currentdate).getTime() < new Date(visit.start).getTime()) {
        dayRecord.visits[index] = new ManualETimesheetDataBO();
        dayRecord.visits[index].activities = visit.activities;
        this.Toastdanger('Future Timings are not allowed!');
      } else if (
        new Date(currentdate).getTime() < new Date(visit.end).getTime()
      ) {
        dayRecord.visits[index] = new ManualETimesheetDataBO();
        dayRecord.visits[index].activities = visit.activities;
        this.Toastdanger('Future Timings are not allowed!');
      }
    }
  }

  //...............................................................Addingbo..............................//
  AddingVisitList(dayRecord) {
    let etimesheet = new ManualETimesheetDataBO();
    etimesheet.activities = JSON.parse(JSON.stringify(this.activitiesList));
    dayRecord.visits.push(etimesheet);
  }
  //...............................................................removebo..............................//
  removeVisitList(dayRecord, index) {
    if (dayRecord.visits.length > 1) {
      dayRecord.visits.splice(index, 1);
    }
  }
  calculateHours() {
    this.WeekDates?.forEach((weekRecord) => {
      weekRecord?.weekRecords?.forEach((dayRecord) => {
        dayRecord.totalhours =
          dayRecord?.visits?.reduce(
            (sum, visit) => sum + (visit?.hours ?? 0),
            0
          ) ?? 0;
      });
      weekRecord.weekTotalHours =
        weekRecord?.weekRecords?.reduce(
          (sum, dayRecord) => sum + (dayRecord?.totalhours ?? 0),
          0
        ) ?? 0;
    });
  }

  // .........................Template...................................................
  CreateManageTemplates() {
    this.TemplateWeeks = new WeeklyManualETimesheetRecordsBO();
    this.TemplateWeeks.weekRecords = [];
    this.isShowManualETimeSheet = false;
    this.isViewPage = false;
    this.errorlist = false;
    this.isTemplateView = true;
    this.isHidden = true;
    let days = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
    ];
    days.forEach((element) => {
      let Etimesheet = new ManualETimesheetsBO();
      Etimesheet.dayOfWeek = element;
      let visit = new ManualETimesheetDataBO();
      Etimesheet.visits = [];
      Etimesheet.visits.push(visit);
      this.TemplateWeeks.weekRecords.push(Etimesheet);
    });
  }
  templatestartTime(visit, dayRecord, visitTemplateindex, val) {
    this.validateTemplateTimes(visit, dayRecord, visitTemplateindex, val);
  }
  templateEndTime(visit, dayRecord, visitTemplateindex, val) {
    this.validateTemplateTimes(visit, dayRecord, visitTemplateindex, val);
  }
  validateTemplateTimes(visit, dayRecord, visitTemplateindex, val) {
    if (visit.start == undefined && val == 'start') {
      visit.start = this.global.DateandTimeFormat(new Date());
      visit.start = visit.start.split('+')[0];
    }
    if (visit.end == undefined && val == 'end') {
      visit.end = this.global.DateandTimeFormat(new Date());
      visit.end = visit.end.split('+')[0];
    }

    if (visit.start != undefined && val == 'start') {
      visit.start = visit.start.split('+')[0];
    }
    if (visit.end != undefined && val == 'end') {
      visit.end = visit.end.split('+')[0];
    }

    let diff;
    let minutesDifference;
    let roundedMinutes;
    let hours;
    if (
      visit.start != null &&
      visit.end != null &&
      visit.start != '' &&
      visit.end != ''
    )
      diff =
        (new Date(visit.end).getTime() - new Date(visit.start).getTime()) /
        1000;
    minutesDifference = Math.abs(Math.floor(diff / 60));
    roundedMinutes = Math.round(minutesDifference / 15) * 15;
    hours = roundedMinutes / 60;

    if (hours < 0.25) {
      this.global.Toastdanger('Hours should not be zero');
      dayRecord.visits[visitTemplateindex] = new ManualETimesheetDataBO();
    }
    if (new Date(visit.start).getTime() > new Date(visit.end).getTime()) {
      dayRecord.visits[visitTemplateindex] = new ManualETimesheetDataBO();
      this.Toastdanger('End Time Should greater than Start Time');
    }
  }
  // saveTemplate...............................................................................................
  async SaveManualETimesheetTemplate() {
    this.TemplateWeeks.weekRecords.forEach((label) => {
      label.date != null && label.date != undefined && label.date != ''
        ? new Date(
          new Date(label.date).toDateString() +
          ' ' +
          new Date(label.date).toLocaleTimeString() +
          ' ' +
          'GMT'
        ).toISOString()
        : null;
      label.visits.forEach((element) => {
        if (
          element.start != null &&
          element.start != undefined &&
          element.start != '' &&
          element.end != null &&
          element.end != undefined &&
          element.end != ''
        ) {
          element.start =
            element.start != null &&
              element.start != undefined &&
              element.start != ''
              ? this.global.getDateTimeString(element.start)
              : null;

          element.end =
            element.end != null && element.end != undefined && element.end != ''
              ? this.global.getDateTimeString(element.end)
              : null;
        }
      });
    });
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
      let saveTemplatedata = new ManualETimesheetsSaveBO();
      (saveTemplatedata.serviceId = 0),
        (saveTemplatedata.serviceRemaingHours = 0);
      (saveTemplatedata.clientId = 0),
        (saveTemplatedata.templateTitle = this.templateTitle);
      if (
        this.selectedTemplateValues == 0 ||
        this.selectedTemplateValues == null ||
        this.selectedTemplateValues == undefined
      ) {
        saveTemplatedata.templateId = 0;
        saveTemplatedata.records = [];
        saveTemplatedata.records.push(this.TemplateWeeks);
      } else {
        saveTemplatedata.templateId = parseInt(this.selectedTemplateValues);
        saveTemplatedata.records = [];
        saveTemplatedata.records.push(this.TemplateWeeks[0]);
      }
      saveTemplatedata.totalhours = 0;
      (saveTemplatedata.employeeId = this.global.EvvUserDetails.entityId),
        saveTemplatedata.records.forEach((a) => {
          a.weekTotalHours = 0;
          a.weekRecords.forEach((vb) => {
            vb.totalhours = 0;
            vb.visits.forEach((b) => {
              b.templateDataId = b.templateDataId > 0 ? b.templateDataId : 0;
              b.hours = 0;
            });
          });
        });
      this.ManualETimeSheetService.AddManualETimesheetTemplate(
        saveTemplatedata
      ).subscribe(
        (data) => {
          this.global.ToastSuccess('Template saved successFully!');
          this.global.disableloading();
          this.back();
        },
        (err) => {
          this.global.Toastdanger(err.message);
          this.errorService.check(err.message);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }
  async UpdateManualETimesheetTemplate() {
    this.TemplateWeeks.weekRecords.forEach((label) => {
      label.date != null && label.date != undefined && label.date != ''
        ? this.global.getDateTimeString(label.date)
        : null;
      label.visits.forEach((element) => {
        if (
          element.start != null &&
          element.start != undefined &&
          element.start != '' &&
          element.end != null &&
          element.end != undefined &&
          element.end != ''
        ) {
          element.start =
            element.start != null &&
              element.start != undefined &&
              element.start != ''
              ? this.global.getDateTimeString(element.start)
              : null;

          element.end =
            element.end != null && element.end != undefined && element.end != ''
              ? this.global.getDateTimeString(element.end)
              : null;
        }
      });
    });
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
      let saveTemplatedata = new ManualETimesheetsSaveBO();
      (saveTemplatedata.serviceId = 0),
        (saveTemplatedata.clientId = 0),
        (saveTemplatedata.templateTitle = this.templateTitle);
      if (
        this.selectedTemplateValues == 0 ||
        this.selectedTemplateValues == null ||
        this.selectedTemplateValues == undefined
      ) {
        saveTemplatedata.templateId = 0;
        saveTemplatedata.records = [];
        saveTemplatedata.records.push(this.TemplateWeeks);
      } else {
        saveTemplatedata.templateId = parseInt(this.selectedTemplateValues);
        saveTemplatedata.records = [];
        saveTemplatedata.records.push(this.TemplateWeeks[0]);
      }
      saveTemplatedata.totalhours = 0;
      (saveTemplatedata.employeeId = this.global.EvvUserDetails.entityId),
        saveTemplatedata.records.forEach((a) => {
          a.weekTotalHours = 0;
          a.weekRecords.forEach((vb) => {
            vb.totalhours = 0;
            vb.visits.forEach((b) => {
              if (b.start == '') {
                b.start = null;
              }

              if (b.end == '') {
                b.end = null;
              }
              b.templateDataId = b.templateDataId > 0 ? b.templateDataId : 0;
              b.hours = 0;
            });
          });
        });
      this.ManualETimeSheetService.AddManualETimesheetTemplate(
        saveTemplatedata
      ).subscribe(
        (data) => {
          this.global.ToastSuccess('Template updated successFully!');
          this.global.disableloading();
          this.back();
        },
        (err) => {
          this.global.Toastdanger(err.message);
          this.errorService.check(err.message);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }
  clearFields() {
    this.selectedTemplateValues = null;
    this.isPayWeek = null;
    this.isBiweekly = false;
    this.ClientId = null;
    this.ServiceId = null;
    this.serviceNameforServiceAuth = null;
    this.serviceName = null;
    this.RemainingUnits = null;
    this.UnConfirmedUnits = null;
    this.billingLov = null;
    this.ConfirmedUnits = null;
    this.isViewPage = true;
    this.ManualElectronicTimesheetType = false;
    this.serviceRemaingHoursandMinutes = null;
    this.serviceRemaingHours = null;
    this.serviceRemaingHoursPerDay = null;
    this.serviceRemaingHoursPerWeek = null;
    this.servicedropDown = [];
    this.remainingHPDBasedOnCurrentDate = null;
    this.remainingHPDBasedOnLastVisitDate = null;
  }
  // Template Date...................................................
  async getTemplateforTheDate() {
    if (this.serviceRemaingHours > 0) {
    } else {
      this.Toastdanger('No units Remaining.. Contact office');
      return;
    }
    let EManualType = this.ManualElectronicTimesheetType == null ? true : this.ManualElectronicTimesheetType;
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
      let param = new URLSearchParams();
      param.append('employeeId', this.global.EvvUserDetails.entityId.toString());
      param.append('date', this.isPayWeek);
      param.append('isBiweekly', JSON.stringify(this.isBiweekly));
      param.append('templateId', this.selectedTemplateValues);
      param.append('isliving', EManualType);
      this.ManualETimeSheetService.getTemplateforTheDate(param).subscribe(
        (data: any) => {
          this.isShowManualETimeSheet = true;
          this.isViewPage = false;
          this.WeekDates = JSON.parse(JSON.stringify(data));
          this.WeekDates = JSON.parse(JSON.stringify(this.WeekDates));
          this.WeekDates.forEach((e) => {
            e.weekRecords.forEach((w) => {
              w.formattedDate = this.getFormattedDate(w.date);
            });
          });
          this.WeekDates.forEach((weeks) => {
            weeks.weekRecords.forEach((dates) => {
              if (dates.visits.length > 0) {
                dates.visits.forEach((e) => {
                  e.start = e.start.split('+')[0];
                  e.end = e.end.split('+')[0];
                });
              }
              if (this.disabled(dates.date)) {
                let visited = new ManualETimesheetDataBO();
                dates.visits = [];
                dates.visits.push(visited);
              }
            });
          });
          this.WeekDates.forEach((e) => {
            e.weekRecords.forEach((w) => {
              this.ActiveAuthorization.forEach(AA => {
                if (this.clientAuthId == AA.clientAuthId) {
                  let weekDate = w.date.split('T')[0];
                  AA.SDate = AA.startDate.split('T')[0];
                  AA.EDate = AA.endDate.split('T')[0];
                  if (weekDate >= AA.SDate && weekDate <= AA.EDate) {
                    w.activeAuthorization = true;
                  } else {
                    w.activeAuthorization = false;
                  }
                }
              })
              w.formattedDate = this.getFormattedDate(w.date);

              if (w.activeAuthorization == false) {
                let a = new ManualETimesheetDataBO();
                w.visits = [];
                w.visits.push(a);
              }
            });
          });
          //  for current date...........................................................................
          this.WeekDates.forEach((a) => {
            a.weekRecords.forEach((b) => {
              var currentdate = new Date();
              if (new Date(currentdate).getDate() == new Date(b.date).getDate()) {
                b.visits.forEach((c) => {
                  if (
                    new Date(currentdate).getTime() < new Date(c.start).getTime()
                  ) {
                    let a = new ManualETimesheetDataBO();
                    b.visits = [];
                    b.visits.push(a);
                  } else if (
                    new Date(currentdate).getTime() < new Date(c.end).getTime()
                  ) {
                    let a = new ManualETimesheetDataBO();
                    b.visits = [];
                    b.visits.push(a);
                  }
                });
              }
            });
          });

          this.calculateHours();
          this.isTemplateHidden = false;
          this.isHidden = true;
          this.getActivitesForTemplate();
          this.global.disableloading();
        },
        (err) => {
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }
  disabled(date): boolean {
    if (new Date(new Date(date).getTime()) > new Date(new Date().getTime())) {
      return true;
    } else {
      return false;
    }
  }
  async getTemplateforEdit(selectedTemplateValues) {
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
      let param = new URLSearchParams();
      param.append('employeeId', this.global.EvvUserDetails.entityId.toString());
      param.append('templateId', selectedTemplateValues);
      this.ManualETimeSheetService.getTemplateforEdit(param).subscribe(
        (data: any) => {
          this.isHidden = true;
          this.TemplateWeeks = data.records;
          this.templateTitle = data.templateTitle;
          this.TemplateWeeks.weekRecords = data.records[0].weekRecords;
          this.TemplateWeeks.weekRecords.forEach((days) => {
            if (days.visits.length > 0) {
              days.visits.forEach((e) => {
                e.start = e.start.split('+')[0];
                e.end = e.end.split('+')[0];
              });
            }

            if (days.visits.length == 0) {
              let visit = new ManualETimesheetDataBO();
              visit.start = '';
              visit.end = '';
              visit.hours = 0;
              days.visits.push(visit);
            }
          });
          this.isTemplateView = true;
          this.isShowManualETimeSheet = false;
          this.isViewPage = false;
          this.errorlist = false;
          this.global.disableloading();
        },
        (err) => {
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }
  ////.......................................TemplateDeleteAlert................................................................//
  async TemplateDeleteAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Delete Template',

      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.deleteTemplate(this.selectedTemplateValues)
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { },
        },

      ],
    });
    await alert.present();
  }
  async deleteTemplate(selectedTemplateValues) {
    await this.global.checkRefreshTokenValidity()
    {
      this.isDeleteAlert = false;
      this.selectedTemplateValues = null;
      await this.global.showLoading();
      let param = new URLSearchParams();
      param.append('employeeId', this.global.EvvUserDetails.entityId.toString());
      param.append('templateId', selectedTemplateValues);
      this.ManualETimeSheetService.deleteTemplate(param).subscribe(
        (data: any) => {
          this.getTemplateValues();
          this.clearFields();
          this.ToastSuccess('Template Deleted Successfully!');
          this.global.disableloading();
        },
        (err) => {
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }
  //..................................Toaster............................................................//
  async Toastdanger(message) {
    let toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'top',
      color: 'danger',
    });
    toast.present();
  }
  async ToastSuccess(message) {
    let toast = await this.toastController.create({
      message: message,
      duration: 8000,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }
  copyWeekdata() {
    var weekrecords = JSON.parse(JSON.stringify(this.WeekDates[0].weekRecords));
    for (var i = 1; i < this.WeekDates.length; i++) {
      this.WeekDates[i].weekTotalHours = this.WeekDates[0].weekTotalHours;
      weekrecords.forEach((z) => {
        this.WeekDates[i].weekRecords.forEach((weekRecord) => {
          if (weekRecord.dayOfWeek.toLowerCase() == z.dayOfWeek.toLowerCase()) {
            weekRecord.totalhours = z.totalhours;
            weekRecord.visits = z.visits;
          }
          var currentdate = new Date();
          let a = this.global.DateandTimeFormat(currentdate);
          if (
            new Date(a).getTime() < new Date(weekRecord.formattedDate).getTime()
          ) {
            let a = new ManualETimesheetDataBO();
            weekRecord.visits = [];
            weekRecord.visits.push(a);
          }
        });
      });
    }
    let count = 0;
    this.WeekDates[1].weekRecords.forEach((weekRecords, index) => {

      if (weekRecords.activeAuthorization == false) {
        let a = new ManualETimesheetDataBO();
        weekRecords.visits = [];
        weekRecords.visits.push(a);
      }

      weekRecords.visits.forEach((visits) => {
        if (
          visits.start != null &&
          visits.start != undefined &&
          visits.start != '' &&
          visits.end != null &&
          visits.end != undefined &&
          visits.end != ''
        ) {
          const weekRecordsDate = new Date(
            this.datePipe.transform(weekRecords.date, 'yyyy-MM-dd')
          );
          const visitsStart = new Date(
            this.datePipe.transform(visits.start, 'yyyy-MM-dd')
          );

          const timeDiff = visitsStart.getTime() - weekRecordsDate.getTime();
          const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          let converted: any = +Math.abs(daysDiff);

          var StartDate = new Date(visits.start);
          let setDate = StartDate.setDate(
            StartDate.getDate() + (converted + count)
          );
          visits.start = this.global.DateandTimeFormat(setDate);
          var EndDate = new Date(visits.end);
          let endDate = EndDate.setDate(
            EndDate.getDate() + (converted + count)
          );
          visits.end = this.global.DateandTimeFormat(endDate);
        }
      });
    });
    count++;

  }

  // FutureDateValidation() {

  //   for (var a = 0; a < this.WeekDates.length; a++) {
  //     for (var b = 0; b < this.WeekDates[a].weekRecords.length; b++) {

  //       if((new Date(this.WeekDates[a].weekRecords[b].date).getTime()) > (new Date().getTime())){

  //         this.WeekDates[a].weekRecords.splice(b,1);

  //       }
  //     }
  //   }
  // }
  addHoursToDate(date, time) {
    // var d = new Date(new Date(date).setHours(0,0,0,0)+(new Date(time).getHours()));
    var d = new Date(new Date(time).setDate(date.getDate()));
    return d;
  }
  copySundayTime1() {
    let weektimes = JSON.parse(
      JSON.stringify(this.TemplateWeeks.weekRecords[0])
    );
    for (let a = 1; a < this.TemplateWeeks.weekRecords.length; a++) {
      weektimes.visits.forEach((visits) => {
        this.TemplateWeeks.weekRecords[a].visits.forEach((weekRecord) => {
          if (
            (weekRecord.start == undefined ||
              weekRecord.start == null ||
              weekRecord.start == '') &&
            (weekRecord.end == undefined ||
              weekRecord.end == null ||
              weekRecord.end == '')
          ) {
            if (this.TemplateWeeks.weekRecords[a].visits.length == 1) {
              this.TemplateWeeks.weekRecords[a].visits = [];
              this.TemplateWeeks.weekRecords[a].visits.push(visits);
            }
          } else {
            if (
              this.TemplateWeeks.weekRecords[a].visits.length !=
              weektimes.visits.length
            ) {
              this.TemplateWeeks.weekRecords[a].visits = [];
              this.TemplateWeeks.weekRecords[a].visits = JSON.parse(
                JSON.stringify(weektimes.visits)
              );
            } else {
              if (
                new Date(weekRecord.start).getTime() ==
                new Date(visits.start).getTime() &&
                new Date(weekRecord.end).getTime() !=
                new Date(visits.end).getTime()
              ) {
                weekRecord.end = visits.end.toString();
              } else if (
                new Date(weekRecord.start).getTime() !=
                new Date(visits.start).getTime() &&
                new Date(weekRecord.end).getTime() ==
                new Date(visits.end).getTime()
              ) {
                weekRecord.start = visits.start.toString();
              } else {
                // weekRecord.start = visits.start.toString();
                // weekRecord.end = visits.end.toString();
                this.TemplateWeeks.weekRecords[a].visits = [];
                this.TemplateWeeks.weekRecords[a].visits = JSON.parse(
                  JSON.stringify(weektimes.visits)
                );
              }
            }
          }
        });
      });
    }
  }
  copySundayTime() {
    let weektimes = JSON.parse(
      JSON.stringify(this.TemplateWeeks.weekRecords[0])
    );
    for (let a = 1; a < this.TemplateWeeks.weekRecords.length; a++) {
      weektimes.visits.forEach((visits) => {
        this.TemplateWeeks.weekRecords[a].visits.forEach((weekRecord) => {
          this.TemplateWeeks.weekRecords[a].visits = [];
          this.TemplateWeeks.weekRecords[a].visits = JSON.parse(
            JSON.stringify(weektimes.visits)
          );
        });
      });
    }
  }
  trashalert() {
    this.isViewPage = false;
    this.isDeleteAlert = true;
  }
  NoALert() {
    this.isViewPage = true;
    this.isDeleteAlert = false;
    this.clearFields();
  }
  // .......................................copywithoutTemplate...........................................................
  copywithoutTemplate() {
    this.WeekDates[0].weekRecords[0]?.visits.forEach((e) => {
      if (e.start != undefined && e.start != null) {
        e.start = this.global.DateandTimeFormat(e.start);
      }
      if (e.end != undefined && e.end != null) {
        e.end = this.global.DateandTimeFormat(e.end);
      }
    });
    let data = JSON.parse(JSON.stringify(this.WeekDates[0].weekRecords[0]));
    for (let Temp = 1; Temp < this.WeekDates[0].weekRecords.length; Temp++) {
      data.visits.forEach((element) => {
        this.WeekDates[0].weekRecords[Temp].visits.forEach((c) => {
          this.WeekDates[0].weekRecords[Temp].visits = [];
          this.WeekDates[0].weekRecords[Temp].visits = JSON.parse(
            JSON.stringify(data.visits)
          );
        });
        if (
          new Date(
            new Date(this.WeekDates[0].weekRecords[Temp].date).getTime()
          ) > new Date(new Date().getTime())
        ) {
          this.WeekDates[0].weekRecords[Temp].visits = [];
          let ManualETimesheetData = new ManualETimesheetDataBO();
          this.WeekDates[0].weekRecords[Temp].visits.push(ManualETimesheetData);
        }
      });
    }

    this.WeekDates[0].weekRecords.forEach((weekRecords, index) => {
      if (weekRecords.activeAuthorization == false) {
        let a = new ManualETimesheetDataBO();
        weekRecords.visits = [];
        weekRecords.visits.push(a);
      }
      weekRecords.visits.forEach((visits) => {
        if (
          visits.start != null &&
          visits.start != undefined &&
          visits.start != '' &&
          visits.end != null &&
          visits.end != undefined &&
          visits.end != ''
        ) {
          var StartDate = new Date(visits.start);
          let setDate = StartDate.setDate(StartDate.getDate() + index);
          visits.start = this.global.DateandTimeFormat(setDate);
          var EndDate = new Date(visits.end);
          let endDate = EndDate.setDate(EndDate.getDate() + index);
          visits.end = this.global.DateandTimeFormat(endDate);
        }
      });
      var currentdate = new Date();
      let a = this.global.DateandTimeFormat(currentdate);
      if (
        new Date(a).getTime() < new Date(weekRecords.formattedDate).getTime()
      ) {
        weekRecords.visits = [];
        let ManualETimesheetData = new ManualETimesheetDataBO();
        weekRecords.visits.push(ManualETimesheetData);
      }
    });
  }

  //......................................copywithTemplate..........................................................
  copywithTemplate() {
    this.WeekDates[0].weekRecords[0]?.visits.forEach((e) => {
      if (e.start != undefined && e.start != null) {
        e.start = this.global.DateandTimeFormat(e.start);
      }
      if (e.end != undefined && e.end != null) {
        e.end = this.global.DateandTimeFormat(e.end);
      }
    });
    let data = JSON.parse(JSON.stringify(this.WeekDates[0].weekRecords[0]));
    for (let Temp = 1; Temp < this.WeekDates[0].weekRecords.length; Temp++) {
      data.visits.forEach((element) => {
        this.WeekDates[0].weekRecords[Temp].visits.forEach((c) => {
          this.WeekDates[0].weekRecords[Temp].visits = [];
          this.WeekDates[0].weekRecords[Temp].visits = JSON.parse(
            JSON.stringify(data.visits)
          );
        });
        if (
          new Date(
            new Date(this.WeekDates[0].weekRecords[Temp].date).getTime()
          ) > new Date(new Date().getTime())
        ) {
          this.WeekDates[0].weekRecords[Temp].visits = [];
          let ManualETimesheetData = new ManualETimesheetDataBO();
          this.WeekDates[0].weekRecords[Temp].visits.push(ManualETimesheetData);
        }
      });
    }

    this.WeekDates[0].weekRecords.forEach((weekRecords, index) => {

      if (weekRecords.activeAuthorization == false) {
        let a = new ManualETimesheetDataBO();
        weekRecords.visits = [];
        weekRecords.visits.push(a);
      }

      weekRecords.visits.forEach((visits) => {
        if (
          visits.start != null &&
          visits.start != undefined &&
          visits.start != '' &&
          visits.end != null &&
          visits.end != undefined &&
          visits.end != ''
        ) {
          var StartDate = new Date(visits.start);
          let setDate = StartDate.setDate(StartDate.getDate() + index);
          visits.start = this.global.DateandTimeFormat(setDate);
          var EndDate = new Date(visits.end);
          let endDate = EndDate.setDate(EndDate.getDate() + index);
          visits.end = this.global.DateandTimeFormat(endDate);
          visits.templateDataId = null;
        }
      });
      var currentdate = new Date();
      let a = this.global.DateandTimeFormat(currentdate);
      if (
        new Date(a).getTime() < new Date(weekRecords.formattedDate).getTime()
      ) {
        weekRecords.visits = [];
        let ManualETimesheetData = new ManualETimesheetDataBO();
        weekRecords.visits.push(ManualETimesheetData);
      }
    });
  }
  //......................................clearRadio..........................................................
  clearRadio() {
    this.selectedTemplateValues = null;
    this.isBiweekly = false;
    this.ClientId = null;
    this.ServiceId = null;
    this.isViewPage = true;
    this.serviceRemaingHoursandMinutes = null;
    this.serviceRemaingHoursPerDay = null;
    this.serviceRemaingHoursPerWeek = null;
    this.serviceNameforServiceAuth = null;
    this.serviceName = null;
    this.UnConfirmedUnits = null;
    this.billingLov = null;
    this.RemainingUnits = null;
    this.ConfirmedUnits = null;
    this.serviceRemaingHours = null;
    this.remainingHPDBasedOnCurrentDate = null;
    this.remainingHPDBasedOnLastVisitDate = null;
    if (this.ManualElectronicTimesheetType) {
      this.isPayWeek = this.global.DateandTimeFormat(new Date());
    }
    else {
      this.isPayWeek = null;
    }
  }
  async getClientisDifferentlyAbled() {
    await this.global.checkRefreshTokenValidity()
    {
      if (this.ClientId != null) {
        let param = new URLSearchParams();
        param.append('clientId', this.ClientId.toString());
        this.ManualETimeSheetService.getClientisDifferentlyAbled(param).subscribe(
          (data: any) => {
            this.global.isDifferentlyAbled = data;
          },
          (err) => {
            this.errorService.check(err);
            if (err.Status == 401) {
              this.global.GloballogOut();
            }
          }
        );
      }
    }
  }
  async getIsSingleDayEmanualEntry() {
    await this.global.checkRefreshTokenValidity()
    {
      let param = new URLSearchParams();
      param.append('employeeId', this.global.EvvUserDetails.entityId.toString());
      this.ManualETimeSheetService.getIsSingleDayEmanualEntry(param).subscribe(
        (data: any) => {
          this.IsSingleDayEmanualEntry = data;
        },
        (err) => {
          this.errorService.check(err);
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }
  // ..................getServicesWithAuthorizationForServiceGroup...............................................
  async getServicesWithAuthorizationForServiceGroup() {
    if (this.servicedropDown.length == 0) {
      return;
    }
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
      let servicedetails = this.servicedropDown?.filter(a => a.serviceId == this.ServiceId)[0];
      this.serviceGroupId = servicedetails.serviceGroupId;
      let authorizationRequestPayload =
      {
        startDate: this.global.timezone == "Minnesota" ? this.global.getDateTimeString(this.StartDate) : this.global.getDateWithUTCFormat(this.StartDate),
        endDate: this.global.timezone == "Minnesota" ? this.global.getDateTimeString(this.EndDate) : this.global.getDateWithUTCFormat(this.EndDate),
        serviceGroupId: parseInt(this.serviceGroupId.toString()),
        clientId: parseInt(this.ClientId),
        serviceTypeLid: this.global.serviceType?.filter((st) => st.Value == "HOMECARE")[0]?.Key
      }
      this.servicesListContent = [];
      this.CS.getServicesWithAuthorizationForServiceGroup(authorizationRequestPayload).subscribe(async (data: any) => {
        this.serviceNameforServiceAuth = null;
        this.remainingHPDBasedOnCurrentDate = null;
        this.remainingHPDBasedOnLastVisitDate = null;
        this.UnConfirmedUnits = null;
        this.billingLov = null;
        this.RemainingUnits = null;
        this.ConfirmedUnits = null;
        this.global.disableloading();
        if (data.exception.length > 0) {
          this.global.Toastdanger(data.exception);
          return;
        }
        this.ActiveAuthorization = data.response;
        this.ActiveAuthorization.forEach(element => {
          this.servicesListContent.push({

            text: this.global.timezone == "Minnesota" ? element.masterServiceName + " -" + element.serviceDescription + ' (' + this.datePipe.transform(element.startDate, "MM/dd/yyyy") + " " + "to" + " " + this.datePipe.transform(element.endDate, "MM/dd/yyyy") + ')' : element.masterServiceName + " -" + element.serviceDescription + ' (' + this.datePipe.transform(element.startDate.split("T")[0], "MM/dd/yyyy") + " " + "to" + " " + this.datePipe.transform(element.endDate.split("T")[0], "MM/dd/yyyy") + ')',

            id: element.serviceId,
            icon: '',
            handler: () => {
              this.getDetails(element)
            }
          })
        });
        this.servicesListContent.push({
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
        })
        const actionSheet = await this.actionSheetCtrl.create({
          header: 'Select Service',
          cssClass: 'my-custom-class',
          buttons: this.servicesListContent
        });

        await actionSheet.present();
        this.global.disableloading();
      }, err => {
        this.global.disableloading()
        this.errorService.check(err);
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
  // ..................getDetails...............................................
  getDetails(element) {
    let service = this.servicedropDown?.filter(
      (a) => a.serviceId == this.ServiceId
    )[0];

    // Remaininghours
    this.serviceRemaingHours = element.remainingUnits;
    //  this.serviceRemaingHours = service.remaingHours;

    this.clientAuthId = element.clientAuthId;
    this.payorId = element.payorId;
    this.masterServiceId = element.masterServiceId;
    this.serviceNameforServiceAuth = element.masterServiceName + "(" + element.serviceDescription + ")";
    // remainingHPDBasedOnCurrentDate 
    this.ConfirmedUnits = element.confirmedUnits;
    this.UnConfirmedUnits = element.unconfirmedUnits;
    this.RemainingUnits = element.remainingUnits;
    let value5 = element.remainingHPDBasedOnCurrentDate.toString().split('.');

    this.billingLov = element.billingLov;
    if (value5.length == 1) {
      this.remainingHPDBasedOnCurrentDate = value5[0] + " hr " + "0 Min";
    }
    else {
      let value6 = 0 + "." + value5[value5.length - 1];
      this.remainingHPDBasedOnCurrentDate = value5[0] + " hr " + (parseFloat(value6) * 60).toString().split('.')[0] + "Min";
    }
    let value7 = element.remainingUPDBasedOnLastVisitDate.toString().split('.');
    if (value7.length == 1) {
      this.remainingHPDBasedOnLastVisitDate = value7[0] + " hr " + "0 Min";
    }
    else {
      let value8 = 0 + "." + value7[value7.length - 1];
      this.remainingHPDBasedOnLastVisitDate = value7[0] + " hr " + (parseFloat(value8) * 60).toString().split('.')[0] + "Min";
    }
  }
  // ..................getWeekDatesForAuthorization...............................................
  async getWeekDatesForAuthorization() {
    await this.global.checkRefreshTokenValidity()
    {
      let EManualType = this.ManualElectronicTimesheetType == null ? true : this.ManualElectronicTimesheetType;
      await this.global.showLoading();
      let param = new URLSearchParams();
      param.append('employeeId', this.global.EvvUserDetails.entityId.toString());
      param.append('date', this.isPayWeek);
      param.append('isBiweekly', JSON.stringify(this.isBiweekly));
      param.append('isliving', EManualType);
      this.ManualETimeSheetService.getWeekDates(param).subscribe(
        (data: any) => {
          this.global.disableloading();
          let WeekDates = data[0].weekRecords;
          let firstElement;
          let lastElement;
          firstElement = WeekDates[0];
          if (this.isBiweekly == true) {
            lastElement = data[1].weekRecords[data[1].weekRecords.length - 1];
          }
          else {
            lastElement = WeekDates[WeekDates.length - 1];
          }
          this.StartDate = firstElement.date;
          this.EndDate = lastElement.date;

        },
        (err) => {
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }


  // ..................selectServiceDrop...............................................
  async selectServiceDrop() {
    this.serviceDropList = [];
    if (this.servicedropDown.length > 0) {
      this.servicedropDown?.forEach(element => {
        this.serviceDropList?.push({
          text: element.serviceName,
          id: element.serviceId,
          icon: '',
          handler: () => {

            this.ServiceId = element.serviceId,
              this.serviceName = element.serviceName;
            this.getServicesWithAuthorizationForServiceGroup()
          }
        })
      });
      this.serviceDropList?.push({
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
      })
      const actionSheet = await this.actionSheetCtrl.create({
        header: 'Select Service',
        cssClass: 'my-custom-class',
        buttons: this.serviceDropList
      });
      await actionSheet.present();
    }
  }




  async checkRemainingUnits(message) {
    await this.global.showLoading();
    let param = new URLSearchParams();
    param.append('remainingUnits', this.RemainingUnits);
    param.append('billingUnit', this.billingLov);
    await this.global.checkRefreshTokenValidity()
    {
      this.CS.checkRemainingUnits(param).subscribe(
        async (data: any) => {
          await this.global.disableloading();
          if (data.body != null && data.body != undefined && data.body != "") {
            this.getErrorNotesforRemainingUnits(data.body, message)
          }
          else {
            if (message == "WithoutTemplate") {
              this.getWeekDates()
            }
            else {
              this.getTemplateforTheDate()
            }

          }
        },
        (err) => {
          this.global.Toastdanger(err.message)
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }


  async getErrorNotesforRemainingUnits(error, message) {
    const alert = await this.alertController.create({
      header: 'Warning',
      message: error,
      buttons: [
        {
          id: "NotesStopSharedError",
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          id: "okorsaveActivies",
          text: 'Ok',
          handler: () => {
            if (message == "WithoutTemplate") {
              this.getWeekDates()
            }
            else {
              this.getTemplateforTheDate()
            }
          }
        }
      ],

      backdropDismiss: false
    });
    await alert.present();


  }
}

