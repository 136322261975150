import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorService } from "./error.service";
import { Globalservice } from "./global.service";

@Injectable({
    providedIn:'root'
})
export class offlineService{
    constructor(private httpClient: HttpClient,public errorService: ErrorService,public global:Globalservice){
    }
    public  getClientsOffline(param:URLSearchParams):Observable<any> {
        let url="api/EvvClient/getClientsOffline?"
        return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
    }
    public  getSchedulesOffline(param:URLSearchParams):Observable<any> {
        let url="api/EvvEmployee/getDashboardDetailsOffline?"
        return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
    }
}