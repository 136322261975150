import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { BasicCarePlanBo, BasicExpandingBo, BasicSignatureBo } from './basic-care-plan.model';
import { BasicCareplanService } from './basic-care-plan.service';
@Component({
  selector: 'app-basic-care-plan',
  templateUrl: './basic-care-plan.page.html',
  styleUrls: ['./basic-care-plan.page.scss'],
})
export class BasicCarePlanPage implements OnInit {
  clientId: any;
  sign: BasicSignatureBo = new BasicSignatureBo();
  getBasicCarePlanList: BasicCarePlanBo = new BasicCarePlanBo();
  isExpand: BasicExpandingBo = new BasicExpandingBo();
  ShowHomeMakingCarePlans: boolean = false;
  BasicCarePlans: any;
  selectedBasicCareplan: any;
  frequencyOfVisit: any;
  AuthorizationItems: any;
  ServiceAuthorizationData: string;
  icd10primaryCode: string;
  icd10Description: string;
  Clientids: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private navCtrl: NavController, public basicservice: BasicCareplanService, public global: Globalservice, public errorService: ErrorService, public PopupController: ModalController,
    public datePipe: DatePipe,) {
    this.global.checkingLogin();
  }
  ngOnInit() {
  }
  async ionViewWillEnter() {
    this.clear();
    await this.global.checkRefreshTokenValidity()
    {
      this.getBasicCareplanDateDropdown();
    }
  
  }
  //....getBasicCareplanDateDropdown...........................
  async getBasicCareplanDateDropdown() {
   
    let param = new URLSearchParams();
    param.append('ClientId', this.global.clientid.toString())
    await this.global.checkRefreshTokenValidity();
    {
      await this.global.showLoading();
    this.basicservice.getBasicCareplanDateDropdown(param).subscribe((data: any) => {
      this.BasicCarePlans = data;
      if (data.length == 0) {
        this.global.Toastdanger("No Records Found");
      }
      this.global.disableloading();
      this.BasicCarePlans?.forEach(element => {
        element.Value = this.datePipe.transform(element.Value, "MM-dd-yyyy")
      });

    }, err => {
      this.errorService.check(err);
      this.global.disableloading();
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  // .......onChangeEvent..................................................................
  onChangeEvent() {
    if (this.selectedBasicCareplan != null) {
      this.getBasicCarePlanDetails();
    }
  }
  //..................getBasicCarePlanDetails...........................................................
  async getBasicCarePlanDetails() {
  
    let param = new URLSearchParams();
    param.append('id', this.selectedBasicCareplan)
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.basicservice.getbasiccarePlanDetails(param).subscribe((data: any) => {

      this.getBasicCarePlanList = JSON.parse(JSON.stringify(data));

      this.GetauthorizationDatesForBasicCarePlan();
      this.getBasicCarePlanList.insuranceNo = data.insuranceNo;
      this.getBasicCarePlanList.serviceactivity = data.serviceactivity;
      this.getBasicCarePlanList.nPI = data.npi;
      this.getBasicCarePlanList.qPPhone = data.qpPhone;
      this.getBasicCarePlanList.qPName = data.qpName;
      this.icd10Description = data.icd10Desc;
      this.icd10primaryCode = data.icd10primaryId;
      if (data.ishealthInsurance != null) {
        this.getBasicCarePlanList.IshealthInsurance = data.ishealthInsurance == true ? true : false
      }
      this.sign.RPsign = data.responsiblePartySignUrl;
      this.sign.Nursesign = data.nurseSignUrl;
      this.sign.PCAsign = data.pcaSignUrl;
      this.sign.Clientsign = data.clientSignUrl;
      this.ShowHomeMakingCarePlans = true;
      this.clearweightheight();
      this.global.disableloading();
    }, err => {
      this.errorService.check(err);
      this.ShowHomeMakingCarePlans = false;
      this.global.disableloading();
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  //.................. GetauthorizationDatesForBasicCarePlan................................
  async GetauthorizationDatesForBasicCarePlan() {
    let params = new URLSearchParams();
    params.append("clientId", this.global.clientid.toString())
    await this.global.checkRefreshTokenValidity()
    {
    this.basicservice.GetauthorizationDatesForBasicCarePlan(params).pipe().subscribe((data: any) => {
      this.AuthorizationItems = data;
      this.ServiceAuthorizationDropList()
      this.frequencyOfVisit = data.filter(a => a.id == this.getBasicCarePlanList.clientAuthId).map(freqOfVisit => freqOfVisit.frequencyOfVisit);
      if (this.frequencyOfVisit.length > 0) {
        let valueFreq = this.frequencyOfVisit.toString().split('.');
        if (valueFreq.length == 1) {
          this.frequencyOfVisit = valueFreq[0] + " hr " + "0 Min";
        }
        else {
          let value = 0 + "." + valueFreq[valueFreq.length - 1];
          this.frequencyOfVisit = valueFreq[0] + " hr " + (parseFloat(value) * 60).toString().split('.')[0] + "Min";
        }
      }
    },
      err => {
        this.errorService.check(err);
        this.ShowHomeMakingCarePlans = false;
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
  //......................................................ServiceAuthorizationDropList............................................
  ServiceAuthorizationDropList() {
    this.ServiceAuthorizationData = this.AuthorizationItems?.filter(serviceAuthId => serviceAuthId.id == this.getBasicCarePlanList.clientAuthId).map(pr =>
      this.datePipe.transform(pr.startdate, "MM/dd/yyyy") + "," + this.datePipe.transform(pr.endDate, "MM/dd/yyyy") + "," + pr.code);
  }
  //....................................................... Back................................................
  public Back() {
    this.clear();
    this.router.navigateByUrl('tabs/my-clients');
  }
  //.................. clear................................
  clear() {
    this.isExpand = new BasicExpandingBo();
    this.sign = new BasicSignatureBo();
    this.getBasicCarePlanList = new BasicCarePlanBo();
    this.ShowHomeMakingCarePlans = false;
    this.selectedBasicCareplan = null;
  }
  //.................. clearweightheight................................
  clearweightheight() {
    if (this.getBasicCarePlanList.weight == 0 || this.getBasicCarePlanList.weight == null || this.getBasicCarePlanList.weight == undefined) {
      this.getBasicCarePlanList.weight = null;
    }
    if (this.getBasicCarePlanList.height == 0 || this.getBasicCarePlanList.height == null || this.getBasicCarePlanList.height == undefined) {
      this.getBasicCarePlanList.height = null;
    }
  }
}


