import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { ClientServiceUnitsService } from './client-service-units.service';
@Component({
  selector: 'app-client-service-units',
  templateUrl: './client-service-units.page.html',
  styleUrls: ['./client-service-units.page.scss'],
})
export class ClientServiceUnitsPage implements OnInit {
  ClientServiceUnitsList:any=[];
  constructor(public modalController: ModalController, public CSU: ClientServiceUnitsService,
    public global: Globalservice,
    public errorService: ErrorService, private datePipe: DatePipe, public router: Router, public http: HttpClient) { 
      this.global.checkingLogin();
    }
  ngOnInit() {
  }
  async ionViewWillEnter() {
    await this.global.checkRefreshTokenValidity()
    {
    this.getClientServiceUnits();
    }
  }
  async getClientServiceUnits() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    let params = new URLSearchParams();
    params.append("ClientId", this.global.EvvUserDetails.entityId.toString());
    
    this.CSU.getClientServiceUnits(params).subscribe((data: any) => {
      data.forEach(element => {
        element.totalunits=element.unitsRemaining+element.unitsUsed;
        element.startTime = element.startTime.split('T')[0];
        element.endTime = element.endTime.split('T')[0];
      });
      this.ClientServiceUnitsList=data;
      this.global.disableloading();
    },
      (err: any) => {
        if (err) {
          this.global.disableloading();
          this.global.Toastdanger(err), 8000;
        }
      })
    }
  }
}
