import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
@Injectable({
    providedIn: 'root'
})
export class ComprehensiveCarePlansService {
    constructor(public http: HttpClient, public errorService: ErrorService, public global: Globalservice) {
    }
    public getComprehensiveCareplanList(id:number): Observable<any> {
        let url = "api/ComprehensiveCarePlan/getComprehensiveCareplanList/" + id;
        return this.http.get(url ).pipe(catchError(this.errorService.handleError));
      }
      public getExistingComprehensiveCareplan(param: URLSearchParams): Observable<any> {
        let url = "api/ComprehensiveCarePlan/GetExistingComprehensiveCareplan?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
      }
      public GetQPEMployeeForClient(param: URLSearchParams): Observable<any> {
        let url = "api/Client/GetQPEMployeeForClient?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
      }
      public GetContactList(param: URLSearchParams): Observable<any> {
        let url = "api/Contact/GetContactList?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
      }
      public GetContactListNew(param: URLSearchParams): Observable<any> {
        let url = "api/Contact/GetContactListNew?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
      }
   
      public GetCaseManagerSingledata(param: URLSearchParams): Observable<any> {
        let url = "api/ClientCaseManagerRelationship/GetCaseManagerSingledata?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
      }

      public GetClientLanguageList(param: URLSearchParams): Observable<any> {
        let url = "api/Language/GetClientLanguageList?";
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
      }    
}








