import { Component, ViewChild, ElementRef } from '@angular/core';
import { MyClientsService } from '../my-clients/my-clients.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ErrorService } from 'src/app/error.service';
import { Services } from '../my-clients/my-clients.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TimesheetService } from '../timesheet/timesheet.service';
import { Globalservice } from 'src/app/global.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { generalservice } from 'src/app/general.service';
import { EmployeeScheduleServiceInfo } from './employeescheduleserviceinfo.service';
import { Network } from '@ionic-native/network/ngx';
import BackgroundGeolocation from 'cordova-background-geolocation-lt';

@Component({
  selector: 'app-employescheduleserviceinfo',
  templateUrl: './employescheduleserviceinfo.page.html',
  styleUrls: ['./employescheduleserviceinfo.page.scss'],
})
export class EmployescheduleserviceinfoPage {
  clientData: any;
  serviceName: string = 'PCA Service';
  trustUrl: SafeResourceUrl;
  activitiesList: Array<Services> = [];
  clientId: number;
  isCommunity: boolean = false;
  serviceId: number;
  startTime: string = null;
  endTime: string = null;
  evvTimesheetId: number = 0;
  scheduleId: number = 0;
  senddata: any;
  Accuracy: any = "";
  isStarted: boolean = false;
  inProgressRecords: Array<any> = [];
  @ViewChild('map')
  isClockIn: boolean = false;
  isClockOut: boolean = false;
  public mapElement: ElementRef;
  // map: mapboxgl.Map;
  lat: any;
  lng: any;
  clientAuthId: number;
  payorId: number;
  serviceGroupId: number = 0;
  backGroundLocationStartCount: any = 0;
  backGroundLocationStopCount: any = 0;
  isOffline: boolean = false;
  constructor(
    public clientService: MyClientsService,
    public errorService: ErrorService,
    public timesheetService: TimesheetService,
    private router: Router,
    private sanitize: DomSanitizer,
    private route: ActivatedRoute,
    public global: Globalservice,
    public general: generalservice,
    public httpservice: EmployeeScheduleServiceInfo,
    public geoLoc: Geolocation,
    public alertController: AlertController,
    public platform: Platform,
    private network: Network
  ) {
    this.route.queryParams.subscribe((params) => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.clientAuthId = data.clientAuthId
        this.serviceGroupId = data.serviceGroupId;
        this.clientId = data.clientId;
        
        this.serviceId = data.serviceId;
        this.startTime = data.startTime;
     
        this.scheduleId = data.scheduleId;
        this.isStarted = data.isStarted;
        this.payorId = data.payorId;
      }
      this.network.onConnect().subscribe(() => {
        this.isOffline = false;
      });
      this.network.onDisconnect().subscribe(() => {
        this.isOffline = true;
      });
    });
    this.global.checkingLogin();
    this.GetEmployeeIsTestingEntity()
  }

  async ionViewWillEnter() {
    this.isClockIn = false;
    this.isClockOut = false;
    this.Accuracy = null;
    this.clientData = null;
    this.serviceName = '';
    this.senddata = null;
    this.activitiesList = [];
    if (this.startTime != null) {
      let isRecordExists: boolean = false;
      this.inProgressRecords.forEach((element) => {
        if (element.clientId == this.clientId) {
          isRecordExists = true;
        }
      });
      if (!isRecordExists) {
        this.inProgressRecords.push({
          clientId: this.clientId,
          serviceId: this.serviceId,
          startTime: this.startTime,
          scheduleId: this.evvTimesheetId,
          evvTimesheetId: this.evvTimesheetId,
          isStarted: this.isStarted,
        });
      }
    }
    await this.global.checkRefreshTokenValidity()
    {
    this.getClients();
    this.getLocation();
    }
    //  this.getActivities();
    // this.getService();
  }

  async getLocation() {
    const position = await this.geoLoc
      .getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      })
      .then((res) => {
      });
  }

  async getService() {

    let param = new URLSearchParams();
    param.append('Client', this.clientId.toString());
    await this.global.checkRefreshTokenValidity()
    {
    this.clientService.getServices(param).subscribe(
      (data: Array<Services>) => {
        data.forEach((element) => {
          if (element.serviceId == this.serviceId) {
            this.serviceName = element.serviceName;
          }
        });
      },
      (err) => {
        this.errorService.check(err);
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }

  async getClients() {
    let param = new URLSearchParams();
    param.append('appointmentId', this.scheduleId.toString());
    await this.global.checkRefreshTokenValidity()
    {
    this.httpservice.getClientById(param).subscribe(
      (data: any) => {
        this.clientData = data;

        this.serviceName = data.serviceName;
        this.activitiesList = data.activities;
        this.evvTimesheetId = this.clientData.evvTimesheetId;
        this.scheduleId = this.clientData.appointmentId;
        this.clientData.mobileNumberFormat =
          this.clientData.mobileNumber != null
            ? this.general.converPhoneGoogleLib(
              this.clientData.mobileNumber.toString()
            )
            : null;
        this.clientData.zip =
          this.clientData.zip != null ? this.clientData.zip : '';

        this.trustUrl = this.sanitize.bypassSecurityTrustResourceUrl(
          'https://maps.google.com/maps?q=' +
          this.clientData.street +
          ',' +
          this.clientData.cityStateZip +
          '&z=15&ie=UTF8&iwloc=&output=embed&zoom=15'
        );
      },
      (err) => {
        this.errorService.check(err);
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }

  async start() {
   
    if (this.global.isTrainer == true) {
      this.startTime = this.global.DateandTimeFormat(this.startTime);
    } else {
      this.startTime = this.global.DateandTimeFormat(new Date());
    }
    let STime = this.startTime;
    STime = this.global.getDateTimeString(STime);
    this.senddata = {
      employeeId: this.global.EvvUserDetails.entityId,
      clientId: this.clientId,
      masterServiceId: this.serviceId,
      clockInTime: STime,
      clientAuthId: this.clientAuthId,
      serviceGroupId: this.serviceGroupId,
      payorId: this.payorId,
      callLongitude: this.lng != null ? parseFloat(this.lng) : null,
      callLatitude: this.lat != null ? parseFloat(this.lat) : null,
      appointmentId: this.scheduleId,
    };
    let param = new URLSearchParams();
    param.append('scheduleId', this.scheduleId.toString());
    this.AuthValidationForOverNightRecords("start");
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.httpservice.startTimesheets(this.senddata).subscribe(
      (data) => {
        // new Code
        this.global.disableloading();

        if (data.errors.length > 0) {
          this.global.Toastdanger(data.errors);
          return;
        }
        this.global.isModalStart = false;
        this.isStarted = true;
        this.evvTimesheetId = data.evvId;
      },
      (err) => {
        this.global.Toastdanger(err.message);
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  async stop() {
  

    if (this.global.isTrainer == true) {
      this.endTime = this.global.DateandTimeFormat(this.endTime);
    } else {
      this.endTime = this.global.DateandTimeFormat(new Date());
    }
    let eTime = this.endTime;
    eTime = this.global.getDateTimeString(eTime);
    let data = {
      evvTimesheetId: this.evvTimesheetId,
      clockOutTime: eTime,
      clientAuthId: this.clientAuthId,
      serviceGroupId: this.serviceGroupId,
      callLongitude: this.lng != null ? parseFloat(this.lng) : null,
      callLatitude: this.lat != null ? parseFloat(this.lat) : null,
      appointmentId: this.scheduleId,
    };
    // this.senddata = data
    let param = new URLSearchParams();
    param.append('scheduleId', this.scheduleId.toString());
    this.AuthValidationForOverNightRecords("stop");
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.httpservice.stopTimesheets(data, param).subscribe(
      (data) => {
        this.global.disableloading();

        if (data.errors.length > 0) {
          this.global.Toastdanger(data.errors);
          return;
        }
        if (data.errors.length == 0) {
          this.global.isModalStop = false;
        }
        

        setTimeout(() => {
          let navigationExtras: NavigationExtras = {
            state: {
              clientId: this.clientId,
              serviceId: this.serviceId,
              startTime: this.startTime,
              endTime: this.endTime,
              scheduleId: this.scheduleId,
              type: 'pending',
              clientSign: null,
              employeeSign: null,
              isSubmitButton: true,
              backto: 'currentdaydashboard',
              evvTimesheetId: this.evvTimesheetId,
              frompage: 'employeeschedule',
            },
          };
          this.router.navigate(['/tabs/timesheet'], navigationExtras);
        }, 500);
      },
      (err) => {
        this.errorService.check(err.message);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  back() {
    if (this.startTime != null) {
      this.inProgressRecords.push({
        clientId: this.clientId,
        serviceId: this.serviceId,
        startTime: this.startTime,
        scheduleId: this.scheduleId,
        isStarted: this.isStarted,
      });
    }

    let navigationExtras: NavigationExtras = {
      state: this.inProgressRecords,
    };
    this.router.navigate(['/tabs/currentdaydashboard'], navigationExtras);
  }
  async StartClockInButton()
  {
  if(this.global.isOffline)
      {
      this.OnSwipe()
      }
      else 

      {
        this.isClockIn=true;
        await this.global.checkRefreshTokenValidity()
    {
      this.OnSwipe()
    }
      }
  }

  async StopClockOutButton()
  {
  if(this.global.isOffline)
      {
      this.onswipeLeft()
      }
      else 

      {
        this.isClockOut=true;
        await this.global.checkRefreshTokenValidity()
    {
      this.onswipeLeft()
    }
      }
  }
  async OnSwipe() {
    this.isClockIn=true
    this.global.isModalStart = false;
    if (this.global.applicationVersionNumber == null || this.global.applicationVersionNumber == undefined || this.global.applicationVersionNumber == "") {
      this.getStartLocation();
    } else {

      await BackgroundGeolocation.ready({
        stationaryRadius: 2,
        distanceFilter: 3,
        debug: false,
        foregroundService: true,
        stopOnTerminate: true,
        locationAuthorizationRequest: 'Always',
        notification: {
          title: "AllCare GPS Tracking",
          text: "Live Location Sharing",
        },
        backgroundPermissionRationale: {
          title: "Allow AllCare to access to this device's location in the background?",
          message: "In order to track your activity in the background, please enable Allow all the time location permission",
        }
      }).then((state) => {
      })
      this.backGroundLocationStartCount = 0;
      this.getBackGroundStartLocation();
    }
  }
  async onswipeLeft() {
    this.isClockOut=true
    this.global.isModalStart = false;
    if (this.global.applicationVersionNumber == null || this.global.applicationVersionNumber == undefined || this.global.applicationVersionNumber == "") {
      this.getStopLocation();
    } else {

      await BackgroundGeolocation.ready({
        stationaryRadius: 2,
        distanceFilter: 3,
        debug: false,
        foregroundService: true,
        stopOnTerminate: true,
        locationAuthorizationRequest: 'Always',
        notification: {
          title: "AllCare GPS Tracking",
          text: "Live Location Sharing",
        },
        backgroundPermissionRationale: {
          title: "Allow AllCare to access to this device's location in the background?",
          message: "In order to track your activity in the background, please enable Allow all the time location permission",
        }
      }).then((state) => {
      })
      this.backGroundLocationStopCount = 0;
      this.getBackGroundStopLocation();
    }
  }
  async getStartLocation() {
    await this.global.showLoading();
    this.geoLoc
      .getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      })
      .then(
        (res) => {
          this.isClockIn = false;
          this.global.disableloading();
          this.lat = res.coords.latitude;
          this.lng = res.coords.longitude;
          this.Accuracy = res.coords.accuracy != null ? parseInt(res.coords.accuracy.toString()) : null;
          this.GetStartCommunity();
        },
        (err: any) => {
          this.isClockIn = false;
          this.global.disableloading();
          this.global.Toastdanger('Turn on Location');
        }
      );
  }
  async getStopLocation() {
    await this.global.showLoading();
    this.geoLoc
      .getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      })
      .then(
        (res) => {
          this.global.disableloading();
          this.isClockOut = false;
          this.lat = res.coords.latitude;
          this.lng = res.coords.longitude;
          this.Accuracy = res.coords.accuracy != null ? parseInt(res.coords.accuracy.toString()) : null;
          this.GetStopCommunity();
        },
        (err: any) => {
          this.isClockOut = false;
          this.global.disableloading();
          this.global.Toastdanger('Turn on Location');
        }
      );
  }
  startservice() {
    this.presentAlertConfirm();
  }
  stopservice() {
    this.presentstopAlertConfirm();
  }

  async presentAlertConfirm() {
    let message: any;
    if (!this.isCommunity) {
      message = 'Do you want to start service?';
      const alert = await this.alertController.create({
        header: 'Clock In Alert',
        message: message,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Yes',
            handler: () => {
              this.checkStartforTrainingEntity();
            },
          },
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
        ],
      });
      await alert.present();
    } else {
      message =
        'You are away from clients home, please enable the Community box to proceed!';
      const alert = await this.alertController.create({
        header: 'Clock In Alert',
        message: message,
        backdropDismiss: false,
        inputs: [
          {
            name: 'Community',
            type: 'checkbox',
            label: 'Community',
          },
        ],
        buttons: [
          {
            text: 'Yes',
            handler: (data) => {
              if (data.length > 0) {
                this.checkStartforTrainingEntity();
              } else {
                this.global.ToastDangerMiniDuration(
                  'Please Select Community Checkbox!'
                );
              }
            },
          },
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
        ],
      });
      await alert.present();
    }
  }
  async presentstopAlertConfirm() {
    let message: any;
    if (!this.isCommunity) {
      message = 'Do you want to stop service?';
      const alert = await this.alertController.create({
        header: 'Clock Out Alert',
        message: message,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Yes',
            handler: () => {

              this.checkStopforTrainingEntity();
            },
          },
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
        ],
      });
      await alert.present();
    } else {
      message =
        'You are away from clients home, please enable the Community box to proceed!';
      const alert = await this.alertController.create({
        header: 'Clock Out Alert',
        message: message,
        backdropDismiss: false,
        inputs: [
          {
            name: 'Community',
            type: 'checkbox',
            label: 'Community',
          },
        ],
        buttons: [
          {
            text: 'Yes',
            handler: (data) => {
              if (data.length > 0) {

                this.checkStopforTrainingEntity();
              } else {
                this.global.ToastDangerMiniDuration(
                  'Please Select Community Checkbox!'
                );
              }
            },
          },
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
        ],
      });
      await alert.present();
    }
  }

  async GetStartCommunity() {
  
    let CommunityData = {
      ClientId: [parseInt(this.clientId.toString())],

      CallLatitude: this.lat != null ? parseFloat(this.lat) : null,
      CallLongitude: this.lng != null ? parseFloat(this.lng) : null,
    };
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.clientService.GetCommunity(CommunityData).subscribe(
      (data: any) => {
        this.isCommunity = data;
        this.isClockIn = false;
        this.global.disableloading();
        this.startservice();

      },
      (err) => {
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  async GetStopCommunity() {
   
    let CommunityData = {
      ClientId: [parseInt(this.clientId.toString())],
      CallLatitude: this.lat != null ? parseFloat(this.lat) : null,
      CallLongitude: this.lng != null ? parseFloat(this.lng) : null,
    };
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.clientService.GetCommunity(CommunityData).subscribe(
      (data: any) => {
        this.isCommunity = data;
        this.isClockOut = false;
        this.global.disableloading();
        this.stopservice();

      },
      (err) => {
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  //.................................................getBackGroundStartLocation................................
  getBackGroundStartLocation() {
    this.Accuracy = null;
    this.platform.ready().then(async () => {
      BackgroundGeolocation.start().then((state) => {
        this.global.disableloading();
        this.isClockIn = false;
        BackgroundGeolocation.onLocation((location) => {
          if (this.backGroundLocationStartCount == 0) {
            this.Accuracy =
              location.coords.accuracy != null
                ? parseInt(location.coords.accuracy.toString())
                : null;
            this.lat = location.coords.latitude;
            this.lng = location.coords.longitude;
            this.backGroundLocationStartCount++;
            this.GetStartCommunity();
            this.global.disableloading();
          }
        }, (error) => {
          this.global.disableloading();
          BackgroundGeolocation.stop()
          this.isClockIn = false;
        });
      }).catch((err) => {
        BackgroundGeolocation.stop()
        this.global.disableloading();
        this.isClockIn = false;
      });;
    }).catch((err) => {
      BackgroundGeolocation.stop()
      this.global.disableloading();
      this.isClockIn = false;
    });
  }
  //.................................................getBackGroundStopLocation................................
  getBackGroundStopLocation() {
    this.Accuracy = null;

    this.platform.ready().then(async () => {
      BackgroundGeolocation.start().then((state) => {
        BackgroundGeolocation.onLocation((location) => {
          if (this.backGroundLocationStopCount == 0) {
            this.Accuracy =
              location.coords.accuracy != null
                ? parseInt(location.coords.accuracy.toString())
                : null;
            this.lat = location.coords.latitude;
            this.lng = location.coords.longitude;
            this.backGroundLocationStopCount++;
            this.GetStopCommunity();
            this.global.disableloading();
          }
        }, (error) => {
          this.global.disableloading();
          BackgroundGeolocation.stop()
          this.isClockOut = false;
        });

      }).catch((err) => {
        this.global.disableloading();
        BackgroundGeolocation.stop()
        this.isClockOut = false;
      });;
    }).catch((err) => {
      this.global.disableloading();
      BackgroundGeolocation.stop()
      this.isClockOut = false;
    });
  }


  async AuthValidationForOverNightRecords(Type) {
    if (this.clientAuthId != null && this.clientAuthId != 0 && this.clientAuthId != undefined) {
      let data = {
        clientAuthorizationId: this.clientAuthId,
        startDate:this.global.timezone == "Minnesota" ?this.startTime: this.global.getDateWithUTC(this.startTime),
        endDate: this.global.timezone == "Minnesota" ?Type == "start" ? null : this.endTime:Type == "start" ? null : this.global.getDateWithUTC(this.endTime)
      }
      await this.global.checkRefreshTokenValidity()
      {
      this.clientService.AuthValidationForOverNightRecords(data).subscribe(
        (data) => {
        },
        (err) => {
          this.showOvernightError(err.message);
        }
      )
      }
    }

  }
  async showOvernightError(err) {
    const alert = this.alertController.create({
      header: 'Warning',
      message: err,
      buttons: [
        {
          text: 'Ok',
          handler: (data) => {
          },
        },
      ],
      backdropDismiss: false,
    });
    await (await alert).present();
  }

  checkStartforTrainingEntity() {
    if (this.global.isTrainer == true) {
      this.global.isModalStart = true;
      this.startTime = null;
      this.selectStartValues();
      return;
    } else {
      this.global.isModalStart = false;
      this.start();
    }
  }
  checkStopforTrainingEntity() {
    if (this.global.isTrainer == true) {
      this.global.isModalStop = true;
      this.endTime = null;
      this.selectEndValues();
      return;
    } else {
      this.global.isModalStop = false;
      this.stop();
    }
  }

  selectStartValues() {
    if (this.startTime == undefined) {
      this.startTime = this.global.DateandTimeFormat(new Date());
    }
  }
  selectEndValues() {
    if (this.endTime == undefined) {
      this.endTime = this.global.DateandTimeFormat(new Date());
    }
  }

  closeStartModal() {
    this.global.isModalStart = false;
  }
  closeStopModal() {
    this.global.isModalStop = false;
  }

  async GetEmployeeIsTestingEntity() {
   
    let params = new URLSearchParams();
    params.append("EmployeeId", this.global.EvvUserDetails.entityId.toString())
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.clientService.GetEmployeeIsTestingEntity(params).subscribe((data: any) => {
      this.global.isTrainer = data;

      this.global.disableloading();
    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }

}
