import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ActionSheetController, Platform } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { getEvvWeeklyTimesheetBO, Pending, Weekly } from './client-timesheets.model';
import { ClientTimesheetsService } from './client-timesheets.service';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-client-timesheets',
  templateUrl: './client-timesheets.page.html',
  styleUrls: ['./client-timesheets.page.scss'],
})
export class ClientAllTimesheetsPage {
  searchClient: string = "";
  servicesListContent = [];
  inProgressRecords = null;
  signnedList: Array<Weekly>;
  pendingList: Array<Pending>;
  weeklyList: Array<getEvvWeeklyTimesheetBO>;
  sortType: string = "weekly";
  yearfilter: any = new Date().toISOString();
  isSubmitButton: boolean = true;
  customPickerOptions = {};
  signednotfound: boolean = false;
  weeknotfound: boolean = false;
  showLoader: boolean = true;
  constructor(public allTimesheetService: ClientTimesheetsService, public errorService: ErrorService, public actionSheetCtrl: ActionSheetController,
    private router: Router, private route: ActivatedRoute, public global: Globalservice, public platform: Platform, public modalController: ModalController,
    public general: generalservice) {
      this.global.checkingLogin();
      this.route.queryParams.subscribe(params => {
        if (this.router.getCurrentNavigation().extras.state) {
          let data = this.router.getCurrentNavigation().extras.state;
          this.inProgressRecords = data;
        }
      });
    
  }
  expandItem(item): void {

    if (item.expanded) {
      item.expanded = false;
    } else {
      this.signnedList.map(listItem => {
        if (item == listItem) {
          listItem.expanded = !listItem.expanded;
        } else {
          listItem.expanded = false;
        }
        return listItem;
      });
    }
  }
  expandItem1(item): void {


    if (item.expanded) {
      item.expanded = false;
    } else {
      this.weeklyList.map(listItem => {
        listItem.weekdata.map(lisdata => {
          if (item == lisdata) {
            lisdata.expanded = !lisdata.expanded;
          } else {
            lisdata.expanded = false;
          }
          return listItem;
        });
      });
    }
  }

  ngOnInit() {
    this.sortType = "weekly";
  }

  async ionViewWillEnter() {
    await this.global.checkRefreshTokenValidity()
    {
    if (this.sortType == "signed" || this.sortType == "pending") {
      this.getSigned();
    }
    else if (this.sortType == "weekly") {
      this.getWeekly();
    }
  }

  }
  ///---------- Get Client List from Service
  async getSigned() {

    await this.global.checkRefreshTokenValidity()
    {
    let date = this.yearfilter != "" ? new Date(this.yearfilter).toISOString() : ""
    if (date != "") {
     await this.global.showLoading();
      let param = new URLSearchParams();
      param.append("searchName", this.searchClient)
      param.append("ClientId", this.global.EvvUserDetails.entityId.toString())
      param.append("filterType", this.sortType)
      param.append("filterdate", date)
      this.allTimesheetService.getSigned(param).subscribe((data: Array<Weekly>) => {
       this.global.disableloading()
        let record = [];
        this.showLoader = false;
        this.signnedList = data;



        if (data.length > 0) {
          this.signnedList.forEach(e => e.mobileNumberFormat = e.mobileNumber != null ? this.general.converPhoneGoogleLib(e.mobileNumber.toString()) : null)
          this.signednotfound = false
        }
        else {
          this.signednotfound = true
        }

        // this.global.disableloading()
        //    document.getElementById("expand-wrapper1").style.maxHeight="150px";
        //    document.getElementById("expand-wrapper").style.maxHeight="150px";
      }, err => {
         this.global.disableloading()
        this.errorService.check(err);
        this.showLoader = false;
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
        // this.global.disableloading()
      })
    }
    else {
      this.signnedList = [];
      this.signednotfound = true;
    }
  }
  }

  async getWeekly() {
    await this.global.checkRefreshTokenValidity()
    {

    let date = this.yearfilter != "" ? new Date(this.yearfilter).toISOString() : ""
    if (date != "") {
       await this.global.showLoading();
      let param = new URLSearchParams();
      param.append("searchName", this.searchClient)
      param.append("ClientId", this.global.EvvUserDetails.entityId.toString())
      param.append("filterType", this.sortType)
      param.append("filterdate", date)
      this.allTimesheetService.getWeekly(param).subscribe((data: Array<getEvvWeeklyTimesheetBO>) => {
         this.global.disableloading()
        this.weeklyList = data;

        this.showLoader = false;
        if (data.length > 0) {
          this.weeklyList.forEach(w => w.mobileNumberFormat = w.mobileNumber != null ? this.general.converPhoneGoogleLib(w.mobileNumber.toString()) : null)
          this.weeknotfound = false;
        }
        else {
          this.weeknotfound = true;
        }

        // this.global.disableloading()
      }, err => {
        this.global.disableloading()
        this.errorService.check(err);
        this.showLoader = false;
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
        
      })
    }
    else {
      this.weeklyList = [];
      this.weeknotfound = true;
    }
  }
  }
  sortTypeChange() {

    if (this.sortType == "signed" || this.sortType == "pending") {
      this.getSigned();
    }
    else if (this.sortType == "weekly") {
      this.getWeekly();
    }

  }
  viewTimeSheet(clientData, timesheetData) {

    if (this.sortType == "signed") {

      this.isSubmitButton = false;
    }
    else if (this.sortType == "pending") {
      this.isSubmitButton = true;
    }
    let navigationExtras: NavigationExtras = {
      state: {

        clientId: clientData.clientId,
        serviceId: timesheetData.serviceId,
        startTime: timesheetData.startTime,
        endTime: timesheetData.endTime,
        clientSign: clientData.clientSign,
        employeeSign: clientData.employeeSign,
        isSubmitButton: this.isSubmitButton,
        evvTimesheetId: timesheetData.evvTimesheetId,
        scheduleId: timesheetData.evvTimesheetId,
        backto: "all-timesheets",
        type: this.sortType,
        notes: timesheetData.notes
      }

    }

    this.router.navigate(['/client/client-timesheet'], navigationExtras)
  }
  signAll(employeeId, timesheetData) {

    let val = { employeeId: employeeId, startDate: timesheetData.startDate, endDate: timesheetData.endDate }
    let navigationExtras: NavigationExtras = {
      state: {
        data: val,
        clientSign: null,
        employeeSign: null,
        type: "timesheet"
      }
    }

    this.router.navigate(['/client/client-sign-all-timesheet'], navigationExtras)
  }
  viewTimeSheetWeekly(clientData, timesheetData) {

    let navigationExtras: NavigationExtras = {
      state: {
        clientId: clientData.employeeId,
        serviceId: timesheetData.serviceId,
        startTime: timesheetData.startTime,
        endTime: timesheetData.endTime,
        evvTimesheetId: timesheetData.evvTimesheetId,
        scheduleId: timesheetData.evvTimesheetId,
        clientSign: null,
        employeeSign: null,
        isSubmitButton: true,
        type: this.sortType,
        backto: "all-timesheets"

      }
    }
    this.router.navigate(['/client/client-timesheet'], navigationExtras)
  }
  getClients() {
    this.sortTypeChange()
  }

}