import { Component, Input, OnInit } from '@angular/core';
import { Globalservice } from 'src/app/global.service';
@Component({
  selector: 'app-dashboard-hour-details',
  templateUrl: './dashboard-hour-details.page.html',
  styleUrls: ['./dashboard-hour-details.page.scss'],
})
export class DashboardHourDetailsPage implements OnInit {
  @Input() vals: any;
  constructor(public global: Globalservice,) { }
  dats: any;
  ngOnInit() {
    this.dats = null;
    this.dats = this.vals;
    this.CalculateHours();
  }
  CalculateHours() {
    this.dats.records.forEach(data => {
      let valweeks = data.totalHours.toString().split('.');
      if (valweeks.length == 1) {
        data.hours = valweeks[0] + " hr " + "0 Min";
      }
      else {
        let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
        data.hours = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + " Min";
      }
    });
  }
  ngOnDestroy() {
    this.vals = null;
  }
}
