import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Globalservice } from './global.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService implements HttpInterceptor {
  ////========>>>Base URL
  //  url: string = "https://allcareuat.azurewebsites.net/"
  //  url: string = "https://uat.allcaresoftware.com/"
  // url: string = "http://localhost:5000/";
  // url: string = "https://allcareuat.azurewebsites.net/"
  // url: string = "http://localhost:5000/";
  // url: string = "https://allcaredevbeta.azurewebsites.net/"
  url: string = "";
  constructor(public global: Globalservice) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let agencyId: any;
    this.global.UserData = JSON.parse(localStorage.getItem("Evv_login"));
    var requrlltrs = request.url.split('').slice(0, 3).join('');
    agencyId = ((this.global.EvvUserDetails != null && this.global.EvvUserDetails != undefined) ?
      ((this.global.EvvUserDetails.agencyId == null || this.global.EvvUserDetails.agencyId == undefined || this.global.EvvUserDetails.agencyId == 0) ? 0 :
        this.global.EvvUserDetails.agencyId) : 0);
    if (this.global.UserData != null) {
      this.global.token = this.global.UserData.accessToken;
    }
  
    this.url= this.global.timezone == "Minnesota"?"https://app.allcaresoftware.com/":"https://us.allcaresoftware.com/"
    if (requrlltrs == 'api') {
      request = request.clone({
        url: this.url + request.url,
        setHeaders: { 
          Authorization: `Bearer ${this.global.token}`,
          'AgencyId': agencyId.toString(),
          'EvvResetAPIKey': 'EvvResetAuthendicationKey'
        },
        withCredentials: true
      })
    }
    return next.handle(request);
  }
}
