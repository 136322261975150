import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { MyClientsService } from '../../my-clients/my-clients.service';
@Component({
  selector: 'app-client-change-password',
  templateUrl: './client-change-password.page.html',
  styleUrls: ['./client-change-password.page.scss'],
})
export class ClientChangePasswordPage implements OnInit {
  NewPassword: any;
  OldPassword: any;
  ConfirmPassword: any;
  ConfirmPasswordError: boolean = false;
  NewPasswordError: boolean = false;
  OldPasswordError: boolean = false;
  constructor(public myclientservice:MyClientsService, private router: Router, public errorService: ErrorService, public http: HttpClient, public global: Globalservice) {}
  ngOnInit() {}
  ionViewWillEnter() {

    this.NewPassword = null;
    this.OldPassword = null;
    this.ConfirmPassword = null;
    this.ConfirmPasswordError = false;
    this.NewPasswordError = false;
    this.OldPasswordError= false;
  }
    //......................................................UpdatePassword............................................
  async UpdatePassword() {
    await this.global.checkRefreshTokenValidity()
    {
    if (this.ConfirmPassword != this.NewPassword) {
      this.global.ToastDangerMiniDuration(
        'New and Confirm password does not match'
      );
     
      return;
     
    }
    this.global.showLoading();
   
    let myParams = new URLSearchParams();
    myParams.append('UserRole', this.global.EvvUserDetails.entityType);
    myParams.append('UserId', this.global.EvvUserDetails.entityId.toString());
    myParams.append('OldPassword', this.OldPassword);
    myParams.append('NewPassword', this.NewPassword);
    this.myclientservice.verifyEvvUserPassword(myParams ).subscribe(
      async (checkDetails: any) => {
        this.global.disableloading();
        this.global.ToastSuccessMiniDuration('Password Changed Successfully');
        if (this.global.role == 'CLIENT') {
          this.router.navigate(['/client/client-all-timesheets'], { replaceUrl: true });
        } else if (this.global.role == 'PCAEMPLOYEE') {
          this.router.navigate(['/tabs/all-timesheets'], { replaceUrl: true })
        }
        this.logoutSession();
      },
      (err) => {
        this.global.disableloading();
        this.errorService.check(err);
        this.global.ToastDangerMiniDuration(err.message);
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  //......................................................assvalid1............................................
  validateOldPassword(event) {
    let str = event.target.value;

    if (
      str.match(/[a-z]/g) &&
      str.match(/[A-Z]/g) &&
      str.match(/[0-9]/g) &&
      str.match(/[^a-zA-Z\d]/g) &&
      str.length >= 8
    ) {
      this.OldPasswordError = false;
    } else {
      this.OldPasswordError = true;
    }
  }
  //......................................................assvalid1............................................
  validateNewPassword(event) {
    let str = event.target.value;

    if (
      str.match(/[a-z]/g) &&
      str.match(/[A-Z]/g) &&
      str.match(/[0-9]/g) &&
      str.match(/[^a-zA-Z\d]/g) &&
      str.length >= 8
    ) {
      this.NewPasswordError = false;
    } else {
      this.NewPasswordError = true;
    }
  }
  //......................................................assvalid1............................................
  validateConfirmPassword(event) {
    let str = event.target.value;

    if (
      str.match(/[a-z]/g) &&
      str.match(/[A-Z]/g) &&
      str.match(/[0-9]/g) &&
      str.match(/[^a-zA-Z\d]/g) &&
      str.length >= 8
    ) {
      this.ConfirmPasswordError = false;
    } else {
      this.ConfirmPasswordError = true;
    }
  }

  async sessionLogout() {
    await this.global.checkRefreshTokenValidity()
    {
    let myParams = new URLSearchParams();
    myParams.append('UserRole', this.global.EvvUserDetails.entityType);
    myParams.append('RefreshTokenString', this.global.refreshTokenString);
    myParams.append('UserId', this.global.EvvUserDetails.entityId.toString());
    myParams.append('UserName',   this.global.EvvUserDetails.userName);
    myParams.append('AgencyCode', this.global.EvvUserDetails.agencyCode);
    this.myclientservice.sessionLogout(myParams).subscribe((data:any) => {
      this.global.disableloading();
    }, err => {
      this.global.disableloading();
      this.errorService.check(err);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
      // this.global.disableloading()
    })
  }
  }

  async logoutSession() {
    await this.global.checkRefreshTokenValidity()
    {
    let url = "api/Auth/logout?"
    let params = new URLSearchParams();
    let islogoutOfAllSessions=true;
    params.append("revokeAllSessions",  JSON.stringify(islogoutOfAllSessions))
    params.append("refreshToken", this.global.refreshTokenString);
    await this.global.showLoading();
    this.http.post(url + params, null).pipe().subscribe((data: any) => {
      this.global.disableloading();
      
    },
      (err: HttpErrorResponse) => {
        this.global.disableloading();
       
      })
  }
}
}
