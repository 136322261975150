export class Profile {
    clientId: number;
    agencyId:number;
    fullname:string;
    firstName:string;
    lastName:string;
    email:string;
    age:number;
    apt:number;
    mobileNumber:number;
    mobileNumberFormat:string;
    address:string;
    street:string;
    city:string;
    state:string;
    zip:string;
    country:string;
    about:string;
    status: number;
    gender:number;

}
export class ClientBO {
    id: number;
    name:string|null;
    agencyId: number;
    firstName: string;
    lastName: string;
    middleName: string;
    genderLid: number;
    dOB: string;
    age: number | null;
    weight: number | null;
    aKA: string;
    medicaid: string;
    medicare: string;
    street: string;
    apt: string;
    phone1: string;
    phone2: string;
    county: string;
    state: string;
    city: string;
    zipCode: string;
    sSN: string;
    email: string;
    statusLid: number;
    status_Name: string;
    userName: string;
    password: string;
    locationStatusLid: number | null;
}
