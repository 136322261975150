import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { Globalservice } from 'src/app/global.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
})
export class ForgotPasswordPage {
  Role: string = '';
  userName: string = '';
  passerror: boolean = false;
  Agencycode: string;
  ForgotCount=0;
  constructor(
    public platform:Platform,
    public menu: MenuController,
    public router: Router,
    public http: HttpClient,
    public global: Globalservice,
    private toastCtrl: ToastController
  ) {}
  ionViewWillEnter() {
    this.menu.enable(false);
  }
  //......................................................proceed............................................
  async proceedtoResetPassword() {
    await this.global.showLoading();
    let param = new URLSearchParams();
    param.append('UserRole', this.Role);
    param.append('AgencyCode', this.Agencycode),
      param.append('UserName', this.userName);

    let url = 'api/EvvCredential/sendResetOtpMail?';
    this.http.get(url + param).subscribe(
      (data: any) => {
        this.presentToast('Otp send to your mail Id', 'success');
       
        this.global.disableloading();
        let navigationExtras: NavigationExtras = {
          state: {
            userId: data.userId,
            agencyId: data.agencyId,
            userName: data.userName,
            userMailId: data.userMailId,
            otpId: data.otpId,
            userRole:this.Role,
            agencycode:this.Agencycode
          },
        };
        this.router.navigate(['/login/reset-password'], navigationExtras);
      },
      (err: HttpErrorResponse) => {
        this.global.disableloading();
        if (this.global.timezone == "US") {
            this.global.disableloading();
            this.presentToast(err.error,'danger');
        }
        // if (err.error == "Agency Not Found for this User" || err.error =="User Details Not Found") {
          let timezone = this.global.timezone == "US" ? "Minnesota" : "US";
          this.global.timezone = timezone;
          localStorage.setItem(
            'TimeZone',
            JSON.stringify(this.global.timezone)
          );
          if (this.ForgotCount == 0) {
            this.proceedtoResetPassword();
            this.ForgotCount++;
          }
        }
      // }
    );
  }
  //......................................................cancel............................................
  cancel() {
    this.router.navigate(['/login/login-password']);
    this.Agencycode = '';
    this.Role = '';
    this.Agencycode = '';
    this.userName = '';
  }
  //......................................................presentToast............................................
  async presentToast(msg, colors) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: colors,
    });
    toast.present();
  }
}
