import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ActionSheetController, AlertController, Platform } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { Services, sharedCare } from '../my-clients/my-clients.model';
import { MyClientsService } from '../my-clients/my-clients.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Network } from '@ionic-native/network/ngx';
import BackgroundGeolocation from 'cordova-background-geolocation-lt';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-shared-care-clients',
  templateUrl: './shared-care-clients.page.html',
  styleUrls: ['./shared-care-clients.page.scss'],
})
export class SharedCareClientsPage implements OnInit {
  clientsList: any = [];
  count: number = 0;
  ShowLoader: boolean = true;
  isCommunity: boolean = false;
  servicesList: Array<Services>;
  servicesListContent = [];
  ClientIds: any = [];
  TsIds: any = [];
  Accuracy: any = "";
  SavePayloadList: any = [];
  
  isClockIn: boolean = false;
  isClockOut: boolean = false;
  serviceId: any;
  serviceGroupId: number;
  sharedCare: sharedCare = new sharedCare();
  service: any;
  errorcheck: any = [];
  lat: any;
  backGroundLocationStartCount: any = 0;
  backGroundLocationStopCount: any = 0;
  lng: any;
  noCLients: boolean = false;
  isStarted: boolean = false;
  isOffline: boolean = false;
  isEditShareCare:boolean=false;
  constructor(public datePipe: DatePipe, public alertController: AlertController, public clientService: MyClientsService, public errorService: ErrorService, public actionSheetCtrl: ActionSheetController,
    private router: Router, private route: ActivatedRoute, public geoLoc: Geolocation, public global: Globalservice, public platform: Platform,
    public general: generalservice, private network: Network,) {
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.serviceId = data.serviceId;
        this.service = data.service;
        this.TsIds = data.evvIds;
        this.serviceGroupId = data.serviceGroupId
        this.isStarted = data.isStarted;
        this.clientsList = data.sharedCareRecords;
       this. isEditShareCare=data.isEditShareCare;
      
        this.clientsList.forEach(element => {
          this.selectClientService(element)
        });
      }
      this.network.onConnect().subscribe(() => {
        this.isOffline = false;
      })
      this.network.onDisconnect().subscribe(() => {
        this.isOffline = true;
      })
    });
    this.global.checkingLogin();
  }
  ngOnInit() {
  }
  //.................................................ionViewWillEnter................................
  ionViewWillEnter() {
   
    this.isClockIn = false;
    this.isClockOut = false;
    this.Accuracy = null;
  }
  //.................................................getClients................................
  async getClients() {
    await this.global.checkRefreshTokenValidity()
    {
    if (this.ClientIds != null && this.ClientIds != undefined && this.ClientIds != '') {
      await this.global.showLoading();
      let param = new URLSearchParams();
      param.append("ClientId", this.ClientIds)
      this.clientService.getClientsList(param).subscribe((data: any) => {
        this.ClientIds = [];
        this.noCLients = false;
        this.ShowLoader = false;
        this.clientsList = data;
        this.clientsList.forEach(w => w.mobileNumberFormat = w.mobileNumber != null ? this.general.converPhoneGoogleLib(w.mobileNumber.toString()) : null)
        this.clientsList.forEach(w => this.ClientIds.push(w.clientId));
        this.global.disableloading()
      }, err => {
        this.noCLients = true;
        this.global.disableloading()
        this.ShowLoader = false;
        this.errorService.check(err);
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
    else {
      this.noCLients = true;
    }
  }
  }
  //.................................................checkStartforTrainingEntity................................
  checkStartforTrainingEntity() {
    if (this.global.isTrainer == true) {
      this.startSharedAlert()
      this.sharedCare.callTime = null;
      this.selectSharedValues();
      return;
    }
    else {
      this.global.isModalSharedStart = false;
      this.StartSharedCare();
    }
  }
  //.................................................startSharedAlert................................
  async startSharedAlert() {
    let message = 'Do you want to start shared care service?';
    const alert = await this.alertController.create({
      header: 'Clock In Alert',
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          id: "SharedCareStartYes",
          text: 'Yes',
          handler: () => {
            this.global.isModalSharedStart = true;
          },
        },
        {
          id: "SharedCareStartNo",
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => { },
        },
      ],
    });
    await alert.present();
  }
  //.................................................stopSharedAlert................................
  async stopSharedAlert() {
    let message = 'Do you want to stop shared care service?';
    const alert = await this.alertController.create({
      header: 'Clock Out Alert',
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          id: "SharedCaredStopYes",
          text: 'Yes',
          handler: () => {
            this.global.isModalSharedStop = true;
          },
        },
        {
          id: "SharedCaredStopNo",
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => { },
        },
      ],
    });
    await alert.present();
  }
  //.................................................checkStopforTrainingEntity................................
  checkStopforTrainingEntity() {
    if (this.global.isTrainer == true) {
      this.stopSharedAlert()
      this.sharedCare.callTime = null;
      this.selectSharedValues();
      return;
    }
    else {
      this.global.isModalSharedStop = false;
      this.StopSharedCare();
    }
  }
  //.................................................selectSharedValues................................
  selectSharedValues() {
    if (this.sharedCare.callTime == undefined || this.sharedCare.callTime == null || this.sharedCare.callTime == '') {
      this.sharedCare.callTime = this.global.DateandTimeFormat(new Date());
    }
  }
  //.................................................closeShareStartModal................................
  closeShareStartModal() {
    this.global.isModalSharedStart = false;
  }
  //.................................................closeShareStopModal................................
  closeShareStopModal() {
    this.global.isModalSharedStop = false;
  }
  async StartClockInButton()
  {
  if(this.global.isOffline)
      {
      this.OnStart()
      }
      else 

      {
        this.isClockIn=true;
        await this.global.checkRefreshTokenValidity()
    {
      this.OnStart()
    }
      }
  }
  async StopClockOutButton()
  {
  if(this.global.isOffline)
      {
      this.OnStop()
      }
      else 

      {
        this.isClockOut=true;
        await this.global.checkRefreshTokenValidity()
    {
      this.OnStop()
    }
      }
  }
  //.................................................OnStart................................
  async OnStart() {
    this.isClockIn=true
    this.global.isModalSharedStart = false;
    await this.global.showLoading();
    if (this.global.applicationVersionNumber == null || this.global.applicationVersionNumber == undefined || this.global.applicationVersionNumber == "") {
      this.getStartLocation();
    } else {
      await BackgroundGeolocation.ready({
        stationaryRadius: 2,
        distanceFilter: 3,
        debug: false,
        foregroundService: true,
        stopOnTerminate: true,
        locationAuthorizationRequest: 'Always',
        notification: {
          title: "AllCare GPS Tracking",
          text: "Live Location Sharing",
        },
        backgroundPermissionRationale: {
          title: "Allow AllCare to access to this device's location in the background?",
          message: "In order to track your activity in the background, please enable Allow all the time location permission",
        }
      }).then((state) => {
      })
      this.backGroundLocationStartCount = 0;
      if (this.platform.is('android')) {
        this.getBackGroundStartLocationandroid();
      } else if (this.platform.is('ios')) {
        this.getBackGroundStartLocationiOS();
      }

    }
  }
  //.................................................OnStop................................
  async OnStop() {
    this.isClockOut=true;
    this.global.isModalSharedStop = false;
    await this.global.showLoading();
    if (this.global.applicationVersionNumber == null || this.global.applicationVersionNumber == undefined || this.global.applicationVersionNumber == "") {
      this.getStopLocation();
    } else {
      await BackgroundGeolocation.ready({
        stationaryRadius: 2,
        distanceFilter: 3,
        debug: false,
        foregroundService: true,
        stopOnTerminate: true,
        locationAuthorizationRequest: 'Always',
        notification: {
          title: "AllCare GPS Tracking",
          text: "Live Location Sharing",
        },
        backgroundPermissionRationale: {
          title: "Allow AllCare to access to this device's location in the background?",
          message: "In order to track your activity in the background, please enable Allow all the time location permission",
        }
      }).then((state) => {
      })
      this.backGroundLocationStopCount = 0;


      if (this.platform.is('android')) {
        this.getBackGroundStopLocationandroid();
      } else if (this.platform.is('ios')) {
        this.getBackGroundStopLocationiOS();
      }
    }
  }
  //.................................................getStartLocation................................
  async getStartLocation() {
    this.global.isModalSharedStart = false;
    this.geoLoc.getCurrentPosition({ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }).then(res => {
      this.global.disableloading();
      this.isClockIn = false;
      this.lat = res.coords.latitude;
      this.lng = res.coords.longitude;
      this.Accuracy = res.coords.accuracy != null ? parseInt(res.coords.accuracy.toString()) : null;
      this.GetStartCommunity();
    },
      (err: any) => {
        this.isClockIn = false;
        this.global.Toastdanger("Turn on Location");
      }
    );
  }
  //.................................................getStopLocation................................
  async getStopLocation() {

    this.global.isModalSharedStop = false;
    this.geoLoc.getCurrentPosition({ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }).then(res => {
      this.global.disableloading();
      this.isClockOut = false;
      this.lat = res.coords.latitude;
      this.lng = res.coords.longitude;
      this.Accuracy = res.coords.accuracy != null ? parseInt(res.coords.accuracy.toString()) : null;
      this.GetStopCommunity();
    },
      (err: any) => {
        this.isClockOut = false;
        this.global.Toastdanger("Turn on Location");
      }
    );
  }
  //.................................................start................................
  async StartSharedCare() {
    await this.global.checkRefreshTokenValidity()
    {
    this.errorcheck = [];
    await this.global.showLoading();
    this.sharedCare.callTime = this.global.isTrainer == true ? this.global.DateandTimeFormat(this.sharedCare.callTime) : this.global.DateandTimeFormat(new Date());
    let sharedCarecallTime = this.global.getDateTimeString(this.sharedCare.callTime);
    let saveStartSharedCare = [];
    let FirstStartSharedIndex;
    let SecondStartSharedIndex = 0;
    for (FirstStartSharedIndex = 0; FirstStartSharedIndex < this.SavePayloadList.length; FirstStartSharedIndex++) {
      saveStartSharedCare[SecondStartSharedIndex] = {
        clientId: this.SavePayloadList[FirstStartSharedIndex].clientId,
        evvTimesheetId: this.SavePayloadList[FirstStartSharedIndex].evvTimesheetId,
        employeeId: this.global.EvvUserDetails.entityId,
        masterServiceId: this.SavePayloadList[FirstStartSharedIndex].masterServiceId,
        callLatitude: this.lat != null ? parseFloat(this.lat) : null,
        callLongitude: this.lng != null ? parseFloat(this.lng) : null,
        scheduleId: 0,
        callTime: sharedCarecallTime,
        clientAuthId: this.SavePayloadList[FirstStartSharedIndex].clientAuthId,
        serviceGroupId: this.serviceGroupId,
        payorId: this.SavePayloadList[FirstStartSharedIndex].payorId,
      }
      SecondStartSharedIndex++;
    }
    
    this.clientService.startTimesheets(saveStartSharedCare).subscribe((data) => {
      this.global.disableloading();
      if (data.length > 0) {
        data.forEach(element => {
          if (element.errors.length > 0) {
            this.errorcheck.push(element.errors)
            this.getErrorNotesforStartShareCareClients()
            return;
          }
          else {
            this.global.isModalSharedStart = false;
            this.isStarted = true;
            this.TsIds = data;
            this.global.disableloading();
            setTimeout(() => {
              let navigationExtras: NavigationExtras = {
                state: {
                  tsids: this.TsIds,
                  isStarted: true
                }
              }
              this.router.navigate(['/tabs/my-clients'], navigationExtras)
            }, 500);

          }
        });
      }
      this.global.disableloading()
    }, err => {
      this.global.Toastdanger(err.message);
      this.errorService.check(err);
      this.isStarted = false;
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }


  //.................................................StopSharedCare................................
  async StopSharedCare() {
    await this.global.checkRefreshTokenValidity()
    {
    this.errorcheck = [];
    await this.global.showLoading();
    this.sharedCare.callTime = this.global.isTrainer == true ? this.global.DateandTimeFormat(this.sharedCare.callTime) : this.global.DateandTimeFormat(new Date());
    let saveStopSharedCare = [];
    let FirstStopSharedIndex;
    let SecondStopShareIndex = 0;
    let sharedCarecallTime = this.global.getDateTimeString(this.sharedCare.callTime);
    for (FirstStopSharedIndex = 0; FirstStopSharedIndex < this.clientsList.length; FirstStopSharedIndex++) {
      saveStopSharedCare[SecondStopShareIndex] = {
        clientId: this.clientsList[FirstStopSharedIndex].clientId,
        evvTimesheetId: this.clientsList[FirstStopSharedIndex].evvTimehseetId,
        employeeId: this.global.EvvUserDetails.entityId,
        masterServiceId: this.clientsList[FirstStopSharedIndex].serviceId,
        callLatitude: this.lat != null ? parseFloat(this.lat) : null,
        callLongitude: this.lng != null ? parseFloat(this.lng) : null,
        scheduleId: this.clientsList[FirstStopSharedIndex].scheduleId == null ||
          this.clientsList[FirstStopSharedIndex].scheduleId == undefined
          ? 0 : this.clientsList[FirstStopSharedIndex].scheduleId,
        callTime: sharedCarecallTime,
        clientAuthId: this.clientsList[FirstStopSharedIndex].clientAuthId,
        serviceGroupId: this.clientsList[FirstStopSharedIndex].serviceGroupId,
        payorId: this.clientsList[FirstStopSharedIndex].payorId,
      }
      SecondStopShareIndex++;
    }
    this.clientService.stopSharedTimesheet(saveStopSharedCare).subscribe((data) => {
      this.global.disableloading();
      // if (data.length > 0) {
      //   this.global.Toastdanger(data.errors);
      //   return;
      // }
      if (data.length > 0) {
        data.forEach(element => {
          if (element.errors.length > 0) {
            this.errorcheck.push(element.errors)
            this.getErrorNotesforStopShareCareClients()
            return;
          }
        });
      }
      else {
        this.global.isModalSharedStop = false;
        setTimeout(() => {
          this.router.navigate(['/tabs/my-clients'])
        }, 500);
        this.isStarted = false;
      }



    }, err => {
      this.global.Toastdanger(err.message);
      this.errorService.check(err);
      this.isStarted = false;
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }

  //.................................................getErrorNotesforShareCareClients................................
  async getErrorNotesforStartShareCareClients() {
    const alert = await this.alertController.create({
      header: 'Note',
      message: this.errorcheck,
      buttons: [
        {
          id: "NotesStartSharedError",
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }
      ]
    });
    await alert.present();
  }
  //.................................................getErrorNotesforStopShareCareClients................................
  async getErrorNotesforStopShareCareClients() {
    let getIndex = 0;
    this.errorcheck?.forEach((element, index) => {
      if (index === this.errorcheck.length - 1) {
        getIndex = element.length
      }
    });
    if (this.errorcheck.length == getIndex) {
      const alert = await this.alertController.create({
        header: 'Note',
        message: this.errorcheck,
        buttons: [
          {
            id: "NotesStopSharedError",
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
            }
          }
        ],
        backdropDismiss: false
      });
      await alert.present();
    }
  }
  //.................................................GetStartCommunity................................
  async GetStartCommunity() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    let CommunityData = {
      ClientId: this.ClientIds,
      CallLatitude: this.lat != null ? parseFloat(this.lat) : null,
      CallLongitude: this.lng != null ? parseFloat(this.lng) : null,
    };
    this.clientService.GetCommunity(CommunityData).subscribe(
      (data: any) => {
        this.isCommunity = data;
        this.isClockIn = false;
        this.global.disableloading();
        this.startCommunity();

      },
      (err) => {
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  //.................................................GetStopCommunity................................
  async GetStopCommunity() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    let CommunityData = {
      ClientId: this.ClientIds,
      CallLatitude: this.lat != null ? parseFloat(this.lat) : null,
      CallLongitude: this.lng != null ? parseFloat(this.lng) : null,
    };
    this.clientService.GetCommunity(CommunityData).subscribe(
      (data: any) => {
        this.isCommunity = data;
        this.isClockOut = false;
        this.global.disableloading();
        this.stopCommunity();

      },
      (err) => {
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  //.................................................startCommunity................................
  async startCommunity() {
    if (this.isCommunity) {
      let message =
        'You are away from clients home, please enable the Community box to proceed!';
      const alert = await this.alertController.create({
        header: 'Clock Out Alert',
        message: message,
        backdropDismiss: false,
        inputs: [
          {
            name: 'Community',
            type: 'checkbox',
            label: 'Community',
          },
        ],
        buttons: [
          {
            id: "AwayCommunityYes",
            text: 'Yes',
            handler: (data) => {
              if (data.length > 0) {
                this.checkStartforTrainingEntity();
              } else {
                this.global.ToastDangerMiniDuration(
                  'Please Select Community Checkbox!'
                );
              }
            },
          },
          {
            id: "AwayCommunityNo",
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
        ],
      });
      await alert.present();
    }
    else {
      this.checkStartforTrainingEntity();
    }
  }
  //.................................................stopCommunity................................
  async stopCommunity() {
    if (this.isCommunity) {
      let message =
        'You are away from clients home, please enable the Community box to proceed!';
      const alert = await this.alertController.create({
        header: 'Clock Out Alert',
        message: message,
        backdropDismiss: false,
        inputs: [
          {
            name: 'Community',
            type: 'checkbox',
            label: 'Community',
          },
        ],
        buttons: [
          {
            id: "AwayStopCommunityYes",
            text: 'Yes',
            handler: (data) => {
              if (data.length > 0) {
                this.checkStopforTrainingEntity();
              } else {
                this.global.ToastDangerMiniDuration(
                  'Please Select Community Checkbox!'
                );
              }
            },
          },
          {
            id: "AwayStopCommunityNo",
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
        ],
      });
      await alert.present();
    }
    else {
      this.checkStopforTrainingEntity();
    }
  }
  // Android
  getBackGroundStartLocationandroid() {
    this.Accuracy = null;
    this.platform.ready().then(async () => {
      BackgroundGeolocation.start().then((state) => {
        this.global.disableloading();
        this.isClockIn = false;
        BackgroundGeolocation.onLocation((location) => {
          if (this.backGroundLocationStartCount == 0) {
            this.Accuracy =
              location.coords.accuracy != null
                ? parseInt(location.coords.accuracy.toString())
                : null;
            this.lat = location.coords.latitude;
            this.lng = location.coords.longitude;
            this.backGroundLocationStartCount++;
            this.GetStartCommunity();
            this.global.disableloading();
          }
        }, (error) => {
          this.global.disableloading();
          BackgroundGeolocation.stop()
          this.isClockIn = false;
        });
      }).catch((err) => {
        BackgroundGeolocation.stop()
        this.global.disableloading();
        this.isClockIn = false;
      });;
    }).catch((err) => {
      BackgroundGeolocation.stop()
      this.global.disableloading();
      this.isClockIn = false;
    });
  }
  getBackGroundStopLocationandroid() {
    this.Accuracy = null;
    this.platform.ready().then(async () => {
      BackgroundGeolocation.start().then((state) => {
        BackgroundGeolocation.onLocation((location) => {
          if (this.backGroundLocationStopCount == 0) {
            this.Accuracy =
              location.coords.accuracy != null
                ? parseInt(location.coords.accuracy.toString())
                : null;
            this.lat = location.coords.latitude;
            this.lng = location.coords.longitude;
            this.backGroundLocationStopCount++;
            this.GetStopCommunity();
            this.global.disableloading();
          }
        }, (error) => {
          this.global.disableloading();
          BackgroundGeolocation.stop()
          this.isClockOut = false;
        });
      }).catch((err) => {
        this.global.disableloading();
        BackgroundGeolocation.stop()
        this.isClockOut = false;
      });;
    }).catch((err) => {
      this.global.disableloading();
      BackgroundGeolocation.stop()
      this.isClockOut = false;
    });
  }
  // IOS
  getBackGroundStartLocationiOS() {
    this.Accuracy = null;
    let backGroundLocationStartCount = 0;
    this.platform
      .ready()
      .then(async () => {

        BackgroundGeolocation.getCurrentPosition({
          timeout: 30,
        }).then((location) => {

          if (backGroundLocationStartCount == 0) {

            this.Accuracy =
              location.coords.accuracy != null
                ? parseInt(location.coords.accuracy.toString())
                : null;
            this.lat = location.coords.latitude;
            this.lng = location.coords.longitude;
            backGroundLocationStartCount++;
            BackgroundGeolocation.stop();
            this.GetStartCommunity();
            this.global.disableloading();
          }
        }).catch((err) => {
          this.global.disableloading();
          this.global.ToastDangerMiniDuration("Turn on location");
          this.isClockIn = false;

        });
      })
      .catch((err) => {
        this.global.disableloading();
        this.global.ToastDangerMiniDuration("turn on location")
        this.isClockIn = false;

      });
  }
  getBackGroundStopLocationiOS() {
    this.Accuracy = null;
    let backGroundLocationStopCount = 0;

    this.platform
      .ready()
      .then(async () => {
        BackgroundGeolocation.getCurrentPosition({
          timeout: 30,
        }).then((location) => {

          if (backGroundLocationStopCount == 0) {

            this.Accuracy =
              location.coords.accuracy != null
                ? parseInt(location.coords.accuracy.toString())
                : null;
            this.lat = location.coords.latitude;
            this.lng = location.coords.longitude;
            backGroundLocationStopCount++;
            BackgroundGeolocation.stop();
            this.GetStopCommunity();
            this.global.disableloading();
          }
        }).catch((err) => {
          this.global.disableloading();
          this.global.ToastDangerMiniDuration("Turn on location");
          this.isClockOut = false;

        });
      })
      .catch((err) => {
        this.global.disableloading();
        this.global.ToastDangerMiniDuration("Turn on location");
        this.isClockOut = false;

      });
  }
  async selectClientService(item) {
   
    item?.serviceGroup?.forEach(element => {
      this.getDetails(element)
      this.SavePayloadList.forEach((spl, index) => {
        if (spl.clientId == element.clientId) {
          this.SavePayloadList.splice(index, 1)
        }
      });
      this.SavePayloadList.push(element);
   
    });
   
  }

  getDetails(element) {
    this.clientsList?.forEach(sharedCareIndex => {
      if (sharedCareIndex.clientId == element.clientId) {
        sharedCareIndex.confirmedUnits = element.confirmedUnits;
        sharedCareIndex.unconfirmedUnits = element.unconfirmedUnits;
        sharedCareIndex.masterServiceName = element.masterServiceName;
        sharedCareIndex.remainingUnits = element.remainingUnits;
        let value1 = element?.remainingHPDBasedOnCurrentDate?.toString().split('.');
        if (value1.length == 1) {
          sharedCareIndex.remainingHoursPerDayBasedOnCurrentDate = value1[0] + " hr " + "0 Min";
        }
        else {
          let value2 = 0 + "." + value1[value1.length - 1];
          sharedCareIndex.remainingHoursPerDayBasedOnCurrentDate = value1[0] + " hr " + (parseFloat(value2) * 60).toString().split('.')[0] + "Min";
        }
        let value3 = element?.remainingHPDBasedOnLastVisitDate?.toString().split('.');
        if (value3.length == 1) {
          sharedCareIndex.remainingHoursPerDayBasedOnLastVisitDate = value3[0] + " hr " + "0 Min";
        }
        else {
          let value4 = 0 + "." + value3[value3.length - 1];
          sharedCareIndex.remainingHoursPerDayBasedOnLastVisitDate = value3[0] + " hr " + (parseFloat(value4) * 60).toString().split('.')[0] + "Min";
        }
      }
    });
  }
}
