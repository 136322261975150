import { Component } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { EmployeeBO, Profile } from './profile.model';
import { ProfileService } from './profile.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage {
  profileData: Profile = null;
  isEdit: boolean = false;
  empList: EmployeeBO = new EmployeeBO();
  isOffline: boolean = false;
  constructor(public clientService: ProfileService, private toastCtrl: ToastController, public errorService: ErrorService,
    public global: Globalservice, public general: generalservice, private network: Network,) {
    this.network.onConnect().subscribe(() => {
      this.isOffline = false;
    })
    this.network.onDisconnect().subscribe(() => {
      this.isOffline = true;
    })
    this.global.checkingLogin();
  }
  async ionViewWillEnter() {
      
    this.isEdit = false;
    await this.global.checkRefreshTokenValidity()
    {
    this.getProfile();
    }
  }
  async getProfile() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    let param = new URLSearchParams();
    param.append("EmployeeId", this.global.EvvUserDetails.entityId.toString())
    this.clientService.getProfile(param).subscribe((data: any) => {
      this.profileData = data;
      this.profileData.mobileNumberFormat = this.profileData.mobileNumber ? this.general.converPhoneGoogleLib(this.profileData.mobileNumber.toString()) : null
      this.profileData.address = data.street != null ? data.street : "" + " " + data.city != null ? data.city : "" + " " +
        data.state != null ? data.state : "" + " " + data.country != null ? data.country : "" + " " + data.zip != null ? data.zip : ""
        this.global.disableloading();
      }, err => {
      this.global.disableloading();
      this.errorService.check(err);
      if (err.Status == 401) {
        this.global.GloballogOut();
       
      }
    })
  }
  }
  editProfile() {
    this.isEdit = true;
  }
  cancelEdit() {
    this.isEdit = false;
    this.getProfile();

  }
  async updateProfile(EmpId) {
    await this.global.checkRefreshTokenValidity()
    {
    this.empList.id = EmpId;
    this.empList.statusLid = this.profileData.status;
    this.empList.genderLid = Math.floor(Number(this.profileData.gender));
    this.empList.age = Math.floor(Number(this.profileData.age));
    this.empList.email = this.profileData.email != undefined ? this.profileData.email : null;
    this.empList.phone1 = this.profileData.mobileNumber.toString();
    this.empList.firstName = this.profileData.fullname.split(' ').slice(0, -1).join('');
    this.empList.lastName = this.profileData.fullname.split(' ').slice(-1).join('');
    this.empList.street = this.profileData.street;
    this.empList.city = this.profileData.city;
    this.empList.steet = this.profileData.state;
    this.empList.county = this.profileData.country;
    this.empList.zipcode = this.profileData.zip;
    this.clientService.UpdateProfile(this.empList).subscribe((data: EmployeeBO) => {
      this.isEdit = false;
      this.empList = data;
      this.profileData.fullname = this.empList.firstName + "," + this.empList.lastName
      this.getProfile();
      this.presentToast('Profile Saved Successfully')
    }
    )
  }
  }
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }

}
