import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-addeventcalender',
  templateUrl: './addeventcalender.page.html',
  styleUrls: ['./addeventcalender.page.scss'],
})
export class AddeventcalenderPage implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}
