import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanguagesPage } from '../languages/languages.page';
import { AllTimesheetsPage } from '../pages/all-timesheets/all-timesheets.page';
import { EmployecurrentdashboardPage } from '../pages/employecurrentdashboard/employecurrentdashboard.page';
import { EmployeescheduledashboardPage } from '../pages/employeescheduledashboard/employeescheduledashboard.page';
import { EmployescheduleserviceinfoPage } from '../pages/employescheduleserviceinfo/employescheduleserviceinfo.page';
import { MyClientsPage } from '../pages/my-clients/my-clients.page';
import { ProfilePage } from '../pages/profile/profile.page';
import { ServiceInfoPage } from '../pages/service-info/service-info.page';
import { SharedCareClientsPage } from '../pages/shared-care-clients/shared-care-clients.page';
import { SignAllTimesheetPage } from '../pages/sign-all-timesheet/sign-all-timesheet.page';
import { SignaturePage } from '../pages/signature/signature.page';
import { TimesheetPage } from '../pages/timesheet/timesheet.page';
import { TabsPage } from './tabs.page';
import { NotesPage } from '../pages/notes/notes.page';
import { OfflinePage } from '../pages/offline/offline.page';
import { ManualElectronicTimesheetPage } from '../pages/manual-electronic-timesheet/manual-electronic-timesheet.page';
import { PrivatePayMileagePage } from '../pages/private-pay-mileage/private-pay-mileage.page';
import { EmployeeActiveSessionPage } from '../pages/employee-active-session/employee-active-session.page';
import { BasicCarePlanPage } from '../pages/basic-care-plan/basic-care-plan.page';
import { HomemakingCarePlanPage } from '../pages/homemaking-care-plan/homemaking-care-plan.page';
import { IclsCarePlanPage } from '../pages/icls-care-plan/icls-care-plan.page';
import { OfflineGuard } from '../offline.guard';
import { ComprehensiveCarePlansPage } from '../pages/comprehensive-care-plans/comprehensive-care-plans.page';
import { EmployeeserviceunitsPage } from '../pages/employeeserviceunits/employeeserviceunits.page';
import { EmployeeChangePasswordPage } from '../pages/employee-change-password/employee-change-password.page';
import { AppMaintenancePage } from '../login/appmaintenance/appmaintenance.page';
const routes: Routes = [
  {
    path: 'tabs',
    component: TabsPage,
    children: [
      {
        path: 'languages',
        component:LanguagesPage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'my-clients',
        component:MyClientsPage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'all-timesheets',
        component:AllTimesheetsPage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'profile',
        component:ProfilePage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'service',
        component:ServiceInfoPage
      },
      {
        path: 'timesheet',
        component:TimesheetPage
      },
      {
        path: 'signature',
        component:SignaturePage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'sign-all-timesheet',
        component:SignAllTimesheetPage
      },
      {
        path: 'scheduledashboard',
        component:EmployeescheduledashboardPage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'currentdaydashboard',
        component:EmployecurrentdashboardPage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'employeescheduleservice',
        component:EmployescheduleserviceinfoPage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'sharedcareClients',
        component:SharedCareClientsPage
      },
      {
        path: 'notes',
        component:NotesPage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'offline',
        component:OfflinePage,
        canActivate: [OfflineGuard]
      },
      // {
      //   path: 'calenderschedule',
      //   component:CalenderschedulerPage
      // },
      {
        path: '',
        redirectTo: '/tabs/my-clients',
        pathMatch: 'full'
      },
      {
        path: 'manual-electronic-timesheet',
        component:ManualElectronicTimesheetPage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'employeeserviceunits',
        component:EmployeeserviceunitsPage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'private-pay-mileage',
        component:PrivatePayMileagePage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'employee-active-session',
        component:EmployeeActiveSessionPage,
        canActivate: [OfflineGuard]
      },
      {
        path:'basic-care',
        component:BasicCarePlanPage,
        canActivate: [OfflineGuard]
      },
      {
        path:'home-making',
        component:HomemakingCarePlanPage,
        canActivate: [OfflineGuard]
      },
      {
        path:'icls',
        component:IclsCarePlanPage,
        canActivate: [OfflineGuard]
      }
      , {
        path:'comprehensive',
        component:ComprehensiveCarePlansPage,
        canActivate: [OfflineGuard]
      },
      {
        path: 'employee-change-password',
        component:EmployeeChangePasswordPage,
        canActivate: [OfflineGuard]
      }, 
      
      // {
      //   path: 'app-maintenance',
      //   component:AppMaintenancePage,
      //   // canActivate: [OfflineGuard]
      // },     
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TabsPageRoutingModule {}
