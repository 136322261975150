import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  constructor(private httpClient: HttpClient,public errorService: ErrorService,public global:Globalservice) { }
  public  getActivities(param:URLSearchParams):Observable<any> {
    let url="api/VisitClient/getClientbyId?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
  public  getTimesheet(param:URLSearchParams):Observable<any> {
    let url="api/Visit/GetMobileVisit?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
  public  saveClientSign(data):Observable<any> {
    let url="api/Visit/SaveClientSignForvisit"
    return this.httpClient.post(url,data).pipe(catchError(this.errorService.handleError));
  }
  public SynOfflineData(data):Observable<any> {
    let url="api/Visit/SyncOfflineTimesheets"
    return this.httpClient.post(url,data).pipe(catchError(this.errorService.handleError));
  }
  public  saveEMployeeSign(data):Observable<any> {
    let url="api/Visit/SaveEmployeeSignForVisit"
    return this.httpClient.post(url,data).pipe(catchError(this.errorService.handleError));
  }
  public  saveTsActivities(data):Observable<any> {
    let url="api/EvvTimesheet/SaveEvvActivities"
    return this.httpClient.post(url,data).pipe(catchError(this.errorService.handleError));
  }
  public  saveTsNotes(data):Observable<any> {
    let url="api/EvvTimesheet/SaveTsNotes"
    return this.httpClient.post(url,data).pipe(catchError(this.errorService.handleError));
  }
  public  UpdateVisitInMobile(data):Observable<any> {
    let url="api/Visit/UpdateVisitInMobile"
    return this.httpClient.post(url,data).pipe(catchError(this.errorService.handleError));
  }
  public  getIsEditableVisit():Observable<any> {
    let url="api/AgencySetting/getIsEditableVisit?"
    return this.httpClient.get(url).pipe(catchError(this.errorService.handleError));
  }
}
