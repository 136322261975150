import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globalservice } from '../../global.service';
@Component({
  selector: 'app-splash',
  templateUrl: './splash.page.html',
  styleUrls: ['./splash.page.scss'],
})
export class SplashPage implements OnInit {
  interval: any;
  constructor(public router: Router, public global: Globalservice) {
    this.global.UserData = JSON.parse(localStorage.getItem("Evv_login"));
 
    if (this.global.applicationVersionNumber != null &&
      this.global.applicationVersionNumber != undefined && this.global.applicationVersionNumber != '') {
      this.interval = setInterval(() => {
        if (this.global.UserData != null) {
          if (this.global.isassignboolean == true) {
 
 
 
            if (this.global.role == 'PCAEMPLOYEE' || this.global.role == 'QPEMPLOYEE') {
              this.router.navigate(['/tabs/all-timesheets'], { replaceUrl: true });
            } else if (this.global.role == "CLIENT") {
              this.router.navigate(['/client/client-all-timesheets'], { replaceUrl: true });
            }
          }
        }
        else {
          localStorage.setItem('isOffline', 'false')
          this.router.navigate(['/login'], { replaceUrl: true })
        }
      }, 5000);
    }
    else {
      if (this.global.UserData != null) {
        if (this.global.role == 'PCAEMPLOYEE' || this.global.role == 'QPEMPLOYEE') {
          this.router.navigate(['/tabs/all-timesheets'], { replaceUrl: true });
        } else if (this.global.role == "CLIENT") {
          this.router.navigate(['/client/client-all-timesheets'], { replaceUrl: true });
        }
 
      }
      else {
        localStorage.setItem('isOffline', 'false')
        this.router.navigate(['/login'], { replaceUrl: true })
      }
    }
 
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
  ngOnInit() {
  }
}
 