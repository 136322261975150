import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';


@Injectable({
  providedIn: 'root'
})
export class MyClientsService {

  constructor(private httpClient: HttpClient, public errorService: ErrorService, public global: Globalservice, private handler: HttpBackend) { }
  public getClients(param: URLSearchParams,): Observable<any> {
    let url = "api/VisitClient/getClientsforVisit?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }

  public getClientsOffline(param: URLSearchParams): Observable<any> {
    let url = "api/EvvClient/getClientsOffline?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public getCLientDetailForCareplan(param: URLSearchParams): Observable<any> {
    let url = "api/EvvClient/getCLientDetailForCareplan?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public getServices(param: URLSearchParams): Observable<any> {
    let url = "api/EvvClient/getClientServiceNew?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public getClientById(param: URLSearchParams): Observable<any> {
    let url = "api/VisitClient/getClientbyId?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public getSharedClients(param: URLSearchParams): Observable<any> {
    let url = "api/EvvClient/getSharedClients?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }

  public getClientsList(param: URLSearchParams): Observable<any> {
    let url = "api/EvvClient/getClients?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }

  public GetContactList(param: URLSearchParams): Observable<any> {
    let url = "api/Contact/GetContactList?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public CommonGetGPList(param: URLSearchParams): Observable<any> {
    let url = "api/GroupPayor/CommonGetGPList?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public GetLocationDrop(param: URLSearchParams): Observable<any> {
    let url = "api/LOV/getLovDropDown?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public GetQPEMployeeForClient(param: URLSearchParams): Observable<any> {
    let url = "api/Client/GetQPEMployeeForClient?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public GetClientLanguageList(param: URLSearchParams): Observable<any> {
    let url = "api/Language/GetClientLanguageList?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public GetPayerList(param: URLSearchParams): Observable<any> {
    let url = "api/GroupPayor/CommonGetGPList?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public getRelationships(param: URLSearchParams): Observable<any> {
    let url = "api/LOV/getLovDropDown?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public GetCaseManagerSingledata(param: URLSearchParams): Observable<any> {
    let url = "api/ClientCaseManagerRelationship/GetCaseManagerSingledata?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public getPhysicianDetails(param: URLSearchParams): Observable<any> {
    let url = "api/Certification/getPhysicianDetails?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }
  public startTimesheets(data): Observable<any> {
    let url = "api/Visit/SaveSharedEvvClockIn"
    return this.httpClient.post(url, data).pipe(catchError(this.errorService.handleError));
  }
  public stopSharedTimesheet(data): Observable<any> {
    let url = "api/Visit/SaveSharedEvvClockOut"
    return this.httpClient.post(url, data).pipe(catchError(this.errorService.handleError));
  }
  public getClientNotes(data): Observable<any> {
    let url = "api/ClientNote/GetClientNoteList"
    return this.httpClient.post(url, data).pipe(catchError(this.errorService.handleError));
  }

  public getconsumerdropdown(param: URLSearchParams): Observable<any> {
    let url = "api/ConsumerAssessment/getconsumerdropdown?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }

  public getconsumer(param: URLSearchParams): Observable<any> {
    let url = "api/ConsumerAssessment/getconsumer?"
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }

  getCareplanList(): Observable<any> {
    let httpLocal = new HttpClient(this.handler);
    return httpLocal.get("./assets/careplan.json");
  }
  public cancelEvvVisit(param: URLSearchParams, VisitIds): Observable<any> {

    let url = "api/Visit/CancelVisitforEmployee?";
    return this.httpClient.post(url + param, VisitIds, { observe: 'response', responseType: "text" },).pipe(catchError(this.errorService.handleError));
  }
  public GetEmployeeIsTestingEntity(param: URLSearchParams,): Observable<any> {
    let url = "api/Employee/GetEmployeeIsTestingEntity?";
    return this.httpClient.get(url + param, {},).pipe(catchError(this.errorService.handleError));
  }

  public GetCommunity(data): Observable<any> {
    let url = "api/Visit/GetCommunity";
    return this.httpClient.post(url, data).pipe(catchError(this.errorService.handleError));
  }
  public verifyEvvUserPassword(param: URLSearchParams): Observable<any> {
    let url = 'api/Login/verifyEvvUserPassword?';
    return this.httpClient.get(url + param, { observe: 'response', responseType: "text" },).pipe(catchError(this.errorService.handleError));
  }
  public sessionLogout(param: URLSearchParams): Observable<any> {
    let url = 'api/Auth/sessionLogout?';
    return this.httpClient.get(url + param, { observe: 'response', responseType: "text" },).pipe(catchError(this.errorService.handleError));
  }
  public CancelVisitforEmployee(payload): Observable<any> {
    let url = "api/Visit/cancelInprogressVisit";
    return this.httpClient.post(url, payload, { observe: 'response', responseType: "text" },).pipe(catchError(this.errorService.handleError));
  }
  public getServicesWithAuthorizationForServiceGroup(authdetails: any): Observable<any> {
    let url = 'api/ClientAuthorization/getAuthorizationsForVisitSelection?'
    return this.httpClient.post(url, authdetails).pipe(catchError(this.errorService.handleError));
  }
  public getSharedCareClientsAuthorizationDetails(clientId,serviceGroupId): Observable<any> {
    let url = 'api/ClientAuthorization/getSharedCareClientsAuthorizationDetails?'
    let param = new URLSearchParams();
    param.append("employeeId", this.global.EvvUserDetails.entityId.toString())
    param.append("clientId", clientId.toString())
    param.append("serviceGroupId", serviceGroupId.toString())
    return this.httpClient.post(url + param, "").pipe(catchError(this.errorService.handleError));
  }


  public AuthValidationForOverNightRecords(authdetails: any): Observable<any> {
    let url = 'api/ClientAuthorization/AuthValidationForOverNightRecords?'
    return this.httpClient.post(url, authdetails).pipe(catchError(this.errorService.handleError));
  }
  

  public GetLatestVersion(param: URLSearchParams,): Observable<any> {
    let url = 'api/Evvconfiguration/GetLatestVersion?';
    return this.httpClient.get(url + param).pipe(catchError(this.errorService.handleError));
  }



  public checkRemainingUnits(param: URLSearchParams): Observable<any> {
    let url = 'api/ClientAuthorization/checkRemainingUnits?';
    return this.httpClient.get(url + param, { observe: 'response', responseType: "text" },).pipe(catchError(this.errorService.handleError));
  }
}
