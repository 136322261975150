import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private httpClient: HttpClient,public errorService: ErrorService,public global:Globalservice) { }
  public  getProfile(param:URLSearchParams):Observable<any> {
    let url="api/EvvEmployee/getEmpolyee?"
    return this.httpClient.get(url+param).pipe(catchError(this.errorService.handleError));
  }
  public  UpdateProfile(data):Observable<any> {
    let url="api/Employee/SaveEmployee"
    return this.httpClient.post(url,data).pipe(catchError(this.errorService.handleError));
  }
}
