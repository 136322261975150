
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-appmaintenance',
  templateUrl: './appmaintenance.page.html',
  styleUrls: ['./appmaintenance.page.scss'],
})
export class AppMaintenancePage implements OnInit {
 Evvweburl="";
  constructor(private router: Router, private route: ActivatedRoute,) {
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        let maintenanceStatus = data.maintenanceStatus;
        if (maintenanceStatus == "ClearLocalStorage") {
          localStorage.clear();
        }
      }
    });
  }
  ngOnInit() { }
  ionViewWillEnter() {
   
  }
}