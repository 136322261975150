import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalenderschedulerPage } from './calenderscheduler/calenderscheduler.page';
import { ClientActiveSessionPage } from './client-active-session/client-active-session.page';
import { ClientDashboardNewPage } from './client-dashboard-new/client-dashboard-new.page';
import { ClientLanguagesPage } from './client-languages/client-languages.page';
import { ClientLoginPage } from './client-login.page';
import { ClientProfilePage } from './client-profile/client-profile.page';
import { ClientServiceInfoPage } from './client-service-info/client-service-info.page';
import { ClientServiceUnitsPage } from './client-service-units/client-service-units.page';
import { ClientSignAllTimesheetPage } from './client-sign-all-timesheet/client-sign-all-timesheet.page';
import { ClientSignaturePage } from './client-signature/client-signature.page';
import { ClientTimesheetPage } from './client-timesheet/client-timesheet.page';
import { ClientAllTimesheetsPage } from './client-timesheets/client-timesheets.page';
import { ClientdashboardPage } from './clientdashboard/clientdashboard.page';
import { ClientChangePasswordPage } from './client-change-password/client-change-password.page';
const routes: Routes = [
  {
    path: 'client',
    component: ClientLoginPage,
    children: [
      {
        path: 'client-all-timesheets',
        component:ClientAllTimesheetsPage
      },

      {
        path: 'client-signature',
        component:ClientSignaturePage
      },
      {
        path: 'client-sign-all-timesheet',
        component:ClientSignAllTimesheetPage
      },
      {
        path: 'client-timesheet',
        component:ClientTimesheetPage
      },
      {
        path: 'client-service-info',
        component:ClientServiceInfoPage
      },
      {
        path: 'client-profile',
        component:ClientProfilePage
      },
      {
        path: 'client-languages',
        component:ClientLanguagesPage
      },
      {
        path: 'client-active-session',
        component:ClientActiveSessionPage
      },
      {
        path: 'client-change-password',
        component:ClientChangePasswordPage,
      },
      {
        path: 'client-service-units',
        component:ClientServiceUnitsPage
      },
      {
        path: 'client-dashboard-new',
        component:ClientDashboardNewPage
      },
      
      {
        path: 'clientdashboard',
        component:ClientdashboardPage
      },
      {
        path: 'calenderschedule',
        component:CalenderschedulerPage
      },
      {
        path: '',
        redirectTo: '/client/client-all-timesheets',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'client-profile',
    loadChildren: () => import('./client-profile/client-profile.module').then( m => m.ClientProfilePageModule)
  },
  
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientLoginPageRoutingModule {}
