import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgCalendarModule } from 'ionic2-calendar';
import { AddeventcalenderPage } from '../pages/addeventcalender/addeventcalender.page';
import { AllTimesheetsPage } from '../pages/all-timesheets/all-timesheets.page';
import { CalenderschedulerPage } from '../pages/client-login/calenderscheduler/calenderscheduler.page';
import { ClientServiceInfoPage } from '../pages/client-login/client-service-info/client-service-info.page';
import { ClientSignAllTimesheetPage } from '../pages/client-login/client-sign-all-timesheet/client-sign-all-timesheet.page';
import { ClientSignaturePage } from '../pages/client-login/client-signature/client-signature.page';
import { ClientTimesheetPage } from '../pages/client-login/client-timesheet/client-timesheet.page';
import { ClientAllTimesheetsPage } from '../pages/client-login/client-timesheets/client-timesheets.page';
import { ClientdashboardPage } from '../pages/client-login/clientdashboard/clientdashboard.page';
import { MyEmployeesPage } from '../pages/client-login/my-employee/my-employee.page';
import { EmployecurrentdashboardPage } from '../pages/employecurrentdashboard/employecurrentdashboard.page';
import { EmployeescheduledashboardPage } from '../pages/employeescheduledashboard/employeescheduledashboard.page';
import { EmployescheduleserviceinfoPage } from '../pages/employescheduleserviceinfo/employescheduleserviceinfo.page';
import { MyClientsPage } from '../pages/my-clients/my-clients.page';
import { ProfilePage } from '../pages/profile/profile.page';
import { ServiceInfoPage } from '../pages/service-info/service-info.page';
import { SignAllTimesheetPage } from '../pages/sign-all-timesheet/sign-all-timesheet.page';
import { SignaturePage } from '../pages/signature/signature.page';
import { TimesheetPage } from '../pages/timesheet/timesheet.page';
import { TabsPageRoutingModule } from './tabs-routing.module';
import { TabsPage } from './tabs.page';
import { SharedCareClientsPage } from '../pages/shared-care-clients/shared-care-clients.page';
import { NotesPage } from '../pages/notes/notes.page';
import { OfflinePage } from '../pages/offline/offline.page';
import { ManualElectronicTimesheetPage } from '../pages/manual-electronic-timesheet/manual-electronic-timesheet.page';
import { PrivatePayMileagePage } from '../pages/private-pay-mileage/private-pay-mileage.page';
import { EmployeeActiveSessionPage } from '../pages/employee-active-session/employee-active-session.page';
import { HomemakingCarePlanPage } from '../pages/homemaking-care-plan/homemaking-care-plan.page';
import { BasicCarePlanPage } from '../pages/basic-care-plan/basic-care-plan.page';
import { IclsCarePlanPage } from '../pages/icls-care-plan/icls-care-plan.page';
import { DashboardHourDetailsPage } from '../pages/dashboard-hour-details/dashboard-hour-details.page';
import { ComprehensiveCarePlansPage } from '../pages/comprehensive-care-plans/comprehensive-care-plans.page';
import { ClientActiveSessionPage } from '../pages/client-login/client-active-session/client-active-session.page';
import { EmployeeserviceunitsPage } from '../pages/employeeserviceunits/employeeserviceunits.page';
import { ClientServiceUnitsPage } from '../pages/client-login/client-service-units/client-service-units.page';
import { ClientDashboardNewPage } from '../pages/client-login/client-dashboard-new/client-dashboard-new.page';
import { MetSignaturePage } from '../pages/manual-electronic-timesheet/Metsignature/Metsignature.page';
import { ResetPasswordPage } from '../login/reset-password/reset-password.page';
import { EmployeeChangePasswordPage } from '../pages/employee-change-password/employee-change-password.page';
import { ClientChangePasswordPage } from '../pages/client-login/client-change-password/client-change-password.page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TabsPageRoutingModule,
    SignaturePadModule,
    TranslateModule,
    NgCalendarModule,
    ReactiveFormsModule
  ],
  declarations: [
    TabsPage,
    MyClientsPage,
    AllTimesheetsPage,
    ProfilePage,
    ServiceInfoPage,
    TimesheetPage,
    SignaturePage,
    ResetPasswordPage,
    SignAllTimesheetPage,
    ClientAllTimesheetsPage,
    MyEmployeesPage,
    ClientServiceInfoPage,
    NotesPage,
    ClientTimesheetPage,
    ClientSignAllTimesheetPage,
    ClientSignaturePage,
    ClientActiveSessionPage,
    ClientdashboardPage,
    EmployeescheduledashboardPage,
    AddeventcalenderPage,
  EmployescheduleserviceinfoPage,
  EmployecurrentdashboardPage,
  CalenderschedulerPage,
  SharedCareClientsPage,
  OfflinePage,
  ManualElectronicTimesheetPage,
  MetSignaturePage,
  EmployeeserviceunitsPage,
  PrivatePayMileagePage,
  EmployeeActiveSessionPage,
  HomemakingCarePlanPage,
  BasicCarePlanPage,
  IclsCarePlanPage,
  DashboardHourDetailsPage,
  ComprehensiveCarePlansPage,
  ClientServiceUnitsPage,
  ClientDashboardNewPage,
  ClientChangePasswordPage,
  EmployeeChangePasswordPage,
  

  ],
  providers: [
    Geolocation,
  ]
})
export class TabsPageModule {}
