export class getNotes{
    clientId: number;
    currentpageno: number = 1;
    field: string ="Initial";
    matchCase: boolean = false;
    operator: string = "contains";
    orderColumn: string = "Date";
    orderType: string = "DESC";
    pageitem: number=10;
    type: string = "string";
    value: string = "";
}


export class EvvNotesBO {
    id: number=0;
    notes: string;
    emloyeeId: number;
    clientId: number;
  }


export class PrivatePayMileageLogsEvvReturnBO {
    serviceDate: string;
    dailymiles: number | null;
    records: PrivatePayMileageLogsEvvBO[];
}

export class PrivatePayMileageLogsEvvBO {
    id: number;
    clientId: number;
    employeeId: number;
    miles: number | null;
    serviceDate: string | null;
    clinetName: string;
    employeeName: string;
      hasValue :boolean;
}
