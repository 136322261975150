import { Injectable } from "@angular/core";
import { SQLite, SQLiteObject } from "@ionic-native/sqlite/ngx";
import { Platform } from "@ionic/angular";
import { Globalservice } from "./global.service";
import { scheduleDashboardEmployeeBO } from "./pages/employeescheduledashboard/employeescheduledashboard.model";
import { ActivityBo, EvvClientsOffline, Services } from "./pages/my-clients/my-clients.model";
@Injectable({
    providedIn: 'root'
})
export class sqliteService {
    public database: SQLite;
    constructor(private sqlite: SQLite, private platform: Platform, public global: Globalservice,) {

    }
    //=========================Create Queries===============================//
    createOfflineTables() {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('CREATE TABLE IF NOT EXISTS client(Id INTEGER PRIMARY KEY AUTOINCREMENT,ClientId INTEGER UNIQUE,name VARCHAR(32),mobileNumber VARCHAR(32),street VARCHAR(32),city VARCHAR(32),state VARCHAR(32),zip VARCHAR(32),county VARCHAR(32),email VARCHAR(32),cityStateZip VARCHAR(32))', []).then(() =>
                db.executeSql('CREATE TABLE IF NOT EXISTS service(Id INTEGER PRIMARY KEY AUTOINCREMENT,serviceId INTEGER,service VARCHAR(32),remainingHours VARCHAR(32),ratio INTEGER,clientId INTEGER,serviceGroupId INTEGER,authDetails VARCHAR(64))', []).then(() => {
                    db.executeSql('CREATE TABLE IF NOT EXISTS activity(Id INTEGER PRIMARY KEY AUTOINCREMENT,serviceId INTEGER,activityId Integer,activity VARCHAR(32),clientId INTEGER)', []).then(() =>
                        db.executeSql('CREATE TABLE IF NOT EXISTS schedule(Id INTEGER PRIMARY KEY AUTOINCREMENT,scheduleId INTEGER,serviceId INTEGER,clientID INTEGER,start VARCHAR(32),end VARCHAR(32),date VARCHAR(32),isCancelled INTEGER)', []).then(() =>
                            db.executeSql('CREATE TABLE IF NOT EXISTS timesheet(Id INTEGER PRIMARY KEY AUTOINCREMENT,timesheetId INTEGER,serviceId INTEGER,clientID INTEGER,start VARCHAR(32),end VARCHAR(32),date VARCHAR(32),isRP INTEGER,callLatitude VARCHAR(32) ,callLongitude VARCHAR(32),serviceGroupId INTEGER,clientAuthId INTEGER,payorId INTEGER)', []).then(() =>
                                db.executeSql('CREATE TABLE IF NOT EXISTS timesheetActivty(Id INTEGER PRIMARY KEY AUTOINCREMENT,timesheetId INTEGER,serviceId INTEGER,clientID INTEGER,activityId INTEGER,isSelected INTEGER,activity VARCHAR(32))', []).then(() =>
                                    console.log("All Tables Created")
                                ).catch(e => console.log(e))
                            ).catch(e => console.log(e))
                        ).catch(e => console.log(e))
                    ).catch(e => console.log(e))
                }).catch(e => console.log(e))
            )
                .catch(e => console.log(e));
        })
            .catch(e => console.log(e));
    }
    //=========================Add Queries===============================//
    addClientsItem(data: EvvClientsOffline) {
       
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('INSERT INTO client VALUES(NULL,?,?,?,?,?,?,?,?,?,?)', [data.clientId, data.name, data.mobileNumber, data.street, data.city, data.state, data.zip, data.country, data.email, data.cityStateZip])
                .then((res) => {

                })
                .catch((err) => {

                    console.log(JSON.stringify(err));

                });
        }).catch((e) => {

            console.log(JSON.stringify(e));

        });
    }
    addClientServiceItems(data: Services, clientId) {
      console.log(data)
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {   
            db.executeSql('INSERT INTO service VALUES(NULL,?,?,?,?,?,?,?)', [data.serviceId, data.serviceName, data.remaingHours, data.ratio, clientId,data.serviceGroupId,JSON.stringify(data.authDetails)])
                .then((res) => {

                })
                .catch((err) => {

                    console.log(JSON.stringify(err));

                });
        }).catch((e) => {

            console.log(JSON.stringify(e));

        });
    }
    addActivitesItems(data: ActivityBo, clientId, serviceId) {
       
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('INSERT INTO activity VALUES(NULL,?,?,?,?)', [serviceId, data.activityId, data.value, clientId])
                .then((res) => {
               

                })
                .catch((err) => {

                    console.log(JSON.stringify(err));
                });
        }).catch((e) => {

            console.log(JSON.stringify(e));

        });
    }

   
    addScheduleItems(data: scheduleDashboardEmployeeBO) {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            //schedule(Id INTEGER AUTOINCREMENT,scheduleId INTEGER,serviceId INTEGER,clientID INTEGER,start VARCHAR(32),end VARCHAR(32),date VARCHAR(32),isCancelled INTEGER)
            db.executeSql('INSERT INTO schedule VALUES(NULL,?,?,?,?,?,?,?)', [data.scheduleId, data.serviceId, data.clientId, data.startTime, data.endTime, data.startDate, (data.isCancelled == undefined || data.isCancelled == null || data.isCancelled == false ? 0 : 1)])
                .then((res) => {

                })
                .catch((err) => {

                    console.log(JSON.stringify(err));
                });
        }).catch((e) => {

            console.log(JSON.stringify(e));

        });
    }
    addTimesheetItems(data): any {
      
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            //Id INTEGER AUTOINCREMENT,timesheetId INTEGER,serviceId INTEGER,clientID INTEGER,start VARCHAR(32),end VARCHAR(32),date VARCHAR(32),isRP INTEGER   INTEGER,
            db.executeSql('INSERT INTO timesheet(timesheetId,serviceId,clientID,start,end,date,isRP,callLatitude,callLongitude,serviceGroupId,clientAuthId,payorId) VALUES(?,?,?,?,?,?,?,?,?,?)', [data.evvTimesheetId, data.serviceId, data.clientId, data.startTime, data.endTime, data.tImesheetDate, (data.isRP == undefined || data.isRP == null || data.isRP == false ? 0 : 1), data.callLatitude, data.callLongitude,data.serviceGroupId,data.clientAuthId,data.payorId])
                .then((row: any) => {

                    return row.insertId;
                })
                .catch((err) => {

                    console.log(JSON.stringify(err));

                });
        }).catch((e) => {

            console.log(JSON.stringify(e));

        });
    }
    addTimesheetActivityItems(data) {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('INSERT INTO timesheetActivty VALUES(NULL,?,?,?,?)', [data.evvTimesheetId, data.serviceId, data.clientId, data.activityId, (data.isSelected == undefined || data.isSelected == null || data.isSelected == false ? 0 : 1)])
                .then((res) => {

                })
                .catch((err) => {

                    console.log(JSON.stringify(err));

                });
        }).catch((e) => {

            console.log(JSON.stringify(e));

        });
    }
    UpdateTimehseetData(data) {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            //serviceId INTEGER,clientID INTEGER,start VARCHAR(32),end VARCHAR(32),date VARCHAR(32)
            db.executeSql('UPDATE timesheet set end=' + data.endTime + ' where Id==' + data.evvTimesheetId)
                .then((res) => {

                })
                .catch((err) => {

                    console.log(JSON.stringify(err));

                });
        }).catch((e) => {

            console.log(JSON.stringify(e));

        });
    }
    AddTimehseetActivites(data) {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            //+data.evvTimesheetId+','+data.clientId+','+data.activityId+','+0+',\''+data.value+'\')'
            //timesheetActivty(Id INTEGER PRIMARY KEY AUTOINCREMENT,timesheetId INTEGER,serviceId INTEGER,clientID INTEGER,activityId INTEGER,isSelected INTEGER,activity VARCHAR(32))
            db.executeSql('INSERT into timesheetActivty values (NULL,?,?,?,?,?,?)', [data.evvTimesheetId, data.serviceId, data.clientId, data.activityId, 0, data.value])
                .then((res) => {
                })
                .catch((err) => {

                    console.log(JSON.stringify(err));

                });
        }).catch((e) => {

            console.log(JSON.stringify(e));

        });
    }
    UpdateTimehseetActivites(data) {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('UPDATE timesheetActivty SET isSelected= ? where Id== ?', [(data.isSelected == true ? 1 : 0), data.Id])
                .then((res) => {

                })
                .catch((err) => {

                    console.log(JSON.stringify(err));

                });
        }).catch((e) => {

            console.log(JSON.stringify(e));

        });
    }
    //========================Get Queries=======================//
    getClientData(): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from client', [])
                .then((data) => {

                    var auth = data.rows.item;

                    return data;
                })
                .catch(e => {

                })
        })
    }
    getSingleClientData(clientId): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from client where ClientId==' + clientId, [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    getClientServiceData(clientId): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from service where clientId==' + clientId, [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    getClientService(clientId, serviceId): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from service where clientId==' + clientId + ' and serviceId==' + serviceId, [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    getClientServiceActivities(clientId, serviceId): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from activity where clientId==' + clientId + ' and serviceId==' + serviceId, [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    getClientActivtyData(clientId, serviceId): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from activity where clientId==' + clientId + ' and serviceId==' + serviceId, [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    getScheduleData(): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from schedule', [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    getAlltimesheetData(): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from timesheet', [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    getTimesheetsForClient(clientId): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from timesheet where clientID==' + clientId, [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    getOfflineTimesheets(): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from timesheet join client on timehsheet.clientID = client.ClientId', [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    getOfflineTimesheetsCount(): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from timesheet', [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    gettimesheetData(timesheetId): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from timesheet where timesheetId==' + timesheetId, [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {
                })
        })
    }
    gettimesheetActivtyData(timesheetId): any {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('select * from timesheetActivty where timesheetId==' + timesheetId, [])
                .then((data) => {
                    let retdata = [];

                    var auth = data.rows.item;

                    if (data.rows.length > 0) {
                        for (let i = 0; i < data.rows.length; i++) {
                            retdata.push(data.rows.item(i))
                        }
                    }
                    return retdata;
                })
                .catch(e => {

                })
        })
    }
    removeAllDataExceptTS() {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            db.executeSql('Delete from client', []).then(() => {
                db.executeSql('Delete from service', []).then(() => {
                    db.executeSql('Delete from activity', []).then(() => {
                        db.executeSql('Delete from schedule', []).then(() => {

                        })
                    })
                })
            })
        }).catch((e) => {

        });
    }
    CancelUpdateTimesheet(data) {
        this.sqlite.create({
            name: 'evv.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
            let sql;
            let values;
            sql = "UPDATE timesheet SET (start) = (?) where Id = ? AND clientID = ?; ";
            values = [null, data.evvTimesheetId, data.clientId]
            db.executeSql(sql, values)
                .then((res) => {
                    this.global.ToastSuccessMiniDuration("Visits cancel successfully")
                    setTimeout(() => {
                        this.global.ToastSuccessMiniDuration("Query updated successfully")
                    }, 1500);

                }).catch((err) => {
                    console.log(JSON.stringify(err));
                });
        }).catch((e) => {
            console.log(JSON.stringify(e));
        });
    }
}