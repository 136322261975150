import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { Globalservice } from 'src/app/global.service';
import { LanguageService } from 'src/app/language.service';
import { Storage } from '@ionic/Storage';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { offlineService } from 'src/app/offline-data.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { sqliteService } from 'src/app/sqlite.service';
import { Device } from '@ionic-native/device/ngx';
import { CookieService } from 'src/app/cookieservice';
import { AppVersion } from '@ionic-native/app-version/ngx';
@Component({
  selector: 'app-login-password',
  templateUrl: './login-password.page.html',
  styleUrls: ['./login-password.page.scss'],
})
export class LoginPassPage {
  isLogin: boolean = true;
  isReset: boolean = false;
  user: string;
  Password: string;
  Agencycode: string;
  Role: any;
  password: string = 'password';
  passwordIcon: string = 'eye-off';
  loginCount = 0;
  constructor(
    public platform: Platform,
    public appVersion: AppVersion,
    public menu: MenuController,
    public router: Router,
    public http: HttpClient,
    public global: Globalservice,
    private toastCtrl: ToastController,
    public sqlService: sqliteService,
    public device: Device,
    public languageService: LanguageService,
    public offline: offlineService,
    private sqlite: SQLite,
    public storage: Storage,
    private localNotifications: LocalNotifications,
    public cookieService: CookieService
  ) { }
  ionViewWillEnter() {
    this.user = null;
    this.Password = null;
    this.Agencycode = null;
    this.Role = null;
    this.menu.enable(false);

    this.platform.ready().then(() => {
      this.appVersion.getVersionNumber().then((data) => {
        this.global.applicationVersionNumber = data;
      });
    });
    this.global.timezone = "Minnesota";
    localStorage.setItem(
      'TimeZone',
      JSON.stringify(this.global.timezone)
    );
  }
  Dinesh: any = "";
  //......................................................login............................................
  async login() {
    this.getEmployeeMenus();
    this.getClientMenus()
    await this.global.showLoading();
    let url = 'api/Auth/login?';
    let LoginPayload = {
      Username: this.user,
      Password: this.Password,
      AgencyCode: this.Agencycode,
      role: this.Role,
      type: 'EVV',
    };
    this.http.post(url, LoginPayload).subscribe(
      (data: any) => {
        this.menu.enable(true);
        this.global.UserData = data;
        this.global.UserData.accessToken = data.accessToken;
        this.global.role = this.Role;
        this.global.password = this.Password;
        let refreshToken = data.refreshToken;
        localStorage.setItem('set_cookie', refreshToken);
        this.global.refreshTokenString = refreshToken;
        localStorage.setItem('Evv_login', JSON.stringify(this.global.UserData));
        localStorage.setItem('Evv_role', JSON.stringify(this.global.role));
        this.global.scheduleRefresh();
        if (data.accessToken != null) {
          this.global
            .getEvvStatus()
            .then((data: any) => {
             
              if (data == null || data == undefined || data == '') {
                this.global.isOptional = false;
              }
              else {
                this.global.isOptional = data.isOptional;
              }
              this.global.CheckForUpdateAvailable();

              if (this.global.isOptional == false || this.global.isOptional == null) {
               
                this.EvvUserDetails();
                this.global.getServiceType();
                if (
                  this.global.applicationVersionNumber != null &&
                  this.global.applicationVersionNumber != undefined &&
                  this.global.applicationVersionNumber != '' &&
                  this.platform.is('android')
                ) {
                  this.platform.ready().then(() => {
                    this.global.getAndroidPermission();
                  });
                }

                this.storage.set('loginAuth', data);
                this.storage.get('CurrentDate').then((val) => {
                  if (!val) {
                    this.storage.set('CurrentDate', new Date().getDate());
                  }
                });
                this.user = '';
                this.Password = '';
                this.Agencycode = '';
                this.Role = '';
                this.global.disableloading();
              }
              else {

                let navigationExtras: NavigationExtras = {
                  state: {
                    maintenanceStatus: "ClearLocalStorage"
                  }
                }
                this.router.navigate(['/login/app-maintenance'], navigationExtras);
              }

            })
            .catch((err) => {
            
            });
          this.global.disableloading();
        }

      },
      (err: HttpErrorResponse) => {
        this.global.disableloading();
        if (this.global.timezone == "Minnesota" && err.status != 0) {
          this.Dinesh = err.error;
          let error = err?.error?.toUpperCase();
          if (error.startsWith("INACTIVE") || (error.startsWith("PASSWORD"))) {
            this.global.disableloading();
            this.presentToast(err.error);
            return;
          }
        }
        if (err.status === 0 && err.error instanceof ProgressEvent && this.global.timezone == "Minnesota") {
          this.global.Toastdanger('No Internet');
          this.global.disableloading();
          return;
        }
        if (this.global.timezone == "US" && err.status != 0) {
          this.global.disableloading();
          this.presentToast(err.error);
        }
        else {
          if (err.status == 0) {
            this.global.Toastdanger(this.Dinesh);
            this.global.disableloading();
          }
        }
        let timezone = this.global.timezone == "US" ? "Minnesota" : "US";
        this.global.timezone = timezone;
        localStorage.setItem(
          'TimeZone',
          JSON.stringify(this.global.timezone)
        );
        if (this.loginCount == 0) {
          this.login();
          this.loginCount++;
        }
      }
    );
  }

  hideShowPassword() {
    this.password = this.password === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }
  //......................................................EvvUserDetails............................................
  async EvvUserDetails() {
    var url = 'api/Auth/getEvvUserDetails?';
    await this.global.showLoading();
    this.http.get(url).subscribe((data: any) => {
      this.global.EvvUserDetails = data;
      if (
        data.setLanguage != null &&
        data.setLanguage != '' &&
        data.setLanguage != undefined
      ) {
        this.global.EvvUserDetails.setLanguage = data.setLanguage;
      } else {
        this.global.EvvUserDetails.setLanguage = 'en';
      }
      localStorage.setItem(
        'EvvUserDetails',
        JSON.stringify(this.global.EvvUserDetails)
      );


      if (
        this.global.applicationVersionNumber != null &&
        this.global.applicationVersionNumber != undefined &&
        this.global.applicationVersionNumber != ''
      ) {
        
        this.platform.ready().then(() => {
          this.global.getFCMNotification();
        });
      }
      else {
        this.global.saveDeviceInfo();
      }
      if (this.global.EvvUserDetails.isLoggedIn == true) {
        this.global.isOffline = false;
        localStorage.setItem('isOffline', 'false');
        if (
          this.global.role == 'PCAEMPLOYEE' ||
          this.global.role == 'QPEMPLOYEE'
        ) {
          localStorage.setItem(
            'Evv_employeelanguages',
            this.global.EvvUserDetails.setLanguage
          );
          this.router.navigate(['/tabs/all-timesheets'], { replaceUrl: true });
          this.languageService.setLanguage(data.setLanguage);
          this.storage.get('Version9').then((val) => {
            if (val != null) {
              this.storage.set('Version9', 'Version9');
            } else {
              this.storage.set('Version9', 'Version9');
              this.storage.get('OfflineDate').then((val) => {
                if (val != new Date().getDate()) {
                  //----TO DELETE RECORDS ON PAGE LOAD AND THEN RELOAD IT---/////
                  this.sqlite
                    .create({
                      name: 'evv.db',
                      location: 'default',
                    })
                    ?.then((db: SQLiteObject) => {
                      this.sqlService.removeAllDataExceptTS();
                      this.sqlService.createOfflineTables();
                      this.storage.remove('OfflineDate');
                      this.getdataoffline();
                    })
                    .catch((e) => console.log(e));
                }
              });
            }
          });
        } else if (this.global.role == 'CLIENT') {
          localStorage.setItem(
            'Evv_clientlanguage',
            this.global.EvvUserDetails.setLanguage
          );
          this.router.navigate(['/client/client-all-timesheets'], {
            replaceUrl: true,
          });
          this.languageService.setLanguage(data.setLanguage);
        }
      } else {
        if (
          this.global.role == 'PCAEMPLOYEE' ||
          this.global.role == 'QPEMPLOYEE'
        ) {
          localStorage.setItem(
            'Evv_employeelanguages',
            this.global.EvvUserDetails.setLanguage
          );
          this.router.navigate(['/tabs/languages'], { replaceUrl: true });
          this.languageService.setLanguage(data.setLanguage);
        } else if (this.global.role == 'CLIENT') {
          localStorage.setItem(
            'Evv_clientlanguage',
            this.global.EvvUserDetails.setLanguage
          );
          this.router.navigate(['/client/client-languages'], {
            replaceUrl: true,
          });
          this.languageService.setLanguage(data.setLanguage);
        }
      }
      localStorage.setItem('serverDatasynced', 'false');
      this.ToasterMessage('Login Successful');
     
      if (this.global.timezone == "US") {
        // this.global.checkZoneConfirmation();
      }
      this.global.disableloading();
      this.global.userId = this.global.EvvUserDetails.entityId;
      (err: HttpErrorResponse) => {
        this.global.disableloading();
        localStorage.clear();
        this.router.navigate(['/login'], { replaceUrl: true });
        this.presentToast(err.error);
      };
    });
  }
  //......................................................forgotPassword............................................
  forgotPassword() {
    this.user = '';
    this.Password = '';
    this.Agencycode = '';
    this.router.navigate(['/login/forgot-password']);
  }
  //......................................................presentToast............................................
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'danger',
    });
    toast.present();
  }
  //......................................................saveDeviceInfo............................................

  //......................................................getdataoffline............................................
  getdataoffline() {
    let param = new URLSearchParams();
    param.append('EmployeeId', this.global.EvvUserDetails.entityId.toString());
    param.append('searchtext', '');
    this.showNotification();
    this.offline.getClientsOffline(param).subscribe((data) => {
      data.forEach((offline) => {
        this.sqlService.addClientsItem(offline);
        offline.mappedServices.forEach((maps) => {
          this.sqlService.addClientServiceItems(maps, offline.clientId);
          maps.activities.forEach((actvities) => {
            this.sqlService.addActivitesItems(
              actvities,
              offline.clientId,
              maps.serviceId
            );
          });
        });
      });
      this.localNotifications.clear(1);
    });
    this.showNotificationSchedule();
    let params = new URLSearchParams();
    params.append('EmployeeId', this.global.EvvUserDetails.entityId.toString());
    this.offline.getSchedulesOffline(params).subscribe((data) => {
      data.forEach((offline) => {
        this.sqlService.addScheduleItems(offline);
      });
      this.localNotifications.clear(2);
    });
    this.storage.set('OfflineDate', new Date().getDate());
  }
  //......................................................showNotification............................................
  showNotification() {
    this.localNotifications.schedule({
      id: 1,
      text: 'Getting Client Offline Data is in progress',
      sound: null,
      icon: 'assets/imgs/logo.png',
      led: true,
    });
  }
  //......................................................showNotificationSchedule............................................
  showNotificationSchedule() {
    this.localNotifications.schedule({
      id: 2,
      text: 'Getting Schedule Offline Data is in progress',
      sound: null,
      icon: 'assets/imgs/logo.png',
      led: true,
    });
  }
  //......................................................ToasterMessage............................................
  async ToasterMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 1000,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }
  getClientMenus() {

    this.global.getClientMenus().subscribe((data: any) => {

      this.global.clientappPages = data;

    });

  }

  getEmployeeMenus() {
    if (this.global.timezone == "US") {
      this.global.getUSEmployeeMenus().subscribe((data: any) => {
        this.global.appPages = data;
      });
    }
    else {
      this.global.getMnEmployeeMenus().subscribe((data: any) => {
        this.global.appPages = data;
      });
    }
  }
}