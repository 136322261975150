
import { Component, ViewChild, ElementRef } from '@angular/core';
import { MyClientsService } from '../my-clients/my-clients.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ErrorService } from 'src/app/error.service';
import {
  Activity,
  MyClient,
  saveActivitiesBO,
  Services,

} from '../my-clients/my-clients.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TimesheetService } from '../timesheet/timesheet.service';
import { Globalservice } from 'src/app/global.service';
import { ServiceInfoService } from './service-info.service';
import { EvvTimesheetBO, offlineactivites } from './service-info.model';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import {
  AlertController,
  AnimationController,
  GestureController,
  Platform,
} from '@ionic/angular';
import { generalservice } from 'src/app/general.service';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { Network } from '@ionic-native/network/ngx';
import { sqliteService } from 'src/app/sqlite.service';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { DatePipe } from '@angular/common';
import BackgroundGeolocation from 'cordova-background-geolocation-lt';
declare var L: any;
@Component({
  selector: 'app-service-info',
  templateUrl: './service-info.page.html',
  styleUrls: ['./service-info.page.scss'],
})
export class ServiceInfoPage {
  @ViewChild('item') slideitem: ElementRef;
  InprogressActivitiesList: Array<Activity> = [];
  clientData: MyClient;
  appointmentId: string;
  serviceName: string = 'PCA Service';
  trustUrl: SafeResourceUrl;
  activitiesList: Array<Services> = [];
  activitesForTs: Array<offlineactivites> = [];
  selected: boolean = false;
  clientId: number;
  backGroundLocationStartCount: any = 0;
  backGroundLocationStopCount: any = 0;
  serviceId: number;
  startTime: string = null;
  endTime: string = null;
  scheduleId: number = 0;
  evvTimesheetId: number = 0;
  senddata: EvvTimesheetBO;
  inProgressRecords: Array<any> = [];
  @ViewChild('map')
  public mapElement: ElementRef;
  lat: any;
  isClockIn: boolean = false;
  isClockOut: boolean = false;
  lng: any;
  isStarted: boolean = false;
  isOffline: boolean = false;
  clientDat: MyClient;
  isCommunity: boolean = false;
  offlineTsId: any = 0;
  Accuracy: any = "";
  clientAuthId: number;
  payorId: number;
  serviceGroupId: number;
  constructor(
    public clientService: MyClientsService,
    public errorService: ErrorService,
    public timesheetService: TimesheetService,
    private router: Router,
    private sanitize: DomSanitizer,
    private route: ActivatedRoute,
    public global: Globalservice,
    public general: generalservice,
    public httpservice: ServiceInfoService,
    public geoLoc: Geolocation,
    public alertController: AlertController,
    public datePipe: DatePipe,
    public gestureCtrl: GestureController,
    public animationCtrl: AnimationController,
    private network: Network,
    public sqlservice: sqliteService,
    public platform: Platform,
    private launchNavigator: LaunchNavigator,
    private sqlite: SQLite
  ) {
    this.route.queryParams.subscribe((params) => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.clientAuthId = data.clientAuthId;
        this.payorId = data.payorId;
        this.serviceGroupId = data.serviceGroupId;
        this.clientId = data.clientId;
        this.serviceId = data.serviceId;
        this.startTime = data.startTime;
        this.offlineTsId = data.offlineTsId;
        if (data.startTime != null && data.startTime != undefined) {
          this.isStarted = true;
        } else {
          this.isStarted = false;
        }
      }
      this.network.onConnect().subscribe(() => {
        this.isOffline = false;
      });
      this.network.onDisconnect().subscribe(() => {
        this.isOffline = true;
      });
    });
    this.global.checkingLogin();
  }
  openMap(address: string) {
    this.launchNavigator.navigate(address);
  }
  async ionViewWillEnter() {
    this.isClockIn = false;
    this.isClockOut = false;
    this.Accuracy = null;
    this.clientData = null;
    this.serviceName = '';
    this.senddata = null;
    this.activitiesList = [];
    if (this.startTime != null) {
      let isRecordExists: boolean = false;
      this.inProgressRecords.forEach((element) => {
        if (element.clientId == this.clientId) {
          isRecordExists = true;
        }
      });
      if (!isRecordExists) {
        this.inProgressRecords.push({
          clientId: this.clientId,
          serviceId: this.serviceId,
          startTime: this.startTime,
          clientAuthId: this.clientAuthId,
          payorId: this.payorId,
          serviceGroupId: this.serviceGroupId
        });
      }
    }
    if (!this.global.isOffline) {
      await this.global.checkRefreshTokenValidity()
      {
      this.getClients();
      this.getCurrentLocation();
      }
    } else if (this.global.isOffline) {
      this.getOfflineClientData();
      this.getserviceDetailsOffline();
      this.getactivitiesOffline();
    }
  }
  async getCurrentLocation() {
    const position = await this.geoLoc
      .getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      })
      .then((res) => {
      });
  }
  async getService() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append('Client', this.clientId.toString());
    this.clientService.getServices(param).subscribe(
      (data: Array<Services>) => {
        data.forEach((element) => {
          if (element.serviceId == this.serviceId) {
            this.serviceName = element.serviceName;
          }
        });
      },
      (err) => {
        this.errorService.check(err);
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  async getClients() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    let param = new URLSearchParams();
    param.append('Employee', this.global.EvvUserDetails.entityId.toString());
    param.append('Client', this.clientId.toString());
    param.append('service', this.serviceId.toString());
    this.clientService.getClientById(param).subscribe(
      (data: any) => {
        data.forEach((element) => {
          if (element.clientId == this.clientId) {
            this.clientData = element;
            this.serviceName = element.serviceName;
            this.activitiesList = element.activities;
            this.scheduleId = this.clientData.scheduleId;
            this.evvTimesheetId = this.clientData.evvTimesheetId;
            this.appointmentId = this.clientData.appointmentId;
            this.clientData.mobileNumberFormat =
              this.clientData.mobileNumber != null
                ? this.general.converPhoneGoogleLib(
                  this.clientData.mobileNumber.toString()
                )
                : null;
            element.zip != null ? element.zip : '';
            if (element.startTime != null && element.startTime != undefined) {
              this.isStarted = true;
              this.GetActivitiesForVisit();
            } else {
              this.isStarted = false;
            }
          }
        });
        this.trustUrl = this.sanitize.bypassSecurityTrustResourceUrl(
          'https://maps.google.com/maps?q=' +
          this.clientData.street +
          ',' +
          this.clientData.cityStateZip +
          '&z=15&ie=UTF8&iwloc=&output=embed&zoom=15'
        );
        this.global.disableloading();
      },
      (err) => {
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    )
    }
  }
  async checkStartforTrainingEntity() {
    await this.global.showLoading();
    if (this.global.isTrainer == true) {
      this.global.isModalStart = true;
      this.startTime = null;
      this.selectStartValues();
      await this.global.disableloading();
      return;
     
    } else {
      this.global.isModalStart = false;
     
      this.start();
    }
  }
  checkStopforTrainingEntity() {
    if (this.global.isTrainer == true) {
      this.global.isModalStop = true;
      this.endTime = null;
      this.selectEndValues();
      return;
    } else {
      this.global.isModalStop = false;
      this.stop();
    }
  }
  async start() {
    await this.global.checkRefreshTokenValidity()
    {
    this.remainingTime = 0;
    await this.global.showLoading();
    if (this.global.isTrainer == true) { 
      this.startTime = this.global.DateandTimeFormat(this.startTime);
    } else {
      this.startTime = this.global.DateandTimeFormat(new Date());
    }
    let STime=this.startTime;
    STime=this.global.getDateTimeString(STime);
    let payload = {
      employeeId: this.global.EvvUserDetails.entityId,
      clientId: this.clientId,
      masterServiceId: this.serviceId,
      clockInTime: STime,
      clientAuthId: this.clientAuthId,
      payorId: this.payorId,
      serviceGroupId: this.serviceGroupId,
      callLongitude: this.lng != null ? parseFloat(this.lng) : null,
      callLatitude: this.lat != null ? parseFloat(this.lat) : null,
    };

    this.AuthValidationForOverNightRecords("start");
    this.httpservice.startTimesheets(payload).subscribe(
      (data) => {
        this.global.disableloading();
        if (data.errors.length > 0) {
          this.global.Toastdanger(data.errors);
          return;
        }
        this.isStarted = true;
        this.global.isModalStart = false;
        this.evvTimesheetId = data.evvId;
        this.GetActivitiesForVisit();
        
        this.isStarted = true;
      },
      (err) => {
        
        this.global.Toastdanger(err.message);
        this.errorService.check(err);
        this.isStarted = false;
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
 
  closeStartModal() {
    this.global.isModalStart = false;
  }
  closeStopModal() {
    this.global.isModalStop = false;
  }
  async startOffline() {
    var tsdata = [];
    this.startTime = this.global.DateandTimeFormat(new Date());
    tsdata.push({
      evvTimesheetId: 0,
      serviceId: this.serviceId,
      clientId: this.clientId,
      clientAuthId: this.clientAuthId,
      payorId: this.payorId,
      serviceGroupId: this.serviceGroupId,
      startTime: this.startTime,
      endTime: null,
      tImesheetDate: this.startTime,
      isRP: false,
      callLongitude: this.lng != null ? parseFloat(this.lng) : null,
      callLatitude: this.lat != null ? parseFloat(this.lat) : null,
    });
    this.addTimesheetItems(tsdata[0]);
  }
  selectStartValues() {
    if (this.startTime == undefined) {
      this.startTime = this.global.DateandTimeFormat(new Date());
    }
  }
  selectEndValues() {
    if (this.endTime == undefined) {
      this.endTime = this.global.DateandTimeFormat(new Date());
    }
  }
 async stop() {
  await this.global.checkRefreshTokenValidity()
  {
    this.global.showLoading();
    if (this.global.isTrainer == true) {
      this.endTime = this.global.DateandTimeFormat(this.endTime);
    } else {
      this.endTime = this.global.DateandTimeFormat(new Date());
    }
 let eTime=this.endTime;
 eTime=this.global.getDateTimeString(eTime);
    let data = {
      appointmentId: this.appointmentId,
      evvTimesheetId: this.evvTimesheetId,
      clientAuthId: this.clientAuthId,
      payorId: this.payorId,
      serviceGroupId: this.serviceGroupId,
      clockOutTime: eTime,
      callLongitude: this.lng != null ? parseFloat(this.lng) : null,
      callLatitude: this.lat != null ? parseFloat(this.lat) : null,
    };
    this.AuthValidationForOverNightRecords("stop");
    this.httpservice.stopTimesheets(data).subscribe(
      (data) => {
        this.global.disableloading();
        if (data.errors.length == 0) {
          this.global.isModalStop = false;
        }
        if (data.errors.length > 0) {
          this.global.Toastdanger(data.errors);
          return;
        }
        setTimeout(() => {
          let navigationExtras: NavigationExtras = {
            state: {
              clientId: this.clientId,
              serviceId: this.serviceId,
              startTime: this.startTime,
              endTime: this.endTime,
              scheduleId: 0,
              type: 'pending',
              clientSign: null,
              employeeSign: null,
              isSubmitButton: true,
              backto: 'my-clients',
              evvTimesheetId: this.evvTimesheetId,
              frompage: 'service',
              notes: '',
              tsType: 'pending',
            },
          };
          this.router.navigate(['/tabs/timesheet'], navigationExtras);
        }, 500);
        this.isStarted = false;
        this.global.disableloading();
      },
      (err) => {
        this.isStarted = true;
        this.global.Toastdanger(err.message);
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  stopOffline() {
    var tsdata = [];
    this.endTime = this.global.DateandTimeFormat(new Date());
    let startdate = new Date(this.startTime);
    let enddate = new Date(this.endTime);
    let diff = (enddate.getTime() - startdate.getTime()) / 1000;
    let minutesDifference = Math.abs(Math.floor(diff / 60));
    let roundedMinutes = Math.round(minutesDifference / 15) * 15;
    let hours = roundedMinutes / 60;
    if (hours < 0.25) {
      this.global.ToastDangerMiniDuration(
        this.clientData.name + ' : ' + 'Hours should not be zero'
      );
      this.isClockOut = false;
      return;
    }
    tsdata.push({
      evvTimesheetId: this.offlineTsId,
      serviceId: this.serviceId,
      clientId: this.clientId,
      clientAuthId: this.clientAuthId,
      payorId: this.payorId,
      serviceGroupId: this.serviceGroupId,
      startTime: this.startTime,
      endTime: this.endTime,
      tImesheetDate: this.startTime,
      isRP: false,
      callLongitude: this.lng != null ? parseFloat(this.lng) : null,
      callLatitude: this.lat != null ? parseFloat(this.lat) : null,
    });
    this.updateTimeSheet(tsdata[0]);
  }
  UpdateTSActivities(serviceId, clientId) {
    this.activitesForTs = [];
    this.sqlite
      .create({
        name: 'evv.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql(
          'select * from activity where clientId==' +
          clientId +
          ' and serviceId==' +
          serviceId,
          []
        )
          .then((data) => {
            if (data.rows.length > 0) {
              for (let i = 0; i < data.rows.length; i++) {
                var activityForTs = {
                  activityId: null,
                  serviceId: null,
                  isSelected: null,
                  value: null,
                  evvTimesheetId: null,
                  clientId: null,
                };
                activityForTs.activityId = data.rows.item(i).activityId;
                activityForTs.isSelected = false;
                activityForTs.serviceId = serviceId;
                activityForTs.value = data.rows.item(i).activity;
                activityForTs.evvTimesheetId = this.offlineTsId;
                activityForTs.clientId = clientId;
                this.activitesForTs.push(activityForTs);
              }
            }
          })
          .then(() => {
            this.activitesForTs.forEach((element) => {
              this.sqlservice.AddTimehseetActivites(element);
            });
          });
      });
  }
  remainingTime: any;
  startTimer() {
    var counter = setTimeout(() => {
      var time = this.getTimerClock(this.remainingTime);
      if (this.remainingTime >= 0) {
        this.startTimer();
        this.remainingTime++;
      } else {
        clearInterval(counter);
      }
    }, 1000);

  }
  getTimerClock(inputSeconds: number) {
    var sec_num = parseInt(inputSeconds.toString(), 10);
    this.remainingTime = sec_num; //Define variable
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;
    var hoursString = '';
    var minutesString = '';
    var secondsString = '';
    hoursString = hours < 10 ? '0' + hours : hours.toString();
    minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
    secondsString = seconds < 10 ? '0' + seconds : seconds.toString();
    return hoursString + ':' + minutesString + ':' + secondsString;
  }

  async StartClockInButton()
  {
  if(this.global.isOffline)
      {
      this.OnSwipe()
      }
      else 

      {
        this.isClockIn=true;
        await this.global.checkRefreshTokenValidity()
    {
      this.OnSwipe()
    }
      }
  }
  async OnSwipe() {
  
    this.isClockIn=true;
    this.global.isModalStart = false;
    await this.global.showLoading();
    if (this.global.applicationVersionNumber == null || this.global.applicationVersionNumber == undefined || this.global.applicationVersionNumber == "") {
      this.getStartLocation();
    } else {
      await BackgroundGeolocation.ready({
        stationaryRadius: 2,
        distanceFilter: 3,
        debug: false,
        foregroundService: true,
        stopOnTerminate: true,
        locationAuthorizationRequest: 'Always',
        notification: {
          title: "AllCare GPS Tracking",
          text: "Live Location Sharing",
        },
        backgroundPermissionRationale: {
          title: "Allow AllCare to access to this device's location in the background?",
          message: "In order to track your activity in the background, please enable Allow all the time location permission",
        }
      }).then((state) => {
      })
      this.backGroundLocationStartCount = 0;


      if (this.platform.is('android')) {
        this.getBackGroundStartLocationandroid();
      } else if (this.platform.is('ios')) {
        this.getBackGroundStartLocationiOS();
      }
    }
  }
  async StopClockOutButton()
  {
  if(this.global.isOffline)
      {
      this.onswipeLeft()
      }
      else 

      {
        this.isClockOut=true;
        await this.global.checkRefreshTokenValidity()
    {
      this.onswipeLeft()
    }
      }
  }
  async onswipeLeft() {
    this.isClockOut=true
    this.global.isModalStart = false;
    await this.global.showLoading();
    if (this.global.applicationVersionNumber == null || this.global.applicationVersionNumber == undefined || this.global.applicationVersionNumber == "") {
      this.getStopLocation();
    } else {
      await BackgroundGeolocation.ready({
        stationaryRadius: 2,
        distanceFilter: 3,
        debug: false,
        foregroundService: true,
        stopOnTerminate: true,
        locationAuthorizationRequest: 'Always',
        notification: {
          title: "AllCare GPS Tracking",
          text: "Live Location Sharing",
        },
        backgroundPermissionRationale: {
          title: "Allow AllCare to access to this device's location in the background?",
          message: "In order to track your activity in the background, please enable Allow all the time location permission",
        }
      }).then((state) => {
      })
      this.backGroundLocationStopCount = 0;

      if (this.platform.is('android')) {
        this.getBackGroundStopLocationandroid();
      } else if (this.platform.is('ios')) {
        this.getBackGroundStopLocationiOS();
      }
    }
  }
  async getStartLocation() {
    this.geoLoc
      .getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      })
      .then(
        (res) => {
          this.global.disableloading();
          this.isClockIn = false;
          this.lat = res.coords.latitude;
          this.lng = res.coords.longitude;
          this.Accuracy = res.coords.accuracy != null ? parseInt(res.coords.accuracy.toString()) : null;
          this.GetStartCommunity();
        },
        (err: any) => {
          this.isClockIn = false;
          this.global.disableloading();
          this.global.Toastdanger('Turn on Location');
        }
      );
  }
  async getStopLocation() {
    this.geoLoc
      .getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      })
      .then(
        (res) => {
          this.global.disableloading();
          this.isClockOut = false;
          this.lat = res.coords.latitude;
          this.lng = res.coords.longitude;
          this.Accuracy = res.coords.accuracy != null ? parseInt(res.coords.accuracy.toString()) : null;
          this.GetStopCommunity();
        },
        (err: any) => {
          this.isClockOut = false;
          this.global.disableloading();
          this.global.Toastdanger('Turn on Location');
        }
      );
  }
  back() {
    if (this.startTime != null) {
      this.inProgressRecords.push({
        clientId: this.clientId,
        serviceId: this.serviceId,
        startTime: this.startTime,
        clientAuthId: this.clientAuthId,
        payorId: this.payorId,
        serviceGroupId: this.serviceGroupId,
      });
    }
    let navigationExtras: NavigationExtras = {
      state: this.inProgressRecords,
    };
    this.router.navigate(['/tabs/my-clients'], navigationExtras);
  }
  getOfflineClientData() {
    this.clientData = new MyClient();
    this.sqlite
      .create({
        name: 'evv.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql(
          'select DISTINCT * from client WHERE ClientId==' + this.clientId,
          []
        ).then((data) => {
          if (data.rows.length > 0) {
            this.clientData.name = data.rows.item(0).name;
            this.clientData.clientId = data.rows.item(0).ClientId;
            this.clientData.mobileNumber = data.rows.item(0).mobileNumber;
            this.clientData.mobileNumberFormat = data.rows.item(0).mobileNumber;
            this.clientData.street = data.rows.item(0).street;
            this.clientData.country = data.rows.item(0).county;
            this.clientData.email = data.rows.item(0).email;
            this.clientData.cityStateZip = data.rows.item(0).cityStateZip;
          }
        });
      });
  }
  getserviceDetailsOffline() {
    this.sqlite
      .create({
        name: 'evv.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql(
          'select DISTINCT * from service WHERE serviceId==' +
          this.serviceId +
          ' and clientId==' +
          this.clientId,
          []
        ).then((data) => {
          if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {
              this.serviceName = data.rows.item(i).service;
            }
          }
        });
      });
  }
  getactivitiesOffline() {
    //select * from activity where clientId=='+clientId +' and serviceId=='+serviceId
    this.activitesForTs = [];
    this.sqlite
      .create({
        name: 'evv.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql(
          'select * from activity where clientId==' +
          this.clientId +
          ' and serviceId==' +
          this.serviceId,
          []
        ).then((data) => {
          if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {
              var activity = {
                activityId: null,
                value: null,
                serviceId: null,
                isSelected: null,
                evvTimesheetId: null,
                clientId: null,
              };
              activity.activityId = data.rows.item(i).activityId;
              activity.value = data.rows.item(i).activity;
              this.activitesForTs.push(activity);
            }
          }
        });
      });
  }
  startservice() {
    this.presentAlertConfirm();
  }
  stopservice() {
    this.presentstopAlertConfirm();
  }
  addTimesheetItems(data): any {
    this.sqlite
      .create({
        name: 'evv.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql('INSERT INTO timesheet VALUES(NULL,?,?,?,?,?,?,?,?,?,?,?,?)', [
          data.evvTimesheetId,
          data.serviceId,
          data.clientId,
          data.startTime,
          data.endTime,
          data.tImesheetDate,
          data.isRP == undefined || data.isRP == null || data.isRP == false
            ? 0
            : 1,
          data.callLatitude,
          data.callLongitude,
          data.serviceGroupId,
          data.clientAuthId,
          data.payorId

        ])
          .then((row: any) => {
            this.offlineTsId = row.insertId;
            this.isStarted = true;
            this.getinsertedData();
            this.UpdateTSActivities(this.serviceId, this.clientId);
          })
          .catch((err) => { });
      })
      .catch((e) => { });
  }
  getinsertedData() {
    this.sqlite
      .create({
        name: 'evv.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql('select * from timesheet', [])
          .then((data) => {
            let retdata = [];
            // var auth = data.rows.item;
            if (data.rows.length > 0) {
              for (let i = 0; i < data.rows.length; i++) {
                retdata.push(data.rows.item(i));
              }
            }
            return retdata;
          })
          .catch((e) => { });
      });
  }
  updateTimeSheet(data) {

    this.sqlite
      .create({
        name: 'evv.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql('UPDATE timesheet SET end=? where Id==?', [
          data.endTime,
          data.evvTimesheetId,
        ])
          .then((res) => {
            // db.executeSql('select * from timesheet where Id=='+data.evvTimehseetId).then((res) =>{
            // })
            this.isStarted = false;
          })
          .then(() => {
            let navigationExtras: NavigationExtras = {
              state: {
                clientId: this.clientId,
                serviceId: this.serviceId,

                startTime: this.startTime,

                endTime: this.endTime,

                scheduleId: 0,

                type: 'pending',

                clientSign: null,

                employeeSign: null,

                isSubmitButton: true,

                backto: 'my-clients',

                evvTimesheetId: this.offlineTsId,

                frompage: 'service',

                notes: '',

                callLongitude: this.lng != null ? parseFloat(this.lng) : null,

                callLatitude: this.lat != null ? parseFloat(this.lat) : null,

                tsType: 'pending',

              },

            };

            this.router.navigate(['/tabs/timesheet'], navigationExtras);

            this.isStarted = false;

          })

          .catch((err) => { });

      })

      .catch((e) => { });

  }
  async presentAlertConfirm() {
    let message: any;
    if (!this.isCommunity) {
      message = 'Do you want to start service?';
      const alert = await this.alertController.create({
        header: 'Clock In Alert',
        message: message,
        backdropDismiss: false,
        buttons: [
          {
            id: "ClockInYes",
            text: 'Yes',
            handler: () => {
              if (!this.global.isOffline) {
                this.checkStartforTrainingEntity();
              } else if (this.global.isOffline) {
                this.startOffline();
              }
            },
          },
          {
            id: "ClockInNo",
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
        ],
      });
      await alert.present();
    } else {
      message =
        'You are away from clients home, please enable the Community box to proceed!';
      const alert = await this.alertController.create({
        header: 'Clock In Alert',
        message: message,
        backdropDismiss: false,
        inputs: [
          {
            name: 'Community',
            type: 'checkbox',
            label: 'Community',
          },
        ],
        buttons: [
          {
            id: "CommunityYes",
            text: 'Yes',
            handler: (data) => {
              if (data.length > 0) {
                if (!this.global.isOffline) {
                  this.checkStartforTrainingEntity();
                } else if (this.global.isOffline) {
                  this.startOffline();
                }
              } else {
                this.global.ToastDangerMiniDuration(
                  'Please Select Community Checkbox!'
                );
              }
            },
          },
          {
            id: "CommunityNo",
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
        ],
      });
      await alert.present();
    }
  }
  async presentstopAlertConfirm() {
    let message: any;
    if (!this.isCommunity) {
      message = 'Do you want to stop service?';
      const alert = await this.alertController.create({
        header: 'Clock Out Alert',
        message: message,
        backdropDismiss: false,
        buttons: [
          {
            id: "ClockOutYes",
            text: 'Yes',
            handler: () => {
              if (!this.global.isOffline) {
                this.checkStopforTrainingEntity();
              } else if (this.global.isOffline) {
                this.stopOffline();
              }
            },
          },
          {
            id: "ClockOutNo",
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
        ],
      });
      await alert.present();
    } else {
      message =
        'You are away from clients home, please enable the Community box to proceed!';
      const alert = await this.alertController.create({
        header: 'Clock Out Alert',
        message: message,
        backdropDismiss: false,
        inputs: [
          {
            name: 'Community',
            type: 'checkbox',
            label: 'Community',
          },
        ],
        buttons: [
          {
            id: "AwayCommunityYes",
            text: 'Yes',
            handler: (data) => {
              if (data.length > 0) {
                if (!this.global.isOffline) {
                  this.checkStopforTrainingEntity();
                } else if (this.global.isOffline) {
                  this.stopOffline();
                }
              } else {
                this.global.ToastDangerMiniDuration(
                  'Please Select Community Checkbox!'
                );
              }
            },
          },
          {
            id: "AwayCommunityNo",
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => { },
          },
        ],
      });
      await alert.present();
    }
  }
  createInputs() {
    const theNewInputs = [];
    for (let i = 0; i < this.InprogressActivitiesList.length; i++) {
      theNewInputs.push({
        type: 'checkbox',
        label: this.InprogressActivitiesList[i].value,
        value: '' + i,
        checked: this.InprogressActivitiesList[i].isSelected,
        disabled: false,
        handler: (data) => {
          this.InprogressActivitiesList[i].isSelected = false;
          if (data.label == this.InprogressActivitiesList[i].value) {
            this.InprogressActivitiesList[i].isSelected = data.checked;
          }
        },
      });
    }
    return theNewInputs;
  }
  async SelectActivites() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Activities',
      inputs: this.createInputs(),
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { },
        },
        {
          text: 'Save Activities',
          handler: () => {
            this.saveActivities();
          },
        },
      ],
    });
    await alert.present();
  }
  async saveActivities() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    var saveBO = new saveActivitiesBO();
    saveBO.activities = this.InprogressActivitiesList;
    saveBO.evvTsId = this.evvTimesheetId;
    this.timesheetService.saveTsActivities(saveBO).subscribe(
      (data) => {
        this.global.ToastSuccess1('Activities Saved Successfully');
        this.global.disableloading();
      },
      (err) => {
        this.global.disableloading();
        this.errorService.check(err);
        this.global.Toastdanger(err.message);
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  VerifyActivities(activities) {
    this.selected = false;
    activities.forEach((data) => {
      if (data.isSelected == true) {
        this.selected = true;
      }
    });
    return this.selected;
  }
 async GetActivitiesForVisit() {  
  await this.global.checkRefreshTokenValidity()
  {
    let param = new URLSearchParams();
    param.append('EvvId', this.evvTimesheetId.toString());
    param.append('ClientId', this.clientId.toString());
    param.append('masterServiceId', this.serviceId.toString());
    this.httpservice.GetActivitiesForVisit(param).subscribe(
      (data: any) => {
        this.InprogressActivitiesList = data;
        this.VerifyActivities(data);
        this.global.disableloading();
      },
      (err) => {
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      }
    );
    }
  }
  async GetStartCommunity() {


    if (!this.global.isOffline) {
      await this.global.checkRefreshTokenValidity()
      {
      await this.global.showLoading();
      let CommunityData = {
        ClientId: [parseInt(this.clientId.toString())],
        CallLatitude: this.lat != null ? parseFloat(this.lat) : null,
        CallLongitude: this.lng != null ? parseFloat(this.lng) : null,
      };
      this.clientService.GetCommunity(CommunityData).subscribe(
        (data: any) => {
          this.isCommunity = data;
          this.isClockIn = false;
          this.global.disableloading();
          this.startservice();
        },
        (err) => {
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
      }
    } else if (this.global.isOffline) {
      this.startservice();
    }
  }
  async GetStopCommunity() {


    if (!this.global.isOffline) {
      await this.global.checkRefreshTokenValidity()
      {
      await this.global.showLoading();
      let CommunityData = {
        ClientId: [parseInt(this.clientId.toString())],
        CallLatitude: this.lat != null ? parseFloat(this.lat) : null,
        CallLongitude: this.lng != null ? parseFloat(this.lng) : null,
      };
      this.clientService.GetCommunity(CommunityData).subscribe(
        (data: any) => {
          this.isCommunity = data;
          this.isClockOut = false;
          this.global.disableloading();
          this.stopservice();
        },
        (err) => {
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
      }
    } else if (this.global.isOffline) {
      this.stopservice();
    }
  }
  // Android
  getBackGroundStartLocationandroid() {
    this.Accuracy = null;
    this.platform.ready().then(async () => {
      BackgroundGeolocation.start().then((state) => {
        this.global.disableloading();
        this.isClockIn = false;
        BackgroundGeolocation.onLocation((location) => {
          if (this.backGroundLocationStartCount == 0) {
            this.Accuracy =
              location.coords.accuracy != null
                ? parseInt(location.coords.accuracy.toString())
                : null;
            this.lat = location.coords.latitude;
            this.lng = location.coords.longitude;
            this.backGroundLocationStartCount++;
            this.GetStartCommunity();
            this.global.disableloading();
          }
        }, (error) => {
          this.global.disableloading();
          BackgroundGeolocation.stop()
          this.isClockIn = false;
        });
      }).catch((err) => {
        BackgroundGeolocation.stop()
        this.global.disableloading();
        this.isClockIn = false;
      });;
    }).catch((err) => {
      BackgroundGeolocation.stop()
      this.global.disableloading();
      this.isClockIn = false;
    });
  }
  getBackGroundStopLocationandroid() {
    this.Accuracy = null;
    this.platform.ready().then(async () => {
      BackgroundGeolocation.start().then((state) => {
        BackgroundGeolocation.onLocation((location) => {
          if (this.backGroundLocationStopCount == 0) {
            this.Accuracy =
              location.coords.accuracy != null
                ? parseInt(location.coords.accuracy.toString())
                : null;
            this.lat = location.coords.latitude;
            this.lng = location.coords.longitude;
            this.backGroundLocationStopCount++;
            this.GetStopCommunity();
            this.global.disableloading();
          }
        }, (error) => {
          this.global.disableloading();
          BackgroundGeolocation.stop()
          this.isClockOut = false;
        });
      }).catch((err) => {
        this.global.disableloading();
        BackgroundGeolocation.stop()
        this.isClockOut = false;
      });;
    }).catch((err) => {
      this.global.disableloading();
      BackgroundGeolocation.stop()
      this.isClockOut = false;
    });
  }

  // IOS
  getBackGroundStartLocationiOS() {
    this.Accuracy = null;
    let backGroundLocationStartCount = 0;
    this.platform
      .ready()
      .then(async () => {

        BackgroundGeolocation.getCurrentPosition({
          timeout: 30,
        }).then((location) => {

          if (backGroundLocationStartCount == 0) {

            this.Accuracy =
              location.coords.accuracy != null
                ? parseInt(location.coords.accuracy.toString())
                : null;
            this.lat = location.coords.latitude;
            this.lng = location.coords.longitude;
            backGroundLocationStartCount++;
            BackgroundGeolocation.stop();
            this.GetStartCommunity();
            this.global.disableloading();
          }
        }).catch((err) => {
          this.global.disableloading();
          this.global.ToastDangerMiniDuration("Turn on location");
          this.isClockIn = false;

        });
      })
      .catch((err) => {
        this.global.disableloading();
        this.global.ToastDangerMiniDuration("turn on location")
        this.isClockIn = false;

      });
  }
  getBackGroundStopLocationiOS() {
    this.Accuracy = null;
    let backGroundLocationStopCount = 0;

    this.platform
      .ready()
      .then(async () => {
        BackgroundGeolocation.getCurrentPosition({
          timeout: 30,
        }).then((location) => {

          if (backGroundLocationStopCount == 0) {

            this.Accuracy =
              location.coords.accuracy != null
                ? parseInt(location.coords.accuracy.toString())
                : null;
            this.lat = location.coords.latitude;
            this.lng = location.coords.longitude;
            backGroundLocationStopCount++;
            BackgroundGeolocation.stop();
            this.GetStopCommunity();
            this.global.disableloading();
          }
        }).catch((err) => {
          this.global.disableloading();
          this.global.ToastDangerMiniDuration("Turn on location");
          this.isClockOut = false;

        });
      })
      .catch((err) => {
        this.global.disableloading();
        this.global.ToastDangerMiniDuration("Turn on location");
        this.isClockOut = false;

      });
  }

  async AuthValidationForOverNightRecords(Type) {
    await this.global.checkRefreshTokenValidity()
    {
   if (this.clientAuthId!=null && this.clientAuthId!=0 && this.clientAuthId!=undefined)
   {
    let data = {
      clientAuthorizationId: this.clientAuthId,
      startDate: this.global.timezone == "Minnesota" ?  this.startTime:this.global.getDateWithUTC(this.startTime),
      endDate: this.global.timezone == "Minnesota" ?  Type=="start"?null:this.endTime:Type=="start"?null:this.global.getDateWithUTC(this.endTime),
    }
    this.clientService.AuthValidationForOverNightRecords(data).subscribe(
      (data) => {
      },
      (err) => {
        this.showOvernightError(err.message);
      }
    )
  }
}
  }
  async showOvernightError(err)
  {
    const alert = this.alertController.create({
      header: 'Warning',
      message: err,
      buttons: [
        {
          text: 'Ok',
          handler: (data) => {
          },
        },
      ],
      backdropDismiss: false,
    });
    await (await alert).present();
  }
}
