import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globalservice } from 'src/app/global.service';
import { LanguageService } from 'src/app/language.service';
@Component({
  selector: 'app-client-login',
  templateUrl: './client-login.page.html',
  styleUrls: ['./client-login.page.scss'],
})
export class ClientLoginPage implements OnInit {
  constructor(public global:Globalservice,public languageService:LanguageService,
    public router: Router,
    ) { }
  ngOnInit() {
    if(this.global.EvvUserDetails.setLanguage!=null){
      this.languageService.setLanguage(this.global.EvvUserDetails.setLanguage);
    }
    else{
      this.languageService.setLanguage("en");
      this.router.navigate(['/client/client-languages'])
    }
  }
}
