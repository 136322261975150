import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  constructor(private httpClient: HttpClient, public errorService: ErrorService, public global: Globalservice, private handler: HttpBackend) { }
  public resetUserPassword(param: URLSearchParams): Observable<any> {
    let url = 'api/EvvCredential/resetUserPassword?';
    return this.httpClient.get(url + param, { observe: 'response', responseType: "text" },).pipe(catchError(this.errorService.handleError));
  }
 
}
