import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { ClientActiveSessionService } from './client-active-session.service';
@Component({
  selector: 'app-client-active-session',
  templateUrl: './client-active-session.page.html',
  styleUrls: ['./client-active-session.page.scss'],
})
export class ClientActiveSessionPage implements OnInit {
  ActiveSessionList: any = [];
  islogoutOfAllSessions: boolean = false;
  constructor(public modalController: ModalController, public clientActivesessionService: ClientActiveSessionService, public global: Globalservice,
    public errorService: ErrorService, private datePipe: DatePipe, public router: Router, public http: HttpClient) { 
      this.global.checkingLogin();
    }
  ngOnInit() {
  }
  async ionViewWillEnter() {
    await this.global.checkRefreshTokenValidity()
    {
    this.getAllOpenSessions();
    }
  }
  async getAllOpenSessions() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    let params = new URLSearchParams();
    params.append("refreshToken", this.global.refreshTokenString);
    this.clientActivesessionService.getAllOpenSessions(params).subscribe((data: any) => {
      this.ActiveSessionList = data;
      this.global.disableloading();
      this.ActiveSessionList.forEach(element => {
        element.loginTime = this.datePipe.transform(element.loginTime, "MM/dd/yyyy HH:mm:ss");
        element.lastUsedTime=this.datePipe.transform(element.lastUsedTime, "MM/dd/yyyy HH:mm:ss");
        
      });
    },
      (err: any) => {
        if (err) {
          this.global.disableloading();
          this.global.Toastdanger(err), 8000;
        }
      })
    }
  }
  async revokeSessions(activeFields) {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    let params = new URLSearchParams();
    params.append("refreshToken", this.global.refreshTokenString);
    this.clientActivesessionService.revokeSessions([activeFields.id],params).subscribe((data: any) => {
      this.global.ToastSuccess('Session Revoked SuccessFully!');
      this.global.disableloading();
      this.getAllOpenSessions();
    },
      (err: HttpErrorResponse) => {
        this.global.disableloading()
        this.global.Toastdanger(err.message);
      });
    }
  }


  async logoutSession() {
    await this.global.checkRefreshTokenValidity()
    {
    let url = "api/Auth/logout?"
    let params = new URLSearchParams();
    this.islogoutOfAllSessions=true;
    params.append("revokeAllSessions",  JSON.stringify(this.islogoutOfAllSessions))
    params.append("refreshToken", this.global.refreshTokenString);
    await this.global.showLoading();
    this.http.post(url + params, null).pipe().subscribe((data: any) => {
      this.global.disableloading();
      this.global.ToastSuccess('Logout Successful');
      this.logout();
    },
      (err: HttpErrorResponse) => {
        this.global.disableloading();
        this.global.ToastSuccess('Logout Successful');
        this.logout();
      })
    }
  }
  logout() {
    
    localStorage.clear();
    this.router.navigate(['/login'], { replaceUrl: true })
  }
}
