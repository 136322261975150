import { Component, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ActionSheetController, AlertController, LoadingController, PopoverController, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { scheduleDashboardEmployeeBO } from '../employeescheduledashboard/employeescheduledashboard.model';
import { MyEmployeeCurrentDashboardService } from './employeecurrentdashboard.service';
import { Storage } from "@ionic/Storage";
import { SQLite } from '@ionic-native/sqlite/ngx';
import { IonRefresher } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import { DashboardHourDetailsPage } from '../dashboard-hour-details/dashboard-hour-details.page';
import { MyClientsService } from '../my-clients/my-clients.service';
import { DatePipe } from '@angular/common';
import { Services } from '../my-clients/my-clients.model';
@Component({
  selector: 'app-employecurrentdashboard',
  templateUrl: './employecurrentdashboard.page.html',
  styleUrls: ['./employecurrentdashboard.page.scss'],
})
export class EmployecurrentdashboardPage {
  showLoadingweekly: boolean = true;
  showLoadingmonthly: boolean = true;
  monthlyHours: any;
  weeklyHours: any;
  servicesList: any = [];
  ShowLoading: boolean = true;
  serviceName: any = '';
  SharedCareClientsRecordLists: any = [];
  selectedShareCareClientIds: any = [];
  whourdata: any;
  isShowServiceAuthorization: boolean = false;
  mhourdata: any;
  ratio: any = "";
  scheduleList: Array<scheduleDashboardEmployeeBO> = [];
  count: number = 0;
  UnConfirmedUnits: any = "";
  ConfirmedUnits: any = "";
  datanotfound: boolean = false;
  servicesListContent = [];
  serviceNameforServiceAuth: string = ""
  RemainingUnits: string;
  payorId: number;
  RemainingHoursPerDayBasedOnCurrentDate: string = "";
  RemainingHoursPerDayBasedOnLastVisitDate: string = "";
  RemainingHoursPerWeek: string = ""
  RemainingHours: any = ""
  elementList: any = [];
  @ViewChild("refresherRef") refresherRef: IonRefresher;
  database: any = [];
  ClientDataList: any = [];
  offlinedata: any = [];
  remaingHours: any;
  acceptLoading = null;
  serviceGroupId: number;
  clientAuthId: number;
  dashboardLoad = null;
  isOffline: boolean = false;
  constructor(public alertController: AlertController, public datePipe: DatePipe, public CS: MyClientsService, public clientService: MyEmployeeCurrentDashboardService, public errorService: ErrorService, public actionSheetCtrl: ActionSheetController,
    public global: Globalservice, private router: Router,
    public general: generalservice, public toastCtrl: ToastController, public popoverController: PopoverController,
    public storage: Storage, private sqlite: SQLite, private network: Network,
    public loadingCtrl: LoadingController,) {
    this.network.onConnect().subscribe(() => {
      this.isOffline = false;
    })
    this.network.onDisconnect().subscribe(() => {
      this.isOffline = true;
    })
    this.global.checkingLogin();
  }
  async doRefresh(refresher: IonRefresher) {
    await setTimeout(() => {
      this.storage.remove("OfflineDate");
      this.ionViewWillEnter()
      this.refresherRef.complete();
    }, 1000);
  }
  async ionViewWillEnter() {
    this.RemainingHoursPerDayBasedOnLastVisitDate = null
    this.RemainingHours = null;
    this.isShowServiceAuthorization = false;
    this.serviceNameforServiceAuth = null;
    this.RemainingHoursPerDayBasedOnCurrentDate = null;
    this.RemainingUnits = null;
    this.UnConfirmedUnits = null;
    this.ConfirmedUnits = null;
    await this.global.checkRefreshTokenValidity()
    {
      this.getweeklyHours();
      this.getMonthlyHours();
      this.getClientsSchduleDashboard();
      this.global.getServiceType();
    }
  }
  async getweeklyHours() {
    let params = new URLSearchParams();
    params.append("EmployeeId", this.global.EvvUserDetails.entityId.toString());
    await this.global.checkRefreshTokenValidity()
    {
      this.clientService.getWeeklyHours(params).subscribe((data: any) => {
        this.whourdata = data;
        this.showLoadingweekly = false;
        let valweeks = data.totalHours.toString().split('.');
        if (valweeks.length == 1) {
          this.weeklyHours = valweeks[0] + " hr " + "0 Min";
        }
        else {
          let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
          this.weeklyHours = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + " Min";
        }
      })
    }
  }
  async getMonthlyHours() {
    let params = new URLSearchParams();
    params.append("EmployeeId", this.global.EvvUserDetails.entityId.toString());
    await this.global.checkRefreshTokenValidity()
    {
      this.clientService.getMonthlyHours(params).subscribe((data: any) => {
        this.mhourdata = data;
        this.showLoadingmonthly = false;
        let valweeks = data.totalHours.toString().split('.');
        if (valweeks.length == 1) {
          this.monthlyHours = valweeks[0] + " hr " + "0 Min";
        }
        else {
          let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
          this.monthlyHours = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + " Min";
        }
      })
    }
  }
  async getClientsSchduleDashboard() {
    let param = new URLSearchParams();
    this.ShowLoading = true;
    param.append("EmployeeId", this.global.EvvUserDetails.entityId.toString());
    await this.global.checkRefreshTokenValidity()
    {
      this.clientService.getDashboardDetails(param).subscribe((data: Array<scheduleDashboardEmployeeBO>) => {
        this.scheduleList = data;
        if (data.length > 0) {
          this.scheduleList.forEach(c => c.employeePhnFormat = c.employeePhn != null ? this.general.reconverPhoneGoogleLibhttpsave(c.employeePhn.toString()) : null)
          this.datanotfound = false;
        }
        else {
          this.datanotfound = true;
        }
        this.ShowLoading = false;
      }, err => {
        this.errorService.check(err);
        this.ShowLoading = false;
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
  async presentActionSheet(scheduleData) {

    this.isShowServiceAuthorization = true;
    this.ClientDataList = scheduleData;

    let valweeks = scheduleData.remaingHours.toString().split('.');
    if (valweeks.length == 1) {
      scheduleData.remaininghrs = valweeks[0] + " hr " + "0 Min";
    }
    else {
      let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
      scheduleData.remaininghrs = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + "Min";
    }
    this.servicesListContent = [];
    if (!this.global.offline) {
      this.servicesListContent.push({
        text: scheduleData.groupName + "  " + scheduleData.remaininghrs + '(remaining)',
        id: scheduleData.serviceId,
        icon: '',
        handler: () => {
          if (scheduleData.remaingHours > 0) {
            let navigationExtras: NavigationExtras = {
              state: {
                clientId: scheduleData.clientId,
                serviceId: scheduleData.serviceId,
                startTime: scheduleData.startTime,
                scheduleId: scheduleData.scheduleId,
                isStarted: scheduleData.isStarted
              }
            }
            this.router.navigate(['/tabs/employeescheduleservice'], navigationExtras)
          } else {
            this.global.Toastdanger("No authorizations found. Please contact office.");
          }
        }
      })
    }
    else {
      this.servicesListContent.push({
        text: scheduleData.Service,
        id: scheduleData.ServiceId,
        icon: '',
        handler: () => {
          if (scheduleData.remaingHours > 0) {
            let navigationExtras: NavigationExtras = {
              state: {
                clientId: scheduleData.ClientId,
                serviceId: scheduleData.ServiceId,
                startTime: scheduleData.StartTime,
                scheduleId: scheduleData.ScheduleId,
                isStarted: scheduleData.IsStarted
              }
            }
            this.router.navigate(['/tabs/employeescheduleservice'], navigationExtras)
          } else {
            this.global.Toastdanger("No authorizations found. Please contact office.");
          }
        }
      })
    }
    this.servicesListContent.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
      handler: () => {
      }
    })
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Select Service',
      cssClass: 'my-custom-class',
      buttons: this.servicesListContent
    });
    await actionSheet.present();
  }
  //=================Cancel  requested appointment
  async cancelRequest(clientData: scheduleDashboardEmployeeBO) {

    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
      this.clientService.cancelClientAppt(clientData).subscribe((data: any) => {
        this.presentToast('Schedule has been cancelled successfully');
        this.getClientsSchduleDashboard();
        this.global.disableloading();
      }, err => {
        this.errorService.check(err);
        this.global.disableloading()
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'success',
    });
    await toast.present();
  }
  inProgress(clientData: scheduleDashboardEmployeeBO) {
    if (clientData.hasNonScheduleTs == true) {
      let navigationExtras: NavigationExtras = {
        state: {
          clientId: clientData.clientId,
          serviceId: clientData.serviceId,
          startTime: clientData.startTime,
          offlineTsId: clientData.evvTimesheetId
        }
      }
      this.router.navigate(['/tabs/service'], navigationExtras)
    } else {
      let navigationExtras: NavigationExtras = {
        state: {
          clientId: clientData.clientId,
          serviceId: clientData.serviceId,
          startTime: clientData.startTime,
          isStarted: clientData.isStarted,
          // scheduleId: clientData.scheduleId
          scheduleId: clientData.appointmentId
        }
      }
      this.router.navigate(['/tabs/employeescheduleservice'], navigationExtras)
    }
  }
  async presentPopover(ev: any, type) {
    let val: any;
    if (type == 'm') {
      val = this.mhourdata;
    } else if (type == 'w') {
      val = this.whourdata;
    }
    const popover = await this.popoverController.create({
      component: DashboardHourDetailsPage,
      componentProps: { vals: val },
      cssClass: 'popOverClass',
      event: ev,
    });
    await popover.present();
  }
  async getService(clientData) {

    if (!this.global.isOffline) {
      let param = new URLSearchParams();
      param.append("Client", clientData.clientId.toString())
      param.append("DefaltServiceId", clientData.defaultServieId.toString())
      this.servicesListContent = [];
      await this.global.checkRefreshTokenValidity()
      {
        await this.global.showLoading()
        this.CS.getServices(param).subscribe(async (data: Array<any>) => {
          this.global.disableloading();
          data.forEach(element => {
            let valweeks = element.remaingHours.toString().split('.');
            if (valweeks.length == 1) {
              element.remaininghrs = valweeks[0] + " hr " + "0 Min";
            }
            else {
              let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
              element.remaininghrs = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + "Min";
            }

            this.servicesListContent.push({
              text: element.serviceName + '  -  ' + element.remaininghrs + ' (remaining)',
              id: element.serviceId,
              icon: '',
              handler: () => {
                this.VerifyService(clientData, element)
              }
            })
          });

          this.servicesListContent.push({
            text: 'Cancel',
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          })
          const actionSheet = await this.actionSheetCtrl.create({
            header: 'Select Service',
            cssClass: 'my-custom-class',
            buttons: this.servicesListContent
          });
          await actionSheet.present();

        }, err => {
          this.global.disableloading();
          this.errorService.check(err);
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        })
      }
    }
  }

  // ..................backtoMyclient...............................................
  backtoMyclient() {
    this.isShowServiceAuthorization = false;
    this.ionViewWillEnter();
  }
  // ..................selectService...............................................
  async selectService(clientData) {
    clientData = this.ClientDataList;
    let param = new URLSearchParams();
    this.global.showLoading()
    param.append("Client", clientData.clientId.toString())
    param.append("EmployeeId", this.global.EvvUserDetails.entityId.toString())
    this.servicesListContent = [];
    await this.global.checkRefreshTokenValidity()
    {
      this.CS.getServices(param).subscribe(async (data: Array<Services>) => {
        this.global.disableloading();
        this.servicesList = data;
        data.forEach(element => {
          let valweeks = element.remaingHours.toString().split('.');
          if (valweeks.length == 1) {
            element.remaininghrs = valweeks[0] + " hr " + "0 Min";
          }
          else {
            let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
            element.remaininghrs = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + "Min";
          }
          this.RemainingHoursPerDayBasedOnLastVisitDate = null
          this.RemainingHours = null;
          this.serviceNameforServiceAuth = null;
          this.RemainingHoursPerDayBasedOnCurrentDate = null;
          this.RemainingUnits = null;
          this.UnConfirmedUnits = null;
          this.ConfirmedUnits = null;
          this.servicesListContent.push({
            text: element.serviceName,
            id: element.serviceId,
            icon: '',
            handler: () => {
             

              this.serviceGroupId = element.serviceGroupId,
                this.RemainingHours = element.remaingHours;
              this.getServicesWithAuthorizationForServiceGroup(element);
            }
          })
        });
        this.servicesListContent.push({
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {

          }
        })
        const actionSheet = await this.actionSheetCtrl.create({
          header: 'Select Service',
          cssClass: 'my-custom-class',
          buttons: this.servicesListContent
        });
        await actionSheet.present();


      }, err => {
        this.global.disableloading()
        this.errorService.check(err);

        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
  // ..................getServicesWithAuthorizationForServiceGroup...............................................
  async getServicesWithAuthorizationForServiceGroup(element) {
    this.global.showLoading();
    let authorizationRequestPayload =
    {
      startDate: this.global.timezone == "Minnesota" ? this.global.getDateTimeString(new Date()) : this.global.getDateWithUTCFormat(new Date()),
      endDate: this.global.timezone == "Minnesota" ? this.global.getDateTimeString(new Date()) : this.global.getDateWithUTCFormat(new Date()),
      serviceGroupId: element.serviceGroupId != null && element.serviceGroupId != undefined && element.serviceGroupId != 0 ?
        parseInt(element.serviceGroupId.toString()) : 0,
      clientId: parseInt(this.ClientDataList.clientId),
      serviceTypeLid: this.global.serviceType?.filter((st) => st.Value == "HOMECARE")[0]?.Key
    }
    this.servicesListContent = [];
    await this.global.checkRefreshTokenValidity()
    {
      this.CS.getServicesWithAuthorizationForServiceGroup(authorizationRequestPayload).subscribe(async (data: any) => {
        this.global.disableloading();
        if (data.exception.length > 0) {
          this.global.Toastdanger(data.exception);
          return;
        }
        data.response.forEach(element => {
          this.servicesListContent.push({

            text: this.global.timezone == "Minnesota" ? element.masterServiceName + " -" + element.serviceDescription + ' (' + this.datePipe.transform(element.startDate, "MM/dd/yyyy") + " " + "to" + " " + this.datePipe.transform(element.endDate, "MM/dd/yyyy") + ')' : element.masterServiceName + " -" + element.serviceDescription + ' (' + this.datePipe.transform(element.startDate.split("T")[0], "MM/dd/yyyy") + " " + "to" + " " + this.datePipe.transform(element.endDate.split("T")[0], "MM/dd/yyyy") + ')',
            id: element.serviceId,
            icon: '',
            handler: () => {
              this.getDetails(element)

            }
          })
        });
        this.servicesListContent.push({
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
        })
        const actionSheet = await this.actionSheetCtrl.create({
          header: 'Select Service',
          cssClass: 'my-custom-class',
          buttons: this.servicesListContent
        });
        await actionSheet.present();

        this.global.disableloading()
      }, err => {
        this.global.ToastDangerMiniDuration(err.message);
        this.global.disableloading()
        this.errorService.check(err);
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
  // ..................getDetails...............................................
  getDetails(element) {
    this.serviceNameforServiceAuth = element.masterServiceName;
    this.clientAuthId = element.clientAuthId;
    this.RemainingUnits = element.remainingUnits;
    this.ConfirmedUnits = element.confirmedUnits;
    this.UnConfirmedUnits = element.unconfirmedUnits;
    this.payorId = element.payorId;
    // remainingHoursPerDay
    let value1 = element?.remainingHPDBasedOnCurrentDate?.toString().split('.');
    if (value1.length == 1) {
      this.RemainingHoursPerDayBasedOnCurrentDate = value1[0] + " hr " + "0 Min";
    }
    else {
      let value2 = 0 + "." + value1[value1.length - 1];
      this.RemainingHoursPerDayBasedOnCurrentDate = value1[0] + " hr " + (parseFloat(value2) * 60).toString().split('.')[0] + "Min";
    }
    // remainingHoursPerWeek
    let value3 = element?.remainingHPDBasedOnLastVisitDate?.toString().split('.');
    if (value3.length == 1) {
      this.RemainingHoursPerDayBasedOnLastVisitDate = value3[0] + " hr " + "0 Min";
    }
    else {
      let value4 = 0 + "." + value3[value3.length - 1];
      this.RemainingHoursPerDayBasedOnLastVisitDate = value3[0] + " hr " + (parseFloat(value4) * 60).toString().split('.')[0] + "Min";
    }
    this.elementList = element;
  }

  // ..................gotoServiceInfo...............................................
  gotoServiceInfo(clientData, element) {
    clientData = this.ClientDataList
    element = this.elementList
    this.checkRemainingUnits(clientData, element)
  }


  getServiceforemployeeSchedule(clientData) {
    this.isShowServiceAuthorization = true;
    this.ClientDataList = clientData;
  }
  VerifyService(clientData, element) {
    this.payorId = element.payorId;
    element.serviceId = element.masterServiceId;
    let navigationExtras: NavigationExtras = {
      state: {
        clientId: clientData.clientId,
        serviceId: element.serviceId,
        startTime: clientData.startTime,
        payorId: this.payorId,
        clientAuthId: element.clientAuthId,
        serviceGroupId: this.serviceGroupId,
        scheduleId: clientData.appointmentId,
        isStarted: clientData.isStarted
      }
    }
   

    if (this.RemainingHours > 0) {
      element.serviceId = element.masterServiceId;
      this.serviceName = element.serviceName;
      if (element.ratio > 1 && !this.global.isOffline) {
        this.EmpSignAgreement(navigationExtras, element.serviceId, clientData.clientId, element.ratio)
      } else {
        this.router.navigate(['/tabs/employeescheduleservice'], navigationExtras)
      }
    } else {
      this.global.Toastdanger('No units Remaining.. Contact office',)
    }
  }
  async EmpSignAgreement(navigationExtras, serviceId, clientId, ratio) {
    const alert = await this.alertController.create({
      header: 'Shared Care',
      message: 'Do you want to start Shared Care Services',
      buttons: [
        {
          text: 'No Thanks',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            // this.router.navigate(['tabs/my-clients'])
          }
        }, {
          text: 'Proceed',
          handler: () => {
            this.getSharedCareClientsAuthorizationDetails(clientId);
          }
        }
      ]
    });
    await alert.present();
  }
  async getSharedCareClientsAuthorizationDetails(clientId) {
    this.global.showLoading();
    this.servicesListContent = [];
    var GroupingClientRecords = [];
    var ShareCareGroupedClients = [];
    await this.global.checkRefreshTokenValidity()
    {
      this.CS.getSharedCareClientsAuthorizationDetails(clientId, this.serviceGroupId).subscribe(async (data: any) => {
        this.global.disableloading()
        if (data.exception.length > 0) {
          this.global.Toastdanger(data.exception);
          return;
        }

        GroupingClientRecords = data.response.reduce((group, record) => {
          group[record.clientId] = group[record.clientId] || [];
          group[record.clientId].push(record);
          return group;
        }, {});

        ShareCareGroupedClients = Object.keys(GroupingClientRecords).map((key) => {
          return { clientId: parseInt(key), clientName: GroupingClientRecords[key][0].clientName, serviceGroup: GroupingClientRecords[key] };
        });

        this.SharedCareClientsRecordLists = ShareCareGroupedClients

        this.ratio = data.response[0]?.ratio;
        this.getClientRecordsForSharedCare();
        this.global.disableloading()
      }, err => {
        this.global.ToastDangerMiniDuration(err.message);
        this.global.disableloading()
        this.errorService.check(err);

        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
  async getClientRecordsForSharedCare() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Select Clients',
      inputs: this.createSharedCareInputs(),
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: data => {
            if (data.length > 0) {
              this.RouteToSharedCare();
            }
            else {
              this.global.ToastDangerMiniDuration("select shared clients")
            }
          }
        }
      ]
    });
    await alert.present();
  }

  createSharedCareInputs() {
    const theNewInputs = [];
    for (let i = 0; i < this.SharedCareClientsRecordLists.length; i++) {
      theNewInputs.push(
        {
          type: 'checkbox',
          label: this.SharedCareClientsRecordLists[i].clientName,
          value: '' + i,
          checked: this.SharedCareClientsRecordLists[i].isSelected,
          handler: data => {
            this.SharedCareClientsRecordLists[i].isSelected = data.checked;
          }
        }
      );
    }
    return theNewInputs;
  }

  RouteToSharedCare() {
    this.selectedShareCareClientIds = [];
    this.SharedCareClientsRecordLists?.forEach(clientRecord => {
      if (clientRecord.isSelected == true) {
        this.selectedShareCareClientIds.push(clientRecord);
      }
    })
    if (this.selectedShareCareClientIds.length <= this.ratio) {
      let navigationExtras: NavigationExtras = {
        state: {
          service: this.serviceName,
          isStarted: false,
          serviceGroupId: this.serviceGroupId,
          sharedCareRecords: this.selectedShareCareClientIds
        }
      }
      this.selectedShareCareClientIds.forEach(element => {
        element.serviceGroup.forEach((ele, index) => {
          if (ele.masterServiceName != this.serviceNameforServiceAuth) {

            element.serviceGroup.splice(index, 1);
          }
        });

      });
      this.router.navigate(['/tabs/sharedcareClients'], navigationExtras)
    }
    else {
      this.presentToast('No.of selected clients must be less than or equal to ' + this.ratio)
    }
  }


  async checkRemainingUnits(clientData, element) {
    let param = new URLSearchParams();

    param.append('remainingUnits', element.remainingUnits);
    param.append('billingUnit', element.billingLov);
    await this.global.checkRefreshTokenValidity()
    {
      this.CS.checkRemainingUnits(param).subscribe(
        (data: any) => {
          if (data.body != null && data.body != undefined && data.body != "") {
            this.getErrorNotesforRemainingUnits(data.body, clientData, element)
          }
          else {
            this.VerifyService(clientData, element)
          }
        },
        (err) => {
          this.global.Toastdanger(err.message)
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }


  async getErrorNotesforRemainingUnits(message, clientData, element) {
    const alert = await this.alertController.create({
      header: 'Warning',
      message: message,
      buttons: [
        {
          id: "NotesStopSharedError",
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          id: "okorsaveActivies",
          text: 'Ok',
          handler: () => {
            this.VerifyService(clientData, element)
          }
        }
      ],

      backdropDismiss: false
    });
    await alert.present();


  }
}
