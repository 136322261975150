import { Component } from '@angular/core';
import { Globalservice } from '../global.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  constructor(public global:Globalservice) { 
  }
}
