import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { EmployeeServiceUnitsService } from './employeeserviceunits.service';
@Component({
  selector: 'app-employeeserviceunits',
  templateUrl: './employeeserviceunits.page.html',
  styleUrls: ['./employeeserviceunits.page.scss'],
})
export class EmployeeserviceunitsPage implements OnInit {
  EmployeeServiceUnitsList: any = [];
  constructor(public modalController: ModalController, public ESU: EmployeeServiceUnitsService,
    public global: Globalservice,
    public errorService: ErrorService, private datePipe: DatePipe, public router: Router, public http: HttpClient) {
    this.global.checkingLogin();
  }
  ngOnInit() {
  }
  async ionViewWillEnter() {
    await this.global.checkRefreshTokenValidity()
    {
    this.getEmployeeServiceUnits();
    }
  }
  async getEmployeeServiceUnits() {
   
    let params = new URLSearchParams();
    params.append("EmployeeId", this.global.EvvUserDetails.entityId.toString());
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.ESU.getEmployeeServiceUnits(params).subscribe((data: any) => {
      data.forEach(element => {
        element.serviceunitDetails.forEach(servicedetails => {
          servicedetails.totalunits=servicedetails.unitsRemaining+servicedetails.unitsUsed;
          servicedetails.startTime = servicedetails.startTime.split('T')[0];
          servicedetails.endTime = servicedetails.endTime.split('T')[0];
        });
      });
      this.EmployeeServiceUnitsList = data;
      this.global.disableloading();
    },
      err => {
        this.global.Toastdanger(err.message);
        this.global.disableloading()
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
}



