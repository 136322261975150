import { Injectable } from "@angular/core";
import { Storage } from "@ionic/Storage";
import { TranslateService } from "@ngx-translate/core";
import { Globalservice } from "src/app/global.service";


const LNG_KEY = "SELECTED_LANGUAGE";

@Injectable({
	providedIn: "root",
})
export class ClientLanguageService  {
	selected = "";

	constructor(private translate: TranslateService, private storage: Storage,public global:Globalservice) {}

	// sets default language as browser language if no other language choice made
	// if language language selected then this value is stored using the ionic storage module
	setInitialAppLanguage() {
		const language = this.translate.getBrowserLang();
		this.translate.setDefaultLang(language);

		this.storage.get(LNG_KEY).then((val) => {
			if (val) {
				this.setLanguage(val);
				this.selected = val;
			
				this.global.languageSelected=val;
			localStorage.setItem("Evv_clientlanguage", JSON.stringify(this.global.languageSelected));    

			}
		});
	}

	getLanguages() {
		return [
			{ text: "English", value: "en" },
			{ text: "Spanish", value: "es" },
			{ text: "Russian", value: "ru" },
			{ text: "Hmong", value: "hmong" },
			{ text: "Somalian", value: "somalian" },
			{ text: "Arabic", value: "arabic" },
			{ text: "Vietnamese", value: "vietnamese" },
		];
	}

	// lng can be 'en', 'fr' or 'sp'
	setLanguage(lng) {
		
		this.translate.use(lng);
		this.selected = lng;
		this.storage.set(LNG_KEY, lng);
		

		
	}
}
