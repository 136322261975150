export class IclsListBo {
    id: number;
    clientId: number;
    agencyId: number | null;
    agencyName: string;
    clientName: string;
    clientDOB: string;
    clientCity: string;
    clientAddress: string;
    clientState: string;
    clientZipcode: string;
    clientEmail: string;
    clientPhone: string;
    clientPhone2: string;
    clientCounty: string;
  
    height: number | null;
    weight: number | null;
    clientHealthInsurance: boolean;
    clientHistory: string;
    goals: string;
    carePlanDate: string;
    allergies: string;
    serviceId: number | null;
    serviceName:string;
    emergencyContactName: string;
    emergencyContactPhone: string;
    legalRepresentativeName: string;
    legalRepresentativePhone: string;
    workOrientedClient: string;
    iCLSCoordinatorSign: string;
    iCLSWorkersSign: string;
    clientSign: string;
    iCLSCoordinatorSignUrl: string;
    iCLSWorkerSignUrl: string;
    clientSignUrl: string;
    iCLSCoordinatorSignDate: string | null;
    clientSignDate: string | null;
    iCLSWorkerSignDate: string | null;
    frequencyOfVisit: number | null;
    date: string;
    resuscitation: boolean | null;
    livingWill: boolean | null;
    dNRDNI: boolean | null;
    noCode: boolean | null;
    startOfDate: string;
    endOfDate: string;
    serviceActivity: ICLSCarePlanServiceActivitiesBO[];
}

export class ICLSCarePlanServiceActivitiesBO {
    id: number;
    iCLSCarePlanId: number;
    masterServiceActivityId: number;
    masterServiceActivityName: string;
    serviceActivitiesComment: string;
    sunday: boolean | null;
    monday: boolean;
    tuesday: boolean | null;
    wednesday: boolean | null;
    thursday: boolean | null;
    saturday: boolean | null;
    friday: boolean | null;
    asNeeded: boolean | null;
}

export class IclsSignatureBo {
    iclscoordinatorsign: string;
    iclsClientsign: string;
    iclsWokersign: string;
}
export class IclsExpandingBo {
    ProviderInformationBool: boolean = true;
    ClientInformationBool: boolean = false;
    EmergencyContactBool: boolean = false;
    LegalRepresentativeBool: boolean = false;
    ClientHistoryDiagnosisBool: boolean = false;
    AllergiesBool: boolean = false;
    CodeFreqBool: boolean = false;
    ServiceInformationBool: boolean = false;
    GoalsBool: boolean = false;
    WorkOrientedcoordinatoronBool: boolean = false;
    IclsCoordinatorBool: boolean = false;
    ClientBool: boolean = false;
    IclsWorkerBool: boolean = false;
}