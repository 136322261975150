
export class ManualETimesheetsSaveBO {
  clientId: number;
  employeeId: number;
  serviceId: number;
  templateId: number | null;
  templateTitle: string;
  totalhours: number;
  records: WeeklyManualETimesheetRecordsBO[];
  clientSign: string;
  employeeSign: string;
  isDayEntry:boolean=false;
  serviceRemaingHours: number | null;
  isRepresentative: boolean | null;
  clientAuthId:number;
  serviceGroupId:number;
  payorId: number;
  isLivein:boolean=false;
}

export class WeeklyManualETimesheetRecordsBO {
  weekTotalHours: number;
  weekRecords: ManualETimesheetsBO[];
}
export class ManualETimesheetsBO {
  formattedDate: string;
  dayOfWeek: string;
  date: string;
  totalhours: number;
  activeAuthorization:any;
  visits: ManualETimesheetDataBO[];
}
export class ManualETimesheetDataBO {
  templateDataId: number | null;
  start: any;
  end: any;
  hours: number;
  activities: ActivityLstBO[];
}
export class ActivityLstBO {
  activityId: number;
  value: string;
  isSelected: boolean;
}


