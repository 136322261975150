import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { Activity } from '../../my-clients/my-clients.model';
import { ClientSignAllTimesheetService } from './client-sign-all-timesheet.service';



@Component({
  selector: 'app-client-sign-all-timesheet',
  templateUrl: './client-sign-all-timesheet.page.html',
  styleUrls: ['./client-sign-all-timesheet.page.scss'],
})
export class ClientSignAllTimesheetPage {
  returnData: any;
  completed: boolean = false;
  clientData: any;
  clientSign: string = null;
  employeeSign: string = null;
  start: string;
  end: string;
  isRP: boolean = false;
  tempdata: any = [];
  isDifferentlyAbled: boolean = false;
  idList: any = [];
  constructor(public errorService: ErrorService,
    private router: Router, private route: ActivatedRoute, public global: Globalservice, public http: ClientSignAllTimesheetService,
    private toastCtrl: ToastController, public general: generalservice, public platform: Platform, public alertController: AlertController,
    public modalController: ModalController) {
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.clientData = data.data;
        this.tempdata = data
        this.start = data.data.startDate
        this.end = data.data.endDate
        this.clientSign = data.clientSign;
        this.returnData = data.returnData;
        this.employeeSign = data.employeeSign;
      }
    });
    this.global.checkingLogin();
  }

  async ionViewWillEnter() {
    await this.global.checkRefreshTokenValidity()
    {
    this.getweekly();
    }
  }

  async getweekly() {
    await this.global.checkRefreshTokenValidity()
    {
    if (this.tempdata.type == "timesheet") {
      this.idList = [];
      await this.global.showLoading();
      let param = new URLSearchParams();
      param.append("employeeId", this.clientData.employeeId.toString());
      param.append("clientId", this.global.EvvUserDetails.entityId.toString());
      param.append("startDate", this.start)
      param.append("endDate", this.end)
      this.http.getWeeklydata(param).subscribe((data: any) => {
        this.clientData = data.result;
        this.isDifferentlyAbled=data.result.isDifferentlyAbled;
        this.employeeSign = this.clientData.employeeSign != null ? this.clientData.employeeSign : this.employeeSign;
        this.clientSign = this.clientData.clientSign != null ? this.clientData.clientSign : this.clientSign;
        this.isRP = this.clientData.isRp;
        
        this.clientData.mobileNumberFormat = this.clientData.mobileNumber != null ? this.general.converPhoneGoogleLib(this.clientData.mobileNumber.toString()) : null
        this.clientData.records.forEach(c => {
          this.activitiesList = c.activities;
          this.VerifyActivities(c.activities);

          let valweeks = c.totalHours.toString().split('.');
          if (valweeks.length == 1) {
            c.totalHours = valweeks[0] + " hr " + "0 Min";
          }
          else {
            let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
            c.totalHours = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + " Min";
          }
          this.idList.push(c.evvTimesheetId);
        })
        this.global.disableloading()

      }, err => {
        this.errorService.check(err);
        this.global.disableloading()
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
    this.verifyTSCreation();
  }
  }
  selected: boolean = false;
  VerifyActivities(activities) {
    this.selected = false;
    activities.forEach(data => {
      if (data.isSelected == true) {
        this.selected = true;
      }
    });
    return this.selected;
  }
  TsType: any;
  activitiesList: Array<Activity> = [];
  createInputs(activities) {
    const theNewInputs = [];
    for (let i = 0; i < activities.length; i++) {
      theNewInputs.push(
        {
          type: 'checkbox',
          label: activities[i].value,
          value: '' + i,
          checked: activities[i].isSelected,
          disabled: true,
          handler: data => {

            if (data.label == activities[i].value) {
              activities[i].isSelected = data.checked;
            }

          }
        }
      );

    }
    return theNewInputs;
  }
  async SelectActivites(activities) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Activities',
      inputs: this.createInputs(activities),
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',

          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: 'Ok',
          handler: () => {

          }
        }
      ]
    });
    await alert.present();
  }
  back() {
    this.isRP = false;
    this.router.navigate(['/client/client-all-timesheets'])
  }
  sign(signType: number) {
    let navigationExtras: NavigationExtras = {
      state: {
        data: this.clientData,
        clientSign: this.clientSign,
        employeeSign: this.employeeSign,
        signType: signType,
        type: "all-sign",
        idList: this.idList,
        isRp: this.isRP
      }
    }
    this.router.navigate(['/client/client-signature'], navigationExtras)
  }


  async complete() {
    this.router.navigate(['/client/client-all-timesheets'])
  }
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'danger',

    });



    toast.present();
  }
  async CliSignAgreement() {
    const alert = await this.alertController.create({
      header: 'Acknowledgement',
      backdropDismiss: false,
      message: 'Review the completed time sheet for accuracy before signing.It is a federal crime to provide false information on personal care service billings for Medical Assistance payment. By signing below, you swear and verify the time and services entered above are accurate and that the services were performed by the PCA listed below as specified in the PCA Care Plan.',
       buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: 'Agree',
          handler: () => {

            this.sign(1);

          }
        }
      ]
    });
    await alert.present();
  }


  verifyTSCreation() {
    if (this.returnData != null) {

      if (this.returnData.TSCreated == true) {
        this.global.disableloading();
        this.completed = true;
        setTimeout(() => {
          this.global.ToastSuccess("Timesheet Completed  Successfully");
          this.router.navigate(['/client/client-all-timesheets'])
          
          this.completed = false;
        }, 3000);
      }
    }
  }

}
