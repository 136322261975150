import { Component, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { ClientTimesheetService } from '../client-timesheet/client-timesheet.service';
import { MyClient, Services } from '../my-employee/my-employee.model';
import { MyEmployeesService } from '../my-employee/my-employee.service';
import { EvvTimesheetBO } from './client-service-info.model';
import { ClientServiceInfoService } from './client-service-info.service';

declare var L: any;
@Component({
  selector: 'app-client-service-info',
  templateUrl: './client-service-info.page.html',
  styleUrls: ['./client-service-info.page.scss'],
})
export class ClientServiceInfoPage {
  clientData: MyClient;
  serviceName: string = "PCA Service";
  trustUrl: SafeResourceUrl;
  activitiesList: Array<Services> = [];
  clientId: number;
  serviceId: number;
  startTime: string = null;
  endTime: string = null;
  scheduleId: number = 0
  senddata: EvvTimesheetBO;
  inProgressRecords: Array<any> = [];
  @ViewChild("map")
  public mapElement: ElementRef;
  // map: mapboxgl.Map;
  lat: any;
  lng: any;

  constructor(public clientService: MyEmployeesService, public errorService: ErrorService, public timesheetService: ClientTimesheetService,
    private router: Router, private sanitize: DomSanitizer, private route: ActivatedRoute, public global: Globalservice, public general: generalservice,
    public httpservice: ClientServiceInfoService, public geoLoc: Geolocation, public alertController: AlertController,
    public platform: Platform) {
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;

        this.clientId = data.clientId;
        this.serviceId = data.serviceId;
        this.startTime = data.startTime;
      }
    });
    this.global.checkingLogin();
  }

  async ionViewWillEnter() {
    
    this.clientData = null;
    this.serviceName = "";
    this.senddata = null;
    this.activitiesList = [];
    if (this.startTime != null) {
      let isRecordExists: boolean = false;
      this.inProgressRecords.forEach(element => {
        if (element.clientId == this.clientId) {
          isRecordExists = true
        }
      });
      if (!isRecordExists) {
        this.inProgressRecords.push({
          clientId: this.clientId,
          serviceId: this.serviceId,
          startTime: this.startTime
        })
      }
    }

    await this.global.checkRefreshTokenValidity()
    {

    this.getClients();
    this.getLocation();
    }
    //  this.getActivities();
    // this.getService();

  }

  async getLocation() {
    const position = await this.geoLoc.getCurrentPosition({ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }).then(res => {
      this.lat = res.coords.latitude;
      this.lng = res.coords.longitude;

    });
  }

  async getService() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("Client", this.clientId.toString())

    this.clientService.getServices(param).subscribe((data: Array<Services>) => {
      data.forEach(element => {
        if (element.serviceId == this.serviceId) {
          this.serviceName = element.serviceName;
        }
      });
    }, err => {
      this.errorService.check(err);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }

  async getClients() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    let param = new URLSearchParams();
    param.append("Employee", this.global.EvvUserDetails.entityId.toString())
    param.append("Client", this.clientId.toString())
    param.append("service", this.serviceId.toString())
    this.clientService.getClientById(param).subscribe((data: any) => {
      data.forEach(element => {
        if (element.clientId == this.clientId) {

          this.clientData = element;

          this.serviceName = element.serviceName
          this.activitiesList = element.activities;
          this.scheduleId = this.clientData.scheduleId
          this.clientData.mobileNumberFormat = this.clientData.mobileNumber != null ? this.general.converPhoneGoogleLib(this.clientData.mobileNumber.toString()) : null
          element.zip != null ? element.zip : ""


        }

      });

      this.trustUrl = this.sanitize.bypassSecurityTrustResourceUrl("https://maps.google.com/maps?q=" + this.clientData.street + "," + this.clientData.cityStateZip + "&z=15&ie=UTF8&iwloc=&output=embed&zoom=15");
      //  this.trustUrl = this.sanitize.bypassSecurityTrustResourceUrl(" https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/12/1171/1566.mvt?style=mapbox://styles/mapbox/streets-v11@00&access_token=pk.eyJ1Ijoic2FuZ2VldGhrdW1hcjMwIiwiYSI6ImNrZWRxMjR6ajB1OGUyc3J2N3NzZnV2NzIifQ.wi5pwxAOkHWJaOIuVYTqpA")
      // setTimeout(() => {
      //   this.getmap();
      // }, 1000);
      this.global.disableloading()

    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  // getActivities() {
  //   this.timesheetService.getActivities().subscribe((data: Array<Services>) => {


  //     data.forEach(element => {
  //       if (element.serviceId == this.serviceId) {
  //         this.activitiesList.push(element)
  //       }
  //     });
  //   }, err => {
  //     this.errorService.check(err);
  //   })
  // }
  async start() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    this.startTime = new Date(new Date().toDateString() + " " + new Date().toLocaleTimeString() + " " + "GMT").toISOString();

    let data = {
      // clientId: this.clientId,
      // serviceId: this.serviceId,
      // employeeId: this.global.EvvUserDetails.entityId,
      // startTime: this.startTime,
      // startLong: this.lng != null ? this.lng.toString() : null,
      // startLat: this.lat != null ? this.lat.toString() : null,
      // scheduleId: 0,
      // endTime: null

      employeeId: this.global.EvvUserDetails.entityId,
      clientId: this.clientId,
      masterServiceId: this.serviceId,
      clockInTime: this.startTime,
      callLongitude: this.lng != null ? parseFloat(this.lng) : null,
      callLatitude: this.lat != null ? parseFloat(this.lat) : null,
    }
    // this.senddata = data

    this.httpservice.startTimesheets(data).subscribe((data) => {
      if (data.errors.length > 0) {
        return;
      }
      this.scheduleId = data.evvId;
      // this.scheduleId = data;
      this.global.disableloading()
    }, err => {
      this.global.Toastdanger(err.message);
      this.errorService.check(err);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  async stop() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    this.endTime = new Date(new Date().toDateString() + " " + new Date().toLocaleTimeString() + " " + "GMT").toISOString();

    let data = {
      // clientId: this.clientId,
      // serviceId: this.serviceId,
      // employeeId: this.global.EvvUserDetails.entityId,
      // startTime: this.startTime,
      // endLong: this.lng!=null?this.lng.toString():null,
      // endLat: this.lat!=null?this.lat.toString():null,
      // scheduleId: this.scheduleId,
      // endTime: this.endTime

      evvTimesheetId: this.scheduleId,
      clockOutTime: this.endTime,
      callLongitude: this.lng != null ? parseFloat(this.lng) : null,
      callLatitude: this.lat != null ? parseFloat(this.lat) : null,
    }
    //  this.senddata = data
    this.httpservice.stopTimesheets(data).subscribe((data) => {
       this.scheduleId = data;
      if (data.errors.length > 0) {
        return;
      }
      let navigationExtras: NavigationExtras = {
        state: {
          clientId: this.clientId,
          serviceId: this.serviceId,
          startTime: this.startTime,
          endTime: this.endTime,
          scheduleId: this.scheduleId,
          type: "pending",
          clientSign: null,
          employeeSign: null,
          isSubmitButton: true,
          backto: "my-clients"
        }
      }
      this.router.navigate(['/client/client-timesheet'], navigationExtras);
      this.global.disableloading()

    }, err => {
      this.errorService.check(err.message);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  back() {
    if (this.startTime != null) {
      this.inProgressRecords.push({
        clientId: this.clientId,
        serviceId: this.serviceId,
        startTime: this.startTime
      })
    }

    let navigationExtras: NavigationExtras = {
      state: this.inProgressRecords
    }
    this.router.navigate(['/client/my-employee'], navigationExtras)
  }

  startservice() {
    this.presentAlertConfirm()
  }
  stopservice() {
    this.presentstopAlertConfirm()
  }

  onswipeLeft() {
    this.getStopLocation();

  } 
  OnSwipe() {
    this.getStartLocation();

  }
  async getStartLocation() {
    await this.global.showLoading();
    this.geoLoc.getCurrentPosition({ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }).then(res => {
      this.lat = res.coords.latitude;
      this.lng = res.coords.longitude;
      this.global.disableloading()
      this.startservice();
    },
      (err: any) => {
        this.global.disableloading()
        this.global.Toastdanger("Turn on Location");
      }
    );
  }
  async getStopLocation() {
    await this.global.showLoading();
    this.geoLoc.getCurrentPosition({ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }).then(res => {
      this.lat = res.coords.latitude;
      this.lng = res.coords.longitude;
      this.global.disableloading()
      this.stopservice();
    },
      (err: any) => {
        this.global.disableloading()
        this.global.Toastdanger("Turn on Location");
      }
    );
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({

      header: 'Clock In Alert',
      message: '<strong>Do you want to start service?</strong>',
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            this.start()
          }
        },
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }
      ]
    });

    await alert.present();
  }
  async presentstopAlertConfirm() {
    const alert = await this.alertController.create({

      header: 'Clock Out Alert',
      message: '<strong>Do you want to stop service?</strong>',
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            this.stop()
          }
        },
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }
      ]
    });

    await alert.present();
  }
}
