import { Component, Input, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { AlertController } from '@ionic/angular';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';

@Component({
  selector: 'app-Metsignature',
  templateUrl: './Metsignature.page.html',
  styleUrls: ['./Metsignature.page.scss'],
})
export class MetSignaturePage {
  deviceWidth: any;
  @ViewChild("ClientSignPad") clientSignaturePad: SignaturePad;
  @ViewChild("EmployeeSignPad") employeeSignaturePad: SignaturePad;

  public signaturePadOptions: Object;
  width: any;
  constructor(public alertCtrl: AlertController, public errorService: ErrorService, private screenOrientation: ScreenOrientation,
    public global: Globalservice,) {
    this.global.checkingLogin();
    if (window.innerWidth < 650) {
      this.width = window.innerWidth - 33;
      this.deviceWidth = (window.innerWidth - 30) + 'px';
    }
    else {
      this.width = 600;
      this.deviceWidth = 603 + 'px';
    }
    this.signaturePadOptions = {
      minWidth: 5,
      canvasWidth: this.width,
      canvasHeight: 200,
      penColor: "blue"
    }
  
    this.screenOrientation.onChange().subscribe(
      () => {
        setTimeout(() => {
          this.getDeviceWidth();
        }, 200)
      }
    );

  }

  getDeviceWidth() {
    if (window.innerWidth < 650) {
      this.width = window.innerWidth - 33;
      this.deviceWidth = (window.innerWidth - 30) + 'px';
      this.canvasResize();
    }
    else {
      this.width = 600;
      this.deviceWidth = 603 + 'px';
      this.canvasResize();
    }
  }
  canvasResize() {
    if(this.global.isDifferentlyAbled==false)
    {
    this.clientSignaturePad.set("minWidth", 1);
    this.clientSignaturePad.set("canvasWidth", this.width);
    this.clientSignaturePad.set("canvasHeight", 200);
    if (!this.clientSignaturePad.isEmpty()) {
      this.clientSignaturePad.fromDataURL(this.global.MetclientSign);
    }
  }
    this.employeeSignaturePad.set("minWidth", 1);
    this.employeeSignaturePad.set("canvasWidth", this.width);
    this.employeeSignaturePad.set("canvasHeight", 200);
    if (!this.employeeSignaturePad.isEmpty()) {
      this.employeeSignaturePad.fromDataURL(this.global.MetemployeeSign);
    }


  }
  ionViewWillEnter() {
   
    this.ngAfterViewInit();
  }
  ngAfterViewInit() {
    // ClientSign
     if(this.global.isDifferentlyAbled==false)
    {
    this.clientSignaturePad.set("minWidth", 5);
    this.clientSignaturePad.clear();
    this.global.MetclientSign = null
    }
    // EmployeeSign
    this.employeeSignaturePad.set("minWidth", 5);
    this.employeeSignaturePad.clear();
    this.global.MetemployeeSign = null
    this.canvasResize();

  }
  // Client Signature Property
  clientSignEvent() {
    this.global.MetclientSign = this.clientSignaturePad.toDataURL();
  }
  clearClientSignature() {
    this.clientSignaturePad.clear();
    this.global.MetclientSign = '';
    this.clientSignaturePad.set("minWidth", 5);
    this.canvasResize();
  }
  // Employee Signature Property
  employeeSignEvent() {
    this.global.MetemployeeSign = this.employeeSignaturePad.toDataURL();
  }
  clearEmployeeSignature() {
    this.employeeSignaturePad.clear();
    this.employeeSignaturePad.set("minWidth", 5);
    this.global.MetemployeeSign = null;
    this.canvasResize();
  }
}

