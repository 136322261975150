import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';

@Injectable({
  providedIn: 'root'
})
export class ClientServiceInfoService {

  constructor(public http:HttpClient,public errorService: ErrorService,public global:Globalservice) {
    
   }
   public  startTimesheets(data):Observable<any> {
    // let url="api/EvvTimesheet/startTimesheet"
    let url="api/Visit/SaveEvvVisitClockIn"
    return this.http.post(url,data).pipe(catchError(this.errorService.handleError));
  }
  public  stopTimesheets(data):Observable<any> {
    let url="api/Visit/SaveEvvVisitClockOut"
    // let url="api/EvvTimesheet/stopTimesheet"
    return this.http.post(url,data).pipe(catchError(this.errorService.handleError));
  }
}
