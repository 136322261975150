import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/Storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth.service';
import { LoginPageModule } from './login/login.module';
import { ClientLoginPageModule } from './pages/client-login/client-login.module';
import { TabsPageModule } from './tabs/tabs.module';
import { NgCalendarModule  } from 'ionic2-calendar';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Network } from '@ionic-native/network/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
export function createTranslateLoader(http:HttpClient) {
  return new TranslateHttpLoader(http,'assets/i18n/','.json',)
} 
import { Device } from '@ionic-native/device/ngx';
import { CookieService } from './cookieservice';
import { AppVersion } from '@ionic-native/app-version/ngx';
import BackgroundGeolocation from 'cordova-background-geolocation-lt';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

@NgModule({
  declarations: [
    AppComponent,
   
  ],
  entryComponents: [],
  imports: [  

    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'evv.db',
         driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    AppRoutingModule,
    HttpClientModule,
    TabsPageModule,
    ReactiveFormsModule,
    FormsModule,
    LoginPageModule,
    ClientLoginPageModule,
    TranslateModule.forRoot(
      {
        loader:{
provide:TranslateLoader,
useFactory:(createTranslateLoader),
deps:[HttpClient]
        }
      }
    ),
    
NgCalendarModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    DatePipe,
    CookieService,
   
    LocalNotifications,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy,  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthService,
      multi: true
    },
    ScreenOrientation,
    LaunchNavigator,
    SQLite,
    Device,
    Network,AppVersion,BackgroundGeolocation, AndroidPermissions,
    LocationAccuracy
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
