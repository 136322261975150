import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ActionSheetController, Platform } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { MyClient, Services } from './my-employee.model';
import { MyEmployeesService } from './my-employee.service';


@Component({
  selector: 'app-my-clients',
  templateUrl: 'my-employee.page.html',
  styleUrls: ['my-employee.page.scss'],
})
export class MyEmployeesPage {

  clientsList: Array<MyClient>;
  count:number=0;
  
  searchClient: string="";
  servicesList: Array<Services>;
  servicesListContent = [];
  inProgressRecords = null;
  datanotfound:boolean=false;
  constructor(public clientService: MyEmployeesService, public errorService: ErrorService, public actionSheetCtrl: ActionSheetController,
    private router: Router, private route: ActivatedRoute,public global:Globalservice,public platform: Platform,
    public general:generalservice) {
      this.global.checkingLogin();
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.inProgressRecords = data;
        
       
      }
      
    });
    
  }
  async ngOnInit()
 {
  
   
   this.count++;
   await this.global.checkRefreshTokenValidity()
   {
   this.getClients()
   }
}
  async ionViewWillEnter() {
    await this.global.checkRefreshTokenValidity()
    {
    if(this.count==0)
    {
      this.getClients();
    }
   
  }

  }

 
  async getClients() {
    await this.global.checkRefreshTokenValidity()
    {
    let param=new URLSearchParams();
    param.append("EmployeeId",this.global.EvvUserDetails.entityId.toString())
    param.append("SearchText",this.searchClient)
    await this.global.showLoading();;
    this.clientService.getClients(param).subscribe((data: Array<MyClient>) => {
     // let record =[];
   
      // data.forEach(element => {
      //   if(element.recordType=="myclients"){
      //     record.push(element);
      //   }
      // });
      // if(this.inProgressRecords!=null){
      //   this.inProgressRecords.forEach(element1 => {
      //     record.forEach(element2 => {
      //       if(element1.clientId == element2.clientId){
      //         element2.serviceId = element1.serviceId;
      //         element2.startTime = element1.startTime;
      //       }
      //     });
      //   });
      // }
      this.count=0;
      this.clientsList = data;
      if( data.length>0)
      {
        this.clientsList.forEach(c=>c.mobileNumberFormat=c.mobileNumber!=null?this.general.converPhoneGoogleLib(c.mobileNumber.toString()):null)
        this.datanotfound=false;
      }
      else{
        this.datanotfound=true;
      }
     
    
      this.global.disableloading()
     
    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if(err.Status==401){
        this.global.GloballogOut();
      }
    })
  }
  }

  ///---------- Load Client Services & Show ActionSheet
  async presentActionSheet(clientData: MyClient) {
   
    let param=new URLSearchParams();
    param.append("Client",clientData.clientId.toString())
    param.append("EmployeeId",this.global.EvvUserDetails.entityId.toString())
    
    this.servicesListContent = [];
    await this.global.showLoading();;
    this.clientService.getServices(param).subscribe(async (data: Array<Services>) => {
      this.servicesList = data;
      data.forEach(element => {
        this.servicesListContent.push({
          text: element.serviceName,
          id: element.serviceId,
          icon: '',
          handler: () => {
            let navigationExtras: NavigationExtras = {
              state: {
                clientId: clientData.clientId,
                serviceId: element.serviceId,
                startTime: clientData.startTime
              }
            }
            this.router.navigate(['/client/client-service'], navigationExtras)
          }
        })
      });
      this.servicesListContent.push({
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
       
        }
      })
      const actionSheet = await this.actionSheetCtrl.create({
        header: 'Select Service',
        cssClass: 'my-custom-class',
        buttons: this.servicesListContent
      });
      await actionSheet.present();
      this.global.disableloading()

    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if(err.Status==401){
        this.global.GloballogOut();
      }
    })
  }
  inProgress(clientData: MyClient) {
    let navigationExtras: NavigationExtras = {
      state: {
        clientId: clientData.clientId,
        serviceId: clientData.serviceId,
        startTime: clientData.startTime
      }
    }
    this.router.navigate(['/client/client-service'], navigationExtras)
  }
}
