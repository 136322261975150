import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { PrivatePayMileageLogsEvvReturnBO } from '../notes/notes.model';
import { PrivatePayMileageService } from './private-pay-mileage.service';
@Component({
  selector: 'app-private-pay-mileage',
  templateUrl: './private-pay-mileage.page.html',
  styleUrls: ['./private-pay-mileage.page.scss'],
})
export class PrivatePayMileagePage implements OnInit {
  totalMiles: any;
  isshowPrivatePay: boolean = false;
  fromDate: any = new Date().toISOString();
  toDate: any = new Date().toISOString();
  PrivatePayReturnList: PrivatePayMileageLogsEvvReturnBO[] = [];
  constructor(public PrivatePayService: PrivatePayMileageService, public global: Globalservice, public errorService: ErrorService, public toastController: ToastController, public alertController: AlertController, public datePipe: DatePipe, public router: Router, public modalController: ModalController) {
    this.global.checkingLogin();
  }
  ngOnInit() {
  }
  async ionViewWillEnter() {
  
       if (this.global.timezone == "US") {
        this.fromDate = this.global.getDateWithStartTime(new Date());
        this.toDate = this.global.getDateWithEndTime(new Date());
      }
      else {
        this.fromDate = new Date().toISOString();
        this.toDate = new Date().toISOString();    
      }
      await this.global.checkRefreshTokenValidity()
      {
        this.getPrivatePayMileageLogsEVV();
      }


  }
  //.............................................savePrivatePayMileageLogs
  async getPrivatePayMileageLogsEVV() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();

    let fromdate;
    let toDate;
    if (this.global.timezone == "US") {
      fromdate = this.global.getDateWithStartTime(this.fromDate);
      toDate = this.global.getDateWithEndTime(this.toDate);
    }
    else {
      fromdate = this.fromDate
      toDate = this.toDate
    }

    let param = new URLSearchParams();
    param.append("employeeId", this.global.EvvUserDetails.entityId.toString());
    param.append("startDate", fromdate);
    param.append('endDate', (toDate));
    this.PrivatePayService.getPrivatePayMileageLogsEVV(param).subscribe((data: any) => {
      this.PrivatePayReturnList = data;
      if (data.length > 0) {
        this.calculatesTotalMiles();
        this.calculatesDailyMiles();
        this.isshowPrivatePay = true;
      }
      else {
        this.isshowPrivatePay = false;
      }
      this.global.disableloading();
    }, err => {
      this.errorService.check(err);
      this.global.disableloading();
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  async SavePrivatePayMileageLogs() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    this.PrivatePayService.AddPrivatePayMileageLogsEVV(this.PrivatePayReturnList).subscribe((data) => {
      this.isshowPrivatePay = false;
      this.fromDate = new Date().toISOString();
      this.toDate = new Date().toISOString();
      this.global.disableloading()
      this.global.ToastSuccess("Logs saved successFully!")
    }, err => {
      this.global.Toastdanger(err.message)
      this.errorService.check(err.message);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  calculatesTotalMiles() {
    var miles = 0;
    this.PrivatePayReturnList?.forEach(a => {
      a?.records?.forEach(b => {
        miles = +b.miles + miles;
      });
    });
    this.totalMiles = miles;
    this.calculatesDailyMiles();
  }
  calculatesDailyMiles() {
    this.PrivatePayReturnList?.forEach(element => {
      element.dailymiles = (element.records?.reduce((sum, dailymiles) => sum + (dailymiles?.miles ?? 0), 0) ?? 0);
    })
  }

}
