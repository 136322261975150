import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagesPage } from '../languages/languages.page';
import { ForgotPasswordPage } from './forgot-password/forgot-password.page';
import { LoginPassPage } from './login-password/login-password.page';
import { LoginPageRoutingModule } from './login-routing.module';
import { LoginPage } from './login.page';
import { AppMaintenancePage } from './appmaintenance/appmaintenance.page';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LoginPageRoutingModule,
    TranslateModule
  ],
  declarations: [
    LoginPage,
    LoginPassPage,
    ForgotPasswordPage,
    LanguagesPage,
    AppMaintenancePage
  ]
})
export class LoginPageModule {}
