export class CollapseBo
{
    isContactinfo:boolean=false;
    isProviderInfo:boolean=false;
    isProviderContactInfo:boolean=false;
    isCanClientCreate:boolean=false;
    ResponsiblepartyInfo:boolean=false;
    ResponsiblePartyWeekly:boolean=false;
    isModeofMonitoring:boolean=false;
    isDiagnosisCode:boolean=false;
    isGoalExpectedOutComes:boolean=false;
    isThingsimpactinfo: boolean = false;
    isMedicationdoseinfo: boolean = false;
    isContactPersoninfo: boolean = false;
    isSensoryStatusinfo: boolean = false;
    isActivitiesdailylivinginfo: boolean = false;
    isComplexmedicalneedsinfo: boolean = false;
    isSeizuresinfo: boolean = false;
    isbehaviourinfo: boolean = false;
    isSupportiveactivitiesdailylivinginfo: boolean = false;
    isInstrumentalactivitiesinfo: boolean = false;
    isCommunicationsinfo: boolean = false;
    isStaffinginfo: boolean = false;
    isExpectedoutcomesgoalsinfo: boolean = false;
    isHealthrelatedtaskinfo: boolean = false;
    isplanofcarereviewinfo: boolean = false;
}
export class CodeDescriptionListBo {
    id: number;
    carePlanId: number;
    code: string;
    description: string;
}

export class DosesFrequencyRouteListBo {
    id: number;
    carePlanId: number;
    doses: string;
    frequency: string;
    route: string;
}
export class getComprehensiveBo {
    id: number;
    carePlanDate: string;
    clientId: number;
    // General Information
    clientFName: string;
    clientMName: string;
    clientLName: string;
    clientGender: string;
    age: string;
    clientDOB: string;
    medicare: string;
    medicaid: string;
    street: string;
    apt: string;
    email: string;
    phone1: string;
    phone2: string;
    state: string;
    city: string;
    county: string;
    zipCode: string;
    height: number | null;
    weight: number | null;

    // Provider Info
    providersName: string;
    providersPhone: string;
    providersStreet: string;
    providersFax: string;
    providersCity: string;
    providersState: string;
    providersZipCode: string;
    providersEmail: string;
    //Provider's Contact Info
    providerName: string;
    providerPhone: string;
    providerEmail: string;
    locationId: number;
    locationName: string;
    qPName: string;
    locContactName: string;
    locContactEmail: string;
    locContactStreet: string;
    locContactState: string;
    locContactCity: string;
    locContactZip: string;
    locContactPhone: string;
    locContactFax: string;
    locEmail: string;
    // Delegate Rp
    delegateRP: string;
    rPRelationship: string;
    rPAddress: string;
    rPPhone: string;
    // Physician ;
    physicianName: string;
    physicianAddress: string;
    physicianPhone: string;
    physicianHospitalOfChoice: string;

    // Medication Route Frequency  
    dosageDetails: CodeDescriptionListBo[];
    // Code & Description
    diagnosisDetails: DosesFrequencyRouteListBo[];
    // Things May Impact
    legalRadio: boolean | null;;
    legalExplanation: string;
    physicalRadio: boolean | null;;
    physicalExplanation: string;
    environmentalRadio: boolean | null;;
    environmentalExplanation: string;
    abusiveRadio: boolean | null;
    abusiveExplanation: string;

    otherRadio: boolean | null;
    otherExplanation: string;

    // goals
    goals: string;

    // Mode of monitoring

    mOMVisit: boolean | null;
    mOMAudio: boolean | null;
    mOMOther: boolean | null;
    // Responsible Party able to monitor PCA services weekly?
    respPartyWeeklyMonitoring: boolean | null;
    // Can client direct own care
    directOwnCare: boolean | null;
    // Emergency
    emergencyNumPosted: boolean | null;
    emergencyNumPostedDesc: string;
    allergies: string;
    waiver: boolean | null;
    waiverMR: boolean | null;
    waiverCADI: boolean | null;
    waiverEW: boolean | null;
    waiverACS: boolean | null;
    waiverCAC: boolean | null;
    waiverTBI: boolean | null;
    payorId: number;
    insuranceNo: string;
    healthInsurance: boolean | null;
    payorName: string;
    // Contact person's

    schoolOrWork: boolean | null;
    program: string = null;
    days: string = null;
    hours: string = null;
    notes: string = null;
    // I.Sensory Status
    // languages
    languageNoImpairment: boolean;
    languageMinimalImpairment: boolean;
    languageUnableToExpress: boolean;
    languageUnResponsive: boolean;
    languageAgeAppropriate: boolean;
    languageComments: string;
    //hearing
    hearingNoImpairment: boolean;
    hearingMinimalImpairment: boolean;
    hearingUnableToHear: boolean;
    hearingAids: boolean | null;
    hearingComments: string;
    // vision
    visionNoImpairment: boolean;
    visionMinimalImpairment: boolean;
    visionGlasses: boolean | null;
    visionNotUseful: boolean;
    visionComments: string;
    // II.
    // dressing
    dressingDetailsId: number;
    dressingComments: string;
    dresssingDetails: ActivitiesofDailyLivingBo;
    // grooming

    
    groomingDetailsId: number;
    groomingComments: string;
    groomingOthers: string;
    groomingDetails: ActivitiesofDailyLivingBo;
    // bathing
    bathingDetailsId: number;
    bathingComments: string;
    bathingDetails: ActivitiesofDailyLivingBo;
    // eating
    eatingDetailsId: number;
    eatingComments: string;
    eatingDetails: ActivitiesofDailyLivingBo;
    // transfer
    transferDetailsId: number;
    transferComments: string;
    transferDetails: ActivitiesofDailyLivingBo;
    // mobility
    mobilityDetailsId: number;
    mobilityComments: string;
    mobilityDetails: ActivitiesofDailyLivingBo;
    // positioning
    positioningDetailsId: number;
    positioningComments: string;
    positioningDetails: ActivitiesofDailyLivingBo;
    // toileting
    toiletingDetailsId: number;
    toiletingComments: string;
    toiletingDetails: ActivitiesofDailyLivingBo;
    // III.Complex Medical Needs
    feedingSuppliment: string;
    feedingAmount: string;
    feedingFrequency: string;
    feedingComments: string;
    skinCareRadio: boolean | null;
    skinCareComments: string;
    respiratoryRadio: boolean | null;
    respiratoryComments: string;
    respiratoryType: string;
    respiratoryFrequency: string;
    respiratoryDirections: string;
    //IV.Seizures/////////////////////////////////////////////
    noHistory: boolean;
    history: boolean;
    lastSeizureDate: string | null;
    type: string;
    frequency: string;
    duration: string;
    // V.Behavior 
    // level1
    selfInjuries: boolean | null;
    physicalInjuries: boolean | null;
    destruction: boolean | null;
    // level2
    unusualHabits: boolean | null;
    withdrawl: boolean | null;
    socialOffensive: boolean | null;
    behaviourOthers: boolean | null;
    // level3
    noPrompts: boolean | null;
    needPrompts: boolean | null;
    intermediatePrompts: boolean | null;
    onGoingPrompts: boolean | null;
    behaviourComments: string;
    // VI.Supportive Activities of Daily Living
    appoinmentManagement: boolean | null;
    accompanyAppoinment: boolean | null;
    supportActsDressingComments: string;
    laundry: boolean | null;
    houseKeeping: boolean | null;
    mealPrep: boolean | null;
    shopping: boolean | null;
    supportActsHMOthers: boolean | null;
    supportActsHMComments: string;
    // VII.Instrumental Activities of Daily Livings(IADLs)    
    // 1
    medicalApps: boolean;
    medicalAppsFrequencyId: number;
    medicalAppsFrequency: CompCarePlanASDLsFrequenciesBO;
    // 2
    communityParticipation: boolean;
    communityParticipationFrequencyId: number;
    communityParticipationFrequency: CompCarePlanASDLsFrequenciesBO;
    // 3
    assistPayBills: boolean;
    assistPayBillsFrequencyId: number;
    assistPayBillsFrequency: CompCarePlanASDLsFrequenciesBO;
    // 4
    phoneCommunication: boolean;
    phoneCommunicationFrequencyId: number;
    phoneCommunicationFrequency: CompCarePlanASDLsFrequenciesBO;
    // 5
    mealPreparation: boolean;
    mealPreparationFrequencyId: number;
    mealPreparationFrequency: CompCarePlanASDLsFrequenciesBO;
    // 6
    medicationPickup: boolean;
    medicationPickupFrequencyId: number;
    medicationPickupFrequency: CompCarePlanASDLsFrequenciesBO;
    // 7
    groceryShopping: boolean;
    groceryShoppingFrequencyId: number;
    groceryShoppingFrequency: CompCarePlanASDLsFrequenciesBO;
    // 8
    essentialShopping: boolean;
    essentialShoppingFrequencyId: number;
    essentialShoppingFrequency: CompCarePlanASDLsFrequenciesBO;
    // 9
    iadLsOthers: boolean;
    iadLsOthersFrequencyId: number;
    iadLsOthersFrequency: CompCarePlanASDLsFrequenciesBO;
    iadLsComments: string;
    // VIII.Communications
    checkWithClient: boolean | null;
    notifySupervisor: boolean | null;
    notifySupervisorIfChanges: boolean | null;
    discoloration: boolean | null;
    swelling: boolean | null;
    bruises: boolean | null;
    increasedPain: boolean | null;
    decreasedAppetite: boolean | null;
    decreasedMobility: boolean | null;
    noAnswerToLockedDoor: boolean | null;
    clientRefused: boolean | null;
    communicationComments: string;
    // IX.Staffing
    serviceBegin: string | null;
    staffingDaysOfWeek: string;
    staffingHoursNeeded: string;
    staffingBackup: string;
    // Expected Outcomes/goals
    planOfCareWithClient: boolean;
    planOfCareWithFamily: boolean;
    planOfCareWithOthers: boolean;
    planOfCareWithOthersName: string;
    planOfCareStartDate: string | null;
    planOfCareEndDate: string | null;
    // healthRelated: string;
    healthRelated: string;
    // --Plan of care review update(Minimally Annually)--
    declaration: string;
    respPartySign: string;
    nurseSign: string;
    pCASign: string;
    respPartySignDate: string | null;
    nurseSignDate: string | null;
    pCASignDate: string | null;
    clientSignDate: string | null;
    clientSign: string;    
    qpSignTimeIn: string | null;
    qpSignTimeOut: string | null;
    clientAuthServiceName:string;
}
export class ActivitiesofDailyLivingBo {
    id: number;
    independent: boolean;
    reminder: boolean;
    supervision: boolean;
    dependent: boolean;
    hairCare: boolean;
    brushing: boolean;
    shaving: boolean;
    shower: boolean;
    tub: boolean;
    daily: boolean;
    others: boolean;
}
export class CompCarePlanASDLsFrequenciesBO {
    id: number;
    eV: boolean;
    d: boolean;
    w: boolean;
    pRN: boolean;
}


export class ResponsiblePartyInfo {
    name: string | null;
    relationship: string | null;
    address: string | null
    phone: string | null;
}

export class CaseManagerInfoBO {
    caseManagerName: string | null;
    county: string | null;
    email: string | null;
    telephone: string | null;
}
