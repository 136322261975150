import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
@Injectable({
    providedIn: 'root'
})
export class ManualElectronicTimeSheetService {
    constructor(public http: HttpClient, public errorService: ErrorService, public global: Globalservice) {
    }
    public getCheckeligibility(param: URLSearchParams): Observable<any> {
        let url = "api/EvvEmployee/Checkeligibility?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
    public getWeekDates(param: URLSearchParams): Observable<any> {
        let url = "api/EvvTimesheet/getWeekDates?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
    public getClientDropdown(param: URLSearchParams): Observable<any> {
        let url = "api/EvvClient/getClientDropdown?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
    public getClientService(param: URLSearchParams): Observable<any> {
        let url = "api/EvvClient/getClientServiceNew?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
    public getActivitiesforManualETimesheet(param: URLSearchParams): Observable<any> {
        let url = "api/EvvClient/getActivitiesforMaualETimesheet?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
    public saveManualETimeSheet(data): Observable<any> {
   
         let url="api/Visit/SaveMobileManualVisit?"
        return this.http.post(url, data).pipe(catchError(this.errorService.handleError));
    }
    // for templates
    public AddManualETimesheetTemplate(data): Observable<any> {
        let url = "api/EvvTimesheet/AddManualETimesheetTemplate";
        return this.http.post(url, data).pipe(catchError(this.errorService.handleError));
    }
    public getTemplatedropdown(param: URLSearchParams): Observable<any> {
        let url = "api/EvvTimesheet/getTemplatedropdown?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
    public getTemplateforTheDate(param: URLSearchParams): Observable<any> {
        let url = "api/EvvTimesheet/getTemplateforTheDate?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
    public getTemplateforEdit(param: URLSearchParams): Observable<any> {
        let url = "api/EvvTimesheet/getTemplateforEdit?"
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
    public deleteTemplate(param: URLSearchParams): Observable<any> {
        let url = "api/EvvTimesheet/deleteTemplate?"
        return this.http.delete(url + param).pipe(catchError(this.errorService.handleError));
    }
    public getClientisDifferentlyAbled(param: URLSearchParams): Observable<any> {
        let url = 'api/EvvClient/getClientisDifferentlyAbled?';
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
    public getIsSingleDayEmanualEntry(param: URLSearchParams): Observable<any> {
        let url = 'api/Employee/getIsSingleDayEmanualEntry?';
        return this.http.get(url + param).pipe(catchError(this.errorService.handleError));
    }
}








