import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ActionSheetController, Platform } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import {
  getEvvWeeklyTimesheetBO,
  Pending,
  Weekly,
} from './all-timesheets.model';
import { AllTimesheetsService } from './all-timesheets.service';
import { Network } from '@ionic-native/network/ngx';
import { sqliteService } from 'src/app/sqlite.service';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Device } from '@ionic-native/device/ngx';
@Component({
  selector: 'app-all-timesheets',
  templateUrl: './all-timesheets.page.html',
  styleUrls: ['./all-timesheets.page.scss'],
})
export class AllTimesheetsPage {
  searchClient: string = '';
  showtut: boolean = false;
  showtut1: boolean = false;
  noOfflineData: boolean = false;
  servicesListContent = [];
  showLoader: boolean = true;
  inProgressRecords = null;
  signnedList: Array<Weekly>;
  pendingList: Array<Pending>;
  weeklyList: Array<getEvvWeeklyTimesheetBO>;
  sortType: string = 'weekly';
  yearfilter: any;
  isSubmitButton: boolean = true;
  customPickerOptions = {};
  signednotfound: boolean = false;
  weeknotfound: boolean = false;
  OfflineTSData: any = [];
  weekdays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  isOffline: boolean = false;
  constructor(
    public allTimesheetService: AllTimesheetsService,
    public errorService: ErrorService,
    public actionSheetCtrl: ActionSheetController,
    private router: Router,
    private route: ActivatedRoute,
    public global: Globalservice,
    public platform: Platform,
    public datePipe: DatePipe,
    public general: generalservice,
    private network: Network,
    public sqlService: sqliteService,
    private sqlite: SQLite,
    public device: Device
  ) {
    this.global.checkingLogin();
    this.route.queryParams.subscribe((params) => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.inProgressRecords = data;
      }
      this.network.onConnect().subscribe(() => {
        this.isOffline = false;
      });
      this.network.onDisconnect().subscribe(() => {
        this.isOffline = true;
      });
    });
  }
  async ngOnInit() {
    this.sortType = 'weekly';
    await this.global.checkRefreshTokenValidity().then((data) => {
    this.global.checkAppStateChange()
    })
  }
  async ionViewWillEnter() { 
    var a = new Date();
    this.yearfilter = this.global.DateandTimeFormat(a);
    if (this.global.isOffline) {
      this.getOfflineData();
    } else {
      await this.global.checkRefreshTokenValidity().then((data) => {
      {
      if (this.sortType == 'signed' || this.sortType == 'pending') {
        this.getSigned();
      } else if (this.sortType == 'weekly') {
        this.getWeekly();
      }
    }
  })
  }
  }
  //......................................................expandItem............................................
  expandItem(item): void {
    if (item.expanded) {
      item.expanded = false;
    } else {
      this.signnedList.map((listItem) => {
        if (item == listItem) {
          listItem.expanded = !listItem.expanded;
        } else {
          listItem.expanded = false;
        }
        return listItem;
      });
    }
  }
  //......................................................expandItemOffline............................................
  expandItemOffline(item): void {
    if (item.expanded) {
      item.expanded = false;
    } else {
      item.expanded = true;
    }
  }
  //......................................................expandItem1............................................
  expandItem1(item): void {
    if (item.expanded) {
      item.expanded = false;
    } else {
      this.weeklyList.map((listItem) => {
        listItem.weekdata.map((lisdata) => {
          if (item == lisdata) {
            lisdata.expanded = !lisdata.expanded;
          } else {
            lisdata.expanded = false;
          }
          return listItem;
        });
      });
    }
  }

  //......................................................getSigned............................................
  async getSigned() {
    let date =
      this.yearfilter != '' ? new Date(this.yearfilter).toISOString() : '';
    if (date != '') {
      this.showLoader = true;
      let param = new URLSearchParams();
      param.append('searchName', this.searchClient);
      param.append(
        'employeeId',
        this.global.EvvUserDetails.entityId.toString()
      );
      param.append('filterType', this.sortType);
      param.append('filterdate', date);
      await this.global.checkRefreshTokenValidity().then((data) => {
       
        this.allTimesheetService.getSigned(param).subscribe(
          (data: Array<Weekly>) => {
            let record = [];
            this.signnedList = data;
            let groupedData = [];
            data.forEach((w) => {
              w.records.forEach((rc) => {
                groupedData.push({
                  tsdate: rc.tImesheetDate,
                  recs: w.records.filter(
                    (i) => i.tImesheetDate === rc.tImesheetDate
                  ),
                });
              });
            });
            if (data.length > 0) {
              this.signnedList.forEach(
                (e) =>
                (e.mobileNumberFormat =
                  e.mobileNumber != null
                    ? this.general.converPhoneGoogleLib(
                      e.mobileNumber.toString()
                    )
                    : null)
              );
              this.signednotfound = false;
            } else {
              this.signednotfound = true;
            }

            this.showLoader = false;
            //    document.getElementById("expand-wrapper1").style.maxHeight="150px";
            //    document.getElementById("expand-wrapper").style.maxHeight="150px";
          },
          (err) => {
            this.errorService.check(err);
            this.showLoader = false;
            if (err.Status == 401) {
              this.global.GloballogOut();
            }
          }
        );
      }
      )
    } else {
      this.signnedList = [];
      this.signednotfound = true;
    }
  }
  //......................................................getWeekly............................................
  async getWeekly() {

    let date =
      this.yearfilter != '' ? new Date(this.yearfilter).toISOString() : '';
   
    if (date != '') {
      this.showLoader = true;
      let param = new URLSearchParams();
      param.append('searchName', this.searchClient);
      param.append(
        'employeeId',
        this.global.EvvUserDetails.entityId.toString()
      );
      param.append('filterType', this.sortType);
      param.append('filterdate', date);
      await this.global.checkRefreshTokenValidity().then((data) => {
       
        this.allTimesheetService.getWeekly(param).subscribe(
          (data: Array<getEvvWeeklyTimesheetBO>) => {
            this.weeklyList = data;
            if (data.length > 0) {
              this.weeklyList.forEach(
                (w) =>
                (w.mobileNumberFormat =
                  w.mobileNumber != null
                    ? this.general.converPhoneGoogleLib(
                      w.mobileNumber.toString()
                    )
                    : null)
              );
              this.weeknotfound = false;
            } else {
              this.weeknotfound = true;
            }
            this.showLoader = false;
          },
          (err) => {
            this.errorService.check(err);
            this.showLoader = false;
            if (err.Status == 401) {
              this.global.GloballogOut();
            }
          }
        );
      })
    } else {
      this.weeklyList = [];
      this.weeknotfound = true;
    }

  }
  //......................................................sortTypeChange............................................
  sortTypeChange() {
    if (this.sortType == 'signed' || this.sortType == 'pending') {
      this.getSigned();
    } else if (this.sortType == 'weekly') {
      this.getWeekly();
    }
  }
  //......................................................viewTimeSheet............................................
  viewTimeSheet(clientData, timesheetData) {
    if (this.sortType == 'signed') {
      this.isSubmitButton = false;
    } else if (this.sortType == 'pending') {
      this.isSubmitButton = true;
    }
    let navigationExtras: NavigationExtras = {
      state: {
        clientId: clientData.clientId,
        serviceId: timesheetData.serviceId,
        startTime: timesheetData.startTime,
        endTime: timesheetData.endTime,
        clientSign: clientData.clientSign,
        employeeSign: clientData.employeeSign,
        isSubmitButton: this.isSubmitButton,
        evvTimesheetId: timesheetData.evvTimesheetId,
        scheduleId: timesheetData.evvTimesheetId,
        backto: 'all-timesheets',
        type: this.sortType,
        notes: this.sortType != 'pending' ? timesheetData.notes : '',
      },
    };
    this.router.navigate(['/tabs/timesheet'], navigationExtras);
  }
  //......................................................signAll............................................
  signAll(clientId, timesheetData) {
    let val = {
      clientId: clientId,
      startDate: timesheetData.startDate,
      endDate: timesheetData.endDate,
    };
    let navigationExtras: NavigationExtras = {
      state: {
        data: val,
        clientSign: null,
        employeeSign: null,
        tsType: this.sortType == 'signed' ? 'signed' : 'unSigned',
        type: 'timesheet',
        notes: timesheetData.notes,
      },
    };
    this.router.navigate(['/tabs/sign-all-timesheet'], navigationExtras);
  }
  //......................................................viewTimeSheetWeekly............................................
  viewTimeSheetWeekly(type, clientData, timesheetData) {
    let navigationExtras: NavigationExtras = {
      state: {
        clientId: clientData.clientId,
        serviceId: timesheetData.serviceId,
        startTime: timesheetData.startTime,
        endTime: timesheetData.endTime,
        scheduleId: timesheetData.evvTimesheetId,
        evvTimesheetId: timesheetData.evvTimesheetId,
        clientSign: null,
        employeeSign: null,
        isSubmitButton: true,
        type: this.sortType,
        backto: 'all-timesheets',
        tsType: type,
      },
    };
    this.router.navigate(['/tabs/timesheet'], navigationExtras);
  }
  //......................................................viewTimeSheetWeeklyOffline............................................
  viewTimeSheetWeeklyOffline(type, clientData, timesheetData) {
    let navigationExtras: NavigationExtras = {
      state: {
        clientId: clientData.clientId,
        serviceId: timesheetData.serviceId,
        startTime: timesheetData.start,
        endTime: timesheetData.end,
        scheduleId: timesheetData.Id,
        evvTimesheetId: timesheetData.Id,
        clientSign: null,
        employeeSign: null,
        isSubmitButton: true,
        type: 'Offline',
        backto: 'all-timesheets',
        tsType: type,
      },
    };
    this.router.navigate(['/tabs/timesheet'], navigationExtras);
  }

  //......................................................getOfflineData............................................
  getOfflineData() {
    var offlineData = [];
    this.sqlite
      .create({
        name: 'evv.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql(
          'select DISTINCT * from timesheet where end IS NOT NULL',
          []
        )
          .then((data) => {
            var auth = data.rows.item;
            if (data.rows.length > 0) {
              this.noOfflineData = false;
              for (let i = 0; i < data.rows.length; i++) {
                offlineData.push(data.rows.item(i));
              }
            } else {
              this.noOfflineData = true;
            }
          })
          .then(() => {
            offlineData.forEach((od) => {
              this.sqlite
                .create({
                  name: 'evv.db',
                  location: 'default',
                })
                .then((db: SQLiteObject) => {
                  db.executeSql(
                    'select DISTINCT * from service where clientId==' +
                    od.clientID +
                    ' and serviceId==' +
                    od.serviceId,
                    []
                  )
                    .then((data) => {
                      if (data.rows.length > 0) {
                        od.service = data.rows.item(0).service;
                      }
                    })
                    .catch((e) => { });
                });
            });
          })
          .then(() => {
            var clientIDs = new Set(offlineData.map((i) => i.clientID));

            var res = [];
            clientIDs.forEach((cli) => {
              res.push({
                clientId: cli,
                values: offlineData.filter((i) => i.clientID === cli),
              });
            });
            this.OfflineTSData = res;
            this.OfflineTSData.forEach((element) => {
              this.sqlite
                .create({
                  name: 'evv.db',
                  location: 'default',
                })
                .then((db: SQLiteObject) => {
                  db.executeSql(
                    'select DISTINCT * from client where ClientId==' +
                    element.clientId,
                    []
                  )
                    .then((data) => {
                      if (data.rows.length > 0) {
                        element.clientId = data.rows.item(0).ClientId;
                        element.name = data.rows.item(0).name;
                        element.mobileNumber = data.rows.item(0).mobileNumber;
                        element.email = data.rows.item(0).email;
                        element.street = data.rows.item(0).street;
                        element.cityStateZip = data.rows.item(0).cityStateZip;
                      }
                    })
                    .catch((e) => { });
                });
            });
          })
          .catch((e) => { });
      });

    this.showLoader = false;
  }
  //......................................................getClients............................................
  getClients() {
   
    this.sortTypeChange();
  }
  //......................................................tutClick............................................
  tutClick() {
    this.showtut = false;
    this.showtut1 = true;
  }
  //......................................................tutClick1............................................
  tutClick1() {
    this.showtut1 = false;
  }
}
