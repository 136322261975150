import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import * as moment from 'moment-timezone';
import { App } from '@capacitor/app';
import {
  AlertController,
  LoadingController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { interval, Observable, of, Subscription } from 'rxjs';
import { CookieService } from './cookieservice';
import { Device } from '@ionic-native/device/ngx';
import { Storage } from '@ionic/Storage';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';

import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

@Injectable({
  providedIn: 'root',
})
export class Globalservice {
  languageSelected: string;
  isOptional: boolean = false;
  refreshSubscription: Subscription;
  FCMSubscription: Subscription;
  isAlertOpen: boolean = false;
  isBgAlertOpen: boolean = false; 
  languageSelectpage: boolean = false;
  password: string;
  serviceType: any = [];
  isDifferentlyAbled: boolean = false;
  isTrainer: boolean = false;
  isModalStart: boolean = false;
  isModalStop: boolean = false;
  isModalEdit: boolean = false;
  timezone: any = "Minnesota";
  clientName: string = "";
  isModalSignAllTimesheetEdit: boolean = false;
  isModalSharedStart: boolean = false;
  isModalSharedStop: boolean = false;
  FCMtoken: string = '';
  MetclientSign: string = null;
  MetemployeeSign: string = null;
  token: string;
  applicationVersionNumber: string;
  RefreshRequestList: RefreshRequest = new RefreshRequest();
  UserData: userdata = new userdata();
  refreshTokenString: string;
  EvvUserDetails: EvvUserDetailBO = new EvvUserDetailBO();
  userId: number;
  isassignboolean: boolean = false;
  otp: number;
  clientid: number;
  useremail: string;
  userAgency: string;
  role: string;
  height: string;
  public refresh: any;
  public offline: boolean;
  saveEmployeeInformation: EmployeeDeviceInformationBO =
    new EmployeeDeviceInformationBO();
  saveClientInformation: ClientDeviceInformationBO =
    new ClientDeviceInformationBO();
  public isOffline: boolean = false;
  public isOfflineChangeByUser: boolean;
  public isOnline: boolean = false;
  public appPages = [];
  public clientappPages = [];
  constructor(
    private locationAccuracy: LocationAccuracy,
    private androidPermissions: AndroidPermissions,
    private platform: Platform,
    public appVersion: AppVersion,
    public cookieService: CookieService,
    public storage: Storage,
    public http: HttpClient,
    public route: Router,
    public device: Device,
    private sqlite: SQLite,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public launchNavigator: LaunchNavigator,
    public toastController: ToastController
  ) {




  }

  ngOnInit() { }
  //  '"bubbles" | "circles" | "circular" | "crescent" | "dots" | "lines" | "lines-small" | "lines-sharp" | "lines-sharp-small"'
  async showLoading() {
    await this.loadingController
      .create({
        spinner: 'lines-sharp',
        message: 'Please wait...',
      })
      .then((res) => {
        res.present();
      });
  }
  disableloading() {
    this.loadingController
      .dismiss()
      .then((res) => { })
      .catch((error) => { });
  }
  openMap(address: string) {
    this.launchNavigator.navigate(address);
  }

  GloballogOut() {
    this.token = null;
    this.UserData.accessToken = null;
    this.deleteDatabase();
    localStorage.clear();
    this.Toastdanger('logged out, Please Login');
    this.route.navigate(['/login'], { replaceUrl: true });
  }
  async Toastdanger(message) {
    let toast = await this.toastController.create({
      message: message,
      duration: 8000,
      position: 'top',
      color: 'danger',
    });
    toast.present();
  }

  async ToastSuccess(message) {
    let toast = await this.toastController.create({
      message: message,
      duration: 8000,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }
  async ToastSuccess1(message) {
    let toast = await this.toastController.create({
      message: message,
      duration: 1500,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }
  async ToastSuccessMiniDuration(message) {
    let toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }
  async ToastDangerMiniDuration(message) {
    let toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top',
      color: 'danger',
    });
    toast.present();
  }

  scheduleRefresh() {
    var t = new Date();
    t.setSeconds(t.getSeconds() + 1500);
    localStorage.setItem("Evv_RefreshExpireTime", t.toString());
  }
  checkingLogin() {
    let loginData = localStorage.getItem('Evv_login');
    if (loginData == null || loginData == undefined || loginData == '') {
      this.route.navigate(['/login'], { replaceUrl: true });
    }

  }
  // getDateTimeString
  getDateTimeString(date: any): string {
    if (this.timezone == "US") {
      var utcTime = new Date(date).toISOString();
      return utcTime;
    }
    else {
      var tzoffset = new Date(date).getTimezoneOffset() * 60000; //offset in milliseconds
      return new Date(new Date(date).getTime() - tzoffset)
        .toISOString()
        .slice(0, -1);
    }

  }
  toFeet(height) {
    var realFeet = height / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + ' ' + 'ft' + ' ' + inches + ' ' + 'in';
  }


  //.........................................CheckForUpdateAvailable..................................
  async CheckForUpdateAvailable() {
    await this.checkRefreshTokenValidity().then((data) => {
    this.platform.ready().then(() => {
      this.appVersion
        .getVersionNumber()
        .then((data) => {
          this.applicationVersionNumber = data;
          this.storage.get('Version').then((val) => {
            if (val != this.applicationVersionNumber) {
              this.storage.set('Version', this.applicationVersionNumber);
            }
          });
        })
        .then(async (data) => {
          if (this.device.uuid != null) {
              let url = 'api/Evvconfiguration/GetLatestVersion?';
              let myParams = new URLSearchParams();
              myParams.append('OldVersion', null);
              myParams.append('OSPlatform', this.device.platform);
              this.http.get(url + myParams).subscribe(
                async (checkDetails: any) => {
                  this.isOptional = checkDetails.isOptional;
                  if (checkDetails == null || checkDetails == undefined || checkDetails == '') {
                    this.isOptional = false;
                  }
                  else {
                    this.isOptional = checkDetails.isOptional;
                  }
                  if (this.isOptional) {
                    let navigationExtras: NavigationExtras = {
                      state: {
                        maintenanceStatus: "NotClearLocalStorage"
                      }
                    }
                    this.route.navigate(['/login/app-maintenance'], navigationExtras);
                  }
                  if (
                    checkDetails != null &&
                    checkDetails != undefined &&
                    checkDetails != ''
                  ) {
                    var mobileversion = this.applicationVersionNumber.split('.');
                    var dbversion = checkDetails.version.split('.');
                    var mobileversioncount = 0;
                    for (let i = 0; i < mobileversion.length; i++) {
                      mobileversioncount =
                        parseInt(mobileversion[i]) + mobileversioncount;
                    }
                    var dbversioncount = 0;
                    for (let i = 0; i < dbversion.length; i++) {
                      dbversioncount = parseInt(dbversion[i]) + dbversioncount;
                    }
                    if (
                      checkDetails.isMust &&
                      mobileversioncount < dbversioncount
                    ) {
                      if (!this.isAlertOpen) {
                        const alert = await this.alertController.create({
                          header: 'New Update!',
                          message: 'Please upgrade the app to proceed further',
                          buttons: [
                            {
                              text: 'Update',
                              handler: (data) => {
                                this.mustUpdate();
                              },
                            },
                          ],
                          backdropDismiss: false,
                        });
                        await alert.present();
                        this.isAlertOpen = true;
                        alert.onDidDismiss().then(() => {
                          this.isAlertOpen = false;
                        });
                      }

                    }
                  }
                },

                (err) => {

                }
              );
          }
        });
    });
  })
  }
  deleteDatabase() {
    if (localStorage.getItem('serverDatasynced') == 'true') {
      this.sqlite
        .deleteDatabase({
          name: 'evv.db',
          location: 'default',
        })
        .then(() => { });
    }
  }
  mustUpdate() {
    if (this.platform.is('android')) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=io.ionic.allcarev1';
    } else if (this.platform.is('ios')) {
      window.location.href =
        'https://apps.apple.com/us/app/allcare-evv/id1552539633?';
    }
  }
  async saveDeviceInfo() {
    await this.checkRefreshTokenValidity().then((data) => {
     
      let url = 'api/Evvconfiguration/SaveDeviceInfo';
      let saveData = new deviceInfoBo();
      if (this.role == 'CLIENT') {
        saveData.clientId = this.EvvUserDetails?.entityId;
        saveData.employeeId = 0;
      } else if (this.role == 'PCAEMPLOYEE') {
        saveData.employeeId = this.EvvUserDetails?.entityId;
        saveData.clientId = 0;
      }
      saveData.deviceManufacturer = this.device.manufacturer;
      saveData.deviceModel = this.device.model;
      saveData.devicePlatform = this.device.platform;
      saveData.deviceSerialNo = this.device.uuid;
      saveData.deviceVersion = this.device.version;
      saveData.isVirtualDevice =
        this.device.isVirtual == null ? '' : this.device.isVirtual.toString();
      saveData.applicationVersionNumber = this.applicationVersionNumber;
      this.SaveClientEmployeeDeviceInformation();
      this.http.post(url, saveData).subscribe(
        (data) => { 

          
        },
        (err) => { }
      );
    })
  }
  async SaveClientDeviceInformation() {
      let url = 'api/Client/SaveClientDeviceInformation';
      let saveData = new ClientDeviceInformationBO();
      saveData.clientId = this.EvvUserDetails?.entityId;
      saveData.deviceUUID = this.device.uuid;
      saveData.fCMToken = this.FCMtoken;
      saveData.deviceType = this.device.platform;
      await this.checkRefreshTokenValidity().then((data) => {
      this.http.post(url, saveData).subscribe(
        (data) => { },
        (err) => { }
      );
    })
  }
  async SaveEmployeeDeviceInformation() {
      let url = 'api/Employee/SaveEmployeeDeviceInformation';
      let saveData = new EmployeeDeviceInformationBO();
      saveData.employeeId = this.EvvUserDetails?.entityId;
      saveData.deviceUUID = this.device.uuid;
      saveData.fCMToken = this.FCMtoken;
      saveData.deviceType = this.device.platform;
      await this.checkRefreshTokenValidity().then((data) => {
      this.http.post(url, saveData).subscribe(
        (data) => { },
        (err) => { }
      );
      }) 
    
  }
  SaveClientEmployeeDeviceInformation() {
    if (this.role == 'CLIENT') {
      this.SaveClientDeviceInformation();
    } else if (this.role == 'PCAEMPLOYEE') {
      this.SaveEmployeeDeviceInformation();
    }
  }
  async getFCMNotification() {
    if (this.platform.is('android')) {
      FCM.subscribeToTopic('Deals');
      await FCM.getToken().then(
        (token) => {
          this.FCMtoken = token;

          if (this.UserData != null) {
            this.saveDeviceInfo();
          }
        },
        (error) => { }
      );
    } else if (
      this.platform.is('ios') ||
      this.platform.is('ipad') ||
      this.platform.is('iphone')
    ) {
      FCM.subscribeToTopic('Deals');
      await FCM.requestPushPermission().then((permission) => {
        if (permission) {
          FCM.getToken().then(
            (token) => {
              this.FCMtoken = token;
             
              this.saveDeviceInfo();
            },
            (error) => {
              this.getFCMNotification();
            }
          );
        }
      });
    }
    FCM.onNotification().subscribe((fcmoutput) => {
      if (!fcmoutput.wasTapped) {
        this.showtoasterinBackground(fcmoutput);
        // } else {
        //   this.route.navigate(['/tabs/currentdaydashboard']);
        //   if (this.role == "CLIENT") {

        //   } else if (this.role == "PCAEMPLOYEE") {

        //   }
      }
    });
  }
  async showtoasterinBackground(data) {
    if (this.platform.is('android')) {
      const alert = this.alertController.create({
        header: 'Schedule Alert!',
        message: data.title + ' : ' + '\n' + data.body,
        buttons: [
          {
            text: 'ok',
            handler: (data) => { },
          },
        ],
        backdropDismiss: false,
      });
      await (await alert).present();
    }
    if (this.platform.is('ios')) {
      const alert = this.alertController.create({
        header: 'Schedule Alert!',
        message: data.title + ' : ' + '\n' + data.body,
        buttons: [
          {
            text: 'ok',
            handler: (data) => { },
          },
        ],
        backdropDismiss: false,
      });
    }
  }
  revokeLogout() {
    this.token = null;
    this.Toastdanger('Session Expired, Your Session Got Expired, Please Login');
    localStorage.clear();
    this.UserData = new userdata();
    this.route.navigate(['/login'], { replaceUrl: true });
    this.EvvUserDetails.agencyId = null;
   
    this.UserData.accessToken = null;
    this.deleteDatabase();
  }
  public async revokeSessionLogout() {

      let url = 'api/Auth/revokeSessionLogout?';
      let Params = new URLSearchParams();
      Params.append('UserRole', this.EvvUserDetails.entityType);
      Params.append('RefreshTokenString', this.refreshTokenString);
      Params.append('UserId', this.EvvUserDetails.entityId.toString());
      Params.append('UserName', this.EvvUserDetails.userName);
      Params.append('AgencyCode', this.EvvUserDetails.agencyCode);
      await this.checkRefreshTokenValidity().then((data) => {
      this.http.get(url + Params).subscribe(
        (data) => { },
        (err: HttpErrorResponse) => {
          if (err.status == 200) {

            if (err.error.text == 'Remove') {
              this.revokeLogout();
            }
          }
        }
      );
    })
  }
  async getAndroidPermission() {
    const Finelocation = (await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION)).hasPermission
    const AccessCoarseLocation = (await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION)).hasPermission
    if (Finelocation && AccessCoarseLocation) {

    }
    else {
   
      if (!this.isBgAlertOpen) {
        const alert = this.alertController.create({
          header: 'Background Location!',
          message: 'This app collect your location data to capture your live location while providing services to your clients!',
          buttons: [
            {
              text: 'Deny',
              handler: async () => {
                navigator['app'].exitApp();
              },
            },
            {
              text: "Accept",
              handler: async () => {
                this.requestGPSPermission()
              },
            }
          ],
          backdropDismiss: false,
        });
        await (await alert).present();
        this.isBgAlertOpen = true;
        (await alert).onDidDismiss().then(() => {
          this.isBgAlertOpen = false;
        });
      }

     
    }
  }
  requestGPSPermission() {
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {


      if (canRequest) {


      } else {


        //Show 'GPS Permission Request' dialogue
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION)
          .then(
            () => {
              // call method to turn on GPS
              this.askToTurnOnGPS();
            },
            error => {
              //Show alert if user click on 'No Thanks'
              alert('requestPermission Error requesting location permissions ' + error)
            }
          );
      }
    });
  }
  askToTurnOnGPS() {

    this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
      () => {
        // When GPS Turned ON call method to get Accurate location coordinates
      },
      error => alert('Error requesting location permissions ' + JSON.stringify(error))
    );
  }

  async getServiceType() {
    await this.checkRefreshTokenValidity().then((data) => {
     
      let url = "api/LOV/getLovDropDown?"
      let param = new URLSearchParams();
      param.append("Code", "SERVICETYPE");
      this.http.get(url + param).subscribe((data: Array<any>) => {

        this.serviceType = data;
      }, err => {
        if (err.Status == 401) {
          this.GloballogOut();
        }
      })
    })
  }
  DateandTimeFormat(date: any): string {
    var tzoffset = new Date(date).getTimezoneOffset() * 60000; //offset in milliseconds
    return new Date(new Date(date).getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
  }


  getDateWithStartTime(Date): string {

    return moment(Date).startOf('d').toISOString();
  }

  getDateWithEndTime(Date): string {

    return moment(Date).endOf('d').toISOString();
  }
  getMnEmployeeMenus(): Observable<any> {
    let EM = [
      {
        title: 'Open Session',
        url: '/tabs/employee-active-session',
        icon: 'albums',
      },
      {
        title: 'My Dashboard',
        url: '/tabs/currentdaydashboard',
        icon: 'stats-chart',
      },
      // {
      //   title: 'My Clients',
      //   url: '/tabs/my-clients',
      //   icon: 'mail'
      // },
      // {
      //   title: 'All Timesheets',
      //   url: '/tabs/all-timesheets',
      //   icon: 'paper-plane'
      // },
      {
        title: 'Select Language',
        url: '/tabs/languages',
        icon: 'language',
      },
      {
        title: 'Service and Units',
        url: '/tabs/employeeserviceunits',
        icon: 'albums',
      },
      {
        title: 'My Schedules',
        url: '/tabs/scheduledashboard',
        icon: 'calendar',
      },
      // {
      //   title: 'Offline Manager',
      //   url: '/tabs/offline',
      //   icon: 'cloud-offline',
      // },

      {
        title: 'Manual E Timesheet',
        url: '/tabs/manual-electronic-timesheet',
        icon: 'clipboard',
      },
      {
        title: 'Private Pay Mileage Logs',
        url: '/tabs/private-pay-mileage',
        icon: 'albums',
      },
      {
        title: 'Change Password',
        url: '/tabs/employee-change-password',
        icon: 'key',
      },
    ];
    return of(EM);
  }

  getUSEmployeeMenus(): Observable<any> {
    let EM = [
      {
        title: 'Open Session',
        url: '/tabs/employee-active-session',
        icon: 'albums',
      },
      {
        title: 'My Dashboard',
        url: '/tabs/currentdaydashboard',
        icon: 'stats-chart',
      },

      {
        title: 'Select Language',
        url: '/tabs/languages',
        icon: 'language',
      },
      {
        title: 'Service and Units',
        url: '/tabs/employeeserviceunits',
        icon: 'albums',
      },
      {
        title: 'My Schedules',
        url: '/tabs/scheduledashboard',
        icon: 'calendar',
      },

      // {
      //   title: 'Offline Manager',
      //   url: '/tabs/offline',
      //   icon: 'cloud-offline',
      // },

      {
        title: 'Manual E Timesheet',
        url: '/tabs/manual-electronic-timesheet',
        icon: 'clipboard',
      },


      {
        title: 'Change Password',
        url: '/tabs/employee-change-password',
        icon: 'key',
      },
    ];
    return of(EM);
  }

  getClientMenus(): Observable<any> {
    let CM = [
      {
        title: 'Open Session',
        url: '/client/client-active-session',
        icon: 'albums',
      },
      {
        title: 'My Dashboard',
        url: '/client/client-dashboard-new',
        icon: 'stats-chart',
      },
      {
        title: 'My Schedules',
        url: '/client/clientdashboard',
        icon: 'calendar',
      },
      {
        title: 'All Timesheets',
        url: '/client/client-all-timesheets',
        icon: 'paper-plane',
      },

      {
        title: 'Select Language',
        url: '/client/client-languages',
        icon: 'language',
      },
      {
        title: 'My Calendar',
        url: '/client/calenderschedule',
        icon: 'calendar',
      },
      {
        title: 'Service and Units',
        url: '/client/client-service-units',
        icon: 'albums',
      },
      {
        title: 'Change Password',
        url: '/client/client-change-password',
        icon: 'key',
      },
    ];;
    return of(CM);
  }
  async checkZoneConfirmation() {
    if (this.EvvUserDetails.timeZone != '' && this.EvvUserDetails.timeZone != null && this.EvvUserDetails.timeZone != undefined) {
      const Zone = moment.tz(moment.tz.guess()).format('Z');
      const agencyZone = moment.tz(this.EvvUserDetails.timeZone).format('Z');
      if (Zone !== agencyZone) {
        const alert = this.alertController.create({
          header: 'TimeZone!',
          message: 'Your Agency Timezone and System Timezone are Not the Same. Please Change Your System Timezone to Agency Zone.',
          buttons: [
            {
              text: 'ok',
              handler: (data) => {
              },
            },
          ],
          backdropDismiss: false,
        });
        await (await alert).present();
      }

    }
  }


  checkAppStateChange() {
    App.addListener('appStateChange', async ({ isActive }) => {



      if (this.applicationVersionNumber != null && this.applicationVersionNumber != undefined && this.applicationVersionNumber != "" && this.offline!=true && isActive) {

        this.CheckForUpdateAvailable();

      }
    });

  }
  getDateWithUTCFormat(time){
    return moment.utc(time).startOf('day').toISOString();  // Return '2024-07-01T00:00:00.000Z'
  }
  getDateWithUTC(Date): string {
    return moment(Date).utc().toISOString();  
   }


  async getEvvStatus(): Promise<void> {
    {
      // await this.checkRefreshTokenValidity().then((data) => {
      return new Promise(async (resolve, reject) => {
        let url = 'api/Evvconfiguration/GetLatestVersion?';
        let myParams = new URLSearchParams();
        myParams.append('OldVersion', null);
        myParams.append('OSPlatform', this.device.platform);
        await this.checkRefreshTokenValidity().then((data) => {
        
        this.http.get(url + myParams).subscribe(
          async (checkDetails: any) => {
            resolve(checkDetails);
          },

          (err) => {
          }
        );
        })

      });

    }
  }
  public getRefreshToken(param: any, payload): Observable<any> {
    let url = "api/Auth/refreshToken"
    return this.http.post(url, payload).pipe();
  }
  async checkRefreshTokenValidity(): Promise<void> {
  
   if (localStorage.getItem('Evv_login') !== null) {
    return new Promise((resolve, reject) => {
      let url = 'api/Auth/refreshToken';
      let refreshpayload = {
        refreshToken: this.refreshTokenString,
        accessToken: this.UserData.accessToken,
      };
      const currentDateTime: any = new Date();
      let expiration: any = localStorage.getItem("Evv_RefreshExpireTime")
      this.disableloading()
      if (new Date(currentDateTime).getTime() > new Date(expiration).getTime()) {
        localStorage.removeItem("Evv_RefreshExpireTime");
        this.getRefreshToken(url, refreshpayload).subscribe(
          (response: any) => {
            this.platform.ready().then(() => {
              this.appVersion.getVersionNumber().then((data) => {
                this.applicationVersionNumber = data;
                this.CheckForUpdateAvailable();
                this.getFCMNotification();
                // if (
                //   this.applicationVersionNumber != null &&
                //   this.applicationVersionNumber != undefined &&
                //   this.applicationVersionNumber != '' &&
                //   this.platform.is('android')
                // ) {
                //   this.platform.ready().then(() => {
                //     this.getAndroidPermission();
                //   });
                // }
              });
            });
            this.isassignboolean = true;
            this.UserData.accessToken = response.accessToken;
            localStorage.setItem('Evv_login', JSON.stringify(response));
            let refreshToken = response.refreshToken;
            localStorage.setItem('set_cookie', refreshToken);
            this.refreshTokenString = refreshToken;
            this.scheduleRefresh()
            this.disableloading()
            resolve(response);
          },
          (err: HttpErrorResponse) => {
            this.revokeLogout();
            reject(err);
          }
        );
      }
      else {
        this.disableloading()
       resolve()
      }
    });
   }
}
}
export class deviceInfoBo {
  clientId: number | null;
  employeeId: number | null;
  deviceManufacturer: string;
  deviceModel: string;
  devicePlatform: string;
  deviceVersion: string;
  deviceSerialNo: string;
  isVirtualDevice: string;
  applicationVersionNumber: string;
  pushUserId: string;
}

export class userdata {
  accessToken: string = null;
}

export class EvvUserDetailBO {
  agencyId: number;
  agencyName: string;
  agencyCode: string;
  agencyPhone: string;
  agencyLogo: string;
  userName: string;
  roleId: string;
  roleName: string;
  setLanguage: string;
  entityType: string;
  entityId: number;
  email: string;
  timeZone: string;
  isLoggedIn: boolean;
}
export class menu {
  children: number;
  icon: string;
  link: string;
  title: string;
  url: string;
}
export class RefreshRequest {
  refreshToken: string;
  accessToken: string;
}
export class ClientDeviceInformationBO {
  deviceUUID: string;
  fCMToken: string;
  deviceType: string;
  clientId: number;
}
export class EmployeeDeviceInformationBO {
  deviceUUID: string;
  fCMToken: string;
  employeeId: number;
  deviceType: String;
}
