export class HomemakingCarePlanBO {
    id: number;
    clientId: number;
    agencyId: number | null;
    serviceId: number | null;
    clientName: string;
    clientPhone: string;
    clientAddress: string;
    clientState: string;
    clientCity: string;
    clientCounty: string;
    clientZipcode: string;
    clientEmail: string;
    clientemergencyContactName: string;
    clientemergencyContactPhone: string;
    agencyName: string;
    agencyStreet: string;
    agencyCity: string;
    height: number | null;
  weight: number | null;
    agencyState: string;
    agencyZipCode: string;
    agencyPhone: string;
    agencyEmail: string;
    agencyFax: string;
    mealPreparation: string;
    errands: string;
    companionship: string;
    notes: string;
    qpName: string;
    qpPhone: string;
    caseName: string;
    serviceName: string;
    casePhone: string;
    completedDate: string;
    isQp: boolean | null;
    isCaseManager: boolean | null;
    qpCompletedDate: string | null;
    caseManagerCompletedDate: string | null;
    date: string;
    qpEmployeeID: number | null;
    pOCConsultation: string;
    pOCDevelopedby: string;
    pOCDevelopedDate: string | null;
    hMKOrientedClientBy: string;
    hMKOrientedDate: string | null;
    hMKCoordinatorSign: string;
    clientSign: string;
    responsiblePartySign: string;
    homeMakerSign: string;
    hMKCoordinatorSignUrl: string;
    clientSignUrl: string;
    responsiblePartySignUrl: string;
    homeMakerSignUrl: string;
    hMKCorrdinatorSignDate: string | null;
    clientSignDate: string | null;
    responsiblePartySignDate: string | null;
    homeMakerSignDate: string | null;
    serviceactivity: homemakingCarePlanServiceActivitiesBO[];
}
export class homemakingCarePlanServiceActivitiesBO {
    id: number;
    homemakingCarePlanId: number;
    masterServiceActivityId: number;
    masterServiceActivityName: string;
    serviceActivitiesComment: string;
    isChecked: boolean;
    everyVisit: boolean;
    evertWeek: boolean;
}

export class HomeMakingExpandingBo {

    ClientInformationBool: boolean = true;
    EmergencyBool: boolean = false;
    ServiceInformationBool: boolean = false;
    MealPreparationBool: boolean = false;
    ErrandsBool: boolean = false;
    CompanionshipSocializationBool: boolean = false;
    NotesBool: boolean = false;

    QualifiedProfessionalBool: boolean = false;
    CaseManagerBool: boolean = false;
    AgencyInformationBool: boolean = false;
    PlanofCareBool: boolean = false;
    HMKCoordinatorBool: boolean = false;
    ClientBool: boolean = false;
    RpBool: boolean = false;
    HomeMakingBool: boolean = false;
}
export class HomeMakingSignatureBo {
    Hmksign: string;
    Clientsign: string;
    Rpsign: string;
    HomeMakingsign: string;
   
}