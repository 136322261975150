import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientLanguagesPage } from './client-languages.page';



const routes: Routes = [
  {
    path: 'languages',
    component: ClientLanguagesPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientLanguagesPageRoutingModule {}
