import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { IclsExpandingBo, IclsListBo, IclsSignatureBo } from './icls-care-plan.model';
import { ICLSService } from './icls-care-plan.service';
@Component({
  selector: 'app-icls-care-plan',
  templateUrl: './icls-care-plan.page.html',
  styleUrls: ['./icls-care-plan.page.scss'],
})
export class IclsCarePlanPage implements OnInit {

clientId: any;
  ShowIclsPlans: boolean = false;
  selectedIclsCareplan: any;
  IclsCarePlans: any;
  isExpand: IclsExpandingBo = new IclsExpandingBo();
  sign: IclsSignatureBo = new IclsSignatureBo();
  getIclsList: IclsListBo = new IclsListBo();
  constructor(private activatedRoute: ActivatedRoute,public general: generalservice, private router: Router,public icls: ICLSService, public global: Globalservice, public errorService: ErrorService, public PopupController: ModalController,
    public datePipe: DatePipe,) { 
      this.global.checkingLogin();
      // this.activatedRoute.queryParams.subscribe(params => {
      //   if (params && params.Iclscare) {
      //     this.clientId = JSON.parse(params.Iclscare);
      //   }
      // });
    }
  ngOnInit() {
  }
  async ionViewWillEnter() {
    this.clear();
    await this.global.checkRefreshTokenValidity()
    {
    this.getICLSCarePlanDateDropdown();
    }
  }
  async getICLSCarePlanDateDropdown() {
 
    let param = new URLSearchParams();
    param.append('ClientId', this.global.clientid.toString())
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.icls.getICLSCarePlanDateDropdown(param).subscribe((data: any) => {
      this.IclsCarePlans = data;
    
      if(data.length==0)
      {
        this.global.Toastdanger("No Records Found");
      }
      this.global.disableloading();
      this.IclsCarePlans?.forEach(element => {
        element.Value = this.datePipe.transform(element.Value, "MM-dd-yyyy");
      });
    }, err => {
      this.errorService.check(err);
      this.global.disableloading();
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }

  // .......onChangeEvent..................................................................
  onChangeEvent() {
    if (this.selectedIclsCareplan != null) {
      this.gethomemakingcarePlanDetails();
    }
  }
  //..................gethomemakingcarePlanDetails...........................................................
  async gethomemakingcarePlanDetails() {
   
    let param = new URLSearchParams();
    param.append('id', this.selectedIclsCareplan)
    await this.global.checkRefreshTokenValidity()
    {
      await this.global.showLoading();
    this.icls.getICLSCarePlanDetails(param).subscribe((data: any) => {
      this.getIclsList = JSON.parse(JSON.stringify(data));
    
      this.getIclsList.serviceActivity = data.serviceActivity;
      this.sign.iclscoordinatorsign = data.iclsCoordinatorSignUrl;
      this.sign.iclsClientsign = data.clientSignUrl;
      this.sign.iclsWokersign = data.iclsWorkerSignUrl;
      this.getIclsList.iCLSCoordinatorSignDate = data.iclsCoordinatorSignDate;
      this.getIclsList.iCLSWorkerSignDate = data.iclsWorkerSignDate;
      this.getIclsList.dNRDNI = data.dnrdni;

      if(data.clientPhone!=null && data.clientPhone!=undefined && data.clientPhone!='')
      {
        this.getIclsList.clientPhone = this.general.converPhoneGoogleLib(data.clientPhone);
      }
      this.ShowIclsPlans = true;
      this.clearweightheight();
      this.global.disableloading();
    }, err => {
      this.errorService.check(err);
      this.ShowIclsPlans = false;
      this.global.disableloading();
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }

  Back() {
    this.clear();
    this.router.navigateByUrl('tabs/my-clients');

  }
  clear()
  {
    this.isExpand=new  IclsExpandingBo();
    this.sign=new IclsSignatureBo();
    this.getIclsList=new IclsListBo();
    this.ShowIclsPlans = false;
    this.selectedIclsCareplan=null;
  }

  clearweightheight() {
    if (this.getIclsList.weight == 0 || this.getIclsList.weight == null || this.getIclsList.weight == undefined) {
      this.getIclsList.weight = null;
    }
    if (this.getIclsList.height == 0 || this.getIclsList.height == null || this.getIclsList.height == undefined) {
      this.getIclsList.height = null;
    }
  }
}
