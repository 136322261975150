import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { Globalservice } from 'src/app/global.service';
import { ResetPasswordService } from './reset-password.service';
import { MyClientsService } from 'src/app/pages/my-clients/my-clients.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage {
  passerror: boolean = true;
  passerror1: boolean = true;
  Otp: any;
  ConfirmPassword: string = ""
  NewPassword: string = ""
  NPPasswordType: string = "password"
  CPPasswordType: string = "password"
  CPPasswordIcon: string = "eye-off"
  NPPasswordIcon: string = "eye-off"
  getResetData: any;
  constructor(  public platform: Platform,public myclientservice:MyClientsService,  private route: ActivatedRoute,public menu: MenuController, public router: Router, public global: Globalservice, public http: HttpClient, private toastCtrl: ToastController,public RpService:ResetPasswordService,) {
    this.route.queryParams.subscribe((params) => {
      if (this.router.getCurrentNavigation().extras.state) {
        let data = this.router.getCurrentNavigation().extras.state;
        this.getResetData = data;
      }
    });
  
  }
  ionViewWillEnter() {
    this.menu.enable(false);
  }
  //......................................................resetPassword............................................
  resetPassword() {
    this.router.navigate(['/login/login-password'])
  }
  //......................................................cancelReset............................................
  cancelReset() {
    this.Otp = null;
    this.ConfirmPassword = ""
    this.NewPassword = "";
    this.passerror = true;
    this.passerror1 = true;
    this.router.navigate(['/login/login-password'])
  }
  //......................................................assvalid............................................
  assvalid(event) {
    let str = event.target.value;
    let res;
    if (str.match(/[a-z]/g) && str.match(
      /[A-Z]/g) && str.match(
        /[0-9]/g) && str.match(
          /[^a-zA-Z\d]/g) && str.length >= 8) {
      this.passerror = false;
    }
    else {
      this.passerror = true;
    }

  }
  //......................................................confirmResetpassword............................................
  async confirmResetpassword() {

      if (this.ConfirmPassword == this.NewPassword) {
        await this.global.showLoading();
        let param = new URLSearchParams();
        param.append('OtpId', this.getResetData.otpId);
        param.append('Otp', this.Otp);
        param.append('LoginUserId', this.getResetData.userId.toString());
        param.append('UserRole', this.getResetData.userRole);
        param.append('AgencyId', this.getResetData.agencyId.toString());
        param.append('UserName', this.getResetData.userName);
        param.append("PassWord", this.NewPassword)
        this.RpService.resetUserPassword( param).subscribe(
          (data: any) => {
            this.global.ToastSuccessMiniDuration('Password Reset Successfully');
            this.sessionLogout();
            this.Otp = null;
            this.ConfirmPassword = ""
            this.NewPassword = "";
            this.passerror = true;
            this.passerror1 = true;
            this.router.navigate(['/login'], { replaceUrl: true });
            this.global.disableloading();
          },
          (err: HttpErrorResponse) => {
            this.global.disableloading();
            this.presentToast(err.message);
          }
        );
       
      } else {
        this.global.ToastDangerMiniDuration("New and Confirm password does not match");
      }
   
  }
  //......................................................NPhide............................................
  NPhide() {
    this.NPPasswordType = this.NPPasswordType === 'text' ? 'password' : 'text';
    this.NPPasswordIcon = this.NPPasswordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }
  //......................................................login............................................
  CPhide() {
    this.CPPasswordType = this.CPPasswordType === 'text' ? 'password' : 'text';
    this.CPPasswordIcon = this.CPPasswordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }
  //......................................................assvalid1............................................
  assvalid1(event) {
    let str = event.target.value;
    let res;
    if (str.match(/[a-z]/g) && str.match(
      /[A-Z]/g) && str.match(
        /[0-9]/g) && str.match(
          /[^a-zA-Z\d]/g) && str.length >= 8) {
      this.passerror1 = false;
    }
    else {
      this.passerror1 = true;
    }
  }
 
  //......................................................presentToast............................................
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'danger',
    });
    toast.present();
  }

  sessionLogout() {
    let count=0;
    let myParams = new URLSearchParams();
    myParams.append('UserName',  this.getResetData.userName);
    myParams.append('AgencyCode', this.getResetData.agencycode);
    myParams.append('UserRole', this.getResetData.userRole);
    myParams.append('RefreshTokenString', "FORGOTPASSWORD");
    myParams.append('UserId', count.toString());
    this.myclientservice.sessionLogout(myParams).subscribe((data:any) => {
      this.global.disableloading();
    }, err => {
      this.global.disableloading();
      
    })
  }
}

