import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Globalservice } from './global.service';
@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(public global: Globalservice) { }
  handleError(error: HttpErrorResponse) {
    let errorMessage = {};
    let status = error.status;
    let message = 'Unknown Error';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      message = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      console.log("server side err", error);

      message = `${error.error}`;
    }
    errorMessage = { "message": message, "Status": status };
    return throwError(errorMessage);
  }
  Getstatus(error: HttpErrorResponse) {
    let status = error.status;
    return status;
  }
  check(errorMessage) {
    console.log(errorMessage);
  }
}
