import { Location } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { Storage } from "@ionic/Storage";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Globalservice } from 'src/app/global.service';
import { ClientLanguageService } from "./client-language.service";
import { LanguageBO } from "./client-languages.model";

@Component({
  selector: 'app-client-languages',
  templateUrl: './client-languages.page.html',
  styleUrls: ['./client-languages.page.scss'],
})
export class ClientLanguagesPage implements OnInit {
  es: FormGroup;
  eng: FormGroup;
  rus: FormGroup;
  languageBo: LanguageBO = new LanguageBO()
  setlang: boolean = true;
  userId: string;
  data: any;
  languages = [];
  selected = "";
  selecteslan: any;
  constructor(public translateService: TranslateService, private location: Location, public router: Router, public global: Globalservice, public storage: Storage, public toastCtrl: ToastController,
    private languageService: ClientLanguageService, public http: HttpClient, public alertController: AlertController, public trans: TranslateModule) {
      this.global.checkingLogin();
        
  }
  ionViewWillEnter()
  {
       
  }
  ngOnInit() {
    this.languages = this.languageService.getLanguages();
    this.selected = this.languageService.selected;
    this.selected = this.global.EvvUserDetails.setLanguage;
    this.selected = localStorage.getItem("Evv_clientlanguage");
  }

  select(lng) {
    this.languageService.setLanguage(lng);
    this.selected = lng;
    this.translateService.use(lng);
    // this.global.languageSelected=lng
    localStorage.setItem("Evv_clientlanguage", lng);
    this.goToNextPage()
  }
  async goToNextPage() {
    await this.global.checkRefreshTokenValidity()
    {
    this.languageBo.userId = this.global.EvvUserDetails.entityId.toString();
    this.languageBo.language = this.selected;
    let url = 'api/LoginUser/SetLanguage';
    this.http.post(url, this.languageBo).subscribe((data) => {
      localStorage.setItem("Evv_clientlanguage", this.languageBo.language);
      this.isLoggedin();
      this.router.navigate(['/client/client-all-timesheets'])
      this.presentToast('Language Changed Successfully')
    })
  }
  }
  async isLoggedin() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams()
    param.append("UserId", this.global.EvvUserDetails.entityId.toString());

    let url = 'api/LoginUser/SetLoginDetail?'
    this.http.post(url + param, '').subscribe((data) => {
    })

  }
  }
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }
  back() {
    this.router.navigate(['/client/client-all-timesheets'], { replaceUrl: true });
    // this.location.back();
  }

}
