import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ClientLanguagesPageModule } from './client-languages/client-languages.module';
import { ClientLoginPageRoutingModule } from './client-login-routing.module';
import { ClientLoginPage } from './client-login.page';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ClientLoginPageRoutingModule,
    TranslateModule,
    ClientLanguagesPageModule,
    ReactiveFormsModule
  ],
  declarations: [ClientLoginPage]
})
export class ClientLoginPageModule {}
