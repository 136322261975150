import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {
  NavController,
  LoadingController,
  MenuController,
  Platform,
  ToastController,
  IonRouterOutlet,
  AlertController,
} from '@ionic/angular';
import { Storage } from '@ionic/Storage';
import { TranslateService } from '@ngx-translate/core';
import { Globalservice } from './global.service';
import { LanguageService } from './language.service';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { MyEmployeeCurrentDashboardService } from './pages/employecurrentdashboard/employeecurrentdashboard.service';
import { generalservice } from './general.service';
import { sqliteService } from './sqlite.service';
import { offlineService } from './offline-data.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Network } from '@ionic-native/network/ngx';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  name: string = '';
  email: string = '';
  applicationVersionNumber: string;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  @ViewChild(NavController) nav: NavController;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  onlineTimer: any;
  offlineTimer: any;
  onlineLoader = null;
  offlineLoader = null;
  islogoutOfAllSessions: boolean = false;
  offlinedata: any = [];
  acceptLoading = null;
  dashboardLoad = null;
  isFirstOpen: boolean = true;
  onFirstOpen: boolean = false;
  database: any = [];
  Offlinedata: any = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public menuCtrl: MenuController,
    public router: Router,
    public global: Globalservice,
    public languageService: LanguageService,
    public storage: Storage,
    public translateService: TranslateService,
    private sqlite: SQLite,
    public device: Device,
    public toastCtrl: ToastController,
    public loadingCtrl: LoadingController,
    public clientService: MyEmployeeCurrentDashboardService,
    public general: generalservice,
    public alertController: AlertController,
    public sqlService: sqliteService,
    public offline: offlineService,
    private network: Network,
    private localNotifications: LocalNotifications,
    public http: HttpClient,
    public appVersion: AppVersion
  ) {
    this.splashScreen.hide();
    this.backButtonEvent();
    let tokenData = JSON.parse(localStorage.getItem('Evv_login'));
    this.global.refreshTokenString=tokenData?.refreshToken;
   
    this.global.UserData = JSON.parse(localStorage.getItem('Evv_login'));
    this.global.EvvUserDetails = JSON.parse(
      localStorage.getItem('EvvUserDetails')
    );

    this.global.timezone = JSON.parse(
      localStorage.getItem('TimeZone')
    );
    this.global.role = JSON.parse(localStorage.getItem('Evv_role'));
    if (this.global.UserData != null) {
      if (
        this.global.role == 'PCAEMPLOYEE' ||
        this.global.role == 'QPEMPLOYEE'
      ) {
        this.global.EvvUserDetails.setLanguage = localStorage.getItem(
          'Evv_employeelanguages'
        );
        this.name = this.global.EvvUserDetails.userName;
        this.email = this.global.EvvUserDetails.email;
        this.languageService.setLanguage(
          this.global.EvvUserDetails.setLanguage
        );
      } else if (this.global.role == 'CLIENT') {
        this.global.EvvUserDetails.setLanguage =
          localStorage.getItem('Evv_clientlanguage');
        this.name = this.global.EvvUserDetails.userName;
        this.email = this.global.EvvUserDetails.email;
        if (this.global.EvvUserDetails.setLanguage != null) {
          this.languageService.setLanguage(
            this.global.EvvUserDetails.setLanguage
          );
        } else {
          this.languageService.setLanguage('en');
          this.router.navigate(['/client/client-languages']);
        }
      }
      this.global.CheckForUpdateAvailable();
      this.network.onConnect().subscribe(() => {
        this.global.offline = false;
        this.global.isOnline = true;
        setTimeout(() => {
          this.global.isOnline = false;
        }, 3000);
      });
      this.network.onDisconnect().subscribe(() => {
        this.global.offline = true;
      });
    }
    this.initializeApp();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.appVersion.getVersionNumber().then((data) => {
        this.applicationVersionNumber = data;
        this.global?.getFCMNotification();
      });
     
     
      this.statusBar.backgroundColorByHexString('#004290');
      this.splashScreen.hide();
      this.global.height = this.platform.height() - 200 + 'px';
      this.statusBar.overlaysWebView(false);
      this.storage.get('loginAuth').then((val) => {
       
        if (val) {
          this.storage.get('CurrentDate').then((val) => {
            if (!val) {
              this.storage.set('CurrentDate', new Date().getDate());
            }
          });
        }
      });
    });
    this.onFirstOpen = true;
  }
  ngOnInit() {
    
    const path = window.location.pathname.split('pages/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.global.appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }
    this.global.UserData = JSON.parse(localStorage.getItem('Evv_login'));
    this.global.role = JSON.parse(localStorage.getItem('Evv_role'));
    let EvvUserData = JSON.parse(localStorage.getItem('Evv_login'));
    if (this.global.role == 'PCAEMPLOYEE' || this.global.role == 'QPEMPLOYEE') {
      if (this.global.timezone == "US") {
        this.global.getUSEmployeeMenus().subscribe((data: any) => {
          this.global.appPages=data;
          
        });
      }
      else 
      {
        this.global.getMnEmployeeMenus().subscribe((data: any) => {
          this.global.appPages=data;
        });
      }
    } else if (this.global.role == 'CLIENT') {
      this.global.getClientMenus().subscribe((data: any) => {
        this.global.clientappPages=data;
      });
    }
    if (EvvUserData != null) {
      this.global.UserData.accessToken = EvvUserData.accessToken;
    //   this.global
    //     .checkRefreshTokenValidity()
    //     .then((data) => {
    //       // this.global.checkZoneConfirmation();
    //       this.global.saveDeviceInfo();
    //       this.global.scheduleRefresh();
    //       this.global.getServiceType();
    //       EvvUserData = JSON.parse(localStorage.getItem('Evv_login'));
    //     })
    //     .catch((err) => {
    //       // this.toasterService.error("Your Session Got Expired, Please Login", 'Session Expired');
    //       this.router.navigate(['/login'], { replaceUrl: true }); //Navigated in refreshToken function itself. Just for extra safety
    //     });
    }
    if (this.global.UserData != null) {
      if (
        this.global.role == 'PCAEMPLOYEE' ||
        this.global.role == 'QPEMPLOYEE'
      ) {
        this.name = this.global.EvvUserDetails.userName;
        this.email = this.global.EvvUserDetails.email;
        this.router.navigate(['/login/splash']);
      } else if (this.global.role == 'CLIENT') {
        this.name = this.global.EvvUserDetails.userName;
        this.email = this.global.EvvUserDetails.email;
        this.router.navigate(['/login/splash']);
      }
    } else {
      localStorage.setItem('isOffline', 'false');
      this.router.navigate(['/login/splash']);
    }
  }
  closeMenuProfile() {
    if (this.global.role == 'PCAEMPLOYEE' || this.global.role == 'QPEMPLOYEE') {
      this.router.navigate(['/tabs/profile']);
    } else if (this.global.role == 'CLIENT') {
      this.router.navigate(['/client/client-profile']);
    }
    this.menuCtrl.toggle();
  }
  logout() {
    this.global.token = null;
    this.global.FCMtoken = null;
    this.global.deleteDatabase();
    localStorage.clear();
    this.router.navigate(['/login'], { replaceUrl: true });
    this.global.UserData.accessToken = null;
    this.global.EvvUserDetails.agencyId = 0;
    // this.global.refreshSubscription.unsubscribe();
  }
  syncOffline() {
    if (localStorage.getItem('isOffline') == 'false') {
      this.global.isOffline = false;
      this.storage.get('Version9').then((val) => {
        if (val != null) {
          this.storage.set('Version9', 'Version9');
        } else {
          this.storage.set('Version9', 'Version9');
          this.storage.get('OfflineDate').then((val) => {
            if (val != new Date().getDate()) {
              //----TO DELETE RECORDS ON PAGE LOAD AND THEN RELOAD IT---/////
              this.sqlite
                .create({
                  name: 'evv.db',
                  location: 'default',
                })
                .then((db: SQLiteObject) => {
                  // this.sqlite.deleteDatabase({ name: 'evv.db', location: 'default' }
                  // ).then(() => {
                  this.sqlService.removeAllDataExceptTS();
                  this.sqlService.createOfflineTables();
                  this.storage.remove('OfflineDate');
                  this.getdataoffline();
                  // }
                  // )
                  //   .catch(e => console.log(e));
                })
                .catch((e) => console.log(e));
            }
          });
        }
      });
      //#endregion
    } else {
      this.global.isOffline = true;
    }
  }
  //////------CREATE TABLE TRANS FOR OFFLINE---/////
  createsql() {
    this.sqlite
      .create({
        name: 'data.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        this.database = db;
        db.executeSql(
          'CREATE TABLE IF NOT EXISTS schedule(Id INTEGER UNIQUE,ScheduleId INTEGER UNIQUE,isUpdate VARCHAR(32),IsStarted VARCHAR(32),ClientId INTEGER,ClientName VARCHAR(32),EmployeeId INTEGER,EmployeeName VARCHAR(32),StartDate VARCHAR(32),StartTime VARCHAR(32),EndTime VARCHAR(32),TotalHrs VARCHAR(32),ServiceId INTEGER,Service VARCHAR(32),EmployeeEmail VARCHAR(32),EmployeePhn VARCHAR(32),IsCancelled VARCHAR(32),IsMissed VARCHAR(32),evvTimesheetId INTEGER)',
          []
        )
          .then(() => console.log('schedule'))
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  }
  //////------CREATE TABLE Client FOR OFFLINE---/////
  createsqlclient() {
    this.sqlite
      .create({
        name: 'data.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql(
          'CREATE TABLE IF NOT EXISTS client(Id INTEGER,ClientId INTEGER UNIQUE,AgencyId INTEGER,isUpdate VARCHAR(32),FirstName VARCHAR(32),LastName VARCHAR(32),MiddleName VARCHAR(32),QPname VARCHAR(32),GenderLid INTEGER,DOB VARCHAR(32),Age INTEGER,Weight INTEGER,AKA VARCHAR(32),Medicaid VARCHAR(32),Medicare VARCHAR(32),Street VARCHAR(32),Apt VARCHAR(32),Phone1 VARCHAR(32),Phone2 VARCHAR(32),County VARCHAR(32),State VARCHAR(32),City VARCHAR(32),ZipCode VARCHAR(32),SSN VARCHAR(32),Email VARCHAR(32),StatusLid INTEGER,Status_Name VARCHAR(32),UserName VARCHAR(32),password VARCHAR(32),LocationStatusLid INTEGER,name INTEGER,RequiredEVV VARCHAR(32),LoginUserId VARCHAR(32),evvtimesheetId INTERGER,serviceId INTEGER)',
          []
        )
          .then(() => console.log('client'))
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  }
  //////------CREATE TABLE Service FOR OFFLINE---/////
  createsqlservice() {
    this.sqlite
      .create({
        name: 'data.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql(
          'CREATE TABLE IF NOT EXISTS service(Id INTEGER UNIQUE,ServiceId INTEGER,ClientId INTEGER,isUpdate VARCHAR(32),ServiceName VARCHAR(32))',
          []
        )
          .then(() => console.log('service'))
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  }
  async onlineSwitch() {
    if (this.global.offline && !this.global.isOfflineChangeByUser) {
      if (this.onFirstOpen) {
        let toast = this.toastCtrl.create({
          message: 'Device will go Online in 5 seconds',
          duration: 5000,
          position: 'bottom',
        });
        (await toast).present();
        this.onlineLoader = await this.loadingCtrl.create({
          message: 'Please wait...Moving to Online',
        });
        this.onlineLoader.present();
        this.onlineTimer = setTimeout(async () => {
          this.onlineLoader.dismiss();
          this.global.refresh = true;
          (
            await this.toastCtrl.create({
              message: 'You are online now !',
              duration: 3000,
            })
          ).present();
          this.global.offline = false;
          this.isFirstOpen = false;
          this.storage.get('loginAuth').then((val) => {
            if (val) {
              this.router.navigate(['/tabs/currentdaydashboard']);
            }
          });
        }, 5000);
      }
    }
  }
  ///////---TO LOAD OFFLINE DASHBOARD DATA---/////////
  async getdataoffline() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append('EmployeeId', this.global.EvvUserDetails.entityId.toString());
    param.append('searchtext', '');
    this.showNotification();
    this.offline.getClientsOffline(param).subscribe((data) => {
      data.forEach((offline) => {
        this.sqlService.addClientsItem(offline);
        offline.mappedServices.forEach((maps) => {
          this.sqlService.addClientServiceItems(maps, offline.clientId);
          maps.activities.forEach((actvities) => {
            this.sqlService.addActivitesItems(
              actvities,
              offline.clientId,
              maps.serviceId
            );
          });
        });
      });
      this.localNotifications.clear(1);
    });
    this.showNotificationSchedule();
    let params = new URLSearchParams();
    params.append('EmployeeId', this.global.EvvUserDetails.entityId.toString());
    this.offline.getSchedulesOffline(params).subscribe((data) => {
      data.forEach((offline) => {
        this.sqlService.addScheduleItems(offline);
      });
      this.localNotifications.clear(2);
    });
    this.storage.set('OfflineDate', new Date().getDate());
  }
  }
  showNotification() {
    this.localNotifications.schedule({
      id: 1,
      text: 'Getting Client Offline Data is in progress',
      sound: null,
      icon: 'assets/imgs/logo.png',
      led: true,
    });
  }
  showNotificationSchedule() {
    this.localNotifications.schedule({
      id: 2,
      text: 'Getting Schedule Offline Data is in progress',
      sound: null,
      icon: 'assets/imgs/logo.png',
      led: true,
    });
  }
  ////----GET client DETAILS---/////
  async clientdetails() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append('EmployeeId', this.global.EvvUserDetails.entityId.toString());
    this.clientService.getClientofflinedata(param).subscribe(
      (val: any) => {
        this.insertdataclient(val);
      },
      (err) => {
        if (this.acceptLoading != null) {
          this.acceptLoading.dismiss();
          this.acceptLoading = null;
        }
        if (this.dashboardLoad != null) {
          this.dashboardLoad.dismiss();
          this.dashboardLoad = null;
        }
        console.log(err);
      }
    );
    }
  }
  ////----INSERT client DETAILS---/////

  insertdataclient(val) {
    if (val.length > 0) {
      for (let i = 0; i < val.length; i++) {
        this.sqlite
          .create({
            name: 'data.db',
            location: 'default',
          })
          .then((db: SQLiteObject) => {
            this.database = db;
            this.database
              .executeSql(
                'INSERT INTO client(Id,ClientId,AgencyId,isUpdate,FirstName,LastName,MiddleName,QPname,GenderLid,DOB,Age,Weight,AKA,Medicaid,Medicare,Street,Apt,Phone1,Phone2,County,State,City,ZipCode,SSN,Email,StatusLid,Status_Name,UserName,password,LocationStatusLid,name,RequiredEVV,LoginUserId,evvtimesheetId,serviceId) VALUES(?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)',
                [
                  this.global.EvvUserDetails.entityId,
                  val[i].clientId,
                  val[i].agencyId,
                  'false',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  0,
                  '',
                  '',
                  '',
                  '',
                  val[i].street,
                  '',
                  val[i].mobileNumber,
                  '',
                  val[i].country,
                  val[i].state,
                  val[i].city,
                  val[i].zip,
                  '',
                  val[i].email,
                  '',
                  '',
                  '',
                  '',
                  '',
                  val[i].name,
                  '',
                  '',
                  val[i].evvTimehseetId,
                  val[i].serviceId,
                ]
              )
              .then(() => console.log('route created'))
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      }
    }
  }
  /////---TO GET DATA FROM SQLITE--------//////////
  selectsql() {
    this.sqlite
      .create({
        name: 'data.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        this.database = db;
        this.database
          .executeSql('SELECT * FROM schedule', [])
          .then((data: any) => {
            this.offlinedata = [];
            if (data.rows.length > 0) {
              for (let i = 0; i < data.rows.length; i++) {
                this.offlinedata.push({
                  ScheduleId: data.rows.item(i).ScheduleId,
                  ClientId: data.rows.item(i).ClientId,
                  ClientName: data.rows.item(i).ClientName,
                  EmployeeEmail: data.rows.item(i).EmployeeEmail,
                  EmployeeId: data.rows.item(i).EmployeeId,
                  EmployeeName: data.rows.item(i).EmployeeName,
                  EmployeePhn: data.rows.item(i).EmployeePhn,
                  EndTime: data.rows.item(i).EndTime,
                  Id: data.rows.item(i).Id,
                  IsCancelled: data.rows.item(i).IsCancelled,
                  IsMissed: data.rows.item(i).IsMissed,
                  IsStarted: data.rows.item(i).IsStarted,
                  Service: data.rows.item(i).Service,
                  endTime: data.rows.item(i).endTime,
                  ServiceId: data.rows.item(i).ServiceId,
                  StartDate: data.rows.item(i).StartDate,
                  StartTime: data.rows.item(i).StartTime,
                  TotalHrs: data.rows.item(i).TotalHrs,
                  evvTimesheetId: data.rows.item(i).evvTimesheetId,
                  isUpdate: data.rows.item(i).isUpdate,
                });
              }
              this.offlinedata.forEach(
                (c) =>
                (c.employeePhnFormat =
                  c.employeePhn != null
                    ? this.general.converPhoneGoogleLib(
                      c.employeePhn.toString()
                    )
                    : null)
              );
            }
          })
          .catch((error: any) => { });
      })
      .catch((error: any) => { });
  }
  ////----INSERT schedule DETAILS---/////
  insertdataschedule(val) {
    if (val.length > 0) {
      for (let i = 0; i < val.length; i++) {
        this.sqlite
          .create({
            name: 'data.db',
            location: 'default',
          })
          .then((db: SQLiteObject) => {
            this.database = db;
            this.database
              .executeSql(
                'INSERT INTO schedule(Id,ScheduleId,isUpdate,IsStarted,ClientId,ClientName,EmployeeId,EmployeeName,StartDate,StartTime,EndTime,TotalHrs,ServiceId,Service,EmployeeEmail,EmployeePhn,IsCancelled,IsMissed,evvTimesheetId) VALUES(?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)',
                [
                  val[i].scheduleId,
                  val[i].scheduleId,
                  'false',
                  val[i].isStarted,
                  val[i].clientId,
                  val[i].clientName,
                  val[i].employeeId,
                  val[i].employeeName,
                  val[i].startDate,
                  val[i].startTime,
                  val[i].endTime,
                  val[i].totalHrs,
                  val[i].serviceId,
                  val[i].service,
                  val[i].employeeEmail,
                  val[i].employeePhn,
                  val[i].isCancelled,
                  val[i].isMissed,
                  val[i].evvTimesheetId,
                ]
              )
              .then(() => console.log('schedule created'))
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      }
    }
  }
  //----GET Service  DETAILS---/////
  async getofflineservice() {
    await this.global.checkRefreshTokenValidity()
    {
    this.clientService.getClientServiceofflinedata().subscribe(
      (val: any) => {
        this.insertdataservice(val);
      },
      (err) => {
        if (this.acceptLoading != null) {
          this.acceptLoading.dismiss();
          this.acceptLoading = null;
        }
        if (this.dashboardLoad != null) {
          this.dashboardLoad.dismiss();
          this.dashboardLoad = null;
        }
        console.log(err);
      }
    );
    }
  }
  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(0, () => {
      this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
        if (this.router.url != '/tabs/all-timesheets') {
          if (this.global.role == 'CLIENT') {
            await this.router.navigate(['/tabs/client-all-timesheets']);
          } else if (this.global.role == 'PCAEMPLOYEE') {
            await this.router.navigate(['/tabs/all-timesheets']);
          } else if (
            this.global.role == '' ||
            this.global.role == null ||
            this.global.role == undefined ||
            localStorage.getItem('Evv_login') != null
          ) {
            await this.router.navigate(['/login']);
          }
        } else if (this.router.url === '/tabs/all-timesheets' || '/login') {
          if (
            new Date().getTime() - this.lastTimeBackPress >=
            this.timePeriodToExit
          ) {
            this.lastTimeBackPress = new Date().getTime();
            this.presentAlertConfirm();
          } else {
            navigator['app'].exitApp();
          }
        }
      });
    });
  }
  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: 'Are you sure you want to exit the app?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => { },
        },
        {
          text: 'Close App',
          handler: () => {
            navigator['app'].exitApp();
          },
        },
      ],
    });
    await alert.present();
  }
  ////----INSERT service DETAILS---/////
  insertdataservice(val) {
    if (val.length > 0) {
      for (let i = 0; i < val.length; i++) {
        this.sqlite
          .create({
            name: 'data.db',
            location: 'default',
          })
          .then((db: SQLiteObject) => {
            this.database = db;
            this.database
              .executeSql(
                'INSERT INTO service(Id,ServiceId,ClientId,isUpdate,ServiceName) VALUES(?,?,?,?,?)',
                [
                  val[i].serviceId,
                  val[i].serviceId,
                  val[i].clientId,
                  'false',
                  val[i].serviceName,
                ]
              )
              .then(() => console.log('service created'))
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      }
    }
  }
  async logoutSession() {
    await this.global.checkRefreshTokenValidity()
    {
    let url = 'api/Auth/logout?';
    let params = new URLSearchParams();
    params.append(
      'revokeAllSessions',
      JSON.stringify(this.islogoutOfAllSessions)
    );
    params.append('refreshToken', this.global.refreshTokenString);
    await this.global.showLoading();
    this.http
      .post(url + params, null)
      .pipe()
      .subscribe(
        (data: any) => {
          this.global.disableloading();
          this.global.ToastSuccess('Logout Successful');
          this.logout();
        },
        (err: HttpErrorResponse) => {
          this.global.disableloading();
          this.global.ToastSuccess('Logout Successful');
          this.logout();
        }
      );
  }
}
}
