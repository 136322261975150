import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { ActionSheetController, AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { sqliteService } from 'src/app/sqlite.service';
import { NotesPage } from '../notes/notes.page';
import { EvvClientsOffline, MyClient, Services, SharedCareClients } from './my-clients.model';
import { MyClientsService } from './my-clients.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import BackgroundGeolocation from 'cordova-background-geolocation-lt';
import { DatePipe } from '@angular/common';
import { Device } from '@ionic-native/device/ngx';
@Component({
  selector: 'app-my-clients',
  templateUrl: './my-clients.page.html',
  styleUrls: ['./my-clients.page.scss'],
})
export class MyClientsPage {
  ClientName: string = "";
  clientsList: MyClient[];
  SharedList: Array<SharedCareClients>;
  count: number = 0;
  serviceName: any = '';
  isServiceAlreadyStarted: boolean = false;
  ShowLoader: boolean = true;
  searchClient: string = "";
  servicesList: Services[];
  servicesListContent = [];
  inProgressRecords = null;
  datanotfound: boolean = false;
  selectedCLientIds: any = [];
  isNext: boolean = false;
  SelectedServiceId: any;
  SharedClientIDs: any = [];
  SharedTsIDs: any = [];
  StartedSharedClientId: any = [];
  Password: string = "";
  clientAuthId: number;
  checkErrorForRh: any = [];
  RemainingHoursList: any = [];
  errorcheck: any = [];
  Notes: string = "";
  hasSharedCare: boolean = false;
  isOffline: boolean = false;
  offlineCliets: Array<EvvClientsOffline>;
  clientDat: MyClient;
  service: Services;
  CarePlanType: any;
  serviceNameforServiceAuth: string = ""
  RemainingHoursPerDayBasedOnCurrentDate: string = "";
  RemainingHoursPerDayBasedOnLastVisitDate: string = ""
  RemainingHours: any = "";
  UnConfirmedUnits: any = "";
  serviceGroupId: number;
  ConfirmedUnits: any = "";
  ratio: any = null;
  SharedCareClientsRecordLists: any = [];
  RemainingUnits: number;
  elementList: any = [];
  payorId: number;
  selectedShareCareClientIds: any = [];
  isRhLessthanone: boolean = false;
  ClientDataList: any = [];
  isShowServiceAuthorization: boolean = false;
  constructor(public device: Device, public datePipe: DatePipe, public clientService: MyClientsService, public errorService: ErrorService, public actionSheetCtrl: ActionSheetController,
    private router: Router, private route: ActivatedRoute, public global: Globalservice, private toastCtrl: ToastController, public platform: Platform,
    public general: generalservice, public alertController: AlertController, public modalController: ModalController, private sqlite: SQLite, public sqlservice: sqliteService,
    private network: Network, public appVersion: AppVersion) {

    this.global.checkingLogin();

    if (JSON.parse(localStorage.getItem("Evv_login")) != null) {
      this.route.queryParams.subscribe(params => {
        if (this.router.getCurrentNavigation().extras.state) {
          let data = this.router.getCurrentNavigation().extras.state;
          this.inProgressRecords = data;
          this.SharedTsIDs = data.tsids;

        }
        this.network.onConnect().subscribe(() => {

          this.isOffline = false;
        })
        this.network.onDisconnect().subscribe(() => {

          this.isOffline = true;
        })
      });
    }
    else {
      this.router.navigate(['/login'], { replaceUrl: true });
    }

  }
  ngOnInit() {
    this.clientsList = [];
    this.selectedCLientIds = [];
    this.selectedShareCareClientIds = [];
    this.count++;
  }
  async ionViewWillEnter() {

    this.RemainingHoursPerDayBasedOnLastVisitDate = null
    this.RemainingHours = null;
    this.serviceNameforServiceAuth = null;
    this.RemainingHoursPerDayBasedOnCurrentDate = null;
    this.RemainingUnits = null;
    this.UnConfirmedUnits = null;
    this.ConfirmedUnits = null;
    this.isShowServiceAuthorization = false;
    this.platform.ready().then(() => {
      this.appVersion.getVersionNumber().then((data) => {
        this.global.applicationVersionNumber = data;
        BackgroundGeolocation.stop();
      });
    });
    this.global.isModalStart = false;
    this.clientService.getCareplanList().pipe().subscribe(data => {
      this.CarePlanType = data;
    });
    this.clientsList = [];
    if (!this.global.isOffline) {
      await this.global.checkRefreshTokenValidity().then((data) => {
        {
          this.global.saveDeviceInfo();
          this.global.getServiceType();
          this.GetEmployeeIsTestingEntity();
          this.getClients();
          this.getEvvStatus()
        }
      })
    } else if (this.global.isOffline) {
      this.getOfflineClientData();
    }
    this.selectedShareCareClientIds = [];
    this.selectedCLientIds = [];
    this.SharedClientIDs = [];
    this.SharedTsIDs = [];
    this.hasSharedCare = false;


  }

  openSharedCare() {
    let StartedSharedCare = [];
    this.clientsList?.forEach(element => {
      this.SharedClientIDs?.forEach(SCID => {
        if (SCID == element.clientId) {
          StartedSharedCare.push(element);
        }
      })
    });
    StartedSharedCare?.forEach(ele => {
      ele.clientName = ele.name
    })
    let navigationExtras: NavigationExtras = {
      state: {
        isStarted: true,
        sharedCareRecords: StartedSharedCare,
        isEditShareCare: true
      }
    }
    this.router.navigate(['/tabs/sharedcareClients'], navigationExtras)
  }
  async getClients() {


    this.ShowLoader = true;
    let param = new URLSearchParams();
    param.append("EmployeeId", this.global.EvvUserDetails.entityId.toString())
    param.append("SearchText", this.searchClient)
    await this.global.checkRefreshTokenValidity();
    this.clientService.getClients(param).subscribe((data: Array<MyClient>) => {
      this.SharedTsIDs = [];
      this.SharedClientIDs = [];
      this.ShowLoader = false;
      this.count = 0;
      data.forEach(list => {
        if (list.startTime != null && list.startTime != undefined && list.startTime != '') {
          let startdate = new Date(list.startTime);
          let addoneDay = startdate.setDate(startdate.getDate() + 1);
          let currentDate: any;
          currentDate = new Date()
          currentDate = currentDate.getTime()
          if (currentDate > addoneDay) {
            list.inprogressValidationforOver24Hours = true;
          }
          else {
            list.inprogressValidationforOver24Hours = false;
          }
        }
        else {
          list.inprogressValidationforOver24Hours = false;
        }
      })
      this.clientsList = data;
      if (data.length > 0) {
        this.clientsList[0].sharedClientId.forEach(d => {
          this.SharedClientIDs.push(d);
        });
        this.clientsList[0].sharedtsId.forEach(d => {
          this.SharedTsIDs.push(d);
        });
        if (this.SharedClientIDs.length > 0) {
          this.hasSharedCare = true;
        } else {
          this.hasSharedCare = false;
        }
        this.clientsList.forEach(c => c.mobileNumberFormat = c.mobileNumber != null ? this.general.converPhoneGoogleLib(c.mobileNumber.toString()) : null)
        this.datanotfound = false;
      }
      else {
        this.datanotfound = true;
      }
    }, err => {
      this.ShowLoader = false;
      this.errorService.check(err);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })


  }

  inProgress(clientData: MyClient) {
    let navigationExtras: NavigationExtras = {
      state: {
        clientId: clientData.clientId,
        clientAuthId: clientData.clientAuthId,
        serviceId: clientData.serviceId,
        serviceGroupId: clientData.serviceGroupId,
        startTime: clientData.startTime,
        offlineTsId: clientData.evvTimesheetId
      }
    }
    this.router.navigate(['/tabs/service'], navigationExtras)
  }
  VerifyService(clientData, element) {
    this.clientAuthId = element.clientAuthId;
    this.payorId = element.payorId;
    element.serviceId = element.masterServiceId;
    let navigationExtras: NavigationExtras = {
      state: {
        clientId: clientData.clientId,
        serviceId: element.serviceId,
        clientAuthId: element.clientAuthId,
        payorId: this.payorId,
        serviceGroupId: this.serviceGroupId,
        startTime: clientData.startTime
      }
    }

    if (this.RemainingHours > 0) {
      element.serviceId = element.masterServiceId;
      this.serviceName = element.serviceName;
      if (element.ratio > 1 && !this.global.isOffline) {
        this.EmpSignAgreement(navigationExtras, element.serviceId, clientData.clientId, element.ratio)
      } else {
        this.router.navigate(['/tabs/service'], navigationExtras)
      }
    } else {
      this.presentToast('No units Remaining.. Contact office', 'danger')
    }
  }
  async presentToast(msg, color) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: color,
    });
    toast.present();
  }
  async EmpSignAgreement(navigationExtras, serviceId, clientId, ratio) {
    const alert = await this.alertController.create({
      header: 'Shared Care',
      backdropDismiss: false,
      message: 'Do you want to start Shared Care Services',
      buttons: [
        {
          id: "NoThanks",
          text: 'No Thanks',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.ionViewWillEnter();
            this.router.navigate(['tabs/my-clients'])
          }
        }, {
          id: "proceed",
          text: 'Proceed',
          handler: () => {
            this.getSharedCareClientsAuthorizationDetails(clientId);
          }
        }
      ]
    });
    await alert.present();
  }
  createInputs(ratio) {
    const theNewInputs = [];
    for (let i = 0; i < this.SharedList.length; i++) {
      theNewInputs.push(
        {
          type: 'checkbox',
          label: this.SharedList[i].clientName,
          value: '' + i,
          checked: this.SharedList[i].isSelected,
          handler: data => {


            if (data.label == this.SharedList[i].clientName) {
              this.SharedList[i].isSelected = data.checked;
              this.fetDetails(this.SharedList, ratio);
            }

          }
        }
      );
    }
    return theNewInputs;
  }



  fetDetails(lst, ratio) {
    let count = 0;
    lst.forEach(sc => {
      if (sc.isSelected == true) {
        count++
      }
    })
    if (count == ratio) {

      // this.OnOK();
    }
  }

  // ..................ComprehensiveModal...............................................

  async presentComprehensiveModal(clientId) {
    this.global.clientid = clientId;

    // let ComprehensivenavigationExtras: NavigationExtras = {
    //   queryParams: {
    //     Comprehensivecare: JSON.stringify(clientId)
    //   }
    // };
    this.router.navigate(['tabs/comprehensive']);
  }
  // ..................HomeMakingModal...............................................
  async presentHomeMakingModal(clientId) {
    this.global.clientid = clientId;
    // let HomeMakingnavigationExtras: NavigationExtras = {
    //   queryParams: {
    //     HomeMakingcare: JSON.stringify(clientId)
    //   }
    // };
    this.router.navigate(['tabs/home-making']);
  }
  // ..................BasicModal...............................................
  async presentBasicModal(clientId) {
    this.global.clientid = clientId;
    // let BasicnavigationExtras: NavigationExtras = {
    //   queryParams: {
    //     basiccare: JSON.stringify(clientId)
    //   }
    // };
    this.router.navigate(['tabs/basic-care']);
  }

  // ..................IclsModal...............................................
  async presentIclsModal(clientId) {
    this.global.clientid = clientId;
    // let IclsnavigationExtras: NavigationExtras = {
    //   queryParams: {
    //     Iclscare: JSON.stringify(clientId)
    //   }
    // };
    this.router.navigate(['tabs/icls']);
  }
  // ..................NotesModal...............................................
  async presentNotesModal(clientId) {
    const modal = await this.modalController.create({
      component: NotesPage,
      componentProps: {
        'clientId': clientId
      },
      // swipeToClose: true,
      cssClass: 'my-custom-class',
    });
    return await modal.present();
  }

  getOfflineClientData() {
    this.isServiceAlreadyStarted = false
    this.ShowLoader = true;
    this.clientsList = [];
    this.sqlite.create({
      name: 'evv.db',
      location: 'default'
    }).then((db: SQLiteObject) => {

      db.executeSql('select DISTINCT * from client', [])
        .then((data) => {

          // var auth = data.rows.item;

          if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {
              this.clientDat = new MyClient();
              this.clientDat.name = data.rows.item(i).name;
              this.clientDat.clientId = data.rows.item(i).ClientId;
              this.clientDat.mobileNumber = data.rows.item(i).mobileNumber;
              this.clientDat.mobileNumberFormat = data.rows.item(i).mobileNumber;
              this.clientDat.street = data.rows.item(i).street;
              this.clientDat.country = data.rows.item(i).county;
              this.clientDat.email = data.rows.item(i).email;
              this.clientDat.cityStateZip = data.rows.item(i).cityStateZip;

              this.clientsList.push(this.clientDat);

            }
          }
        }).then(() => {
          this.clientsList.forEach(cl => {

            db.executeSql('select DISTINCT * from timesheet where clientID==' + cl.clientId, [])
              .then((data) => {

                if (data.rows.length > 0) {
                  for (let i = 0; i < data.rows.length; i++) {

                    if ((data.rows.item(i).start != null && data.rows.item(i).start != undefined && data.rows.item(i).start != '') && (data.rows.item(i).end != null && data.rows.item(i).end != undefined && data.rows.item(i).end != '')) {

                    } else {
                      cl.startTime = data.rows.item(i).start;
                      cl.evvTimesheetId = data.rows.item(i).Id;
                      cl.serviceId = data.rows.item(i).serviceId;

                      if (this.clientsList.some(offlineList => offlineList.startTime != null && offlineList.startTime != undefined && offlineList.startTime != '')) {

                        this.isServiceAlreadyStarted = true;
                      }
                      else {
                        this.isServiceAlreadyStarted = false;
                      }
                      if (cl.startTime != null && cl.startTime != undefined && cl.startTime != '') {

                        this.ClientName = cl.name;
                        let startdate = new Date(cl.startTime);
                        let addoneDay = startdate.setDate(startdate.getDate() + 1);
                        let currentDate: any;
                        currentDate = new Date()
                        // currentDate = startdate.setDate(currentDate.getDate() + 2);
                        currentDate = currentDate.getTime()
                        if (currentDate > addoneDay) {
                          cl.inprogressValidationforOver24Hours = true;
                        }
                        else {
                          cl.inprogressValidationforOver24Hours = false;
                        }
                      }
                      else {
                        cl.evvTimesheetId = null;
                        cl.inprogressValidationforOver24Hours = false;
                      }

                    }
                  }
                } else {
                  return { start: null, Id: null };
                }
              })
              .catch(e => {
              })
          })
        })
        .catch(e => {

          this.global.ToastDangerMiniDuration(e.message)
        })
    })

    this.ShowLoader = false;

  }

  checkInprogress(clientId): any {
    this.sqlite.create({
      name: 'evv.db',
      location: 'default'
    }).then((db: SQLiteObject) => {
      db.executeSql('select DISTINCT * from timesheet where clientID==' + clientId, [])
        .then((data) => {

          if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {

              if ((data.rows.item(i).start != null && data.rows.item(i).start != undefined && data.rows.item(i).start != '') && (data.rows.item(i).end != null && data.rows.item(i).end != undefined && data.rows.item(i).end != '')) {

              } else {

                return data.rows.item(i);
              }
            }
          } else {
            return { start: null, Id: null };
          }
        })
        .catch(e => {

        })
    })
  }
  onChangeCareplan(clientId, selectElectronicForms) {
    if (selectElectronicForms == "ComprehensiveCarePlan") {
      this.presentComprehensiveModal(clientId);
    }
    else if (selectElectronicForms == "HomeMakingCarePlan") {
      this.presentHomeMakingModal(clientId);
    }
    else if (selectElectronicForms == "BasicCarePlan") {
      this.presentBasicModal(clientId);
    }
    else if (selectElectronicForms == "ICLSCarePlan") {
      this.presentIclsModal(clientId);
    }
  }

  async DeleteVisitConfirmation(list) {
    this.Password = null;
    const alert = await this.alertController.create({
      header: 'Delete Visit',
      message: 'Enter password to Delete the visit',
      inputs: [
        {
          name: 'Password',
          placeholder: 'Password',
          type: 'password'

        },
      ],
      backdropDismiss: false,
      buttons: [
        {
          text: 'Confirm',
          handler: data => {
            if (data.Password != null && data.Password != undefined && data.Password != '') {
              this.Password = data.Password;
              this.DeleteVisits(list);
            }
            else {
              this.global.ToastDangerMiniDuration("Password not entered!")
            }
          }
        },
        {
          text: 'Deny',
          role: 'Deny',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }
      ]
    });
    await alert.present();
  }
  async DeleteVisits(items) {

    let params = new URLSearchParams();
    params.append("Password", this.Password);
    await this.global.showLoading();
    await this.global.checkRefreshTokenValidity();
    this.clientService.cancelEvvVisit(params, [items.evvTimehseetId]).subscribe((data: any) => {
      this.presentToast('Visits has been cancelled successfully', 'success');
      this.Password = null;
      this.getClients();
      this.global.disableloading();

    }, err => {
      this.errorService.check(err);
      this.global.Toastdanger(err.message)
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })

  }
  cancelOfflineVisits(value) {
    this.sqlservice.CancelUpdateTimesheet(value)
    setTimeout(() => {
      this.getOfflineClientData()
    }, 2000);
  }

  async GetEmployeeIsTestingEntity() {
    this.global.showLoading();
    let params = new URLSearchParams();
    params.append("EmployeeId", this.global.EvvUserDetails.entityId.toString())
    await this.global.checkRefreshTokenValidity();
    this.clientService.GetEmployeeIsTestingEntity(params).subscribe((data: any) => {
      this.global.isTrainer = data;

      this.global.disableloading();
    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })

  }

  async CancelVisitConfirmation(list) {
    this.Notes = list.visitNote;

    const alert = await this.alertController.create({
      header: 'Cancel Visit',
      message: 'Enter notes to Cancel the visit',
      inputs: [
        {
          name: "notes",
          placeholder: 'notes',
          type: 'text',
          value: this.Notes
        },
      ],
      backdropDismiss: false,
      buttons: [
        {
          text: 'Confirm',
          handler: data => {
            if (data.notes != null && data.notes != undefined && data.notes != '') {
              this.Notes = data.notes;
              this.CancelVisits(list);
            }
            else {
              if (this.Notes != null && this.Notes != undefined && this.Notes != '') {


                this.Notes = this.Notes;
                this.CancelVisits(list);
              }
              else {
                this.global.ToastDangerMiniDuration("Please enter notes!")
              }
            }
          }
        },
        {
          text: 'Deny',
          role: 'Deny',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }
      ]
    });
    await alert.present();
  }

  async CancelVisits(items) {

    await this.global.showLoading();
    let payload = {
      evvTimesheetId: parseInt(items.evvTimehseetId.toString()),
      employeeId: parseInt(this.global.EvvUserDetails.entityId.toString()),
      visitNote: this.Notes,
    }
    await this.global.checkRefreshTokenValidity();
    this.clientService.CancelVisitforEmployee(payload).subscribe((data: any) => {
      this.presentToast('Visits has been cancelled successfully', 'success');
      this.Password = null;
      this.getClients();
      this.global.disableloading();

    }, err => {
      this.errorService.check(err);
      this.global.Toastdanger(err.message)
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })

  }
  // ..................backtoMyclient...............................................
  backtoMyclient() {
    this.isShowServiceAuthorization = false;
    this.ionViewWillEnter();
  }
  ///---------- Load Client Services & Show ActionSheet

  getClientData(clientData: MyClient) {
    this.ClientDataList = clientData;
    this.isShowServiceAuthorization = true;
  }
  // ..................selectService...............................................
  async selectService(clientData) {
    clientData = this.ClientDataList;
    if (this.global.isOffline) {
      if (this.isServiceAlreadyStarted == true) {
        this.global.ToastDangerMiniDuration(this.ClientName + "," + "One service is already in progress.Please end the current service before starting a new one");
        return;
      }
    }
    if (!this.global.isOffline) {

      this.servicesList = [];
      let param = new URLSearchParams();
      this.global.showLoading()
      param.append("Client", clientData.clientId.toString())
      param.append("EmployeeId", this.global.EvvUserDetails.entityId.toString())
      this.servicesListContent = [];
      this.ShowLoader = true;
      await this.global.checkRefreshTokenValidity();
      this.clientService.getServices(param).subscribe(async (data: Array<Services>) => {
        this.servicesList = data;
        this.global.disableloading()
        data.forEach(element => {
          let valweeks = element.remaingHours.toString().split('.');
          if (valweeks.length == 1) {
            element.remaininghrs = valweeks[0] + " hr " + "0 Min";
          }
          else {
            let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
            element.remaininghrs = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + "Min";
          }
          this.RemainingHoursPerDayBasedOnLastVisitDate = null
          this.RemainingHours = null;
          this.serviceNameforServiceAuth = null;
          this.RemainingHoursPerDayBasedOnCurrentDate = null;
          this.RemainingUnits = null;
          this.UnConfirmedUnits = null;
          this.ConfirmedUnits = null;
          this.servicesListContent.push({
            text: element.serviceName,
            id: element.serviceId,
            icon: '',
            handler: () => {
              this.serviceGroupId = element.serviceGroupId,
                this.RemainingHours = element.remaingHours;
              this.getServicesWithAuthorizationForServiceGroup();
            }
          })
        });
        this.servicesListContent.push({
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {

          }
        })
        const actionSheet = await this.actionSheetCtrl.create({
          header: 'Select Service',
          cssClass: 'my-custom-class',
          buttons: this.servicesListContent
        });
        await actionSheet.present();
        this.ShowLoader = false;

      }, err => {
        this.global.disableloading()
        this.errorService.check(err);
        this.ShowLoader = false;
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })

    } else if (this.global.isOffline) {
      this.servicesList = [];
      this.servicesListContent = [];
      this.sqlite.create({
        name: 'evv.db',
        location: 'default'
      }).then((db: SQLiteObject) => {
        db.executeSql('select DISTINCT * from service where clientId==' + clientData.clientId, [])
          .then((data) => {
            if (data.rows.length > 0) {
              for (let i = 0; i < data.rows.length; i++) {
                this.service = new Services();
                this.service.serviceId = data.rows.item(i).serviceId;
                this.service.serviceName = data.rows.item(i).service;
                this.service.remaingHours = parseInt(data.rows.item(i).remainingHours.toString());
                this.service.remaininghrs = data.rows.item(i).remainingHours;
                this.service.ratio = data.rows.item(i).ratio;
                this.service.serviceGroupId = data.rows.item(i).serviceGroupId;
                this.service.authDetails = JSON.parse((data.rows.item(i).authDetails));
                this.servicesList.push(this.service);

              }
            }
          }).then(async () => {
            this.RemainingHoursPerDayBasedOnLastVisitDate = null
            this.RemainingHours = null;
            this.serviceNameforServiceAuth = null;
            this.RemainingHoursPerDayBasedOnCurrentDate = null;
            this.RemainingUnits = null;
            this.UnConfirmedUnits = null;
            this.ConfirmedUnits = null;
            this.servicesList.forEach(element => {
              let remainingHours: any;
              remainingHours = element.remaingHours;
              element.remainingHours = remainingHours;
              let valweeks = element.remaininghrs.toString().split('.');
              if (valweeks.length == 1) {
                element.remaininghrs = valweeks[0] + " hr " + "0 Min";
              }
              else {
                let valsweeks = 0 + "." + valweeks[valweeks.length - 1];
                element.remaininghrs = valweeks[0] + " hr " + (parseFloat(valsweeks) * 60).toString().split('.')[0] + "Min";
              }
              this.servicesListContent.push({
                text: element.serviceName,
                id: element.serviceId,
                icon: '',
                handler: () => {
                  this.serviceGroupId = element.serviceGroupId,
                    this.RemainingHours = element.remaingHours;
                  this.getofflineAuth(element)
                }
              })
            })
            this.servicesListContent.push({
              text: 'Cancel',
              icon: 'close',
              role: 'cancel',
              handler: () => {
              }

            })
            const actionSheet = await this.actionSheetCtrl.create({
              header: 'Select Service',
              cssClass: 'my-custom-class',
              buttons: this.servicesListContent
            });
            await actionSheet.present();
          })
      })
    }

  }
  // ..................getServicesWithAuthorizationForServiceGroup...............................................
  async getServicesWithAuthorizationForServiceGroup() {

    this.global.showLoading()
    let authorizationRequestPayload =
    {
      startDate: this.global.timezone == "Minnesota"?  this.global.getDateTimeString(new Date()):this.global.getDateWithUTCFormat(new Date()),
      endDate: this.global.timezone == "Minnesota"?  this.global.getDateTimeString(new Date()):this.global.getDateWithUTCFormat(new Date()),
      serviceGroupId: this.serviceGroupId != null && this.serviceGroupId != undefined && this.serviceGroupId != 0 ?
        parseInt(this.serviceGroupId.toString()) : 0,
      clientId: parseInt(this.ClientDataList.clientId),
      serviceTypeLid: this.global.serviceType?.filter((st) => st.Value == "HOMECARE")[0]?.Key
    }
    this.servicesListContent = [];
    await this.global.checkRefreshTokenValidity();
    this.clientService.getServicesWithAuthorizationForServiceGroup(authorizationRequestPayload).subscribe(async (data: any) => {
      this.global.disableloading()
      if (data.exception.length > 0) {
        this.global.Toastdanger(data.exception);
        return;
      }
      this.servicesList = data.response;
      data.response.forEach(element => {
        let remainingHours: any;
        remainingHours = element.remainingHours;
        element.remaingHours = remainingHours;
        this.servicesListContent.push({
          text: this.global.timezone == "Minnesota" ? element.masterServiceName + " -" + element.serviceDescription + ' (' + this.datePipe.transform(element.startDate, "MM/dd/yyyy") + " " + "to" + " " + this.datePipe.transform(element.endDate, "MM/dd/yyyy") + ')' : element.masterServiceName + " -" + element.serviceDescription + ' (' + this.datePipe.transform(element.startDate.split("T")[0], "MM/dd/yyyy") + " " + "to" + " " + this.datePipe.transform(element.endDate.split("T")[0], "MM/dd/yyyy") + ')',

          
          id: element.serviceId,
          icon: '',
          handler: () => {
            this.getDetails(element)

          }
        })
      });
      this.servicesListContent.push({
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
      })
      const actionSheet = await this.actionSheetCtrl.create({
        header: 'Select Service',
        cssClass: 'my-custom-class',
        buttons: this.servicesListContent
      });
      await actionSheet.present();
      this.ShowLoader = false;
      this.global.disableloading()
    }, err => {
      this.global.ToastDangerMiniDuration(err.message);
      this.global.disableloading()
      this.errorService.check(err);
      this.ShowLoader = false;
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })

  }
  async getofflineAuth(data) {
    this.serviceGroupId = data.serviceGroupId;
    this.servicesList = [];
    this.servicesListContent = []
    this.servicesList = data.authDetails;
    this.global.disableloading()
    this.servicesList?.forEach(element => {
      let remainingHours: any;
      remainingHours = element.remainingHours;
      element.remaingHours = remainingHours;
      this.servicesListContent.push({
        text: element.masterServiceName +
          "-" + '(' + this.datePipe.transform(element.startDate, "MM/dd/yyyy") + " " + "to" + " " + this.datePipe.transform(element.endDate, "MM/dd/yyyy") + ')',
        id: element.serviceId,
        icon: '',
        handler: () => {
          this.getDetails(element)

        }
      })
    });
    this.servicesListContent.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
    })
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Select Service',
      cssClass: 'my-custom-class',
      buttons: this.servicesListContent
    });
    await actionSheet.present();
    this.ShowLoader = false;
    this.global.disableloading()
  }
  // ..................getDetails...............................................
  getDetails(element) {
    this.serviceNameforServiceAuth = element.masterServiceName;
    this.clientAuthId = element.clientAuthId;
    this.payorId = element.payorId;
    this.RemainingUnits = element.remainingUnits;
    this.ConfirmedUnits = element.confirmedUnits;
    this.UnConfirmedUnits = element.unconfirmedUnits
    let value1 = element?.remainingHPDBasedOnCurrentDate?.toString().split('.');
    if (value1.length == 1) {
      this.RemainingHoursPerDayBasedOnCurrentDate = value1[0] + " hr " + "0 Min";
    }
    else {
      let value2 = 0 + "." + value1[value1.length - 1];
      this.RemainingHoursPerDayBasedOnCurrentDate = value1[0] + " hr " + (parseFloat(value2) * 60).toString().split('.')[0] + "Min";
    }
    let value3 = element?.remainingHPDBasedOnLastVisitDate?.toString().split('.');
    if (value3.length == 1) {
      this.RemainingHoursPerDayBasedOnLastVisitDate = value3[0] + " hr " + "0 Min";
    }
    else {
      let value4 = 0 + "." + value3[value3.length - 1];
      this.RemainingHoursPerDayBasedOnLastVisitDate = value3[0] + " hr " + (parseFloat(value4) * 60).toString().split('.')[0] + "Min";
    }
    this.elementList = element;
  }
  // ..................gotoServiceInfo...............................................
  gotoServiceInfo(clientData, element) {
    this.isNext = true;
    clientData = this.ClientDataList
    element = this.elementList
    if (this.global.isOffline) {
      this.getOfflineErrorForCheckRemainingUnits(clientData,element);
      this.isNext = false;
    }
    else {
      this.checkRemainingUnits(clientData, element)
    }
  }
  async getOfflineErrorForCheckRemainingUnits(clientData,element)
  {
    var remainingHrs = element.remainingUnits / element.billingLov;
    if (remainingHrs <= 1) {
      element.Warning = clientData.name + " " + "Remaining hours are less than 1 hours. Please contact the admin"
      this.global.ToastDangerMiniDuration(element.Warning);
    }
    else if (remainingHrs > 1 && remainingHrs <= 2) {
      element.Warning = clientData.name + " " + remainingHrs + " " + "Hours Left on this authorization";

      const alert = await this.alertController.create({
        header: 'Warning',
        message:  element.Warning,
        
        buttons: [
          {
            id: "NotesOfflineRemainingUnits",
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
            }
          },
          {
            id: "OkOffline",
            text: 'Ok',
            handler: () => {
              this.VerifyService(clientData, element);
            }
          }
        ],
        backdropDismiss: false,
      });
      await alert.present();
      
    }
    else {
      this.VerifyService(clientData, element);
    }
  }
  async getSharedCareClientsAuthorizationDetails(clientId) {

    this.global.showLoading();
    this.servicesListContent = [];
    var GroupingClientRecords = [];
    var ShareCareGroupedClients = [];
    await this.global.checkRefreshTokenValidity();
    this.clientService.getSharedCareClientsAuthorizationDetails(clientId, this.serviceGroupId).subscribe(async (data: any) => {
      this.global.disableloading()
      if (data.exception.length > 0) {
        this.global.Toastdanger(data.exception);
        return;
      }
      let SharedClientResponse = [];
      data?.response?.forEach((element, index) => {
        if (element.masterServiceName == this.serviceNameforServiceAuth) {
          SharedClientResponse.push(element);
        }
      });
      GroupingClientRecords = SharedClientResponse.reduce((group, record) => {
        group[record.clientId] = group[record.clientId] || [];
        group[record.clientId].push(record);
        return group;
      }, {});

      ShareCareGroupedClients = Object.keys(GroupingClientRecords).map((key) => {
        return { clientId: parseInt(key), clientName: GroupingClientRecords[key][0].clientName, serviceGroup: GroupingClientRecords[key] };
      });

      this.SharedCareClientsRecordLists = ShareCareGroupedClients

      this.ratio = data.response[0]?.ratio;
      this.getClientRecordsForSharedCare();
      this.global.disableloading()
    }, err => {
      this.global.ToastDangerMiniDuration(err.message);
      this.global.disableloading()
      this.errorService.check(err);
      this.ShowLoader = false;
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })

  }
  async getClientRecordsForSharedCare() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Select Clients',
      inputs: this.createSharedCareInputs(),
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',

          handler: data => {
            if (data.length > 0) {
              this.checkRemainingUnitsForSharedCare();

            }
            else {
              this.global.ToastDangerMiniDuration("select shared clients")
            }
          }
        }
      ]
    });
    await alert.present();
  }
  createSharedCareInputs() {
    const theNewInputs = [];
    for (let i = 0; i < this.SharedCareClientsRecordLists.length; i++) {
      theNewInputs.push(
        {
          type: 'checkbox',
          label: this.SharedCareClientsRecordLists[i].clientName,
          value: '' + i,
          checked: this.SharedCareClientsRecordLists[i].isSelected,
          handler: data => {
            // if (data.label == this.SharedList[i].clientName) {
            this.SharedCareClientsRecordLists[i].isSelected = data.checked;
            //   this.fetDetails(this.SharedList, ratio);
            // }

          }
        }
      );
    }
    return theNewInputs;
  }
  checkRemainingUnitsForSharedCare() {


    this.RouteToSharedCare();

  }
  RouteToSharedCare() {
    this.selectedShareCareClientIds = [];
    this.SharedCareClientsRecordLists?.forEach(clientRecord => {
      if (clientRecord.isSelected == true) {
        this.selectedShareCareClientIds.push(clientRecord);
      }
    })
    if (this.selectedShareCareClientIds.length <= this.ratio) {
      let navigationExtras: NavigationExtras = {
        state: {
          serviceId: this.SelectedServiceId,
          service: this.serviceName,
          isStarted: false,
          serviceGroupId: this.serviceGroupId,
          sharedCareRecords: this.selectedShareCareClientIds,
          isEditShareCare: false
        }
      }
      this.selectedShareCareClientIds.forEach(element => {
        element.serviceGroup.forEach((ele, index) => {
          if (ele.masterServiceName != this.serviceNameforServiceAuth) {
            element.serviceGroup.splice(index, 1);
          }
        });
      });
      let arrayList = [];
      this.RemainingHoursList = [];
      this.checkErrorForRh = [];
      this.errorcheck = []
      arrayList = this.selectedShareCareClientIds;
      arrayList?.forEach(element => {
        element?.serviceGroup?.forEach(e1 => {
          this.RemainingHoursList.push(e1);
        });
      });
      this.RemainingHoursList.forEach(e2 => {
        this.checkErrorForRh.push(e2);
      });
      this.errorcheck = this.checkErrorForRh;
      if (this.errorcheck.length > 0) {
        this.getErrorNotesforRemainingHours(navigationExtras);
      }
    }
    else {
      this.presentToast('No.of selected clients must be less than or equal to ' + this.ratio, 'danger')
    }
  }
  async getErrorNotesforRemainingHours(navigationExtras) {
    let error: any = [];
    this.checkErrorForRh?.forEach((element, index) => {
      var remainingHrs = element.remainingUnits / element.billingLov
      if (remainingHrs <= 1) {
        element.Warning = element.clientName + " " + "Remaining hours are less than 1 hours. Please contact the admin"
        error.push(element.Warning);
        this.isRhLessthanone = true;
      }
      if (remainingHrs > 1 && remainingHrs <= 2) {
        element.Warning = element.clientName + " " + remainingHrs + " " + "Hours Left on this authorization";
        error.push(element.Warning);
      }
    });
    if (this.checkErrorForRh.length == this.errorcheck.length) {
      if (error.length > 0) {
        const alert = await this.alertController.create({
          header: 'Note',
          message: error,
          buttons: [
            {
              id: "NotesSharedCareRemainingUnits",
              text: 'Ok',
              role: 'Ok',
              cssClass: 'secondary',
              handler: () => {
                if (!this.isRhLessthanone) {
                  this.router.navigate(['/tabs/sharedcareClients'], navigationExtras)
                }
              }
            }
          ],
          backdropDismiss: false
        });
        await alert.present();
      }
      else {
        this.router.navigate(['/tabs/sharedcareClients'], navigationExtras)
      }
    }
  }
  async getEvvStatus() {
    let myParams = new URLSearchParams();
    myParams.append('OldVersion', null);
    myParams.append('OSPlatform', this.device.platform);
    await this.global.checkRefreshTokenValidity();
    this.clientService.GetLatestVersion(myParams).subscribe((data: any) => {
      if (data == null || data == undefined || data == '') {
        this.global.isOptional = false;
      }
      else {
        this.global.isOptional = data.isOptional;
      }

      if (this.global.isOptional) {
        let navigationExtras: NavigationExtras = {
          state: {
            maintenanceStatus: "NotClearLocalStorage"
          }
        }
        this.router.navigate(['/login/app-maintenance'], navigationExtras);
      }
    }, err => {
      this.errorService.check(err);
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })

  }
  async checkRemainingUnits(clientData, element) {
    let param = new URLSearchParams();

    param.append('remainingUnits', element.remainingUnits);
    param.append('billingUnit', element.billingLov);
    await this.global.checkRefreshTokenValidity()
    {
      this.clientService.checkRemainingUnits(param).subscribe(
        (data: any) => {
          this.isNext = false;
          if (data.body != null && data.body != undefined && data.body != "") {
            this.getErrorNotesforRemainingUnits(data.body, clientData, element)
          }
          else {
            this.VerifyService(clientData, element)
          }
        },
        (err) => {
          this.isNext = false;
          this.global.Toastdanger(err.message)
          this.errorService.check(err);
          this.global.disableloading();
          if (err.Status == 401) {
            this.global.GloballogOut();
          }
        }
      );
    }
  }
  async getErrorNotesforRemainingUnits(message, clientData, element) {
    const alert = await this.alertController.create({
      header: 'Warning',
      message: message,
      buttons: [
        {
          id: "NotesStopSharedError",
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          id: "okorsaveActivies",
          text: 'Ok',
          handler: () => {
            this.VerifyService(clientData, element)
          }
        }
      ],

      backdropDismiss: false
    });
    await alert.present();


  }
}








