import { Component, OnInit } from '@angular/core';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { AlertController, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { offlineService } from 'src/app/offline-data.service';
import { sqliteService } from 'src/app/sqlite.service';
import { TimesheetService } from '../timesheet/timesheet.service';
import { Storage } from "@ionic/Storage";
@Component({
  selector: 'app-offline',
  templateUrl: './offline.page.html',
  styleUrls: ['./offline.page.scss'],
})
export class OfflinePage implements OnInit {
  constructor(public global: Globalservice, private sqlite: SQLite, public sqlservice: sqliteService,
    public toastCtrl: ToastController, public http: TimesheetService, public errorService: ErrorService,
    public sqlService: sqliteService, public offlineSvc: offlineService,
    public alertController: AlertController,
    public storage: Storage, private localNotifications: LocalNotifications) { }
  isSynBoolean: boolean = false;
  countOfTimesheets: any = 0;
  Timehssets: any = [];
  showError: any = [];
  isFabBoolean: boolean = false;
  ngOnInit() {
    // this.getTImesheetsCount();
    this.getData();
    // this.getTimesheets();
  }
  
  async ionViewWillEnter() {
    await this.global.checkRefreshTokenValidity()
    {
    this.getData();
  }
  }
  onChange(event) {

    localStorage.setItem('isOffline', this.global.isOffline == true ? 'true' : 'false')
  }
  getTImesheetsCount() {
    this.countOfTimesheets = 0;
    this.countOfTimesheets = this.sqlservice.getOfflineTimesheetsCount();
  }
  async syncOfflineData() {
    await this.global.checkRefreshTokenValidity()
    {
    this.isSynBoolean = true;
    this.Timehssets.forEach(ts => {
      ts.date = new Date(new Date(ts.date).toDateString() + " " + new Date(ts.date).toLocaleTimeString() + " " + "GMT").toISOString()
      ts.evvId = ts.Id,
        ts.clockInTime =this.global.timezone == "US"?this.global.getDateTimeString(ts.start): ts.start,
        ts.clockOutTime =this.global.timezone == "US"?this.global.getDateTimeString(ts.end): ts.end,
        ts.callLatitude = ts.callLatitude != null ? parseFloat(ts.callLatitude) : null,
        ts.callLongitude = ts.callLongitude != null ? parseFloat(ts.callLongitude) : null,
        ts.clockoutCallLatitude = ts.callLatitude != null ? parseFloat(ts.callLatitude) : null,
        ts.clockoutCallLongitude = ts.callLongitude != null ? parseFloat(ts.callLongitude) : null,
        ts.clientId = ts.clientID,
        ts.masterServiceId = ts.serviceId;
    });
    await this.global.showLoading();
    this.http.SynOfflineData(this.Timehssets).subscribe(data => {
      this.global.disableloading();
      this.isSynBoolean = false;
      this.showError = [];
      if (data.length > 0) {
        // this.getErrorNotes(data);
        data.forEach(element => {

          this.showError.push(element.clientName, element.employeeName, element.error)
          this.global.Toastdanger(
            this.showError);
        });
        return;
      }
      // this.ToastSuccess("Server Data Synced Successfully!")
      this.clearData1();
      this.clearData();
      this.getData();
    }, err => {
      this.isSynBoolean = false;
      this.errorService.check(err);
      this.global.disableloading();
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
    // this.isSynBoolean=false;
  }
  async getErrorNotes(data) {
    const alert = await this.alertController.create({
      header: 'Note',
      message: data,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }
      ]
    });
    await alert.present();
  }
  clearData() {
    this.sqlite.create({
      name: 'evv.db',
      location: 'default'
    }).then((db: SQLiteObject) => {
      db.executeSql('Delete from timesheetActivty', []).then(() => {
      })
    }).catch((e) => { this.global.disableloading() });
  }
  clearData1() {
    this.sqlite.create({
      name: 'evv.db',
      location: 'default'
    }).then((db: SQLiteObject) => {
      db.executeSql('Delete from timesheet', []).then(() => {

        // this.global.disableloading();
        this.presentToast('TimeSheets Synced successfully')
      })
    }).catch((e) => { this.global.disableloading() });
  }
  getData() {
    this.countOfTimesheets = 0;
    this.Timehssets = [];

    this.sqlite.create({
      name: 'evv.db',
      location: 'default'
    }).then((db: SQLiteObject) => {
      db.executeSql('select DISTINCT * from timesheet where end IS NOT NULL', [])
        .then((data) => {

          if (data.rows.length > 0) {
            for (let i = 0; i < data.rows.length; i++) {
              // var item;
              this.Timehssets.push(data.rows.item(i));
            }
          }
        }).then(() => {
          this.Timehssets.forEach(ts => {
            var allactivites = [];
            db.executeSql('select * from timesheetActivty where timesheetId==' + ts.Id, [])
              .then((data) => {

                if (data.rows.length > 0) {
                  for (let i = 0; i < data.rows.length; i++) {
                    // retdata.push(data.rows.item(i))
                    var Activity = { Id: null, value: null, isSelected: null, activityId: null, evvTimesheetId: null };
                    Activity.value = data.rows.item(i).activity;
                    Activity.isSelected = data.rows.item(i).isSelected == 0 ? false : true;
                    Activity.activityId = data.rows.item(i).activityId;
                    allactivites.push(Activity);
                  }
                }
              })
              .catch(e => {

              })
            ts.activities = allactivites;
            ts.employeeId = this.global.EvvUserDetails.entityId;
          })
        }).then(() => { this.countOfTimesheets = this.Timehssets.length })
        .catch(e => {

        })
    })

  }
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'success',
    });
    toast.present();
  }
  syncdata() {
    this.global.isOffline = false;
    this.isFabBoolean = true;
    localStorage.setItem("isOffline", "false");
    this.storage.get("OfflineDate").then((val) => {

      //----TO DELETE RECORDS ON PAGE LOAD AND THEN RELOAD IT---/////
      this.sqlite.create({
        name: 'evv.db',
        location: 'default'
      }).then((db: SQLiteObject) => {
        // this.sqlite.deleteDatabase({ name: 'evv.db', location: 'default' }
        // ).then(() => {
        this.sqlService.removeAllDataExceptTS();
        this.sqlService.createOfflineTables();
        this.storage.remove("OfflineDate");
        this.getdataoffline();
        this.storage.set("Version9", "Version9");
        // }
        // )
        //   .catch(e => console.log(e));
      })
        .catch(e => console.log(e));
    });
    //#endregion
  }
  async getdataoffline() {
    await this.global.checkRefreshTokenValidity()
    {
    let param = new URLSearchParams();
    param.append("EmployeeId", this.global.EvvUserDetails.entityId.toString());
    param.append("searchtext", "");
    this.showNotification();
    this.offlineSvc.getClientsOffline(param).subscribe(data => {
      this.isFabBoolean = false;
      this.ToastSuccess("Server Data Synced Successfully!");
      localStorage.setItem("serverDatasynced", "true");
      data.forEach(offline => {
        this.sqlService.addClientsItem(offline);
        offline.mappedServices.forEach(maps => {
         
          this.sqlService.addClientServiceItems(maps, offline.clientId);
          maps.activities.forEach(actvities => {
            this.sqlService.addActivitesItems(actvities, offline.clientId, maps.serviceId);
          })

        
        });
      })
      this.localNotifications.clear(1);
    })

    this.showNotificationSchedule();
    let params = new URLSearchParams();
    params.append("EmployeeId", this.global.EvvUserDetails.entityId.toString());
    this.offlineSvc.getSchedulesOffline(params).subscribe(data => {

      data.forEach(offline => {
        this.sqlService.addScheduleItems(offline);
      })
      this.localNotifications.clear(2);
    })
    this.storage.set("OfflineDate", new Date().getDate());
  }
  }
  showNotification() {
    this.localNotifications.schedule({
      id: 1,
      text: 'Getting Client Offline Data is in progress',
      sound: null,
      icon: 'assets/imgs/logo.png',
      led: true
    });
  }
  showNotificationSchedule() {
    this.localNotifications.schedule({
      id: 2,
      text: 'Getting Schedule Offline Data is in progress',
      sound: null,
      icon: 'assets/imgs/logo.png',
      led: true
    });
  }
  async ToastSuccess(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 1000,
      position: 'top',
      color: 'success',

    });
    toast.present();
  }
}
