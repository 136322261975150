import { Component } from '@angular/core';
import { ActionSheetController, AlertController, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { scheduleDashboardClientBO } from './clientdashboard.model';
import { MyClientsDashboardService } from './clientdashboard.service';

@Component({
  selector: 'app-clientdashboard',
  templateUrl: './clientdashboard.page.html',
  styleUrls: ['./clientdashboard.page.scss'],
})
export class ClientdashboardPage {
  scheduleList: Array<scheduleDashboardClientBO> = [];
  count: number = 0;
  datanotfound: boolean = false;
  fromDate: any = new Date().toISOString();
  toDate: any = new Date().toISOString();
  searchText: any = "";
  constructor(public clientService: MyClientsDashboardService, public errorService: ErrorService, public actionSheetCtrl: ActionSheetController,
    private alertController: AlertController, public global: Globalservice,
    public general: generalservice, public toastCtrl: ToastController) {
    this.global.checkingLogin();
  }
  async ionViewWillEnter() {
    await this.global.checkRefreshTokenValidity()
    {
    this.getClientsSchduleDashboard();
    }
  }
  //========================Get schedule for dashboard
  async getClientsSchduleDashboard() {
    await this.global.checkRefreshTokenValidity()
    {
    let fromdate;
    let toDate;
    if (this.global.timezone == "US") {
      fromdate = this.global.getDateWithStartTime(this.fromDate);
      toDate = this.global.getDateWithEndTime(this.toDate);
    }
    else {
      fromdate = this.fromDate
      toDate = this.toDate
    }


    if (new Date(new Date(this.fromDate).toDateString()).getTime() > new Date(new Date(this.toDate).toDateString()).getTime()) {
      this.presentToast('Error: Enddate must be greater than Fromdate', 'danger')
    } else {
      let param = new URLSearchParams();
      param.append("ClientId", this.global.EvvUserDetails.entityId.toString());
      param.append("start", fromdate);
      param.append("end", toDate);
      param.append("searchText", this.searchText);
      await this.global.showLoading();
      this.clientService.getDashboardDetails(param).subscribe((data: Array<scheduleDashboardClientBO>) => {
        this.scheduleList = data;
        if (data.length > 0) {
          this.scheduleList.forEach(c => c.employeePhnFormat = c.employeePhn != null ? this.general.converPhoneGoogleLib(c.employeePhn.toString()) : null)
          this.datanotfound = false;
        }
        else {
          this.datanotfound = true;
        }
        this.global.disableloading();
      }, err => {
        this.errorService.check(err);
        this.global.disableloading()
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
  }
  }
  ///----------  Show Appointment cancel sheet 
  presentActionSheet(clientData: scheduleDashboardClientBO) {
    this.presentAlertConfirm(clientData);
  }
  async presentAlertConfirm(clientData: scheduleDashboardClientBO) {
    const alert = await this.alertController.create({
      header: 'Cancel Appointment',
      message: '<strong>Do you want to cancel this appointment?</strong>',
      buttons: [
        {
          text: 'Yes',
          handler: () => {
            this.cancelRequest(clientData);
          }
        },
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }
      ]
    });
    await alert.present();
  }
  //=================Cancel  requested appointment
  async cancelRequest(clientData: scheduleDashboardClientBO) {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();;
    this.clientService.cancelClientAppt(clientData).subscribe((data: any) => {
      this.presentToast('Schedule has been cancelled successfully', 'success');
      this.getClientsSchduleDashboard();
      this.global.disableloading();

    }, err => {
      this.errorService.check(err);
      this.global.disableloading()
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    })
  }
  }
  //=============Toast controller
  async presentToast(msg, color) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: color,
    });
    await toast.present();
  }
}

