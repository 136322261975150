import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
import { MyClientsService } from '../my-clients/my-clients.service';
import { EvvNotesBO, getNotes } from '../notes/notes.model'
import { NotesService } from './notes.service';
@Component({
  selector: 'app-notes',
  templateUrl: './notes.page.html',
  styleUrls: ['./notes.page.scss'],
})
export class NotesPage implements OnInit {
  isDataNotAvailable:boolean=false;
  @Input() clientId: any;
  deleteID:string;
  isDeleteAlert:boolean=false;
  getBO: getNotes = new getNotes();
  pages: any = [];
  currentPage: any = 1;
  isCreateNotes: boolean = false;
  isShowTableView: boolean = false;
  saveEvvNotesBO: EvvNotesBO = new EvvNotesBO();
  NotesList: any;
  notesData: any = [];
  constructor(public modalController: ModalController, public clientService: MyClientsService, public global: Globalservice, public errorService: ErrorService, public notesService: NotesService, public toastCtrl: ToastController,public alertController: AlertController) { 
    this.global.checkingLogin();
  }

  ngOnInit() {

  }

  async ionViewWillEnter() {
    // this.getNotes();
    // this.currentPage = 1;
    await this.global.checkRefreshTokenValidity()
    {
    this.GetEvvNotes();
    }
  }

  async getNotes() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    this.getBO.clientId = this.clientId;
    this.getBO.currentpageno = parseInt(this.currentPage);
    this.clientService.getClientNotes(this.getBO).subscribe((data: any) => {

      this.notesData = data.listcli;

      this.calculatePages(data.totalCount);
      this.global.disableloading();
    }, err => {
      this.errorService.check(err);
      this.global.disableloading();
      if (err.Status == 401) {
        this.global.GloballogOut();
      }
    });
  }
  }

  OnClick(data) {
    if (data.isExpanded == true) {
      data.isExpanded = false;
    } else {
      data.isExpanded = true;
    }
  }

  calculatePages(totalCount) {
    this.pages = [];
    let pagecout = totalCount / 10;
    if (pagecout % 1 != 0) {
      pagecout = pagecout + 1;
    }
    let i = 1
    for (i = 1; i <= pagecout; i++) {
      this.pages.push(i);
    }

  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  //.....................................................................GetEvvNotes.................................
  async GetEvvNotes() {
    await this.global.checkRefreshTokenValidity()
    {
    if (this.clientId != null && this.global.EvvUserDetails.entityId != null) {
      await this.global.showLoading();
      let param = new URLSearchParams();
      param.append("clientId", this.clientId.toString());
      param.append("employeeId", this.global.EvvUserDetails.entityId.toString());
      this.notesService.GetEvvNotes(param).subscribe((data: any) => {
        this.NotesList = data;
        if(data.length>0)
        {
          this.isDataNotAvailable=false;
        }
        else 
        {
          this.isDataNotAvailable=true;
        }
        this.global.disableloading();
      },
        (err: HttpErrorResponse) => {
          this.global.disableloading()
          this.presentToast(err.message);
        });
    }
  }
  }
  // 
  editNotesValues(notesValueDetails: EvvNotesBO) {
    this.saveEvvNotesBO = JSON.parse(JSON.stringify(notesValueDetails));
    this.isCreateNotes=true;
    this.isShowTableView=true;
    this.isHideAddnotes=true;
  }
  isHideAddnotes:boolean=false;
  addnotes()
  {
    this.saveEvvNotesBO.id=0;
    this.saveEvvNotesBO.notes="";
    this.isHideAddnotes=true;
  }
  close()

  {
    this.isHideAddnotes=false;
    this.isShowTableView=false;
    this.isCreateNotes=false;
  }
  //..............................................................savenotes..............................................................//
  async saveorUpdateNotes() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    let savepayload =
    {
      id: this.saveEvvNotesBO.id,
      notes: this.saveEvvNotesBO.notes,
      emloyeeId: this.global.EvvUserDetails.entityId,
      clientId: parseInt(this.clientId)
    }
    this.notesService.SaveUpdateEvvNotes(savepayload).subscribe((data) => {
      this.global.disableloading()
      this.GetEvvNotes();
      this.isCreateNotes = false;
      this.isShowTableView = false;
      this.isHideAddnotes=false;
    },
      (err: HttpErrorResponse) => {
        this.global.disableloading()
        this.presentToast(err.message);
      });
    }
  }

  Delete(id)
  {
    this.deleteID=id;
    this.isDeleteAlert=true;
    this.isShowTableView=true;
    this.isHideAddnotes=true;
  }
  async DeleteEvvNotes() {
    await this.global.checkRefreshTokenValidity()
    {
    await this.global.showLoading();
    let param = new URLSearchParams();
    param.append("Id", this.deleteID.toString());
    this.notesService.DeleteEvvNotes(param).subscribe((data: any) => {
      this.isDeleteAlert=false;
      this.isShowTableView=false;
      this.isHideAddnotes=false;
      this.global.ToastSuccess("Notes Deleted Successfully!")
      this.GetEvvNotes();
      this.global.disableloading();
    },
      (err: HttpErrorResponse) => {
        this.global.disableloading()
        this.presentToast(err.message);
      });
    }
  }

  NoALert()
  {
    this.isDeleteAlert=false;
    this.isShowTableView=false;
    this.isHideAddnotes=false;
  }
  async presentToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: 'danger',

    });
    toast.present();
  }

 

  async presentstopAlertConfirm(id) {
    const alert = await this.alertController.create({
    
      header: 'Delete Alert',
      message: '<strong>Do you want to delete the notes ?</strong>',
      buttons: [
        {
          text: 'Yes',
          handler: () => {
           this.DeleteEvvNotes()
          }
        },
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            
          }
        }
      ]
    });

    await alert.present();
  }
}
