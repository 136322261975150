import { DatePipe } from '@angular/common';
import { Component,  OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { DosesFrequencyRouteListBo, getComprehensiveBo,CollapseBo, ResponsiblePartyInfo, CodeDescriptionListBo, CaseManagerInfoBO } from './comprehensive-care-plans.modal';
import { ComprehensiveCarePlansService } from './comprehensive-care-plans.service';
@Component({
    selector: 'app-comprehensive-care-plans',
    templateUrl: './comprehensive-care-plans.page.html',
    styleUrls: ['./comprehensive-care-plans.page.scss'],
})
export class ComprehensiveCarePlansPage implements OnInit {
    selectedCareplan: any;
    enabledcollapse: CollapseBo = new CollapseBo();
    RespPartiesList: ResponsiblePartyInfo = new ResponsiblePartyInfo();
    getComprehensiveList: getComprehensiveBo = new getComprehensiveBo();
    CaseManagerDetails: CaseManagerInfoBO = new CaseManagerInfoBO();;
    CodeDescriptionList: CodeDescriptionListBo[] = [];
    DosesFrequencyRouteList: DosesFrequencyRouteListBo[] = [];
    EmergencyContactitems:any = [];
    LanguagesName:any = [];
    viewplan: boolean = false;
    QpFields:any=[];
    ResponsiblePartysignimage:string="";
    Qualifiedsignimage:string="";
    Clientsignimage:string="";
    PCASignimage:string="";
    CarePlansDates: any = [];
    constructor(private router: Router,public general: generalservice,
        public global: Globalservice, public errorService: ErrorService, public modalController: ModalController,
        public datePipe: DatePipe, public Service: ComprehensiveCarePlansService) {
          this.global.checkingLogin();
    }

    ngOnInit() {
    }

    async ionViewWillEnter() {
      await this.global.checkRefreshTokenValidity()
      {
      this.getComprehensiveDropDown();
        this.selectedCareplan = null;
        this.viewplan = false;
       
        this.getClientQpEmployee();
        this.getClientRpContact();
        this.getClientcashmanagerdetails();
        this.getClientEmergencyContact();
        this.getClientLanguages();
      }
    }
    async getClientQpEmployee() {
      let params = new URLSearchParams();
      params.append("client", this.global.clientid.toString());
      await this.global.checkRefreshTokenValidity()
      {
      this.Service.GetQPEMployeeForClient(params).subscribe((data: any) => {
        this.QpFields = data.Value;
      }
    
      )
    }
  }
    //..................... For getting Comprehensive Care Plan Dropdown dates.....................
    async getComprehensiveDropDown() {
     
      await this.global.checkRefreshTokenValidity()
      {
        await this.global.showLoading();
        this.Service.getComprehensiveCareplanList(this.global.clientid).subscribe((data: any) => {
            this.CarePlansDates = data;
            if (data.length == 0) {
                this.global.Toastdanger("No Records Found");
            }
            this.global.disableloading();
            this.CarePlansDates?.forEach(element => {
                element.Value = this.datePipe.transform(element.Value, "MM-dd-yyyy")
            });
        }, err => {
            this.errorService.check(err);
            this.global.disableloading();
            if (err.Status == 401) {
                this.global.GloballogOut();
            }
        })
      }
    }
    onChange() {
      if (this.selectedCareplan != null) {
        this.getExistingComprehensiveCareplan();
      }
    }

    async getExistingComprehensiveCareplan() {
     
      let param = new URLSearchParams();
      param.append('CareplanId', this.selectedCareplan)
      param.append('ClientId', this.global.clientid.toString())
      await this.global.checkRefreshTokenValidity()
      {
        await this.global.showLoading();
      this.Service.getExistingComprehensiveCareplan(param).subscribe((data: any) => {
       
        this.getComprehensiveList = JSON.parse(JSON.stringify(data));
        this.CodeDescriptionList = data.diagnosisDetails;
        this.DosesFrequencyRouteList=data.dosageDetails ;
        if(data.phone1!=null && data.phone1!=undefined && data.phone1!='')
        {
          this.getComprehensiveList.phone1 = this.general.converPhoneGoogleLib(data.phone1); 
        }
        if(data.phone2!=null && data.phone2!=undefined && data.phone2!='')
        {
          this.getComprehensiveList.phone2 = this.general.converPhoneGoogleLib(data.phone2);    
        }
        if(data.providerPhone!=null && data.providerPhone!=undefined && data.providerPhone!='')
        {
          this.getComprehensiveList.providerPhone = this.general.converPhoneGoogleLib(data.providerPhone);  
        }
        if(data.providersPhone!=null && data.providersPhone!=undefined && data.providersPhone!='')
        {
          this.getComprehensiveList.providersPhone = this.general.converPhoneGoogleLib(data.providersPhone);  
        }

        if(data.providersFax!=null && data.providersFax!=undefined && data.providersFax!='')
        {
          this.getComprehensiveList.providersFax = this.general.converPhoneGoogleLib(data.providersFax);
        }
        if(data.locContactFax!=null && data.locContactFax!=undefined && data.locContactFax!='')
        {
          this.getComprehensiveList.locContactFax = this.general.converPhoneGoogleLib(data.locContactFax);
        }
        if(data.locContactPhone!=null && data.locContactPhone!=undefined && data.locContactPhone!='')
        {
          this.getComprehensiveList.locContactPhone = this.general.converPhoneGoogleLib(data.locContactPhone);
        }
          if(data.rpPhone!=null && data.rpPhone!=undefined && data.rpPhone!='')
        {
          this.getComprehensiveList.rPPhone = this.general.converPhoneGoogleLib(data.rpPhone);
        }

        if(data.physicianPhone!=null && data.physicianPhone!=undefined && data.physicianPhone!='')
        {
          this.getComprehensiveList.physicianPhone = this.general.converPhoneGoogleLib(data.physicianPhone);
        }

    
        this.getComprehensiveList.rPRelationship = data.rpRelationship;
        this.getComprehensiveList.rPAddress = data.rpAddress;

     
        this.ResponsiblePartysignimage = data.respPartySign;
        this.Qualifiedsignimage = data.nurseSign;
        this.Clientsignimage = data.clientSign;
        this.PCASignimage = data.pcaSign;

        this.getComprehensiveList.dresssingDetails = data?.dresssingDetails;
      this.getComprehensiveList.groomingDetails = data?.groomingDetails;
      this.getComprehensiveList.bathingDetails = data?.bathingDetails;
      this.getComprehensiveList.eatingDetails = data?.eatingDetails;
      this.getComprehensiveList.transferDetails = data?.transferDetails;
      this.getComprehensiveList.mobilityDetails = data?.mobilityDetails;
      this.getComprehensiveList.positioningDetails = data?.positioningDetails;
      this.getComprehensiveList.toiletingDetails = data?.toiletingDetails;
      this.getComprehensiveList.pCASignDate = data.pcaSignDate;


    
      this.getComprehensiveList.medicalAppsFrequency = data?.medicalAppsFrequency;
      if (data.medicalAppsFrequency != null) {
        this.getComprehensiveList.medicalAppsFrequency.eV = data.medicalAppsFrequency.ev == true ? true : false;;
        this.getComprehensiveList.medicalAppsFrequency.d = data.medicalAppsFrequency.d == true ? true : false;;
        this.getComprehensiveList.medicalAppsFrequency.w = data.medicalAppsFrequency.w == true ? true : false;;
        this.getComprehensiveList.medicalAppsFrequency.pRN = data.medicalAppsFrequency.prn == true ? true : false;
      }
      // 2
      this.getComprehensiveList.communityParticipationFrequency = data?.communityParticipationFrequency;
      if (data.communityParticipationFrequency != null) {
        this.getComprehensiveList.communityParticipationFrequency.eV = data.communityParticipationFrequency.ev == true ? true : false;;
        this.getComprehensiveList.communityParticipationFrequency.d = data.communityParticipationFrequency.d == true ? true : false;;
        this.getComprehensiveList.communityParticipationFrequency.w = data.communityParticipationFrequency.w == true ? true : false;;
        this.getComprehensiveList.communityParticipationFrequency.pRN = data.communityParticipationFrequency.prn == true ? true : false;
      }
      // 3
      this.getComprehensiveList.assistPayBillsFrequency = data?.assistPayBillsFrequency;
      if (data.assistPayBillsFrequency != null) {
        this.getComprehensiveList.assistPayBillsFrequency.eV = data.assistPayBillsFrequency.ev == true ? true : false;;
        this.getComprehensiveList.assistPayBillsFrequency.d = data.assistPayBillsFrequency.d == true ? true : false;;
        this.getComprehensiveList.assistPayBillsFrequency.w = data.assistPayBillsFrequency.w == true ? true : false;;
        this.getComprehensiveList.assistPayBillsFrequency.pRN = data.assistPayBillsFrequency.prn == true ? true : false;
      }
      // 4
      this.getComprehensiveList.phoneCommunicationFrequency = data?.phoneCommunicationFrequency;
      if (data.phoneCommunicationFrequency != null) {
        this.getComprehensiveList.phoneCommunicationFrequency.eV = data.phoneCommunicationFrequency.ev == true ? true : false;;
        this.getComprehensiveList.phoneCommunicationFrequency.d = data.phoneCommunicationFrequency.d == true ? true : false;;
        this.getComprehensiveList.phoneCommunicationFrequency.w = data.phoneCommunicationFrequency.w == true ? true : false;;
        this.getComprehensiveList.phoneCommunicationFrequency.pRN = data.phoneCommunicationFrequency.prn == true ? true : false;
      }
      // 5
      this.getComprehensiveList.mealPreparationFrequency = data?.mealPreparationFrequency;
      if (data.mealPreparationFrequency != null) {
        this.getComprehensiveList.mealPreparationFrequency.eV = data.mealPreparationFrequency.ev == true ? true : false;;
        this.getComprehensiveList.mealPreparationFrequency.d = data.mealPreparationFrequency.d == true ? true : false;;
        this.getComprehensiveList.mealPreparationFrequency.w = data.mealPreparationFrequency.w == true ? true : false;;
        this.getComprehensiveList.mealPreparationFrequency.pRN = data.mealPreparationFrequency.prn == true ? true : false;
      }
      // 6
      this.getComprehensiveList.medicationPickupFrequency = data?.medicationPickupFrequency;
      if (data.medicationPickupFrequency != null) {
        this.getComprehensiveList.medicationPickupFrequency.eV = data.medicationPickupFrequency.ev == true ? true : false;;
        this.getComprehensiveList.medicationPickupFrequency.d = data.medicationPickupFrequency.d == true ? true : false;;
        this.getComprehensiveList.medicationPickupFrequency.w = data.medicationPickupFrequency.w == true ? true : false;;
        this.getComprehensiveList.medicationPickupFrequency.pRN = data.medicationPickupFrequency.prn == true ? true : false;
      }
      // 7
      this.getComprehensiveList.groceryShoppingFrequency = data?.groceryShoppingFrequency;
      if (data.groceryShoppingFrequency != null) {
        this.getComprehensiveList.groceryShoppingFrequency.eV = data.groceryShoppingFrequency.ev == true ? true : false;;
        this.getComprehensiveList.groceryShoppingFrequency.d = data.groceryShoppingFrequency.d == true ? true : false;;
        this.getComprehensiveList.groceryShoppingFrequency.w = data.groceryShoppingFrequency.w == true ? true : false;;
        this.getComprehensiveList.groceryShoppingFrequency.pRN = data.groceryShoppingFrequency.prn == true ? true : false;
      }
      // 8
      this.getComprehensiveList.essentialShoppingFrequency = data?.essentialShoppingFrequency;
      if (data.essentialShoppingFrequency != null) {
        this.getComprehensiveList.essentialShoppingFrequency.eV = data.essentialShoppingFrequency.ev == true ? true : false;;
        this.getComprehensiveList.essentialShoppingFrequency.d = data.essentialShoppingFrequency.d == true ? true : false;;
        this.getComprehensiveList.essentialShoppingFrequency.w = data.essentialShoppingFrequency.w == true ? true : false;;
        this.getComprehensiveList.essentialShoppingFrequency.pRN = data.essentialShoppingFrequency.prn == true ? true : false;
      }
      // 9
      this.getComprehensiveList.iadLsOthersFrequency = data?.iadLsOthersFrequency;

      if (data.iadLsOthersFrequency != null) {
        this.getComprehensiveList.iadLsOthersFrequency.eV = data.iadLsOthersFrequency.ev == true ? true : false;;
        this.getComprehensiveList.iadLsOthersFrequency.d = data.iadLsOthersFrequency.d == true ? true : false;;
        this.getComprehensiveList.iadLsOthersFrequency.w = data.iadLsOthersFrequency.w == true ? true : false;;
        this.getComprehensiveList.iadLsOthersFrequency.pRN = data.iadLsOthersFrequency.prn == true ? true : false;
      }
        if (data.momVisit != null) {
          this.getComprehensiveList.mOMVisit = data.momVisit == true ? true : false;
        }
        if (data.momAudio != null) {
          this.getComprehensiveList.mOMAudio = data.momAudio == true ? true : false;
        }
        if (data.momOther != null) {
          this.getComprehensiveList.mOMOther = data.momOther == true ? true : false;
        }
       this.clearweightheight();
        this.viewplan = true;
        this.global.disableloading();
      }, err => {
        this.viewplan = false;
        this.errorService.check(err);
        this.global.disableloading();
        if (err.Status == 401) {
          this.global.GloballogOut();
        }
      })
    }
    }
    async getClientEmergencyContact() {
      let params = new URLSearchParams();
      params.append("clientId", this.global.clientid.toString());
      await this.global.checkRefreshTokenValidity()
      {
      this.Service.GetContactListNew(params).subscribe((data: any) => {
      
        data?.forEach(element => {
          element.phone = this.general.converPhoneGoogleLib(element?.phone);
         });
        this.EmergencyContactitems = data;
     
      
      },
      )
    }
    }
    async getClientLanguages() {
      let params = new URLSearchParams();
      params.append("clientId", this.global.clientid.toString());
      await this.global.checkRefreshTokenValidity()
      {
      this.Service.GetClientLanguageList(params).subscribe((data) => {
        this.LanguagesName = [];
        data.forEach(element => {
          this.LanguagesName.push(element.languageLidName);
        });
  
      },
  
      )
    }
    }
    //........................getClientRPContact...............................
    async getClientRpContact() {
      let params = new URLSearchParams();
      params.append("clientId", this.global.clientid.toString());
      await this.global.checkRefreshTokenValidity()
      {
      this.Service.GetContactList(params).subscribe((data: any) => {
        if (data.respParties != null) {
          this.RespPartiesList.name = data?.respParties?.name;
          this.RespPartiesList.relationship = data?.respParties?.relationship;
          this.RespPartiesList.address = data?.respParties?.address;
          if (data?.respParties?.phone != null) {
            this.RespPartiesList.phone =
              this.general.converPhoneGoogleLib(
                data?.respParties?.phone
              );
          } }
      },
    )
      }}

    async getClientcashmanagerdetails() {
      let params = new URLSearchParams();
      params.append("client", this.global.clientid.toString());
      await this.global.checkRefreshTokenValidity()
      {
      this.Service.GetCaseManagerSingledata(params).subscribe((data: any) => {
        if (data != null) {
          this.CaseManagerDetails.caseManagerName = data?.caseManagerName;
          this.CaseManagerDetails.county = data?.county;
          this.CaseManagerDetails.email = data?.email;
          this.CaseManagerDetails.telephone = this.general.converPhoneGoogleLib(data?.telephone);
        }
        },
        )
      }
    }
    dismiss() {
        this.selectedCareplan = null;
        this.viewplan = false;
        this.enabledcollapse=new CollapseBo();
        this.router.navigateByUrl('tabs/my-clients');
    }
    clearweightheight() {
      if (this.getComprehensiveList.weight == 0 || this.getComprehensiveList.weight == null || this.getComprehensiveList.weight == undefined) {
        this.getComprehensiveList.weight = null;
      }
      if (this.getComprehensiveList.height == 0 || this.getComprehensiveList.height == null || this.getComprehensiveList.height == undefined) {
        this.getComprehensiveList.height = null;
      }
    }
}
