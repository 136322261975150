import { Component, Input } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ErrorService } from 'src/app/error.service';
import { generalservice } from 'src/app/general.service';
import { Globalservice } from 'src/app/global.service';
import { ClientBO, Profile } from './client-profile.model';
import { ClientProfileService } from './client-profile.service';
@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.page.html',
  styleUrls: ['./client-profile.page.scss'],
})
export class ClientProfilePage {
  @Input('progressValue') progressValue;
  profileData:Profile=null;
  isEdit:boolean=false;
  ClientList: any = new ClientBO();
  getServiceData:any;
  constructor(public clientService: ClientProfileService,private toastCtrl: ToastController, public errorService: ErrorService,public global:Globalservice,public general:generalservice) {
  }

  async ionViewWillEnter() {
    this.isEdit = false;
    await this.global.checkRefreshTokenValidity()
    {
    this.getProfile();
    }

    
  }
 
 async getProfile() {
  await this.global.checkRefreshTokenValidity()
  {
    await this.global.showLoading();
    let param=new URLSearchParams();
    param.append("ClientId",this.global.EvvUserDetails.entityId.toString())
    this.clientService.getProfileClient(param).subscribe((data: any) => {
      this.profileData = data;
  
      this.profileData.mobileNumberFormat=this.profileData.mobileNumber?this.general.converPhoneGoogleLib(this.profileData.mobileNumber.toString()):null
      this.profileData.address=data.street!=null?data.street:""+" "+data.city!=null?data.city:""+" "+
       data.state!=null?data.state:""+" "+data.country!=null?data.country:""+" "+ data.zip!=null?data.zip:"";
       this.getServiceData= data.cAuthList;
       this.global.disableloading();
}, err => {
  this.global.disableloading();
      this.errorService.check(err);
      if(err.Status==401){
        this.global.GloballogOut();
      }
    })
  }
  }

  editProfile(){
    this.isEdit = true;
  }
  cancelEdit(){
    this.isEdit = false;
  }
  async updateProfile(ClientId){
    await this.global.checkRefreshTokenValidity()
    {
    this.ClientList.id=ClientId;
    this.ClientList.statusLid=this.profileData.status;
     
      this.ClientList.genderLid = Math.floor(Number(this.profileData.gender));
      this.ClientList.age = Math.floor(Number(this.profileData.age));
      this.ClientList.email = this.profileData.email != undefined ? this.profileData.email : null;
      this.ClientList.phone1 = this.profileData.mobileNumber;
      this.ClientList.firstName=this.profileData.fullname.split(' ').slice(0, -1).join('');
      this.ClientList.lastName=this.profileData.fullname.split(' ').slice(-1).join('');
      this.ClientList.street=this.profileData.street;
      this.ClientList.city=this.profileData.city;
      this.ClientList.state=this.profileData.state;
      this.ClientList.county=this.profileData.country;
      this.ClientList.zipCode=this.profileData.zip;
     this.clientService.UpdateProfile(this.ClientList).subscribe((data: ClientBO) => {
          this.isEdit = false;
         
          this.ClientList=data;
          this.profileData.fullname=this.ClientList.firstName+","+this.ClientList.lastName
          this.getProfile();
          this.presentToast('Profile Saved Successfully')
    }, err => {
      this.errorService.check(err);
      if(err.Status==401){
        this.global.GloballogOut();
      }
    }
        )}
  }
   async presentToast(msg) {
          let toast = await this.toastCtrl.create({
            message: msg,
            duration: 3000,
            position: 'top',
            color: 'success',});
  toast.present(); }

}