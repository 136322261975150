import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globalservice } from '../global.service';
@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage implements OnInit {
  logincredentials:any;
  constructor(public global:Globalservice,    public route: Router) {
    this.logincredentials=JSON.parse(localStorage.getItem("Evv_login"))
   this.global.checkingLogin();
  }
  ngOnInit(): void {
  }

}
