export interface Profile {
    employeeId:number;
    fullname:string;
    email:string;
    age:number;
    mobileNumber:number;
    mobileNumberFormat:string;
    address:string;
    street:string;
    city:string;
    state:string;
    zip:string;
    country:string;
    about:string;
    gender:number;
    status:number;
    apt:string;
}
export class EmployeeBO {
    id: number;
    agencyId: number;
    firstName: string;
    lastName: string;
    middleName: string;
    type: boolean;
    dOB: string;
    genderLid: number;
    statusLid: number;
    age: number | null;
    aKA: string;
    maritalStatusLid: number | null;
    street: string;
    email: string;
    phone1: string;
    phone2: string;
    steet: string;
    city: string;
    county: string;
    zipcode: string;
    name: string;
    username: string;
    password: string;
}