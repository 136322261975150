import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from 'src/app/error.service';
import { Globalservice } from 'src/app/global.service';
@Injectable({
    providedIn: 'root'
})
export class ClientActiveSessionService {
    constructor(public http: HttpClient, public errorService: ErrorService, public global: Globalservice) {
    }
      revokeSessions(revokeIds,refreshToken): Observable<number> {
        let url = "api/Auth/revokeSessions?";
        return this.http.post<number>(url+refreshToken, revokeIds, { observe: 'response' }).pipe(
          map(data => data.body),
          catchError(this.errorService.handleError)
        )
      }

      public getAllOpenSessions(refreshToken): Observable<any> {
        let url = "api/Auth/getAllOpenSessions?";
        return this.http.get(url+refreshToken).pipe(catchError(this.errorService.handleError));
    }


    logoutSession(logoutsessions): Observable<number> {
      let url = "api/Auth/logout"
      return this.http.post<number>(url, logoutsessions, { observe: 'response' }).pipe(
        map(data => data.body),
        catchError(this.errorService.handleError)
      )
    }
}








