import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Globalservice } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineGuard implements CanActivate {
  constructor(public global: Globalservice, private router: Router){
  }
  canActivate( next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):boolean {
      let stateValue = state.url;
    if (!this.global.isOffline && this.global.isOptional!=true){
      return true
    } else {
      if(this.global.isOptional)
      {
        this.router.navigate(['/login/app-maintenance']);
      }
      else if (stateValue == "/tabs/my-clients" || stateValue== "/tabs/all-timesheets" || stateValue == "/tabs/offline")
      {
      
      }
      else 
      {
        this.router.navigate(['/tabs/offline']);
      }
    }
  }
}
